import { getRemindersAPI } from "api/reminders";
import { useQuery, useQueryClient } from "react-query";

export const useFetchReminders = (date, options) => {
    const queryClient = useQueryClient()

    return useQuery(
        ["reminders"],
        async () => {
            const response = await getRemindersAPI(date);
            return response.data;
        },
        {
            ...options,
            onSuccess: (data) => {
                queryClient.setQueryData(["reminders"], data);
            },
        }
    );
};

const RemindersService = { useFetchReminders };

export default RemindersService;
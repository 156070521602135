import axios from "../lib/axios";

export const getLabResults = async () => {
    return await axios.post(`/users/me/lab_results`, {});
};

export const uploadDocumentLabResultsApi = async (params) => {

    return await axios.post(
        `/users/me/lab_results/upload/`,
        params,
        {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }
    );
};

export const deleteDocumenLabResultstApi = async (documentId) => {

    return await axios.post(
        `/users/me/lab_results/delete/${documentId}`,
        {},
        {
            headers: {
                "Content-Type": "application/json",
            },
        }
    );
};

export const getPatientLabResults = async ({ patient_id }) => {
    return await axios.post(`/users/${patient_id}/lab_results`, {});
};

import { useState } from "react";

// Components
import { SearchInput } from "components/Common/Form/Inputs";
import GradientButton from "components/Common/UI/GradientButton/GradientButton";
import DoctorNotifications from "./components/DoctorNotifications/DoctorNotifications";
import UserDropdown from "./components/UserDropdown/UserDropdown";
import LanguageBtn from "components/Common/UI/LanguageBtn/LanguageBtn";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./DoctorHeader.module.scss";

const DoctorHeader = () => {
	// Search
	const [searchQuery, setSearchQuery] = useState("");

	return (
		<div className={styles.doctor_header}>
			<SearchInput
				inputLabel=""
				inputName="search"
				placeHolder={Tr.tr("basic:Search")}
				inputVal={searchQuery}
				changeHandler={(e) => setSearchQuery(e.target.value)}
				classes={styles.header_search}
			/>
			<div className={styles.right}>
				{/* Add Button */}
				<GradientButton
					btn
					type="button"
					label={Tr.tr("basic:Add")}
					withIconImg
					icon="/img/add.svg"
					classes="p610 flex-shrink-0"
				/>

				{/* Notifications */}
				<DoctorNotifications />

				{/* User Dropdown */}
				<UserDropdown />

				{/* Language Button */}
				<LanguageBtn />
			</div>
		</div>
	);
};

export default DoctorHeader;

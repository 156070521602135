import React, { useRef, useEffect } from "react";

const OutsideClickHandler = ({ children, onOutsideClick }) => {
	const targetRef = useRef(null);

	useEffect(() => {
		const handleClickOutside = (event) => {
			// Check if the click is outside the target element
			if (targetRef.current && !targetRef.current.contains(event.target)) {
				onOutsideClick();
			}
		};

		// Attach the event listener to the document
		document.addEventListener("mousedown", handleClickOutside);

		// Cleanup: Remove the event listener on component unmount
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [onOutsideClick]);

	return <div ref={targetRef}>{children}</div>;
};

export default OutsideClickHandler;

import { Link } from "react-router-dom";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./Footer.module.scss";

const Footer = () => {
	return (
		<div className={styles.app_footer}>
			<div className={styles.footer_top}>
				<div className="container">
					<div className="row row-cols-2 row-cols-md-3 row-cols-lg-5">
						<div className="col mb-5 mb-lg-0">
							<div className={styles.footer_col}>
								<h5>{Tr.tr("patient:Footer:Tiryaaq")}</h5>
								<div className={styles.footer_links}>
									<Link to="">{Tr.tr("patient:Footer:About")}</Link>
									<Link to="">{Tr.tr("patient:Footer:Blog")}</Link>
									<Link to="">{Tr.tr("patient:Footer:Careers")}</Link>
									<Link to="">{Tr.tr("patient:Footer:Contact_Us")}</Link>
								</div>
							</div>
						</div>
						<div className="col mb-5 mb-lg-0">
							<div className={styles.footer_col}>
								<h5>{Tr.tr("patient:Footer:For_Patients")}</h5>
								<div className={styles.footer_links}>
									<Link to="/patient/find-a-doctor">
										{Tr.tr("patient:Footer:Find_Doctors")}
									</Link>
									<Link to="">{Tr.tr("patient:Footer:Video_Call")}</Link>
									<Link to="">{Tr.tr("patient:Footer:Consultation_Call")}</Link>
									<Link to="">{Tr.tr("patient:Footer:Lab_Tests")}</Link>
									<Link to="">{Tr.tr("patient:Footer:Surgeries")}</Link>
								</div>
							</div>
						</div>
						<div className="col mb-5 mb-lg-0">
							<div className={styles.footer_col}>
								<h5>{Tr.tr("patient:Footer:For_Doctor")}</h5>
								<div className={styles.footer_links}>
									<Link to="">{Tr.tr("patient:Footer:Tiryaaq_Profile")}</Link>
									<Link to="/doctor/login">
										{Tr.tr("patient:Footer:Login")}
									</Link>
									<Link to="">{Tr.tr("patient:Footer:Join_With_Us")}</Link>
								</div>
							</div>
						</div>
						<div className="col mb-5 mb-lg-0">
							<div className={styles.footer_col}>
								<h5>{Tr.tr("patient:Footer:For_Hospitals")}</h5>
								<div className={styles.footer_links}>
									<Link to="">{Tr.tr("patient:Footer:Tiryaaq_Profile")}</Link>
									<Link to="">{Tr.tr("patient:Footer:Login")}</Link>
									<Link to="">{Tr.tr("patient:Footer:Join_With_Us")}</Link>
								</div>
							</div>
						</div>
						<div className="col">
							<div className={styles.footer_col}>
								<h5>{Tr.tr("patient:Footer:More")}</h5>
								<div className={styles.footer_links}>
									<Link to="">{Tr.tr("patient:Footer:Privacy_Policy")}</Link>
									<Link to="">{Tr.tr("patient:Footer:Terms_Conditions")}</Link>
									<Link to="">{Tr.tr("patient:Footer:Help")}</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className={styles.footer_bottom}>
				<div className="container">
					<div className={styles.footer_bottom_content}>
						<div className={styles.footer_logo}>
							<img src="/img/footer-logo.svg" alt="" />
						</div>
						<div className={styles.copyRights}>
							© 2023 Tiryaaq. All rights reserved.
						</div>
						<div className={styles.social_links}>
							<Link to="">
								<img src="/img/facebook.svg" alt="" />
							</Link>
							<Link to="">
								<img src="/img/instagram.svg" alt="" />
							</Link>
							<Link to="">
								<img src="/img/linkedIn.svg" alt="" />
							</Link>
							<Link to="">
								<img src="/img/youtube.svg" alt="" />
							</Link>
							<Link to="">
								<img src="/img/twitter.svg" alt="" />
							</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Footer;

// Components
import GradientButton from "components/Common/UI/GradientButton/GradientButton";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./CheckSymptoms.module.scss";

const CheckSymptoms = () => {
	return (
		<section className={styles.check_symptoms}>
			<div className="container">
				<div className={styles.check_symptoms_content}>
					<div className={styles.left}>
						<img
							src="/img/symptoms.svg"
							alt=""
							className={styles.symptoms_img}
						/>
						<div className={styles.txt}>
							<h3>{Tr.tr("patient:Home:Check_Your_Symptoms")}</h3>
							<p>Lorem ipsum dolor sit amet consectetur. Neque blandit</p>
						</div>
					</div>
					<GradientButton
						label={Tr.tr("basic:Start")}
						withIconImg
						icon="/img/arrow-right.svg"
						rightPos
						arrowIcon
						redirectTo="/patient/check-symptoms?stage=1"
					/>
				</div>
			</div>
		</section>
	);
};

export default CheckSymptoms;

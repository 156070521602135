import { useEffect, useState } from "react";

// Components
import PageHead from "components/Patient/PageHead/PageHead";
import GradientButton from "components/Common/UI/GradientButton/GradientButton";
import {
    MultiSelectDropdown,
    SearchInput,
    SelectDropdown,
} from "components/Common/Form/Inputs";
import QuestionCard from "./components/QuestionCard/QuestionCard";
import AddQuestionDrawer from "./components/AddQuestionDrawer/AddQuestionDrawer";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./QuestionAnswer.module.scss";
import { useQuery } from "react-query";
import { getMyQuestionsAPI } from "api/questions";
import { specialtiesAPI } from "api/specialties";

const QuestionAnswer = () => {
    // Show Add Question Drawer
    const [visible, setVisible] = useState(false);
    const [specialtiesData, setSpecialtiesData] = useState([]);

    // Type
    const [selectedType, setSelectedType] = useState(null);
    const [filtersObj, setFiltersObj] = useState({
        page: 1,
        search: '',
        order: '',
        filters: { doctor_specialty_id: [] }
    });
    const types = [
        { label: "Latest questions", value: "Latest questions" },
        { label: "Answered", value: "Answered" },
    ];

    // Specialties
    // const [selectedSpecialties, setSelectedSpecialties] = useState(null);
    const { data: specialties, isLoading: specialtiesLoading } = useQuery("specialities", specialtiesAPI);

    // Search
    // const [searchQuery, setSearchQuery] = useState("");

    // Questions
    const { data: questions, isLoading: questionsLoading } = useQuery(
        ["questions", filtersObj],
        getMyQuestionsAPI,
        {
            select: (response) => {
                return response.data;
            },
        }
    );
    useEffect(() => {
        specialties?.data.length > 0 && specialties?.data.map((item) => { 
            setSpecialtiesData(prev => [...prev, { label: item.name, value: item.id }]) })
    }, [specialtiesLoading])
    return (
        <>
            <div className={styles.question_answer}>
                <PageHead
                    title={Tr.tr("patient:PatientProfile:Question_Answer")}
                    subTitle="This is a subtitle"
                    mb="24px">
                    <GradientButton
                        btn
                        type="button"
                        label={Tr.tr("patient:PatientProfile:Add_Question")}
                        withIconImg
                        icon="/img/add.svg"
                        classes="p610"
                        buttonClicked={() => {
                            setVisible(true);
                        }}
                    />
                </PageHead>

                {/* View By */}
                <div className={styles.view_by}>
                    <h5>{Tr.tr("patient:PatientProfile:View_By")}:</h5>
                    <div className={styles.view_by_filter}>
                        <div className="row">
                            <div className="col-md-6 col-xl-4 mb-3 mb-xl-0">
                                <SelectDropdown
                                    inputLabel=""
                                    inputName="type"
                                    placeHolder="Latest questions"
                                    options={types}
                                    inputVal={selectedType}
                                    changeHandler={(e) => setSelectedType(e.value)}
                                    appendTo="self"
                                />
                            </div>
                            <div className="col-md-6 col-xl-4 mb-3 mb-xl-0">
                                <MultiSelectDropdown
                                    inputLabel=""
                                    inputName="specialties"
                                    placeHolder="Specialties"
                                    options={specialtiesData}
                                    inputVal={filtersObj.filters.doctor_specialty_id}
                                    // changeHandler={(e) => setSelectedSpecialties(e.value)}
                                    changeHandler={(e) => setFiltersObj({ ...filtersObj, filters: { doctor_specialty_id: e.value } })}
                                    appendTo="self"
                                    filter={true}
                                />
                            </div>
                            <div className="col-md-6 col-xl-4 mb-3 mb-xl-0">
                                <SearchInput
                                    inputLabel=""
                                    inputName="search"
                                    placeHolder={Tr.tr("basic:Search")}
                                    inputVal={filtersObj.search}
                                    // changeHandler={(e) => setSearchQuery(e.target.value)}
                                    changeHandler={(e) => setFiltersObj({ ...filtersObj, search: e.target.value })}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Questions */}
                <div className={styles.qtys}>
                    <h2>
                        {questions?.number_of_items} {Tr.tr("patient:PatientProfile:Questions")}
                    </h2>
                    <div className={styles.qty_cards}>
                        {questions?.data?.map((qty) => (
                            <QuestionCard data={qty} key={qty.id} />
                        ))}
                    </div>
                </div>
            </div>

            {/* Add Question Drawer */}
            <AddQuestionDrawer
                visible={visible}
                hideHandler={() => {
                    setVisible(false);
                }}
            />
        </>
    );
};

export default QuestionAnswer;

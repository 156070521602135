import { useState } from "react";

// Components
import DoctorListCard from "components/Patient/DoctorListCard/DoctorListCard";
import Pagination from "components/Common/UI/Pagination/Pagination";
import FindDoctorLoadingSkeleton from "pages/Patient/FindDoctor/FindDoctorLoadingSkeleton";

// Query
import { useQuery } from "react-query";

// Api
import { doctorsAPI } from "api/doctors";

// Styles
import styles from "./DoctorsResults.module.scss";

const DoctorsResults = ({
	doctors,
	number_of_pages,
	number_of_products,
	doctorsLoading,
}) => {
	if (doctorsLoading) {
		// if (true) {
		return (
			<>
				<FindDoctorLoadingSkeleton />
				<FindDoctorLoadingSkeleton />
			</>
		);
	}

	// console.log(doctors);
	return (
		<div className={styles.doctors_results}>
			<div className={styles.doctors_holder}>
				{doctors &&
					doctors?.map((doctor, idx) => (
						<DoctorListCard data={doctor} key={doctor.id} idx={idx} />
					))}
			</div>
			<Pagination
				number_of_pages={number_of_pages}
				number_of_products={number_of_products}
			/>
		</div>
	);
};

export default DoctorsResults;

// Components
import PageHead from "components/Patient/PageHead/PageHead";
import Information from "./components/Information/Information";
import Vitals from "./components/Vitals/Vitals";
import LabResults from "./components/LabResults/LabResults";
import CurrentMedications from "./components/CurrentMedications/CurrentMedications";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./MedicalProfile.module.scss";

const MedicalProfile = () => {
	return (
		<div className={styles.medical_profile}>
			<PageHead
				title={Tr.tr("patient:PatientProfile:Medical_Profile")}
				subTitle="This is a subtitle"
				mb="32px"
			/>

			{/* Information */}
			<Information />

			{/* Vitals */}
			<Vitals />

			{/* Lab Results */}
			<LabResults />

			{/* Current Medications */}
			<CurrentMedications />
		</div>
	);
};

export default MedicalProfile;

import { useContext, useEffect, useState } from "react";
import { TabPanel, TabView } from "primereact/tabview";

// Components
import { CalendarInput, CustomDropdown } from "components/Common/Form/Inputs";
import EarningsReports from "./components/EarningReports/EarningsReports";
import AppointmentsReports from "./components/AppointmentsReports/AppointmentsReports";
import RatingsReports from "./components/RatingsReports/RatingsReports";

// Context
import { SidebarContext } from "context/DoctorSidebarContext";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./DoctorReports.module.scss";

const DoctorReports = () => {
	const { setSmallSize } = useContext(SidebarContext);

	// Doctors
	const doctors = [
		{
			label: "Dr. Amr Mostafa",
			value: "Dr. Amr Mostafa",
		},
		{
			label: "Dr. Ahmed Mostafa",
			value: "Dr. Ahmed Mostafa",
		},
	];

	const [selectedDoctor, setSelectedDoctor] = useState(doctors[0].value);

	// Date [From]
	const [fromDate, setFromDate] = useState("");

	// Date [To]
	const [toDate, setToDate] = useState("");

	useEffect(() => {
		setSmallSize(false);
	}, [setSmallSize]);

	// Tabview Header
	const headerTemplate = (options, grayIcon, gradientIcon) => {
		return (
			<button
				type="button"
				onClick={options.onClick}
				className={`${options.className} tab_btn`}>
				<img src={grayIcon} alt="" className="gray_icon" />
				<img src={gradientIcon} alt="" className="gradient_icon" />
				<span className="title">{options.titleElement}</span>
			</button>
		);
	};

	return (
		<div className={styles.doctor_reports}>
			<div className={styles.doctor_reports_head}>
				<div className={styles.left}>
					<h1>{Tr.tr("basic:Reports")}</h1>
					<CustomDropdown
						inputName="doctors"
						inputLabel=""
						inputVal={selectedDoctor}
						options={doctors}
						placeHolder={Tr.tr("basic:Select")}
						changeHandler={(e) => setSelectedDoctor(e.value)}
						dropdownIcon="/img/specialty-icon.svg"
					/>
				</div>
				<div className={styles.right}>
					<div className={styles.from_date}>
						<h5>{Tr.tr("basic:From")}</h5>
						<CalendarInput
							inputLabel=""
							inputName="from"
							placeHolder="1 Nov, 2023"
							inputVal={fromDate}
							changeHandler={(e) => setFromDate(e.target.value)}
							appendTo={document.body}
							showIcon={true}
							view="date"
							dateFormat="mm/dd/yy"
						/>
					</div>
					<div className={styles.to_date}>
						<h5>{Tr.tr("basic:To")}</h5>
						<CalendarInput
							inputLabel=""
							inputName="to"
							placeHolder="7 Nov, 2023"
							inputVal={toDate}
							changeHandler={(e) => setToDate(e.target.value)}
							appendTo={document.body}
							showIcon={true}
							view="date"
							dateFormat="mm/dd/yy"
						/>
					</div>
				</div>
			</div>

			<div className={styles.doctor_reports_body}>
				<div className={styles.reports_tabs}>
					<TabView>
						<TabPanel
							header={Tr.tr("doctor:Reports:Earnings")}
							headerTemplate={(options) =>
								headerTemplate(
									options,
									"/img/earnings-gray.svg",
									"/img/earnings-gradient.svg"
								)
							}>
							<EarningsReports />
						</TabPanel>
						<TabPanel
							header={Tr.tr("doctor:Reports:Appointments")}
							headerTemplate={(options) =>
								headerTemplate(
									options,
									"/img/time-gray.svg",
									"/img/time-gradient.svg"
								)
							}>
							<AppointmentsReports />
						</TabPanel>
						<TabPanel
							header={Tr.tr("doctor:Reports:Ratings")}
							headerTemplate={(options) =>
								headerTemplate(
									options,
									"/img/star-gray.svg",
									"/img/star-gradient.svg"
								)
							}>
							<RatingsReports />
						</TabPanel>
					</TabView>
				</div>
			</div>
		</div>
	);
};

export default DoctorReports;

import { useState } from "react";
import { Link } from "react-router-dom";

// Components
import GradientButton from "components/Common/UI/GradientButton/GradientButton";

// Translation
import { useTranslation } from "react-i18next";

// Styles
import styles from "./EventPopover.module.scss";

const formatDate = (isoDateString) => {
	const date = new Date(isoDateString);
	const options = {
		weekday: 'short',
		year: 'numeric',
		month: 'short',
		day: 'numeric',
		hour: 'numeric',
		minute: '2-digit',
		timeZoneName: 'short'
	};

	let formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
	const amIndex = formattedDate.indexOf('AM');
	const pmIndex = formattedDate.indexOf('PM');
	if (amIndex !== -1) {
		formattedDate = formattedDate.substring(0, amIndex + 2);
	} else if (pmIndex !== -1) {
		formattedDate = formattedDate.substring(0, pmIndex + 2);
	}

	return formattedDate;
}

const EventPopover = ({ event, userData, ...props }) => {

	const { i18n } = useTranslation();
	const currLang = i18n.language;

	const [done, setDone] = useState(false);

	const formattedDate = formatDate(userData.start);

	return (
		<>
			<div
				className={styles.event_popover}
				style={
					currLang === "ar"
						? {
							position: "absolute",
							top: event?.clientY - 150 || event?.jsEvent.clientY - 150,
							left: event?.clientX - 370 || event?.jsEvent.clientX - 350,
						}
						: {
							position: "absolute",
							top: event?.clientY - 150 || event?.jsEvent.clientY - 150,
							left: event?.clientX || event?.jsEvent.clientX,
						}
				}>
				<div className={styles.event_popover_header}>
					<img src="/img/check-circle-green.svg" alt="" />
					<p>Appointment is confirmed</p>
				</div>
				<div className={styles.event_popover_body}>
					<div className={styles.body_row}>
						<div className={styles.doctor_info}>
							<div className={styles.img}>
								<img src={userData.image || "/img/event-doctor.png"} alt="" />
							</div>
							<div className={styles.info}>
								<h3>{userData.title}</h3>
								<Link to={`/doctor/patients/${userData.id}`} className={styles.view_profile}>
									View Profile
								</Link>
							</div>
						</div>
						<GradientButton
							btn
							type="button"
							label="Add Records"
							classes="w-100 p712"
							buttonClicked={props.showRecordModal}
						/>
					</div>

					<div className={styles.body_row}>
						<div className={styles.info_row}>
							<img src="/img/call-gray.svg" alt="" />
							<span>{userData.phone}</span>
						</div>
						<div className={styles.info_row}>
							<img src="/img/mail-gray.svg" alt="" />
							<span>{userData.email}</span>
						</div>
					</div>

					<div className={`${styles.body_row} ${styles.booking_details}`}>
						<img src="/img/hospital-gray.svg" alt="" />
						<div className={styles.details}>
							<h3>{formattedDate}</h3>
							<p>{userData.location}</p>
						</div>
					</div>
				</div>

				<div className={styles.event_popover_footer}>
					{!done ? (
						<GradientButton
							btn
							type="button"
							label="Check-In"
							outlined
							classes="w-100 p712"
							buttonClicked={() => setDone(true)}
						/>
					) : (
						<GradientButton
							btn
							type="button"
							label="Done"
							outlined
							classes="w-100 p712"
							withIconImg
							icon="/img/check-gradient.svg"
						/>
					)}
					<a href={`${window.location.protocol}//${window.location.host}/doctor/prepareVideoCall/${userData.meetingId}`} target="_blank" rel="noopener noreferrer" className="w-100">
						<GradientButton
							btn
							type="button"
							label="Join"
							classes="w-100 p712"
						/>
					</a>
				</div>
			</div>
		</>
	);
};

export default EventPopover;

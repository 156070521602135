// Components
import GradientButton from "components/Common/UI/GradientButton/GradientButton";
import Rate from "components/Common/UI/Rate/Rate";

// Utils
import { stripHtmlUsingDOMParser } from "utils/stripHtmlUsingDOMParser";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./DoctorListCard.module.scss";

const DoctorListCard = ({ data, idx }) => {
	return (
		<div className={styles.doctorList_card} id={idx} key={idx}>
			<div className={styles.left}>
				<div className={styles.doctor_img}>
					<img src={data.image || "/img/doctor-img.png"} alt="" />
				</div>
				<GradientButton
					label={Tr.tr("basic:View_Profile")}
					outlined
					classes="w-100"
					redirectTo={`/patient/find-a-doctor/${data.id}`}
				/>
			</div>
			<div className={styles.middle}>
				<div className={styles.name}>
					{data.title?.name} {data.name}
				</div>
				<div className={styles.speciality}>
					{"Professor of "}
					{data.doctor_specialty_ids.map((spec) => spec.name).join(", ")}
				</div>

				<div className={styles.rate_reviews}>
					<Rate rateVal={data.rating?.avg} readOnly={true} />
					<div className={styles.reviews}>
						{Tr.tr("patient:FindADoctor:Reviews")}
					</div>
				</div>
				<div className={styles.info_list}>
					<div className={styles.info_row}>
						<img src="/img/specialty-icon.svg" alt="" />
						<span>
							{data?.description
								? stripHtmlUsingDOMParser(data.description)
								: "Analysis"}
						</span>
					</div>
					<div className={styles.info_row}>
						<img src="/img/place-icon.svg" alt="" />
						<span>
							{data.country_id?.name}, {data.state_id?.name}
						</span>
					</div>
					<div className={styles.info_row}>
						<img src="/img/language-gray.svg" alt="" />
						<span>{data.lang_ids.map((lang) => lang.name).join(", ")}</span>
					</div>
					<div className={styles.info_row}>
						<img src="/img/time.svg" alt="" />
						<span>Waiting Time : {data.waiting_time} minutes</span>
					</div>
					<div className={styles.info_row}>
						<img src="/img/dollar.svg" alt="" />
						<span>Fees: {data.clinic_fees}</span>
					</div>
				</div>
			</div>
			<div className={styles.right}>
				<GradientButton
					type="button"
					label={Tr.tr("patient:FindADoctor:Book_Now")}
					classes="w-100"
					redirectTo={`/patient/find-a-doctor/${data.id}`}
				/>
				{/* <GradientButton
					btn
					label={Tr.tr("patient:FindADoctor:Call_Now")}
					withIconImg
					icon="/img/call.svg"
					outlined
					classes="w-100"
				/> */}
			</div>
		</div>
	);
};

export default DoctorListCard;

import { useRef, useState } from "react";
import { Menu } from "primereact/menu";
import { Button } from "primereact/button";

// Components
import PageHead from "components/Patient/PageHead/PageHead";
import GradientButton from "components/Common/UI/GradientButton/GradientButton";
import PaymentCardModal from "./components/PaymentCardModal/PaymentCardModal";
import BankAccountModal from "./components/BankAccountModal/BankAccountModal";
import PaypalModal from "./components/PaypalModal/PaypalModal";

// Translation
import { useTranslation } from "react-i18next";

// Styles
import styles from "./BillingInformation.module.scss";

const BillingInformation = () => {
	const { i18n } = useTranslation();
	const currLang = i18n.language;

	// Payment Card Modal Visible
	const [paymentCardModalVisible, setPaymentCardModalVisible] = useState(false);

	// Bank Account Modal Visible
	const [bankAccountModalVisible, setBankAccountModalVisible] = useState(false);

	// Paypal Modal Visible
	const [paypalModalVisible, setPaypalModalVisible] = useState(false);

	// Billing Method Dropdown
	const billingMethodMenu = useRef(null);

	const billingMethodItems = [
		{
			template: (item, options) => {
				return (
					<Button
						onClick={(e) => {
							options.onClick(e);
							setPaymentCardModalVisible(true);
						}}
						className={`${options.className}`}>
						<img src="/img/credit-card-gray.svg" alt="" />
						<span className="lbl">Payment card</span>
					</Button>
				);
			},
		},
		{
			template: (item, options) => {
				return (
					<Button
						onClick={(e) => {
							options.onClick(e);
							setBankAccountModalVisible(true);
						}}
						className={`${options.className}`}>
						<img src="/img/bank-gray.svg" alt="" />
						<span className="lbl">Bank Account</span>
					</Button>
				);
			},
		},
		{
			template: (item, options) => {
				return (
					<Button
						onClick={(e) => {
							options.onClick(e);
							setPaypalModalVisible(true);
						}}
						className={`${options.className}`}>
						<img src="/img/Paypal.svg" alt="" />
						<span className="lbl">Paypal</span>
					</Button>
				);
			},
		},
	];

	// Action Items
	const menu = useRef(null);

	const items = [
		{
			template: (item, options) => {
				return (
					<Button
						onClick={(e) => options.onClick(e)}
						className={`${options.className}`}>
						<img src="/img/edit-gray.svg" alt="" />
						<span>Edit</span>
					</Button>
				);
			},
		},
		{
			template: (item, options) => {
				return (
					<Button
						onClick={(e) => options.onClick(e)}
						className={`${options.className}`}>
						<img src="/img/delete-gray.svg" alt="" />
						<span>Delete</span>
					</Button>
				);
			},
		},
	];

	return (
		<>
			<div className={styles.billing_information}>
				<PageHead
					title="Manage billing methods"
					subTitle="Add, update, or remove your billing methods"
					mb="32px"
					titleSize="16px">
					<Menu
						model={billingMethodItems}
						popup
						ref={billingMethodMenu}
						id="popup_menu"
						className={styles.billing_menu}
						popupAlignment={currLang === "ar" ? "left" : "right"}
					/>
					<GradientButton
						btn
						type="button"
						label="Add Billing Method"
						withIconImg
						icon="/img/arrow-drop-down-white.svg"
						classes="p712"
						rightPos
						buttonClicked={(event) => billingMethodMenu.current.toggle(event)}
					/>
				</PageHead>

				<div className={styles.billing_rows}>
					<div className={styles.billing_row}>
						<div className={styles.billing_info}>
							<div className={styles.icon}>
								<img src="/img/Paypal.svg" alt="" />
							</div>
							<div className={styles.text}>
								<h5>PayPal</h5>
								<p>ahmedali@tiryaaq.com</p>
							</div>
						</div>
						<div className={styles.row_action}>
							<Menu
								model={items}
								popup
								ref={menu}
								id="popup_menu"
								popupAlignment={currLang === "ar" ? "left" : "right"}
								className={styles.action_menu}
							/>
							<Button
								icon="pi pi-ellipsis-v"
								onClick={(event) => menu.current.toggle(event)}
								aria-controls="popup_menu"
								aria-haspopup
								className={styles.action_btn}
							/>
						</div>
					</div>
				</div>
			</div>

			{/* Payment Card Modal */}
			<PaymentCardModal
				visible={paymentCardModalVisible}
				hideHandler={() => setPaymentCardModalVisible(false)}
			/>

			{/* Bank Account Modal */}
			<BankAccountModal
				visible={bankAccountModalVisible}
				hideHandler={() => setBankAccountModalVisible(false)}
			/>

			{/* Paypal Modal */}
			<PaypalModal
				visible={paypalModalVisible}
				hideHandler={() => setPaypalModalVisible(false)}
			/>
		</>
	);
};

export default BillingInformation;

import { Button } from "primereact/button";
import { Link } from "react-router-dom";

// Styles
import styles from "./GrayButton.module.scss";

const GrayButton = ({ ...props }) => {
	return props.btn ? (
		<Button
			type={props.type}
			label={props.label}
			className={`${styles.gray_btn} ${
				props.outlined ? styles.outlinedBtn : ""
			} ${props.leftPos ? styles.leftPos : styles.rightPos} ${props.classes}`}
			onClick={props.buttonClicked}
			disabled={props.disabled}
			loading={props.isLoading}>
			{props.withIconImg && <img src={props.icon} alt="" />}
			{props.withIcon && <i className={props.icon}></i>}
		</Button>
	) : (
		<Link
			to={props.redirectTo}
			className={`${styles.gray_btn} ${
				props.outlined ? styles.outlinedBtn : ""
			} ${props.leftPos ? styles.leftPos : styles.rightPos} ${props.classes}`}>
			{props.withIconImg && <img src={props.icon} alt="" />}
			{props.withIcon && <i className={props.icon}></i>}
			{props.label && <span>{props.label}</span>}
		</Link>
	);
};

export default GrayButton;

import { useState } from "react";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./AboutTheDoctor.module.scss";

const AboutTheDoctor = ({ data, ...props }) => {
	const [htmlString, setHtmlString] = useState(
		data?.description !== "False" ? data?.description : ""
	);

	return (
		<div className={styles.about_doctor}>
			<h3>{Tr.tr("patient:DoctorDetails:About_The_Doctor")}</h3>
			<div dangerouslySetInnerHTML={{ __html: htmlString }} />
		</div>
	);
};

export default AboutTheDoctor;

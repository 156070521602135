import { useEffect, useState } from "react";

// Components
import WhiteBlackButton from "components/Common/UI/WhiteBlackButton/WhiteBlackButton";
import GradientButton from "components/Common/UI/GradientButton/GradientButton";
import AddPaymentModal from "../AddPaymentModal/AddPaymentModal";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./PaymentMethods.module.scss";
import toast from "react-hot-toast";
import { useMutation, } from "react-query";
import { deletePaymentMethod, } from "api/payment";

const PaymentItem = ({ m, refetch }) => {
    const { mutate, isLoading, } = useMutation(deletePaymentMethod, {
        onError: (error) => { console.log({ error }) },
        onSuccess: (res) => {
            console.log({ res });
            refetch()
            toast.success("deleted successfully")
        }
    })


    return (
        <>
            <div className={styles.payment_method} key={m.id}>
                <div className={styles.info}>
                    <img src={"/img/master-card.svg"} alt="" />
                    <div className={styles.txt}>
                        <h5>Ending in  {m.display_name}</h5>
                        {/* <p>Expiry {m.expireAt}</p> */}
                    </div>
                </div>
                <div className={styles.actions}>
                    <WhiteBlackButton
                        btn
                        type="button"
                        label={Tr.tr("basic:Delete")}
                        classes="p610"
                        isLoading={isLoading}
                        buttonClicked={() => mutate({ id: m?.id })}
                    />
                    <GradientButton
                        btn
                        type="button"
                        label={Tr.tr("basic:Edit")}
                        classes="p610"
                    />
                </div>
            </div>
        </>
    );
};

export default PaymentItem;

import { useFormik } from "formik";
import { useMutation } from "react-query";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

// Components
import PageHead from "components/Patient/PageHead/PageHead";
import { PasswordInput } from "components/Common/Form/Inputs";
import GradientButton from "components/Common/UI/GradientButton/GradientButton";

// Utils
import { getFormErrorMessage } from "utils/FormField";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./PasswordTab.module.scss";

import { changePasswordSettingsAPI } from "api/auth";

const PasswordTab = () => {
	const navigate = useNavigate();

	const { mutate: updatePassword } = useMutation(changePasswordSettingsAPI, {
		onSuccess: (data) => {
			toast.success(data.message);
			localStorage.clear();
			navigate("/doctor/login");
		},
		onError: (error) => {
			toast.error(Tr.tr("doctor:Settings:Current_Password_Is_Incorrect"));
		},
	});

	// Formik
	const formik = useFormik({
		initialValues: {
			currentPassword: "",
			newPassword: "",
			confirmNewPassword: "",
		},
		validate: (data) => {
			let errors = {};

			if (!data.currentPassword) {
				errors.currentPassword = Tr.tr(
					"doctor:Settings:Current_Password_Is_Required"
				);
			}

			if (!data.newPassword) {
				errors.newPassword = Tr.tr("doctor:Settings:New_Password_Is_Required");
			}

			if (
				!data.confirmNewPassword ||
				data.confirmNewPassword !== data.newPassword
			) {
				errors.confirmNewPassword = Tr.tr(
					"doctor:Settings:Passwords_Must_Match"
				);
			}

			return errors;
		},
		onSubmit: async (data, { setSubmitting }) => {
			// console.log("submitted");
			// setTimeout(() => {
			// 	setSubmitting(false);
			// 	formik.resetForm();
			// }, 2000);
			// 			"current_password": "string",
			//   "new_password": "string"
			const payload = {
				current_password: data.currentPassword,
				new_password: data.newPassword,
			};
			// console.log(payload);
			await updatePassword(payload);
		},
	});

	return (
		<div className={styles.password_tab}>
			<PageHead
				title={Tr.tr("doctor:Settings:Password")}
				subTitle={Tr.tr("doctor:Settings:Change_Password_For_Account")}
				mb="32px"
				titleSize="16px"
			/>

			<form className={styles.form_holder} onSubmit={formik.handleSubmit}>
				<div className={styles.inputs_wrapper}>
					<div className="row">
						<div className="col-lg-6">
							<div className="row">
								{/* Current Password */}
								<div className="col-md-12 m-b-20">
									<PasswordInput
										inputLabel={Tr.tr("doctor:Settings:Current_Password")}
										inputName="currentPassword"
										placeHolder={Tr.tr("doctor:Settings:Current_Password")}
										inputVal={formik.values.currentPassword}
										changeHandler={formik.handleChange}
										inputError={getFormErrorMessage("currentPassword", formik)}
									/>
								</div>

								{/* New Password */}
								<div className="col-md-12 m-b-20">
									<PasswordInput
										inputLabel={Tr.tr("doctor:Settings:New_Password")}
										inputName="newPassword"
										placeHolder={Tr.tr("doctor:Settings:New_Password")}
										inputVal={formik.values.newPassword}
										changeHandler={formik.handleChange}
										inputError={getFormErrorMessage("newPassword", formik)}
										handleBlur={formik.handleBlur}
									/>
									<div className={styles.hint}>
										{Tr.tr(
											"doctor:Settings:Password_Must_Be_Characters_Longer",
											{ num: 8 }
										)}
										.
									</div>
								</div>

								{/* Confirm New Password */}
								<div className="col-md-12">
									<PasswordInput
										inputLabel={Tr.tr("doctor:Settings:Confirm_New_Password")}
										inputName="confirmNewPassword"
										placeHolder={Tr.tr("doctor:Settings:Confirm_New_Password")}
										inputVal={formik.values.confirmNewPassword}
										changeHandler={formik.handleChange}
										inputError={getFormErrorMessage(
											"confirmNewPassword",
											formik
										)}
										handleBlur={formik.handleBlur}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>

				<GradientButton
					btn
					type="submit"
					label={Tr.tr("doctor:Settings:Save_Changes")}
					classes="p712"
				/>
			</form>
		</div>
	);
};

export default PasswordTab;

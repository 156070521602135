// Components
import MedicationCard from "components/Patient/MedicationCard/MedicationCard";

// Styles
import styles from "./MedicationsContent.module.scss";
import { useQuery } from "react-query";
import { medicinesAPI } from "api/medicines";
import moment from "moment";

const MedicationsContent = () => {
    const { data: medications, isLoading: medicinesLoading, refetch } = useQuery(
        ["medications"],
        medicinesAPI,
        {
            select: (response) => {
                return response.data.data.map(medic => ({
                    ...medic,
                    icon: "/img/tablet.svg",
                    dosage: `${medic.dosages.length} times: ${medic.dosages.map(dos => `${dos} `)}`,
                    days: medic.days.map(day => (`${day.charAt(0).toUpperCase() + day.slice(1)} `)),
                    start: moment(medic.start_date).format('ll'),
                    end: moment(medic.start_date).add(medic.duration, 'M').format('ll'),
                }));
            },
        }
    );

    return (
        <div className={styles.medications_content}>
            {medications && medications.map((medication) => (
                <MedicationCard data={medication} key={medication.id} />
            ))}
        </div>
    );
};

export default MedicationsContent;

import { useState } from "react";
import { useFormik } from "formik";
import { Button } from "primereact/button";

// Components
import GradientButton from "components/Common/UI/GradientButton/GradientButton";
import { SearchInput } from "components/Common/Form/Inputs";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./Symptoms.module.scss";

const Symptoms = ({ ...props }) => {
	// Search
	const [searchQuery, setSearchQuery] = useState("");

	// Search Keywords
	const [keywords, setKeywords] = useState([
		{
			id: 1,
			label: "Pain in one knee",
		},
		{
			id: 2,
			label: "Pain in lower limb",
		},
	]);

	// Remove Keyword Handler
	const removeKeyword = (id) => {
		let filteredKeywords = keywords.filter((keyword) => keyword.id !== id);
		setKeywords(filteredKeywords);
	};

	// Formik
	const formik = useFormik({
		initialValues: {},
		validate: (data) => {
			let errors = {};

			return errors;
		},
		onSubmit: (data, { setSubmitting }) => {
			console.log("submitted");
			props.onNex();
		},
	});

	return (
		<form className={styles.step_content} onSubmit={formik.handleSubmit}>
			<div className={styles.top_content}>
				<div className={styles.symptoms_head}>
					<h2>Add your symptoms</h2>
					<p>Add symptoms to get the most accurate results.</p>
				</div>

				<div className={styles.symptoms_body}>
					<div className="row">
						<div className="col-md-10 col-lg-8 col-xl-6">
							<SearchInput
								inputLabel=""
								inputName="search"
								placeHolder="Search, e.g., headache"
								inputVal={searchQuery}
								changeHandler={(e) => setSearchQuery(e.target.value)}
							/>
						</div>

						{keywords.length > 0 && (
							<div className="col-md-12">
								<div className={styles.search_keywords}>
									{keywords.map((keyword) => (
										<div className={styles.keyword_box} key={keyword.id}>
											<span className={styles.keyword_label}>
												{keyword.label}
											</span>
											<Button
												type="button"
												className={styles.remove_btn}
												onClick={() => removeKeyword(keyword.id)}>
												<img src="/img/close-white.svg" alt="" />
											</Button>
										</div>
									))}
								</div>
							</div>
						)}
					</div>
				</div>
			</div>

			{/* Actions */}
			<div className={styles.step_actions}>
				{/* Back */}
				<GradientButton
					btn
					type="button"
					label={Tr.tr("basic:Back")}
					withIconImg
					icon="/img/chevron-left-gradient.svg"
					outlined
					classes={`p712 ${styles.backBtn}`}
					buttonClicked={() => {
						props.onPrevious();
					}}
				/>

				{/* Next */}
				<GradientButton
					btn
					type="submit"
					label={Tr.tr("basic:Next")}
					withIconImg
					icon="/img/chevron-right-white.svg"
					rightPos
					classes={`p712 ${styles.nextBtn}`}
				/>
			</div>
		</form>
	);
};

export default Symptoms;

import { useState } from "react";
import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";

// Components
import GradientButton from "components/Common/UI/GradientButton/GradientButton";
import GrayButton from "components/Common/UI/GrayButton/GrayButton";
import { SelectDropdown } from "components/Common/Form/Inputs";
import ProgressBarWrapper from "components/Common/UI/ProgressBarWrapper/ProgressBarWrapper";
import { toast } from "react-hot-toast";

// Translation
import { useTranslation, withTranslation } from "react-i18next";
import { Tr } from "utils/i18n";

// Services
import DoctorUploadDocumentService from "features/doctors/uploadDocumentService";

// Styles
import styles from "./UploadDocumentDrawer.module.scss";

const UploadDocumentDrawer = ({ ...props }) => {
	const { i18n } = useTranslation();
	const currLang = i18n.language;

	const { mutate: uploadDocument } =
		DoctorUploadDocumentService.useUploadDoctorDocument();
	const { mutate: deleteDocument } =
		DoctorUploadDocumentService.useDeleteDoctorDocument();

	const [uploadProgress, setUploadProgress] = useState(0);
	const [selectedDocumentType, setSelectedDocumentType] = useState("");
	const [uploadedDocument, setUploadedDocument] = useState(null);

	// Document Types
	const documentTypes = [
		{ label: "ID Document", value: "id_document" },
		{ label: "Residence Certificate", value: "residence_certificate" },
	];

	// Upload File Handler
	const handleFileChange = (event, type) => {
		if (event.target.files && event.target.files[0]) {
			const file = event.target.files[0];
			if (!selectedDocumentType) {
				toast.error("Please select a document type first.");
				return;
			}
			setUploadProgress(0);

			setTimeout(() => setUploadProgress(20), 100);
			setTimeout(() => setUploadProgress(80), 200);
			const formData = new FormData();
			formData.append("docs", file);
			formData.append("document_type", selectedDocumentType);

			uploadDocument(formData, {
				onSuccess: (data) => {
					setUploadedDocument(data.data);
					setUploadProgress(100);
					toast.success("Document uploaded successfully!");
				},
				onError: (error) => {
					console.error(error);
					setUploadProgress(0);
					toast.error("Failed to upload document.");
				},
			});
		}
	};

	const handleDelete = (documentId) => {
		deleteDocument(documentId, {
			onSuccess: () => {
				setUploadedDocument(null);
				setUploadProgress(0);
				toast.success("Document deleted successfully!");
			},
		});
	};

	const isImage = (mimetype) => {
		if (mimetype?.includes("image")) {
			return true;
		}
		return false;
	};

	return (
		<Sidebar
			visible={props.visible}
			position={currLang === "ar" ? "left" : "right"}
			onHide={props.hideHandler}
			className={styles.add_drawer_holder}
			maskClassName={styles.add_drawer}
			blockScroll={true}>
			<div className={styles.drawer_header}>
				<h2>{Tr.tr("doctor:Settings:Upload_Document")}</h2>
				<Button
					icon="pi pi-times"
					className={styles.close_btn}
					onClick={props.hideHandler}></Button>
			</div>
			<div className={styles.drawer_content}>
				{/* Document Type */}
				<div className="col-md-12 mb-4">
					<SelectDropdown
						inputLabel={Tr.tr("doctor:Settings:Document_Type")}
						inputName="documentType"
						placeHolder={Tr.tr("doctor:Settings:Select_Type")}
						options={documentTypes}
						inputVal={selectedDocumentType}
						changeHandler={(e) => {
							setSelectedDocumentType(e.value);
							setUploadedDocument(null);
							setUploadProgress(0);
						}}
						appendTo="self"
					/>
				</div>

				{/* Upload */}
				<section className={styles.upload_sec}>
					<div className={styles.upload_head}>
						<h5>{Tr.tr("doctor:Settings:Upload")}</h5>
						<p>PDF, DOC, JPG, PNG</p>
					</div>

					{/* Upload Action */}
					{!uploadedDocument && (
						<label className={styles.upload_action}>
							<input type="file" onChange={handleFileChange} />
							<img
								src="/img/upload-gray.svg"
								alt=""
								className={styles.upload_icon}
							/>
							<div className={styles.txt}>
								<h5>{Tr.tr("doctor:Settings:Click_Upload_Drag_Drop")}</h5>
								<p>PDF, DOC, JPG, PNG</p>
							</div>
						</label>
					)}

					{/* Uploaded Files */}
					{uploadedDocument && (
						<div className={styles.uploaded_files}>
							<div className={styles.uploaded_file}>
								<div className={styles.file_data}>
									<div className={styles.file_info}>
										<img
											src={
												isImage(uploadedDocument?.file?.mimetype)
													? uploadedDocument.file.url
													: "/img/pdf.svg"
											}
											alt=""
											className={styles.file_icon}
										/>

										<div className={styles.txt}>
											<h4>{uploadedDocument?.file?.name}</h4>
											<p>
												{(
													uploadedDocument?.file?.file_size /
													1024 /
													1024
												).toFixed(2)}{" "}
												MB
											</p>
										</div>
									</div>
									<Button
										type="button"
										text
										className={styles.delete_btn}
										onClick={() => handleDelete(uploadedDocument.id)}>
										<img src="/img/delete-red.svg" alt="" />
									</Button>
								</div>
							</div>
						</div>
					)}
					<ProgressBarWrapper
						value={uploadProgress}
						showStatus={false}
						showVal={false}
					/>
				</section>
			</div>

			<div className={styles.drawer_footer}>
				{/* <GrayButton btn type="button" label="Discard" outlined classes="p712" /> */}
				<GrayButton
					btn
					type="button"
					label={Tr.tr("doctor:Settings:Close")}
					outlined
					classes="p712"
					buttonClicked={props.hideHandler}
				/>
				<GradientButton
					btn
					type="submit"
					label={Tr.tr("doctor:Settings:Reset_Upload")}
					classes={`p712 ${styles.done_btn}`}
					buttonClicked={() => {
						setUploadedDocument(null);
						setUploadProgress(0);
					}}
				/>
			</div>
		</Sidebar>
	);
};

export default withTranslation()(UploadDocumentDrawer);

import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import Cookies from "js-cookie";

export const useErrorHandling = () => {
	const navigate = useNavigate();

	const handleErrors = (err) => {
		if (err.response?.status === 401) {
			console.log(err);
			const userType = Cookies.get("user_type");
			localStorage.clear();
			Cookies.remove("session_id");
			const message = err.response?.data?.message
				? err.response.data.message
				: err.message;
			toast.error(message);
			if (userType === "patient") {
				navigate("/patient/login");
			} else {
				navigate("/doctor/login");
			}
		} else {
			const message = err.response?.data?.message
				? err.response.data.message
				: err.message;
			console.log(err);
			toast.error(message);
		}
	};

	return handleErrors;
};

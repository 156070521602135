import { useContext, useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useNavigate } from "react-router-dom";

// Components
import { SearchInput } from "components/Common/Form/Inputs";
import FilterPatients from "components/Doctor/FilterPatients/FilterPatients";
import GradientButton from "components/Common/UI/GradientButton/GradientButton";
import AddPatientDrawer from "./components/AddPatientDrawer/AddPatientDrawer";

// Context
import { SidebarContext } from "context/DoctorSidebarContext";

// JSON
import patientsData from "json/patientsData.json";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./DoctorPatients.module.scss";
import { getDoctorPatientsAPI } from "api/doctors";
import { useQuery } from "react-query";
import { getMeApi } from "api/auth";

const DoctorPatients = () => {
    const { setSmallSize } = useContext(SidebarContext);

    // Navigation
    const navigate = useNavigate();

    // Search
    const [searchQuery, setSearchQuery] = useState("");

    // Patients Table
    const [patients, setPatients] = useState({});

    // [Table] Name Cell Template
    const nameBodyTemplate = (rowData) => {
        return (
            <div className={styles.patient_info}>
                <div className={styles.patient_img}>
                    <img src={rowData.image} alt="" />
                </div>
                <div className={styles.data}>
                    <h5>{rowData.name}</h5>
                    <p>Last updated: {rowData.lastUpdated}</p>
                </div>
            </div>
        );
    };

    const {
        data: userData,
        isLoading: isUserDataLoading,
        isError: isUserDataError,
    } = useQuery("userData", getMeApi);

    const userId = userData?.data?.id;

    const {
        data,
        isLoading: isPatientLoading,
        isError,
    } = useQuery(["patients", userId],
        () => getDoctorPatientsAPI({ doctor_id: userId }),
        {
            onSuccess: (data) => {
                // console.log(data.data, '=====> test');
                setPatients(data?.data);
            },
            enabled: !!userId,
        }
    );
    // Show Add Patient Drawer
    const [addPatientVisible, setAddPatientVisible] = useState(false);

    useEffect(() => {
        setSmallSize(true);

        // Simulate importing data from a JSON file
        // setPatients(patientsData);
        setPatients(data?.data);
    }, [setSmallSize, isPatientLoading]);

    // On Row Clicked
    const onRowSelect = (event) => {
        // console.log(event.data.id, "pp");
        navigate(`/doctor/patients/${event.data.id}`);
    };

    return (
        <>
            <div className={styles.doctor_patients}>
                <div className={styles.doctor_patients_head}>
                    <div className={styles.title}>
                        <h1>{Tr.tr("basic:Patients")}</h1>
                        <p>{patients?.data?.length} {Tr.tr("doctor:Patients:Patients")}</p>
                    </div>
                    <div className={styles.actions}>
                        {/* Search */}
                        <SearchInput
                            inputLabel=""
                            inputName="search"
                            placeHolder={Tr.tr("doctor:Patients:Search_Patients")}
                            inputVal={searchQuery}
                            changeHandler={(e) => setSearchQuery(e.target.value)}
                        />

                        {/* Filter */}
                        <FilterPatients />

                        {/* Add Patient */}
                        <GradientButton
                            btn
                            type="button"
                            label={Tr.tr("basic:Add_Patient")}
                            withIconImg
                            icon="/img/add.svg"
                            classes="p712"
                            buttonClicked={() => setAddPatientVisible(true)}
                        />
                    </div>
                </div>

                {/* Patients Table */}
                <div className={styles.patients_table}>
                    <DataTable
                        value={patients?.data || []}
                        tableStyle={{ minWidth: "50rem" }}
                        selectionMode="single"
                        onRowSelect={onRowSelect}>
                        <Column header={Tr.tr("basic:Name")} body={nameBodyTemplate}></Column>
                        <Column field="age" header={Tr.tr("basic:Age")}></Column>
                        <Column field="gender" header={Tr.tr("basic:Gender")}></Column>
                        <Column field="phone" header={Tr.tr("basic:Mobile_Number")}></Column>
                        <Column field="email" header={Tr.tr("basic:Email_Address")}></Column>
                    </DataTable>
                </div>
            </div>

            {/* Add Patient Sidebar */}
            <AddPatientDrawer
                visible={addPatientVisible}
                userId={userId}
                hideHandler={() => {
                    setAddPatientVisible(false);
                }}
            />
        </>
    );
};

export default DoctorPatients;

import { useEffect, useState } from "react";

// Components
import DoctorsAvailable from "./components/DoctorsAvailable/DoctorsAvailable";
import FindTheCare from "./components/FindTheCare/FindTheCare";
import FindDoctorLoadingSkeleton from "./FindDoctorLoadingSkeleton";

// Query
import { useQuery } from "react-query";

// Store
import usePaginationStore from "store/store";

// Api
import { doctorsAPI } from "api/doctors";

// Styles
import styles from "./FindDoctor.module.scss";

const FindDoctor = () => {
	const [filter, setFilter] = useState();

	const [search, setSearch] = useState();

	const { pageNumber } = usePaginationStore();

	const { data: doctors, isLoading: doctorsLoading } = useQuery(
		["doctor", search, filter, pageNumber],
		doctorsAPI,
		{
			select: (response) => {
				// console.log(response?.data);
				return response?.data;
			},
		}
	);

	const globalFilter = (newFilter) => {
		setFilter({
			...filter,
			...newFilter,
		});
	};

	const globalSearch = (newSearch) => {
		console.log(newSearch);
		setSearch(newSearch);
	};

	const appendToURL = (key, value) => {
		// let queryString = encodeURIComponent(key) + '=' + encodeURIComponent(value);
		// const baseUrl = window.location.origin + window.location.pathname;
		// const newUrl = baseUrl + '?' + queryString;
		// window.history.pushState({ path: newUrl }, '', newUrl);
		const urlSearchParams = new URLSearchParams(window.location.search);
		urlSearchParams.set(key, value);
		const baseUrl = window.location.origin + window.location.pathname;
		const newUrl = baseUrl + "?" + urlSearchParams.toString();

		// Update the URL of the page
		window.history.pushState({ path: newUrl }, "", newUrl);
	};

	const removeFromURL = (keyToRemove) => {
		const urlSearchParams = new URLSearchParams(window.location.search);

		// Remove the specified key from the URL query parameters
		urlSearchParams.delete(keyToRemove);

		// Construct the new URL without the specified key
		const baseUrl = window.location.origin + window.location.pathname;
		const newUrl = baseUrl + "?" + urlSearchParams.toString();

		// Update the URL of the page
		window.history.pushState({ path: newUrl }, "", newUrl);
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<>
			<FindTheCare
				globalFilter={globalFilter}
				appendToURL={appendToURL}
				globalSearch={globalSearch}
			/>
			<DoctorsAvailable
				globalFilter={globalFilter}
				appendToURL={appendToURL}
				removeFromURL={removeFromURL}
				doctorsLoading={doctorsLoading}
				doctors={doctors}
			/>
		</>
	);
};

export default FindDoctor;

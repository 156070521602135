import { addToWishlist, getWishlist , removeFromWishlist } from 'api/wishlist'
import { create } from 'zustand'

const useWishlistStore = create((set) => ({
    wishlist: [],
    toggleWishlistLoading:false,
    getWishlist: async () => {
        try {
            const res = await getWishlist()
            console.log({res})
            set((state) => ({ wishlist: res?.data }))
        } catch (error) { }
    },
    addToWishlist:async (doctor) => {
        console.log({doctor})
        try {
            set((state) => ({...state , toggleWishlistLoading:true  }))
            const res = await addToWishlist(doctor)
            console.log({res})
            set((state) => ({ wishlist: [...state?.wishlist , doctor] , toggleWishlistLoading:false}))
        } catch (error) { }
    },
    removeFromWishlist:async (doctor) => {
        console.log({doctor})
        try {
            set((state) => ({...state , toggleWishlistLoading:true  }))
            const res = await removeFromWishlist(doctor)
            console.log({res})
            set((state) => {
                const  updatedWishlistData = [...state?.wishlist].filter((item)=>item?.id !== doctor?.id)
                return({ wishlist: updatedWishlistData , toggleWishlistLoading:false})
            })
        } catch (error) { }
    },
}))


export default useWishlistStore
import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";

// Components
import {
    MultiSelectDropdown,
    TextAreaInput,
    TextInput,
} from "components/Common/Form/Inputs";
import GradientButton from "components/Common/UI/GradientButton/GradientButton";

// Utils
import { getFormErrorMessage } from "utils/FormField";

// Translation
import { useTranslation, withTranslation } from "react-i18next";
import { Tr } from "utils/i18n";

// Styles
import { addQuestionsAPI, editQuestionsAPI } from "api/questions";
import { specialtiesAPI } from "api/specialties";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useMutation, useQuery, useQueryClient } from "react-query";
import styles from "./AddQuestionDrawer.module.scss";

const AddQuestionDrawer = ({ ...props }) => {
    const { i18n } = useTranslation();
    const currLang = i18n.language;

    const queryClient = useQueryClient();

    const { mutate: addQuestion } = useMutation(addQuestionsAPI, {
        onSuccess: (data) => {
            toast.success("Question has been added Successfully");
            queryClient.invalidateQueries('questions');
            // props.updateView();
            props.hideHandler();
        },
        onError: (error) => {
            toast.error(error?.response?.data?.message);
        },
    });

    const { mutate: editQuestion } = useMutation(editQuestionsAPI, {
        onSuccess: (data) => {
            toast.success("Question has been Updated Successfully");
            // props.updateView();
            queryClient.invalidateQueries('questions');
            props.hideHandler();
        },
        onError: (error) => {
            toast.error(error?.response?.data?.message);
        },
    })

    // Specialties
    // const specialties = [
    //     { label: "Dermatology (Skin)", value: "Dermatology (Skin)" },
    //     { label: "Dentistry (Teeth)", value: "Dentistry (Teeth)" },
    //     {
    //         label: "Psychiatry (Mental, Emotional or Behavioral Disorders)",
    //         value: "Psychiatry (Mental, Emotional or Behavioral Disorders)",
    //     },
    //     {
    //         label: "Pediatrics and New Born (Child)",
    //         value: "Pediatrics and New Born (Child)",
    //     },
    //     {
    //         label: "Neurology (Brain & Nerves)",
    //         value: "Neurology (Brain & Nerves)",
    //     },
    // ];
    const [specialties, setSpecialties] = useState([]);

    const { data: specialitiesData, isLoading } = useQuery("specialities", specialtiesAPI);

    useEffect(() => {
        if (specialitiesData && specialitiesData.data) {
            const updatedspecialties = [];
            specialitiesData.data?.map(item => updatedspecialties.push({ label: item.name, value: item.id }))
            setSpecialties(updatedspecialties);
        }
    }, [specialitiesData]);


    // Formik
    const formik = useFormik({
        initialValues: {
            question: props.flag == 'edit' ? props.questionData?.name : "",
            specialties: null,
            description: props.flag == 'edit' ? props.questionData?.description : "",
        },
        validate: (data) => {
            let errors = {};

            if (!data.question) {
                errors.question = "Question is required.";
            }

            if (!data.description) {
                errors.description = "Description is required.";
            }

            return errors;
        },
        onSubmit: async (data, { setSubmitting }) => {
            if (props.flag == 'edit') {
                await editQuestion({
                    id: props.questionId,
                    data: {
                        name: data.question,
                        description: data.description,
                        doctor_specialty_id: data.specialties
                    }
                })
            } else {
                await addQuestion({
                    name: data.question,
                    description: data.description,
                    doctor_specialty_id: data.specialties
                })
                // setTimeout(() => {
                //     setSubmitting(false);
                //     formik.resetForm();
                // }, 2000);
            }
        },
    });

    return (
        <Sidebar
            visible={props.visible}
            position={currLang === "ar" ? "left" : "right"}
            onHide={props.hideHandler}
            className={styles.add_drawer_holder}
            maskClassName={styles.add_drawer}
            blockScroll={true}>
            <div className={styles.drawer_header}>
                <h2>{props.flag == 'edit' ? Tr.tr("patient:PatientProfile:Edit_Question") : Tr.tr("patient:PatientProfile:Add_Question")}</h2>
                <Button
                    icon="pi pi-times"
                    className={styles.close_btn}
                    onClick={props.hideHandler}></Button>
            </div>
            <form className={styles.drawer_form}>
                <div className={styles.drawer_content}>
                    <div className="row">
                        {/* Question */}
                        <div className="col-md-12 m-b-20">
                            <TextInput
                                inputLabel={Tr.tr("patient:PatientProfile:Question")}
                                inputName="question"
                                placeHolder={Tr.tr("patient:PatientProfile:Question")}
                                inputVal={formik.values.question}
                                changeHandler={formik.handleChange}
                                inputError={getFormErrorMessage("question", formik)}
                            />
                        </div>

                        {/* Specialties */}
                        <div className="col-md-12 m-b-20">
                            <MultiSelectDropdown
                                inputLabel={Tr.tr("patient:PatientProfile:Specialties")}
                                inputName="specialties"
                                placeHolder={Tr.tr("patient:PatientProfile:Specialties")}
                                options={specialties}
                                inputVal={formik.values.specialties}
                                changeHandler={(e) =>
                                    formik.setFieldValue("specialties", e.value)
                                }
                                appendTo="self"
                                filter={true}
                            />
                        </div>

                        {/* Description */}
                        <div className="col-md-12 m-b-20">
                            <TextAreaInput
                                inputName={'description'}
                                placeHolder={Tr.tr("basic:Description")}
                                inputVal={formik.values.description}
                                changeHandler={formik.handleChange}
                                inputError={getFormErrorMessage("description", formik)}
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.drawer_footer}>
                    <Button
                        type="button"
                        label={Tr.tr("basic:Discard")}
                        className={styles.discard_btn}
                        onClick={() => props.hideHandler()}
                    />
                    <GradientButton
                        btn
                        type="submit"
                        label={Tr.tr("patient:PatientProfile:Question")}
                        classes={styles.done_btn}
                        buttonClicked={formik.handleSubmit}
                    />
                </div>
            </form>
        </Sidebar>
    );
};

export default withTranslation()(AddQuestionDrawer);

import { useEffect, useState } from "react";

// Components
import WhiteBlackButton from "components/Common/UI/WhiteBlackButton/WhiteBlackButton";
import GradientButton from "components/Common/UI/GradientButton/GradientButton";
import AddPaymentModal from "../AddPaymentModal/AddPaymentModal";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./PaymentMethods.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useMutation, useQueries, useQuery, useQueryClient } from "react-query";
import { getPaymentMethod, verifyPaymentMethod } from "api/payment";
import PaymentItem from "./PaymentItem";

const PaymentMethods = () => {
	const location = useLocation()
	const navigate = useNavigate()
	const searchParams = new URLSearchParams(location?.search)
	const id = searchParams.get('id')
	const status = searchParams.get('status')
	const message = searchParams.get('message')
	const { data, refetch, isLoading: loadingPayment } = useQuery('userData', getPaymentMethod)

	const { mutate, isLoading, } = useMutation(verifyPaymentMethod, {
		onError: (error) => { console.log({ error }) },
		onSuccess: (res) => {
			console.log({ res }); refetch();
			toast.success('Your payment methods saved successfully.')
			setTimeout(() => {
				navigate(window.location.pathname,);

			}, 500)

		}
	})



	useEffect(() => {
		if (id) {
			if (status === 'paid' || status === 'verified') {
				mutate({ payment_id: id })
			} else {
				message && toast.error(message)
			}
		}
	}, [id])





	// Payment Methods
	const paymentMethods = [
		{
			id: 1,
			icon: "/img/master-card.svg",
			endIn: "1234",
			expireAt: "06/2024",
		},
		{
			id: 2,
			icon: "/img/visa.svg",
			endIn: "1234",
			expireAt: "06/2024",
		},
	];

	// Add Payment Modal Visible
	const [addPaymentModalVisible, setAddPaymentModalVisible] = useState(false);

	const onAddPaymentMethod = (values) => {
		console.log({ values })
	}

	return (
		<>
			<div className={styles.payment_methods}>
				{/* Head */}
				<div className={styles.head}>
					<h2>{Tr.tr("patient:PatientProfile:Payment_Methods")}</h2>
					<p>{Tr.tr("patient:PatientProfile:Your_Saved_Payment_Methods")}</p>
				</div>

				{/* Saved Payment Methods */}
				<div className={styles.saved_payment_methods}>
					{data?.data?.map((m) => (<PaymentItem m={m} refetch={refetch} />))}
				</div>

				{/* Add Payment Method */}
				<GradientButton
					btn
					type="button"
					label={Tr.tr("patient:PatientProfile:Add_Payment_Method")}
					withIconImg
					icon="/img/add.svg"
					classes="p616"
					buttonClicked={() => setAddPaymentModalVisible(true)}
				/>
			</div>

			{/* Add Payment Modal */}
			<AddPaymentModal
				visible={addPaymentModalVisible}
				hideHandler={() => setAddPaymentModalVisible(false)}
				onAddPaymentMethod={onAddPaymentMethod}
			/>
		</>
	);
};

export default PaymentMethods;

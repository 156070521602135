import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import { useEffect, useRef, useState } from "react";

// Components
import { SelectDropdown } from "components/Common/Form/Inputs";
import GradientButton from "components/Common/UI/GradientButton/GradientButton";
import DialogWrapper from "components/Patient/DialogWrapper/DialogWrapper";

// Translation
import { useTranslation, withTranslation } from "react-i18next";
import { Tr } from "utils/i18n";

// Styles
import LabResultsUploadDocumentService from "features/labResults/uploadDocumentLabResultsService";
import toast from "react-hot-toast";
import styles from "./LabResults.module.scss";

const LabResults = () => {
    const { i18n } = useTranslation();
    const currLang = i18n.language;

    const menu = useRef(null);

    const [visible, setVisible] = useState(false);
    const [attachedFile, setAttachFile] = useState(null);
    const [docID, setdocId] = useState(null);
    const [imageUrl, setImageUrl] = useState('');

    const { data: labResultsFetched, refetch } = LabResultsUploadDocumentService.useFetchLabResultsDocuments('lab_results')
    const { mutate: uploadDocument } = LabResultsUploadDocumentService.useUploadLabResultsDocument();
    const { mutate: deleteDocument, isLoading } = LabResultsUploadDocumentService.useDeleteLabResultsDocument();
    // Ellipses Menu
    const items = [
        // {
        //     template: (item, options, id) => {
        //         return (
        //             <Button
        //                 onClick={(e) => options.onClick(e)}
        //                 className={`${options.className}`}>
        //                 <img src="/img/upload-gray.svg" alt="" />
        //                 <span className="update">{Tr.tr("basic:Update")}</span>
        //             </Button>
        //         );
        //     },
        // },
        {
            template: (item, options, id) => {
                return (
                    <Button
                        // onClick={(e) => options.onClick(e)}
                        onClick={(e) => { handleDelete(docID); options.onClick(e) }}
                        className={`${options.className}`}>
                        <img src="/img/delete-red.svg" alt="" />
                        <span className="delete">{Tr.tr("basic:Delete")}</span>
                    </Button>
                );
            },
        },
    ];


    // Lab Results Data
    const labResults = [
        {
            id: 1,
            name: "X-Ray",
            lastUpdate: "06/01/2023",
        },
        {
            id: 2,
            name: "Allergen-specific IgE",
            lastUpdate: "06/01/2023",
        },
        {
            id: 3,
            name: "Nasal Endoscopy",
            lastUpdate: "06/01/2023",
        },
        {
            id: 4,
            name: "CT",
            lastUpdate: "06/01/2023",
        },
    ];

    // Types
    const types = [
        { label: "First option", value: "First option" },
        { label: "Second option", value: "Second option" },
        { label: "Third option", value: "Third option" },
        { label: "Fourth option", value: "Fourth option" },
        { label: "Another option", value: "Another option" },
    ];

    const imgFileHandler = (e) => {
        const file = e.target.files[0];
        const allowedTypes = ["image/jpeg", "image/png", "application/pdf"]; // Add more allowed file types if needed

        if (file && allowedTypes.includes(file.type)) {
            setAttachFile(file)
            const reader = new FileReader();
            reader.onload = function (e) {
                setImageUrl(e.target.result);
            };
            reader.readAsDataURL(file);
        } else {
            toast.error("Invalid file format. Please choose an image or PDF file.");
        }
    };

    const handleDelete = (documentId) => {
        deleteDocument(documentId);
        toast.success("Document deleted successfully!");
    };

    const isImage = (mimetype) => {
        if (mimetype?.includes("image")) {
            return true;
        }
        return false;
    };

    useEffect(() => {
        refetch();
    }, [isLoading])

    // Formik
    const formik = useFormik({
        initialValues: {
            lab_result_type: "",
        },
        validate: (data) => {
            let errors = {};

            if (!data.lab_result_type) {
                errors.lab_result_type = "Type is required.";
            }
            return errors;
        },
        onSubmit: (data, { setSubmitting }) => {
            const formdata = new FormData();
            formdata.append("docs", attachedFile);
            formdata.append("lab_result_type", data.lab_result_type);
            uploadDocument(formdata, {
                onSuccess: (data) => {
                    refetch();
                    setSubmitting(false);
                    formik.resetForm();
                    toast.success("Document uploaded successfully!");
                    setVisible(false);
                },
                onError: (error) => {
                    console.error(error);
                    setSubmitting(false);
                    toast.error("Failed to upload document.");
                },
            });
        },
    });

    return (
        <>
            <section className={styles.lab_results}>
                {/* Section Head */}
                <div className="title_action">
                    <h2>{Tr.tr("patient:PatientProfile:Lab_Results")}</h2>
                    <GradientButton
                        btn
                        type="button"
                        label={Tr.tr("basic:Upload")}
                        withIconImg
                        icon="/img/upload-white.svg"
                        classes="p610"
                        buttonClicked={() => setVisible(true)}
                    />
                </div>

                {/* Lab Results Card */}
                <div className={styles.lab_results_card}>
                    {labResultsFetched?.map((result) => (
                        <div className={styles.result_row} key={result.id}>
                            <div className="row">
                                <div className="col-md-7 mb-3 mb-md-0">
                                    <div className={styles.icon_name}>
                                        <img src="/img/file-gradient.svg" alt="" />
                                        <span className={styles.name}>{result.name}</span>
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div className={styles.date_action}>
                                        <span className={styles.date}>
                                            {Tr.tr("basic:Last_Update")} {result?.create_date}
                                        </span>
                                        <div className={styles.action}>
                                            <Menu
                                                model={items}
                                                popup
                                                ref={menu}
                                                id="popup_menu"
                                                popupAlignment={currLang === "ar" ? "left" : "right"}
                                                className={styles.action_menu}
                                            />
                                            <Button
                                                icon="pi pi-ellipsis-v"
                                                onClick={(event) => {
                                                    menu.current.toggle(event);
                                                    setdocId(result.id);
                                                }}
                                                aria-controls="popup_menu"
                                                aria-haspopup
                                                className={styles.action_btn}
                                                value={result.id}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </section>

            {/* Upload Modal */}
            <DialogWrapper
                header={Tr.tr("patient:PatientProfile:Upload_Lab_Result")}
                visible={visible}
                width="42vw"
                hideHandler={() => setVisible(false)}>
                <form onSubmit={formik.handleSubmit}>
                    <div className="modal_body">
                        <div className="row">
                            {/* Type */}
                            <div className="col-md-12 m-b-20">
                                <SelectDropdown
                                    inputLabel={Tr.tr("patient:PatientProfile:Type")}
                                    inputName="lab_result_type"
                                    placeHolder={Tr.tr("patient:PatientProfile:Select_Type")}
                                    options={types}
                                    inputVal={formik.values.lab_result_type}
                                    changeHandler={(e) => formik.setFieldValue("lab_result_type", e.value)}
                                    appendTo={document.body}
                                    inputError={formik.errors['lab_result_type']}
                                />
                            </div>

                            {/* Upload File */}
                            <div className="col-md-12 m-b-20">
                                <label className={styles.custom_upload_file}>
                                    <input type="file" name="docs" onChange={(e) => imgFileHandler(e)} />
                                    <div className={styles.upload_txt}>
                                        <h3>{Tr.tr("basic:Drag_Drop__Files")}</h3>
                                        <h4>
                                            {Tr.tr("basic:File_Format_Supported")}: PDF, PNG, JPG
                                        </h4>
                                    </div>
                                    <div className={styles.upload_btn}>
                                        <img src="/img/upload-black.svg" alt="" />
                                        <span>{Tr.tr("basic:Upload_Your_Files")}</span>
                                    </div>
                                </label>
                                {attachedFile !== null &&
                                    <div className="d-flex justify-content-between align-items-center mt-3">
                                        <div className={styles.left}>
                                            <img
                                                key={attachedFile.id}
                                                src={
                                                    isImage(attachedFile?.type)
                                                        ? imageUrl
                                                        : "/img/pdf.svg"
                                                }
                                                alt=""
                                                className={styles.file_icon}
                                            />
                                            <div className={styles.file_data}>
                                                <h5>{attachedFile?.name}</h5>
                                                <h6>
                                                    {(attachedFile?.size / 1024 / 1024).toFixed(2)} MB
                                                </h6>
                                            </div>
                                        </div>
                                        <Button
                                            type="button"
                                            className={styles.delete_btn}
                                            onClick={() => { setAttachFile(null); setImageUrl('') }}>
                                            <img src="/img/delete-red.svg" alt="" />
                                        </Button>
                                    </div>}
                            </div>
                        </div>
                    </div>
                    <div className="modal_footer">
                        <Button
                            type="button"
                            label={Tr.tr("basic:Discard")}
                            className="discard_btn"
                            onClick={() => setVisible(false)}
                        />
                        <GradientButton
                            btn
                            type="submit"
                            label={Tr.tr("basic:Upload")}
                            classes="done_btn"
                        />
                    </div>
                </form>
            </DialogWrapper>
        </>
    );
};

export default withTranslation()(LabResults);

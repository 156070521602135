import { useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import moment from "moment";

// Components
import { CustomDropdown } from "components/Common/Form/Inputs";
import GradientButton from "components/Common/UI/GradientButton/GradientButton";
import FullCalendarView from "components/Doctor/FullCalendarView/FullCalendarView";
import AddAppointmentDrawer from "./components/AddAppointmentDrawer/AddAppointmentDrawer";
import ScheduleData from "./components/ScheduleData/ScheduleData";

// Context
import { SidebarContext } from "context/DoctorSidebarContext";

// Api
import { getAppointmentsAPI } from "api/appointments";
import { getMeApi } from "api/auth";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./DoctorAppointments.module.scss";

const formatDateString = (dateFrom) => {
	const day = moment(dateFrom).format("dddd");
	const month = moment(dateFrom).format("D MMM");
	return `${day} ${month}`;
};

const formatDay = (dateString) => {
	const dateFrom = moment.utc(dateString).local();
	const date = formatDateString(dateFrom);
	return date;
};

const groupAppointmentsByDay = (appointments) => {
	const grouped = {};
	appointments.forEach((appointment) => {
		const key = formatDay(appointment.date_from);
		const type = appointment.type === "in_clinic" ? "In Clinic" : "Video Call";
		if (!grouped[key]) {
			grouped[key] = { All: [], "In Clinic": [], "Video Call": [] };
		}
		grouped[key][type].push(appointment);
		grouped[key]["All"].push(appointment);
	});
	return grouped;
};

const DoctorAppointments = () => {
	const [times, setTimes] = useState([]);
	const { setSmallSize } = useContext(SidebarContext);

	const [appointments, setAppointments] = useState([]);
	const [scheduleInfo, setScheduleInfo] = useState([]);

	const { mutate: getConfirmedAppointments } = useMutation(getAppointmentsAPI, {
		onSuccess: (data) => {
			const newTimes = data.data.data.map((appointment) => ({
				userId: appointment.partner_id.id,
				title: appointment.partner_id.name,
				start: appointment.date_from.replace(" ", "T"),
				end: appointment.date_to.replace(" ", "T"),
				location: appointment.type === "in_clinic" ? "In Clinic" : "Video Call",
				email: appointment.partner_id.email,
				phone: appointment.partner_id.phone,
				image: appointment.partner_id.image,
				meetingId: appointment.id,
			}));
			setTimes(newTimes);
			setAppointments(data.data.data);

			const groupedAppointments = groupAppointmentsByDay(data.data.data);
			const scheduleInfoArray = Object.keys(groupedAppointments).map((day) => ({
				day: day,
				appointments: groupedAppointments[day],
			}));
			setScheduleInfo(scheduleInfoArray);
		},
	});

	const { mutate: getMe } = useMutation(getMeApi, {
		onSuccess: (data) => {
			// debugger
			getConfirmedAppointments({ id: data.data.id, status: "confirmed" });
		},
	});

	useEffect(() => {
		getMe();
	}, []);

	// Show Add Appointment Drawer
	const [appointmentVisible, setAppointmentVisible] = useState(false);

	// Doctors
	const doctors = [
		{
			label: "Dr. Amr Mostafa",
			value: "Dr. Amr Mostafa",
		},
		{
			label: "Dr. Khaled Hassan",
			value: "Dr. Khaled Hassan",
		},
		{
			label: "Dr. Ahmed ALi",
			value: "Dr. Ahmed Ali",
		},
	];

	const [selectedDoctor, setSelectedDoctor] = useState(doctors[0].value);

	useEffect(() => {
		setSmallSize(true);
	}, [setSmallSize]);
	const obj = [];
	console.log(scheduleInfo);
	return (
		<>
			<div className={styles.doctor_appointments}>
				<div className={styles.doctor_appointments_head}>
					<h1>{Tr.tr("basic:Appointments")}</h1>
					<div className={styles.actions}>
						{/* Doctors Dropdown */}
						<CustomDropdown
							inputName="doctor"
							inputLabel=""
							inputVal={selectedDoctor}
							options={doctors}
							placeHolder="Select a Doctor"
							changeHandler={(e) => setSelectedDoctor(e.value)}
							dropdownIcon="/img/specialty-icon.svg"
						/>

						{/* Add Appointment */}
						<GradientButton
							btn
							type="button"
							label={Tr.tr("basic:Add_Appointment")}
							withIconImg
							icon="/img/add.svg"
							classes="p712"
							buttonClicked={() => setAppointmentVisible(true)}
						/>
					</div>
				</div>

				<div className={styles.doctor_appointments_body}>
					<div className="row">
						<div className="col-md-8">
							<FullCalendarView events={times} />
						</div>
						<div className="col-md-4">
							<ScheduleData scheduleInfo={scheduleInfo} />
						</div>
					</div>
				</div>
			</div>

			{/* Add Appointment Drawer */}
			<AddAppointmentDrawer
				visible={appointmentVisible}
				hideHandler={() => {
					setAppointmentVisible(false);
				}}
			/>
		</>
	);
};

export default DoctorAppointments;

import { useState } from "react";
import { useFormik } from "formik";
import { Button } from "primereact/button";

// Components
import DialogWrapper from "components/Patient/DialogWrapper/DialogWrapper";
import {
	CalendarInput,
	CheckBox,
	SelectDropdown,
	TextInput,
} from "components/Common/Form/Inputs";
import GradientButton from "components/Common/UI/GradientButton/GradientButton";

// Utils
import { getFormErrorMessage } from "utils/FormField";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./AddDoctorModal.module.scss";

const AddDoctorModal = ({ ...props }) => {
	// Selected Notifications Values
	const [selectedNotificationsOptions, setSelectedNotificationsOptions] =
		useState({});

	const [selectedPermissions, setSelectedPermissions] = useState([]);

	// Gender
	const genderTypes = [
		{ label: Tr.tr("doctor:Settings:Male"), value: "Male" },
		{ label: Tr.tr("doctor:Settings:Female"), value: "Female" },
	];

	// Specialties
	const specialties = [
		{ label: "Dermatology (Skin)", value: "Dermatology (Skin)" },
		{ label: "Dentistry (Teeth)", value: "Dentistry (Teeth)" },
		{
			label: "Psychiatry (Mental, Emotional or Behavioral Disorders)",
			value: "Psychiatry (Mental, Emotional or Behavioral Disorders)",
		},
		{
			label: "Pediatrics and New Born (Child)",
			value: "Pediatrics and New Born (Child)",
		},
		{
			label: "Neurology (Brain & Nerves)",
			value: "Neurology (Brain & Nerves)",
		},
	];

	// Notifications
	const notifications = [
		{
			id: 1,
			title: "Daily Schedule",
			options: [
				{ label: Tr.tr("basic:SMS"), value: "SMS" },
				{ label: Tr.tr("basic:Email"), value: "Email" },
			],
		},
		{
			id: 2,
			title: "Monthly Schedule",
			options: [
				{ label: Tr.tr("basic:SMS"), value: "SMS" },
				{ label: Tr.tr("basic:Email"), value: "Email" },
			],
		},
	];

	// Permissions
	const permissions = [
		{
			label: "All Permissions",
			value: "All Permissions",
		},
		{
			label: "Access to one month Reporting",
			value: "Access to one month Reporting",
		},
	];

	// Formik
	const formik = useFormik({
		initialValues: {
			name: "",
			mobile: "",
			email: "",
			specialty: null,
			gender: null,
			birthDate: "",
		},
		validate: (data) => {
			let errors = {};

			if (!data.name) {
				errors.name = "Name is required.";
			}

			if (!data.mobile) {
				errors.mobile = "Mobile Number is required.";
			}

			if (!data.email) {
				errors.email = "Email Address is required.";
			}

			if (!data.specialty) {
				errors.specialty = "Specialty is required.";
			}

			if (!data.gender) {
				errors.gender = "Gender is required.";
			}

			if (!data.birthDate) {
				errors.birthDate = "Date of Birth is required.";
			}

			return errors;
		},
		onSubmit: (data, { setSubmitting }) => {
			console.log("submitted");
			setTimeout(() => {
				setSubmitting(false);
				formik.resetForm();
			}, 2000);
		},
	});

	// Handle Notifications Checkboxes
	const handleCheckboxChange = (notificationId, optionValue) => {
		setSelectedNotificationsOptions((prevSelectedOptions) => ({
			...prevSelectedOptions,
			[notificationId]: {
				...(prevSelectedOptions[notificationId] || {}),
				[optionValue]: !prevSelectedOptions[notificationId]?.[optionValue],
			},
		}));
	};

	// Handle Permissions Checkboxes
	const onSelectionChange = (e, selectedOptions) => {
		let _selectedOptions = [...selectedOptions];

		if (e.checked) {
			_selectedOptions.push(e.value);
		} else {
			for (let i = 0; i < _selectedOptions.length; i++) {
				const selectedOption = _selectedOptions[i];

				if (selectedOption.value === e.value.value) {
					_selectedOptions.splice(i, 1);
					break;
				}
			}
		}

		setSelectedPermissions(_selectedOptions);
	};

	return (
		<DialogWrapper
			header={Tr.tr("doctor:OnBoarding:Add_A_Doctor")}
			visible={props.visible}
			width="42vw"
			hideHandler={props.hideHandler}>
			<form onSubmit={formik.handleSubmit}>
				<div className="modal_body">
					<h2 className={styles.from_head}>
						{Tr.tr("doctor:OnBoarding:Information")}
					</h2>

					<div className="row">
						{/* Name */}
						<div className="col-md-12 m-b-20">
							<TextInput
								inputLabel={Tr.tr("basic:Name")}
								inputName="name"
								placeHolder={Tr.tr("basic:Enter_Name")}
								inputVal={formik.values.name}
								changeHandler={formik.handleChange}
								inputError={getFormErrorMessage("name", formik)}
							/>
						</div>

						{/* Mobile Number */}
						<div className="col-md-6 m-b-20">
							<TextInput
								inputLabel={Tr.tr("basic:Mobile_Number")}
								inputName="mobile"
								placeHolder={Tr.tr("basic:Enter_Mobile_Number")}
								inputVal={formik.values.mobile}
								changeHandler={formik.handleChange}
								inputError={getFormErrorMessage("mobile", formik)}
							/>
						</div>

						{/* Email Address */}
						<div className="col-md-6 m-b-20">
							<TextInput
								inputLabel={Tr.tr("basic:Email_Address")}
								inputName="email"
								placeHolder={Tr.tr("basic:Enter_Email_Address")}
								inputVal={formik.values.email}
								changeHandler={formik.handleChange}
								inputError={getFormErrorMessage("email", formik)}
							/>
						</div>

						{/* Specialty */}
						<div className="col-md-12 m-b-20">
							<SelectDropdown
								inputLabel={Tr.tr("doctor:OnBoarding:Specialty")}
								inputName="specialty"
								placeHolder={Tr.tr("doctor:OnBoarding:Choose_Your_Specialty")}
								options={specialties}
								inputVal={formik.values.specialty}
								changeHandler={(e) =>
									formik.setFieldValue("specialty", e.value)
								}
								appendTo={document.body}
							/>
						</div>

						{/* Gender */}
						<div className="col-md-6 m-b-20">
							<SelectDropdown
								inputLabel={Tr.tr("basic:Gender")}
								inputName="gender"
								placeHolder={Tr.tr("basic:Select_Gender")}
								options={genderTypes}
								inputVal={formik.values.gender}
								changeHandler={(e) => formik.setFieldValue("gender", e.value)}
								appendTo={document.body}
							/>
						</div>

						{/* Date of Birth */}
						<div className="col-md-6 m-b-20">
							<CalendarInput
								inputLabel={Tr.tr("basic:Date_Of_Birth")}
								inputName="birthDate"
								placeHolder="DD/MM/YYYY"
								inputVal={formik.values.birthDate}
								changeHandler={(e) =>
									formik.setFieldValue("birthDate", e.value)
								}
								appendTo={document.body}
								showIcon={true}
								view="date"
								dateFormat="mm/dd/yy"
							/>
						</div>

						{/* Notifications */}
						<div className="col-md-12 m-b-20">
							<h6 className={styles.choices_title}>
								{Tr.tr("doctor:OnBoarding:Notifications")}
							</h6>
							<div className={styles.choices_rows}>
								{notifications.map((notification) => (
									<div className={styles.choices_row} key={notification.id}>
										<div className="row">
											<div className="col-md-8">
												<div className={styles.notify_type}>
													{notification.title}
												</div>
											</div>
											<div className="col-md-4">
												<div className={styles.check_boxes}>
													{notification.options.map((option, index) => (
														<CheckBox
															key={index}
															name={`option-${notification.id}`}
															label={option.label}
															value={option.value}
															handleChange={() =>
																handleCheckboxChange(
																	notification.id,
																	option.value
																)
															}
															checked={
																selectedNotificationsOptions[notification.id]?.[
																	option.value
																] || false
															}
														/>
													))}
												</div>
											</div>
										</div>
									</div>
								))}
							</div>
						</div>

						{/* Permissions */}
						<div className="col-md-12">
							<h6 className={styles.choices_title}>
								{Tr.tr("doctor:OnBoarding:Permissions")}
							</h6>
							<div className={styles.choices_rows}>
								{permissions.map((permission, index) => (
									<div className={styles.choices_row} key={index}>
										<CheckBox
											name="permission"
											label={permission.label}
											value={permission}
											handleChange={(e) =>
												onSelectionChange(e, selectedPermissions)
											}
											checked={selectedPermissions.some(
												(item) => item.value === permission.value
											)}
										/>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
				<div className="modal_footer">
					<Button
						type="button"
						label={Tr.tr("basic:Discard")}
						className="discard_btn withBorder"
						onClick={props.hideHandler}
					/>
					<GradientButton
						btn
						type="submit"
						label={Tr.tr("basic:Next")}
						classes="done_btn"
					/>
				</div>
			</form>
		</DialogWrapper>
	);
};

export default AddDoctorModal;

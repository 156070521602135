import { Dropdown } from "primereact/dropdown";

// Components
import ErrorUI from "components/Common/UI/ErrorUI/ErrorUI";

// Styles
import styles from "./CustomDropdown.module.scss";

export function CustomDropdown({ ...props }) {
	const valueTemplate = (option) => {
		return (
			<div className={styles.value_template}>
				{option?.image ? <img src={option.image} alt="" /> :<img src={props.dropdownIcon} alt="" />}
				{option ? (
					<div className={styles.selected_val}>{option.value}</div>
				) : (
					<div className={styles.place_holder}>{props.placeHolder}</div>
				)}
			</div>
		);
	};

	const itemTemplate = (option) => {
		return (
			<div className={styles.item_template}>
				{option?.image ? <img src={option.image} alt="" /> :<img src={props.dropdownIcon} alt="" />}
				<div>{option.label}</div>
			</div>
		);
	};

	return (
		<div className="input_holder">
			<div className={styles.field_holder}>
				{props.inputLabel && (
					<h6 className={props.required ? "required" : ""}>
						{props.inputLabel}
					</h6>
				)}
				<div
					className={`w-100 ${styles.input_box} ${
						props.inputError ? "p-invalid" : ""
					}`}>
					<Dropdown
						disabled={props.disable}
						name={props.inputName}
						value={props.inputVal}
						options={props.options}
						optionLabel="label"
						optionValue="value"
						placeholder={props.placeHolder}
						onChange={props.changeHandler}
						panelClassName={styles.dropdown_panel}
						className="w-100"
						itemTemplate={itemTemplate}
						valueTemplate={valueTemplate}
						appendTo="self"
					/>
				</div>
			</div>
			{props.inputError && (
				<ErrorUI errorMsg={props.inputError} withBG={props.withBG} />
			)}
		</div>
	);
}

import { Outlet } from "react-router-dom";

// Components
import Header from "layout/Patient/Header/Header";
import Footer from "layout/Patient/Footer/Footer";

// Styles
import styles from "./MainLayout.module.scss";

const MainLayout = () => {
	return (
		<div className={styles.main_layout}>
			<Header />
			<div className={styles.outlet_content}>
				<Outlet />
			</div>
			<Footer />
		</div>
	);
};

export default MainLayout;

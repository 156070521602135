import { BreadCrumb } from "primereact/breadcrumb";
import { Link } from "react-router-dom";

// Styles
import styles from "./BreadCrumbWrapper.module.scss";

const BreadCrumbWrapper = ({ items, homeItem }) => {
	const homeItemTemplate = (item) => {
		return <Link to={item.url}>{item.label}</Link>;
	};

	const home = {
		...homeItem,
		template: homeItemTemplate,
	};

	return (
		<div className={styles.bread_crumb}>
			<div className="container">
				<BreadCrumb model={items} home={home} separatorIcon="pi pi-minus" />
			</div>
		</div>
	);
};

export default BreadCrumbWrapper;

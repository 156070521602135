export const convertToFullTimestamp = (time) => {
	const timeStr = String(time);

	const [hours, decimalMinutes] = timeStr.split(".");

	const now = new Date();
	const year = now.getUTCFullYear();
	const month = now.getUTCMonth();
	const day = now.getUTCDate();
	const seconds = now.getUTCSeconds();

	const newYear = now.getFullYear();
	const newMonth = now.getMonth();
	const newDay = now.getDate();

	// console.log(new Date(newYear, newMonth, newDay, Number(hours), decimalMinutes, 0, 0))

	// return new Date(
	// 	Date.UTC(year, month, day, Number(hours) - 2, decimalMinutes, 0, 0)
	// )

	return new Date(
		newYear,
		newMonth,
		newDay,
		Number(hours),
		decimalMinutes,
		0,
		0
	);
};

export const convertFrom12To24 = (time) => {
	if (typeof time === "string") {
		const [timePart, modifier] = time.split(" ");
		let [hours, minutes] = timePart.split(":");
		hours = parseInt(hours, 10);
		minutes = parseInt(minutes, 10);

		if (modifier === "PM" && hours < 12) hours += 12;
		if (modifier === "AM" && hours === 12) hours = 0;

		hours = hours.toString().padStart(2, "0");
		minutes = minutes.toString().padStart(2, "0");
		return `${hours}.${minutes}`;
	} else if (time instanceof Date) {
		const hours = time.getHours().toString().padStart(2, "0");
		const minutes = time.getMinutes().toString().padStart(2, "0");
		return `${hours}.${minutes}`;
	}

	return "00.00";
};

import axios from "../lib/axios";

export const countriesAPI = async (filters) => {
	return await axios.post(`/countries`, {});
};

export const citiesAPI = async (filters) => {
	if (!filters) {
		return await axios.post(`/states`, {});
	}
	const params = {
		filters: {
			country_id: filters?.queryKey[1]?.id,
		},
	};
	return await axios.post(`/states`, params);
};

import { useFormik } from "formik";
import { Link } from "react-router-dom";

// Components
import { TextInput } from "components/Common/Form/Inputs";
import GradientButton from "components/Common/UI/GradientButton/GradientButton";

// Utils
import { getFormErrorMessage } from "utils/FormField";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./DownloadApp.module.scss";

const DownloadApp = () => {
	const formik = useFormik({
		initialValues: {
			phoneNumber: "",
		},
		validate: (data) => {
			let errors = {};

			if (!data.phoneNumber) {
				errors.phoneNumber = "Phone number is required.";
			} else if (!/^[0-9]{9,15}$/g.test(data.phoneNumber)) {
				errors.phoneNumber = "Incorrect phone number";
			}

			return errors;
		},
		onSubmit: (data, { setSubmitting }) => {
			console.log("submitted");
			setTimeout(() => {
				setSubmitting(false);
				formik.resetForm();
			}, 2000);
		},
	});

	return (
		<section className={styles.download_app}>
			<div className="container">
				<div className="row d-flex align-items-center">
					<div className="col-lg-6">
						<img
							src="/img/download-app.png"
							alt=""
							className={styles.download_app_img}
						/>
					</div>
					<div className="col-lg-6">
						<div className={styles.right_side}>
							<h2>{Tr.tr("basic:Download_App")}</h2>
							<p>
								It is a long established fact that a reader will be distracted
								by the readable content
							</p>

							{/* Download Form */}
							<form
								className={`${styles.download_form} ${
									getFormErrorMessage("phoneNumber", formik)
										? "align-items-center"
										: "align-items-end"
								}`}
								onSubmit={formik.handleSubmit}>
								{/* Phone Number */}
								<TextInput
									inputLabel={Tr.tr("basic:Get_App_Link")}
									inputName="phoneNumber"
									placeHolder={Tr.tr("basic:Enter_Phone_Number")}
									inputVal={formik.values.phoneNumber}
									changeHandler={formik.handleChange}
									inputError={getFormErrorMessage("phoneNumber", formik)}
								/>

								<GradientButton
									btn
									type="submit"
									label={Tr.tr("basic:Send_SMS")}
								/>
							</form>

							{/* Apps */}
							<div className={styles.download_btns}>
								<Link to="" className={styles.app_btn}>
									<img src="/img/app-store.svg" alt="" />
								</Link>
								<Link to="" className={styles.app_btn}>
									<img src="/img/play-store.svg" alt="" />
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default DownloadApp;

import { useContext, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";

// Utils
import { splitFromLastSlash } from "utils/SplitFromLastSlash";

// Context
import { StepContext } from "../../DoctorSignUp";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./DoctorSignUpSidebar.module.scss";

const DoctorSignUpSidebar = () => {
	const location = useLocation();

	const { currentPath, setCurrentPath } = useContext(StepContext);

	useEffect(() => {
		const result = splitFromLastSlash(location.pathname);
		console.log(result, "ooo");
		setCurrentPath(result);
	}, [currentPath, setCurrentPath, location.pathname]);

	return (
		<div className={styles.sidebar}>
			<div className={styles.sidebar_top}>
				<div className={styles.sidebar_head}>
					<h1>{Tr.tr("basic:Welcome_To_Tiryaaq")}</h1>
					<p>{Tr.tr("basic:Millions_Patients_Looking_Doctor")}</p>
				</div>

				<div className={styles.sidebar_links}>
					<NavLink
						to="create-account"
						className={`${styles.sidebar_link} ${
							currentPath === "create-account" ||
							currentPath === "account-verification"
								? styles.active
								: ""
						} ${
							currentPath === "personal-details" ||
							currentPath === "registeration-documents"
								? styles.bullet_checked
								: ""
						}`}
						onClick={(e) => e.preventDefault()}>
						<div className={styles.bullet}></div>
						<div className={styles.txt}>
							<h5>{Tr.tr("basic:Create_account")}</h5>
							<p>This is a description.</p>
						</div>
					</NavLink>
					<NavLink
						to="personal-details"
						className={`${styles.sidebar_link} ${
							currentPath === "registeration-documents"
								? styles.bullet_checked
								: ""
						}`}
						onClick={(e) => e.preventDefault()}>
						<div className={styles.bullet}></div>
						<div className={styles.txt}>
							<h5>{Tr.tr("basic:Personal_Details")}</h5>
							<p>This is a description.</p>
						</div>
					</NavLink>
					<NavLink
						to="registeration-documents"
						className={styles.sidebar_link}
						onClick={(e) => e.preventDefault()}>
						<div className={styles.bullet}></div>
						<div className={styles.txt}>
							<h5>{Tr.tr("basic:Registration_Of_Documents")}</h5>
							<p>We need to verify your information</p>
						</div>
					</NavLink>
				</div>
			</div>

			{/* Have Account */}
			<div className={styles.have_account}>
				<span>{Tr.tr("basic:Already_Have_account")}</span>
				<Link to="/doctor/login">{Tr.tr("basic:Sign_In")}</Link>
			</div>
		</div>
	);
};

export default DoctorSignUpSidebar;

import { useContext, useEffect } from "react";
import { TabPanel, TabView } from "primereact/tabview";

// Components
import MyInvoices from "./components/MyInvoices/MyInvoices";
import BillingInformation from "./components/BillingInformation/BillingInformation";

// Context
import { SidebarContext } from "context/DoctorSidebarContext";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./SettingsBilling.module.scss";

const SettingsBilling = () => {
	const { setSmallSize } = useContext(SidebarContext);

	useEffect(() => {
		setSmallSize(true);
	}, [setSmallSize]);

	// Tabview Header
	const headerTemplate = (options) => {
		return (
			<button
				type="button"
				onClick={options.onClick}
				className={`${options.className} tab_btn`}>
				{options.index === 0 && (
					<>
						<img src="/img/invoice-gray.svg" alt="" className="gray" />
						<img src="/img/invoice-gradient.svg" alt="" className="gradient" />
					</>
				)}
				{options.index === 1 && (
					<>
						<img src="/img/credit-card-gray.svg" alt="" className="gray" />
						<img
							src="/img/credit-card-gradient.svg"
							alt=""
							className="gradient"
						/>
					</>
				)}
				{options.titleElement}
			</button>
		);
	};

	return (
		<div className={styles.settings_billing}>
			<h1>{Tr.tr("doctor:Settings:Billing")}</h1>

			<div className={styles.billing_tabs}>
				<TabView>
					<TabPanel
						header={Tr.tr("doctor:Settings:My_Invoices")}
						headerTemplate={(options) => headerTemplate(options)}>
						<MyInvoices />
					</TabPanel>
					<TabPanel
						header={Tr.tr("doctor:Settings:Billing_Information")}
						headerTemplate={(options) => headerTemplate(options)}>
						<BillingInformation />
					</TabPanel>
				</TabView>
			</div>
		</div>
	);
};

export default SettingsBilling;

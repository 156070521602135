import { Button } from "primereact/button";

// Translation
import { withTranslation } from "react-i18next";
import { Tr } from "utils/i18n";

// Styles
import styles from "./AccordionHeader.module.scss";

const AccordionHeader = ({ accordionLabel }) => {
	return (
		<div className="head_content">
			<span className="accordion_label">
				{Tr.tr(`basic:${accordionLabel}`)}
			</span>
			<div className="accordion_actions">
				<Button type="button" text onClick={(e) => e.stopPropagation()}>
					<img src="/img/edit-gray.svg" alt="" />
				</Button>
				<Button type="button" text onClick={(e) => e.stopPropagation()}>
					<img src="/img/delete-gray.svg" alt="" />
				</Button>
			</div>
		</div>
	);
};

export default withTranslation()(AccordionHeader);

import { Button } from "primereact/button";

// Components
import GradientButton from "components/Common/UI/GradientButton/GradientButton";
import WhiteBlackButton from "components/Common/UI/WhiteBlackButton/WhiteBlackButton";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./AppointmentCard.module.scss";

function formatDate(dateStr) {
	const date = new Date(dateStr);
	const options = { month: "short", day: "2-digit" };
	return date.toLocaleDateString("en-US", options);
}

function formatTime(dateStr) {
	const date = new Date(dateStr);
	const options = { hour: "2-digit", minute: "2-digit", hour12: true };
	return date.toLocaleTimeString("en-US", options);
}

const AppointmentCard = ({ data, ...props }) => {
	return (
		<div className={styles.appointment_card}>
			<div className={styles.dateTime_box}>
				<img src={"/img/hospital-gray.svg"} alt="" />
				{data?.date_from && (
					<div className={styles.date}>{formatDate(data?.date_from)}</div>
				)}
				{data?.date_from && (
					<div className={styles.time}>{formatTime(data?.date_from)}</div>
				)}
			</div>
			<div className={styles.right}>
				{data?.status === "requested" && (
					<div className={`${styles.status} ${styles.waiting_for}`}>
						<img src="/img/history-gradient.svg" alt="" />
						<span className={styles.txt}>
							{Tr.tr("patient:PatientProfile:Waiting_For_Confirmation")}
						</span>
					</div>
				)}
				{data?.status === "done" && (
					<div className={`${styles.status} ${styles.completed}`}>
						<img src="/img/check-circle-green.svg" alt="" />
						<span className={styles.txt}>
							{Tr.tr("patient:PatientProfile:Completed")}
						</span>
					</div>
				)}
				{data?.status === "declined" && (
					<div className={`${styles.status} ${styles.cancelled}`}>
						<img src="/img/close-circle-red.svg" alt="" />
						<span className={styles.txt}>
							{Tr.tr("patient:PatientProfile:Reservation_Cancelled")}
						</span>
					</div>
				)}
				<div className={styles.doctor_info}>
					<div className={styles.doctor_img}>
						<img src={data?.doctor_id?.image || "/img/doctor-img.png"} alt="" />
					</div>
					<div className={styles.doctor_data}>
						<div className={styles.name_desc}>
							<div className={styles.name}>Doctor {data?.doctor_id?.name}</div>
							<div className={styles.desc}>
								{Tr.tr("patient:PatientProfile:Professor_Of")}{" "}
								{data?.doctor_id?.speciality || "Pediatrics"}
							</div>
						</div>
						<div className={styles.location}>
							<div className={styles.where}>Where</div>
							{data?.type === "in_clinic" ? (
								<p>
									{Tr.tr("patient:PatientProfile:In_Clinic")} :{" "}
									{data?.address ||
										"3 shehab street, borg alnozha - floor 5 - beside Hassan Abdou"}
								</p>
							) : data?.type === "video_call" ? (
								<div className={styles.video_call_container}>
									<p className={styles.video_call_text}>
										{Tr.tr("patient:PatientProfile:Online_Video_Call")}:
									</p>
									<a
										href={`${window.location.protocol}//${window.location.host}/patient/prepareVideoCall/${data?.id}`}
										target="_blank"
										rel="noopener noreferrer">
										<GradientButton
											btn
											type="button"
											label={Tr.tr("basic:Join")}
											classes={`${styles.join_button} p712`}
										/>
									</a>
								</div>
							) : data?.type === "phoneCall" ? (
								<p>{Tr.tr("patient:PatientProfile:Phone_Call")}</p>
							) : null}
						</div>
						<div className={styles.card_actions}>
							{data?.type === "in_clinic" && data?.status === "done" && (
								<GradientButton
									btn
									type="button"
									label={Tr.tr("patient:PatientProfile:View_Prescription")}
									withIconImg
									icon="/img/prescription.svg"
									classes={`p610`}
								/>
							)}
							<GradientButton
								btn
								type="button"
								label={Tr.tr("patient:PatientProfile:Message")}
								classes={`p610`}
								buttonClicked={props.openMessagesModal}
							/>
							<WhiteBlackButton
								btn
								type="button"
								label={Tr.tr("patient:PatientProfile:View_Profile")}
								classes={`p610`}
								outlined
							/>
						</div>

						{/* Add Feedback */}
						<div className={styles.add_feedback}>
							<Button
								type="button"
								text
								className={styles.add_feedback_btn}
								onClick={props.openFeedbackModal}>
								<img src="/img/stars.svg" alt="" />
							</Button>
							<span className={styles.add_feedback_txt}>
								{Tr.tr("patient:PatientProfile:Add_Your_Feedback")}
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AppointmentCard;

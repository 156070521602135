// Components
import Rate from "components/Common/UI/Rate/Rate";
import ProgressBarWrapper from "components/Common/UI/ProgressBarWrapper/ProgressBarWrapper";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./RatingsSummary.module.scss";

const RatingsSummary = () => {
	return (
		<section className={styles.summary}>
			<h2>{Tr.tr("doctor:Reports:Summary")}</h2>
			<div className={styles.summary_box}>
				<div className="row">
					<div className="col-lg-4 mb-3 mb-lg-0">
						<div className={styles.info_side}>
							<div className={styles.avg_ratings}>
								<div className={styles.left}>
									<h6>{Tr.tr("doctor:Reports:Average_Rating")}</h6>
									<h3>4.7</h3>
									<Rate rateVal={4} readOnly={true} />
								</div>
								<div className={styles.growth}>
									<img src="/img/grow-arrow-green.svg" alt="" />
									<span>1.3%</span>
								</div>
							</div>
							<div className={styles.total_reviews}>
								<h6>{Tr.tr("doctor:Reports:Total_Reviews")}</h6>
								<h4>1000</h4>
							</div>
						</div>
					</div>
					<div className="col-lg-8">
						<div className={styles.ratings}>
							<div className={styles.rating_row}>
								<h5>5 {Tr.tr("doctor:Reports:Stars")}</h5>
								<ProgressBarWrapper
									value={75}
									showStatus={false}
									showVal={false}
								/>
								<h6>750 (75%)</h6>
							</div>
							<div className={styles.rating_row}>
								<h5>4 {Tr.tr("doctor:Reports:Stars")}</h5>
								<ProgressBarWrapper
									value={20}
									showStatus={false}
									showVal={false}
								/>
								<h6>200 (20%)</h6>
							</div>
							<div className={styles.rating_row}>
								<h5>3 {Tr.tr("doctor:Reports:Stars")}</h5>
								<ProgressBarWrapper
									value={5}
									showStatus={false}
									showVal={false}
								/>
								<h6>50 (5%)</h6>
							</div>
							<div className={styles.rating_row}>
								<h5>2 {Tr.tr("doctor:Reports:Stars")}</h5>
								<ProgressBarWrapper
									value={0}
									showStatus={false}
									showVal={false}
								/>
								<h6>0 (0%)</h6>
							</div>
							<div className={styles.rating_row}>
								<h5>1 {Tr.tr("doctor:Reports:Stars")}</h5>
								<ProgressBarWrapper
									value={0}
									showStatus={false}
									showVal={false}
								/>
								<h6>0 (0%)</h6>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default RatingsSummary;

// Utils
import { toHoursAndMinutes } from "utils/toHoursAndMinutes";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./BookingInformation.module.scss";

const BookingInformation = ({ bookingInfo }) => {
	return (
		<div className={styles.booking_info}>
			<h3>{Tr.tr("patient:DoctorDetails:Booking_Information")}</h3>
			<div className={styles.booking_rows}>
				<div className={styles.booking_row}>
					<img src="/img/location-gradient.svg" alt="" />
					<span>
						{bookingInfo?.state_id?.name}, {bookingInfo?.country_id?.name}
					</span>
				</div>
				<div className={styles.booking_row}>
					<img src="/img/time-gradient.svg" alt="" />
					<span>{toHoursAndMinutes(bookingInfo?.waiting_time)}</span>
				</div>
				<div className={styles.booking_row}>
					<img src="/img/dollar-gradient.svg" alt="" />
					<span>
						{bookingInfo?.clinic_fees ? bookingInfo?.clinic_fees + " SAR" : ""}
					</span>
				</div>
				<div className={styles.booking_row}>
					<img src="/img/video-gradient.svg" alt="" />
					<span>
						Video Consult{" "}
						{bookingInfo?.can_make_video_consult
							? "(Available)"
							: "(Not Available)"}
					</span>
				</div>
				<div className={styles.booking_row}>
					<img src="/img/call.svg" alt="" />
					<span>
						Consultation Call{" "}
						{bookingInfo?.can_make_consultation_call
							? "(Available)"
							: "(Not Available)"}
					</span>
				</div>
			</div>
		</div>
	);
};

export default BookingInformation;

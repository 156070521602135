import { useContext, useEffect, useState } from "react";
import { NavLink, Outlet, useLocation } from "react-router-dom";

// Utils
import { splitFromLastSlash } from "utils/SplitFromLastSlash";

// Context
import { SidebarContext } from "context/DoctorSidebarContext";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./DoctorOnBoarding.module.scss";

const DoctorOnBoarding = () => {
	const { setSmallSize } = useContext(SidebarContext);

	const location = useLocation();

	// Dashboard Steps
	const dashboardSteps = [
		{
			text: Tr.tr("doctor:OnBoarding:Operational_Hours"),
			path: "operational-hours",
		},
		{
			text: Tr.tr("doctor:OnBoarding:Practice_Staff"),
			path: "practice-staff",
		},
		{
			text: Tr.tr("doctor:OnBoarding:Pricing"),
			path: "pricing",
		},
		{
			text: Tr.tr("doctor:OnBoarding:Communication"),
			path: "communication",
		},
	];

	const [currentPath, setCurrentPath] = useState("operational-hours");

	useEffect(() => {
		setSmallSize(false);
		const result = splitFromLastSlash(location.pathname);
		setCurrentPath(result);
	}, [setSmallSize, currentPath, setCurrentPath, location.pathname]);

	const renderItems = () => {
		const foundItem = dashboardSteps.find((item) => item.path === currentPath);
		return dashboardSteps.indexOf(foundItem);
	};

	const result = renderItems();

	const handleClick = (result) => {
		// Select all previous siblings based on the current index
		const previousSiblings = dashboardSteps.slice(0, result);

		// Do something with the selected siblings

		return previousSiblings;
	};

	return (
		<div className={styles.doctor_dashboard}>
			<h1>
				{Tr.tr("doctor:OnBoarding:Good_Morning")} 👋{" "}
				{Tr.tr("doctor:OnBoarding:Dr")} {localStorage.getItem("user_name")}
			</h1>

			{/* Welcome Tiryaaq */}
			<section className={styles.welcome_sec}>
				<div className="row">
					<div className="col-md-6 col-lg-8">
						<div className={styles.txt}>
							<h2>{Tr.tr("basic:Welcome_To_Tiryaaq")}</h2>
							<p>{Tr.tr("doctor:OnBoarding:Thank_You_Joining_Tiryaaq")}</p>
						</div>
					</div>
					<div className="col-md-6 col-lg-4">
						<div className={styles.welcome_img}>
							<img src="/img/hero-img.png" alt="" />
						</div>
					</div>
				</div>
			</section>

			{/* Get Started */}
			<section className={styles.get_started}>
				<h3>{Tr.tr("doctor:OnBoarding:Get_Started_With_Tiryaaq")}</h3>

				<div className={styles.get_started_content}>
					<div className={styles.get_started_links}>
						{dashboardSteps.map((step, index) => (
							<NavLink
								key={index}
								to={step.path}
								className={`${styles.get_started_link} ${
									handleClick(result).find((r) => r.path === step.path)
										? styles.currentStep
										: ""
								}`}
								onClick={(e) => e.preventDefault()}>
								<div className={styles.bullet}>
									<span>{index + 1}</span>
								</div>
								<div className={styles.txt}>{step.text}</div>
							</NavLink>
						))}
					</div>

					<div className={styles.outlet_wrapper}>
						<Outlet />
					</div>
				</div>
			</section>
		</div>
	);
};

export default DoctorOnBoarding;

// Components
import ConsultDoctor from "./components/ConsultDoctor/ConsultDoctor";
import PossibleConditions from "./components/PossibleConditions/PossibleConditions";
import YourAnswers from "./components/YourAnswers/YourAnswers";
import GradientButton from "components/Common/UI/GradientButton/GradientButton";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./Results.module.scss";

const Results = () => {
	return (
		<div className={styles.symptoms_results}>
			{/* Consult a Doctor */}
			<ConsultDoctor />

			{/* Possible Conditions */}
			<PossibleConditions />

			{/* Your Answers */}
			<YourAnswers />

			{/* Start New Checkup Btn */}
			<GradientButton
				label={Tr.tr("patient:CheckSymptoms:Start_New_Checkup")}
				redirectTo="/patient/check-symptoms?stage=1"
				classes={`w-100 p712 ${styles.new_checkup}`}
			/>
		</div>
	);
};

export default Results;

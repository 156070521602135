// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./YourAnswers.module.scss";

const YourAnswers = () => {
	// Your Answers
	const yourAnswers = [
		{
			title: "Patient",
			answers: [
				{
					text: "Sex: Male",
				},
				{
					text: "Age: 50 years",
				},
			],
		},
		{
			title: "Reported symptoms",
			answers: [
				{
					text: "Pain in lower limb",
				},
			],
		},
		{
			title: "Other symptoms",
			answers: [
				{
					text: "Limping",
				},
				{
					text: "Thigh pain",
				},
				{
					text: "Shin pain",
				},
				{
					text: "Pain in one foot",
				},
				{
					text: "Pain in lower limb lasting between 1 day and 7 days",
				},
				{
					text: "Buttocks pain",
				},
				{
					text: "Difficulty bending down",
				},
				{
					text: "Tingling or numbness",
				},
			],
		},
	];

	return (
		<div className={styles.your_answers}>
			<h2>{Tr.tr("patient:CheckSymptoms:Your_Answers")}</h2>

			<div className={styles.answers_rows}>
				{yourAnswers.map((answer, index) => (
					<div className={styles.answer_row} key={index}>
						<h3 className={styles.answer_title}>{answer.title}</h3>
						<ul className={styles.answer_list}>
							{answer.answers.map((ans, idx) => (
								<li key={idx}>{ans.text}</li>
							))}
						</ul>
					</div>
				))}
			</div>
		</div>
	);
};

export default YourAnswers;

// Styles
import styles from "./CustomEventMonthContent.module.scss";

const CustomEventMonthContent = ({ arg, ...props }) => {
	return (
		<div
			className={`event_box ${
				arg.event.extendedProps.location.toLowerCase() === "in clinic"
					? "inClinic"
					: "videoCall"
			}`}
			onClick={props.handleEventClick}>
			<p className="event_title">{arg.event.title}</p>
		</div>
	);
};

export default CustomEventMonthContent;

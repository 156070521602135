import { useContext, useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import { useMutation } from "react-query";
import { toast } from "react-hot-toast";

// Components
import TitleWithLine from "components/Common/UI/TitleWithLine/TitleWithLine";
import {
	CalendarInput,
	MultiSelectDropdown,
	SelectDropdown,
	TextAreaInput,
	TextInput,
} from "components/Common/Form/Inputs";
import GradientButton from "components/Common/UI/GradientButton/GradientButton";
import { ProgressSpinner } from "primereact/progressspinner";
import { NumericInput } from "components/Common/Form/Inputs/NumericInput/NumericInput";

// Context
import { SidebarContext } from "context/DoctorSidebarContext";

// Utils
import { getFormErrorMessage } from "utils/FormField";
import { formatDate } from "utils/formatDate";
import { stripHtmlUsingDOMParser } from "utils/stripHtmlUsingDOMParser";
import { dtoMapper } from "utils/DtoMapper";

// APIs
import { getMeApi, updateMeApi } from "api/auth";
import { getLangaugesAPI } from "api/language";
import { specialtiesAPI, titlesAPI } from "api/specialties";
import { citiesAPI } from "api/location";

// Cookie
import Cookies from "js-cookie";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./SettingsPracticeDetails.module.scss";

const SettingsPracticeDetails = () => {
	const { setSmallSize } = useContext(SidebarContext);

	useEffect(() => {
		setSmallSize(true);
	}, [setSmallSize]);
	const [isImageLoading, setIsImageLoading] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	// Handle Image Change
	const [image, setImage] = useState(null);
	const [imageFile, setImageFile] = useState(null);
	const onImageChange = (event) => {
		if (event.target.files && event.target.files[0]) {
			let img = event.target.files[0];
			setImage(URL.createObjectURL(img));
			let reader = new FileReader();
			reader.onload = function (e) {
				// e.target.result;
				setImageFile(e.target.result);
			};
			reader.readAsDataURL(event.target.files[0]);
		} else {
			console.log("No file selected");
		}
	};
	// state managements
	const [cities, setCities] = useState([]);
	const [specialities, setSpecialities] = useState([]);
	const [titles, setTitles] = useState([]);
	const [languages, setLanguages] = useState([]);
	const [me, setMe] = useState();

	// data fetching
	const { mutate: getMe } = useMutation(getMeApi, {
		onSuccess: (data) => {
			setMe(data.data);
			setImage(me?.image + `&session_id=${Cookies.get("session_id")}`);
			setTimeout(() => setIsLoading(false), 700);
		},
		onError: (error) => {
			toast.error(error.response.data.message);
			setTimeout(() => setIsLoading(false), 500);
		},
	});
	const { mutate: getSpecialities } = useMutation(specialtiesAPI, {
		onSuccess: (data) => {
			setSpecialities(dtoMapper(data.data));
		},
		// onError: (error) => {
		// 	toast.error(error.response.data.message);
		// },
	});

	const { mutate: getTitles } = useMutation(titlesAPI, {
		onSuccess: (data) => {
			setTitles(dtoMapper(data.data));
		},
		// onError: (error) => {
		// 	toast.error(error.response.data.message);
		// },
	});

	const { mutate: getLanguages } = useMutation(getLangaugesAPI, {
		onSuccess: (data) => {
			setLanguages(dtoMapper(data.data));
		},
		// onError: (error) => {
		// 	toast.error(error.response.data.message);
		// },
	});

	const { mutate: getCities } = useMutation(citiesAPI, {
		onSuccess: (data) => {
			setCities(dtoMapper(data.data));
		},
		// onError: (error) => {
		// 	toast.error(error.response.data.message);
		// },
	});

	const { mutate: updateProfile } = useMutation(updateMeApi, {
		onSuccess: (data) => {
			toast.success(data.message);
			localStorage.setItem("user_name", data.data.name);
		},
		// onError: (error) => {
		// 	toast.error(error.response.data.message);
		// },
	});

	// Experiences
	// const experiences = [
	// 	{
	// 		label: 1,
	// 		value: 1,
	// 	},
	// 	{
	// 		label: 2,
	// 		value: 2,
	// 	},
	// 	{
	// 		label: 3,
	// 		value: 3,
	// 	},
	// 	{
	// 		label: 4,
	// 		value: 4,
	// 	},
	// ];

	// Gender
	const genders = [
		{
			label: "Male",
			value: "Male",
		},
		{
			label: "Female",
			value: "Female",
		},
	];

	// Formik
	const formik = useFormik({
		initialValues: {
			image: me?.image + `&session_id=${Cookies.get("session_id")}`,
			title: me?.title?.name,
			name: me?.name,
			speciality: me?.doctor_specialty_ids && me?.doctor_specialty_ids[0]?.name,
			language: me?.lang_ids?.map((lang) => lang.name),
			experience: me?.years_of_experience,
			city: me?.state_id?.name,
			gender: me?.gender,
			birthDate: me?.birth_date || "",
			aboutMe: me?.description ? me?.description : "",
		},
		validate: (data) => {
			let errors = {};

			if (!data.name) {
				errors.name = "Name is required.";
			}

			return errors;
		},
		onSubmit: async (data, { setSubmitting }) => {
			// console.log("submitted");
			// setTimeout(() => {
			// 	setSubmitting(false);
			// 	formik.resetForm();
			// }, 2000);
			const payload = {
				name: data.name,
				doctor_specialty_ids: [
					specialities.find((s) => s.label === data.speciality)?.id,
				],
				lang_ids: languages
					.filter((lang) => data.language.includes(lang.label))
					.map((l) => l?.id),
				years_of_experience: data.experience,
				state_id: cities.find((c) => c.label === data.city)?.id,
				description: data.aboutMe,
				birth_date: data.birthDate ? formatDate(data.birthDate) : null,
				image: imageFile?.split("base64,")[1],
				gender: data?.gender ? data?.gender?.toLowerCase() : null,
				title: titles.find((title) => title.value === data?.title)?.id,
			};
			console.log(payload);
			await updateProfile(payload);
		},
	});
	const ref = useRef(false);
	useEffect(() => {
		formik.setFieldValue("title", me?.title?.name);
		formik.setFieldValue("name", me?.name);
		formik.setFieldValue(
			"speciality",
			me?.doctor_specialty_ids && me?.doctor_specialty_ids[0]?.name
		);
		formik.setFieldValue(
			"language",
			me?.lang_ids?.map((lang) => lang.name)
		);
		formik.setFieldValue("experience", me?.years_of_experience);
		formik.setFieldValue("city", me?.state_id?.name);
		formik.setFieldValue(
			"gender",
			me?.gender?.charAt(0).toUpperCase() + me?.gender?.slice(1)
		);
		formik.setFieldValue(
			"birthDate",
			me?.birth_date ? new Date(me?.birth_date) : ""
		);
		formik.setFieldValue(
			"aboutMe",
			me?.description ? stripHtmlUsingDOMParser(me?.description) : ""
		);

		if (me) return;
		setIsLoading(true);
		getMe();
		getSpecialities();
		getTitles();
		getLanguages();
		getCities();
	}, [me]);

	useEffect(() => {
		const img = new Image();
		let imageUrl;

		if (me?.image) {
			imageUrl = `${me?.image}&session_id=${Cookies.get("session_id")}`;
		} else {
			imageUrl = "/img/profile-img.png";
		}

		img.src = imageUrl;

		const handleImageLoad = () => {
			setImage(imageUrl);
			setIsImageLoading(true);
		};

		img.onload = handleImageLoad;

		if (img.complete) {
			handleImageLoad();
		}

		return () => {
			img.onload = null;
		};
	}, [me]);

	return (
		<div className={styles.settings_practiceDetails}>
			<TitleWithLine title={Tr.tr("doctor:Settings:Practice_Details")} />

			{/* Form */}
			<form onSubmit={formik.handleSubmit}>
				<div className="row">
					<div className="col-lg-6">
						<div className={styles.profile_wrapper}>
							<h6>{Tr.tr("doctor:Settings:Profile_Photo_Logo")}</h6>
							{!isImageLoading || isLoading ? (
								<ProgressSpinner />
							) : (
								<label className={styles.upload_img}>
									<input
										type="file"
										name="profileImg"
										onChange={onImageChange}
									/>
									<div className={styles.img_box}>
										<img
											src={image}
											alt={Tr.tr("doctor:Settings:Profile_Img")}
											onLoad={() => setIsImageLoading(true)}
										/>
									</div>
									<div className={styles.upload_txt}>
										<h5>{Tr.tr("doctor:Settings:Upload_Your_Photo_Logo")}</h5>
										<h6>
											{Tr.tr(
												"doctor:Settings:Photos_Help_Your_Teammates_Recognize_You"
											)}
										</h6>
									</div>
								</label>
							)}
							{/* Upload Photo */}
						</div>

						{/* Inputs */}
						<div className={styles.inputs_wrapper}>
							<div className="row">
								{/* Title & Name */}
								<div className="col-md-12">
									<div className="row">
										{/* Title */}
										<div className="col-5 col-lg-4 m-b-20">
											<SelectDropdown
												inputLabel={Tr.tr("doctor:Settings:Title")}
												inputName="title"
												placeHolder="Dr."
												options={titles}
												inputVal={formik.values.title}
												changeHandler={(e) =>
													formik.setFieldValue("title", e.value)
												}
												appendTo="self"
											/>
										</div>

										{/* Name */}
										<div className="col-7 col-lg-8 m-b-20">
											<TextInput
												inputLabel={Tr.tr("doctor:Settings:Name")}
												inputName="name"
												placeHolder="Ahmed Ali"
												inputVal={formik.values.name}
												changeHandler={formik.handleChange}
												inputError={getFormErrorMessage("name", formik)}
											/>
										</div>
									</div>
								</div>

								{/* Specialities */}
								<div className="col-md-12 m-b-20">
									<SelectDropdown
										inputLabel={Tr.tr("doctor:Settings:Specialities")}
										inputName="speciality"
										placeHolder={Tr.tr(
											"doctor:Settings:Choose_Your_Specialties"
										)}
										options={specialities}
										inputVal={formik.values.speciality}
										changeHandler={(e) =>
											formik.setFieldValue("speciality", e.value)
										}
										appendTo="self"
										inputError={getFormErrorMessage("speciality", formik)}
									/>
								</div>

								{/* Languages */}
								<div className="col-md-12 m-b-20">
									<MultiSelectDropdown
										inputLabel={Tr.tr("doctor:Settings:Languages")}
										inputName="language"
										placeHolder={Tr.tr("doctor:Settings:Languages")}
										options={languages}
										inputVal={formik.values.language}
										changeHandler={(e) =>
											formik.setFieldValue("language", e.value)
										}
										appendTo="self"
										filter={false}
										display="chip"
									/>

									<div className={styles.hint}>
										{Tr.tr(
											"doctor:Settings:Please_Choose_Languages_You_Communicating_In"
										)}
									</div>
								</div>

								{/* Experience & City */}
								<div className="col-md-12">
									<div className="row">
										{/* Experience */}
										<div className="col-md-6 m-b-20">
											<NumericInput
												inputLabel={Tr.tr(
													"doctor:Settings:Years_Of_Experience"
												)}
												inputName="experience"
												placeHolder={Tr.tr(
													"doctor:Settings:Select_Number_Of_Years"
												)}
												inputVal={formik.values.experience}
												changeHandler={formik.handleChange}
												inputError={getFormErrorMessage("experience", formik)}
												maxValue={100}
											/>
										</div>

										{/* City */}
										<div className="col-md-6 m-b-20">
											<SelectDropdown
												inputLabel={Tr.tr("doctor:Settings:City")}
												inputName="city"
												placeHolder={Tr.tr("doctor:Settings:Select_City")}
												options={cities}
												inputVal={formik.values.city}
												changeHandler={(e) =>
													formik.setFieldValue("city", e.value)
												}
												appendTo="self"
												inputError={getFormErrorMessage("city", formik)}
											/>
										</div>
									</div>
								</div>

								{/* Gender & Date Of Birth */}
								<div className="col-md-12">
									<div className="row">
										{/* Gender */}
										<div className="col-md-6 m-b-20">
											<SelectDropdown
												inputLabel={Tr.tr("doctor:Settings:Gender")}
												inputName="gender"
												placeHolder={Tr.tr("doctor:Settings:Select_Gender")}
												options={genders}
												inputVal={formik.values.gender}
												changeHandler={(e) =>
													formik.setFieldValue("gender", e.value)
												}
												appendTo="self"
												inputError={getFormErrorMessage("gender", formik)}
											/>
										</div>

										{/* Date Of Birth */}
										<div className="col-md-6 m-b-20">
											<CalendarInput
												inputLabel={Tr.tr("doctor:Settings:Date_Of_Birth")}
												inputName="birthDate"
												placeHolder="DD/MM/YYYY"
												inputVal={formik.values.birthDate}
												changeHandler={(e) =>
													formik.setFieldValue("birthDate", e.value)
												}
												appendTo={document.body}
												showIcon={true}
												view="date"
												dateFormat="mm/dd/yy"
												startDate={new Date("1920-01-01")}
												endDate={new Date()}
											/>
										</div>
									</div>
								</div>

								{/* About Me */}
								<div className="col-md-12">
									<TextAreaInput
										inputLabel={Tr.tr("doctor:Settings:About_Me")}
										inputName="aboutMe"
										placeHolder={Tr.tr("doctor:Settings:About_Me")}
										inputVal={formik.values.aboutMe}
										changeHandler={formik.handleChange}
										inputError={getFormErrorMessage("aboutMe", formik)}
									/>
								</div>
							</div>
						</div>

						<GradientButton
							btn
							type="submit"
							label={Tr.tr("doctor:Settings:Save_Changes")}
							// disabled={!formik.dirty || formik.isSubmitting}
							isLoading={formik.isSubmitting}
							classes="p712 lineH-24"
						/>
					</div>
				</div>
			</form>
		</div>
	);
};

export default SettingsPracticeDetails;

import { getAppointmentsAPI } from "api/appointments";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { getMeApi } from "api/auth";

// Components
import GradientButton from "components/Common/UI/GradientButton/GradientButton";
import AppointmentCard from "components/Patient/AppointmentCard/AppointmentCard";
import { ProgressSpinner } from "primereact/progressspinner";
import FeedBackModal from "components/Patient/FeedBackModal/FeedBackModal";
import MessagesModal from "components/Patient/MessagesModal/MessagesModal";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./UpcomingAppointment.module.scss";

const UpcomingAppointment = () => {
	const [appointments, setAppointments] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	// FeedBack Modal
	const [feedbackVisible, setFeedbackModal] = useState(false);

	// Messages Modal
	const [messagesVisible, setMessagesModal] = useState(false);

	const { mutate: getMe } = useMutation(getMeApi, {
		onMutate: () => {
			setIsLoading(true);
		},
		onSuccess: (data) => {
			getAppointments({ id: data.data.id });
		},
		onError: () => {
			setIsLoading(false);
		},
	});

	const { mutate: getAppointments } = useMutation(getAppointmentsAPI, {
		onSuccess: (data) => {
			setAppointments(data.data.data);
			setIsLoading(false);
		},
		onError: () => {
			setIsLoading(false);
		},
	});

	useEffect(() => {
		getMe();
	}, []);

	return (
		<>
			<div className={styles.upcoming_appointment}>
				<div className={styles.head}>
					<h2>{Tr.tr("patient:PatientProfile:Upcoming_Appointment")}</h2>
					<GradientButton
						label={Tr.tr("basic:View_All")}
						redirectTo="/patient/my-profile/my-appointments"
						classes="p616"
					/>
				</div>
				{isLoading ? (
					<div className={styles.spinner}>
						<ProgressSpinner />
					</div>
				) : (
					<>
						{/* Upcoming Appointments */}
						<div className={styles.upcoming_cards}>
							{appointments.map((data) => (
								<AppointmentCard
									data={data}
									key={data.id}
									openFeedbackModal={() => setFeedbackModal(true)}
									openMessagesModal={() => setMessagesModal(true)}
								/>
							))}
						</div>
					</>
				)}
			</div>

			{/* FeedBack Modal */}
			<FeedBackModal
				visible={feedbackVisible}
				hideHandler={() => setFeedbackModal(false)}
			/>

			{/* Messages Modal */}
			<MessagesModal
				visible={messagesVisible}
				hideHandler={() => setMessagesModal(false)}
			/>
		</>
	);
};

export default UpcomingAppointment;

import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

// Styles
import styles from "./CircularProgress.module.scss";

const CircularProgress = ({ percentage, pathColor, trailColor, ...props }) => {
	return (
		<div style={{ width: 72, height: 72, flexShrink: 0 }}>
			<CircularProgressbar
				value={percentage}
				text={`${percentage}%`}
				strokeWidth={15}
				styles={buildStyles({
					textColor: "#202326",
					textSize: "18px",
					pathColor: pathColor,
					trailColor: trailColor,
				})}
				className={`${styles.circular_progress} ${props.classes}`}
			/>
		</div>
	);
};

export default CircularProgress;

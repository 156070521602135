import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "react-query";

// utils
import {
	convertToFullTimestamp,
	convertFrom12To24,
} from "utils/convertToFullTimeStamp";
import { checkTimeValidity } from "utils/checkTimeValidity";
import { toast } from "react-hot-toast";

// Components
import GradientButton from "components/Common/UI/GradientButton/GradientButton";
import {
	SelectDropdownWithRadioBtn,
	SwitchInput,
	TimePicker,
} from "components/Common/Form/Inputs";

// Api
import { getMeApi, updateMeApi } from "api/auth";

// Store
import useBoardingStore from "store/onboarding";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./OperationalHours.module.scss";

const OperationalHours = () => {
	const navigate = useNavigate();

	const { toggleOnBoarding } = useBoardingStore();

	const { mutate } = useMutation(updateMeApi, {
		onSuccess: (data) => {
			// debugger
			if (data.data.configuration_stage === 1)
				navigate("/doctor/on-boarding/practice-staff");
			if (data.data.configuration_stage === 3) navigate("/doctor/dashboard");
		},
	});

	const { data } = useQuery("profile", getMeApi, {
		onSuccess: (data) => {
			// console.log(data.data.config_availability_ids);
			// formik.setFieldValue("consultOnline", data.data.can_make_video_consult);
			// formik.setFieldValue("videoDuration", "15 minutes");
			if (data.data.configuration_stage === 1)
				navigate("/doctor/on-boarding/practice-staff");
			if (data.data.configuration_stage === 3) navigate("/doctor/dashboard");
			setConsultOnline(data.data.can_make_video_consult);
			// console.log(data)
			setPracticeDays((prevPracticeDays) => {
				const updatedPracticeDays = prevPracticeDays.map((existingDay) => {
					const matchingItem = data?.data.config_availability_ids?.find(
						(item) => item.day.toLowerCase() === existingDay.day.toLowerCase()
					);
					if (matchingItem) {
						return {
							...existingDay,
							day:
								matchingItem.day[0].toUpperCase() + matchingItem.day.slice(1),
							opened: true,
							start: convertToFullTimestamp(matchingItem.from_hour),
							end: convertToFullTimestamp(matchingItem.to_hour),
						};
					}
					return existingDay;
				});
				return updatedPracticeDays;
			});
		},
	});

	// Duration
	const durations = [
		{
			label: "30 minutes",
			value: 0.5,
		},
		{
			label: "1 hour",
			value: 1.0,
		},
		{
			label: "1 hour 30 minutes",
			value: 1.5,
		},
		{
			label: "2 hour",
			value: 2.0,
		},
	];

	const [duration, setDuration] = useState(durations[1].value);

	// Consult Video Online
	const [consultOnline, setConsultOnline] = useState(false);

	// Video Consult Duration
	const videoConsultDurations = [
		{
			label: "30 minutes",
			value: 0.5,
		},
		{
			label: "1 hour",
			value: 1.0,
		},
		{
			label: "1 hour 30 minutes",
			value: 1.5,
		},
		{
			label: "2 hour",
			value: 2.0,
		},
	];

	let [videoDuration, setVideoDuration] = useState(
		videoConsultDurations[0].value
	);

	// Practice Days
	const [practiceDays, setPracticeDays] = useState([
		{
			id: 1,
			day: Tr.tr("basic:Sunday"),
			opened: false,
			start: "9:00 AM",
			end: "5:00 PM",
		},
		{
			id: 2,
			day: Tr.tr("basic:Monday"),
			opened: false,
			start: "9:00 AM",
			end: "5:00 PM",
		},
		{
			id: 3,
			day: Tr.tr("basic:Tuesday"),
			opened: false,
			start: "9:00 AM",
			end: "5:00 PM",
		},
		{
			id: 4,
			day: Tr.tr("basic:Wednesday"),
			opened: false,
			start: "9:00 AM",
			end: "5:00 PM",
		},
		{
			id: 5,
			day: Tr.tr("basic:Thursday"),
			opened: false,
			start: "9:00 AM",
			end: "5:00 PM",
		},
		{
			id: 6,
			day: Tr.tr("basic:Friday"),
			opened: false,
			start: "9:00 AM",
			end: "5:00 PM",
		},
		{
			id: 7,
			day: Tr.tr("basic:Saturday"),
			opened: false,
			start: "9:00 AM",
			end: "5:00 PM",
		},
	]);

	const toggleSwitch = (itemId) => {
		setPracticeDays((prevItems) =>
			prevItems.map((item) =>
				item.id === itemId ? { ...item, opened: !item.opened } : item
			)
		);
	};

	const handleStartTimeChange = (itemId, newTime) => {
		setPracticeDays((prevItems) =>
			prevItems.map((item) =>
				item.id === itemId ? { ...item, start: newTime } : item
			)
		);
		// console.log(newTime)
		// console.log(practiceDays)
	};

	const handleEndTimeChange = (itemId, newTime) => {
		setPracticeDays((prevItems) =>
			prevItems.map((item) =>
				item.id === itemId ? { ...item, end: newTime } : item
			)
		);
	};

	const handleAllData = async () => {
		const availableTimes = practiceDays
			.filter((data) => data.opened)
			.map((data) => {
				if (data.opened)
					return {
						from_hour: convertFrom12To24(data.start),
						to_hour: convertFrom12To24(data.end),
						day: data.day.toLowerCase(),
					};
			});
		if (!duration) {
			toast.error(`Error: Duration is required.`);
			return;
		}
		if (consultOnline && !videoDuration) {
			toast.error(`Video Duration is required.`);
			return;
		}
		if (!consultOnline) {
			console.log("object");
			videoDuration = 0.0;
		}
		const data = {
			duration_of_session: duration,
			can_make_video_consult: consultOnline,
			duration_of_video_session: videoDuration,
			config_availability_ids: availableTimes,
			configuration_stage: 1,
		};

		let invalidDays = [];
		availableTimes.forEach((timeslot) => {
			if (!checkTimeValidity(timeslot.from_hour, timeslot.to_hour)) {
				invalidDays.push(
					timeslot.day.charAt(0).toUpperCase() + timeslot.day.slice(1)
				);
			}
		});

		if (invalidDays.length > 0) {
			toast.error(
				`Error: Invalid time slots detected on the following days:\n ${invalidDays.join(
					", "
				)}.`
			);
			return;
		}
		await mutate(data);
	};
	// const defaultTime = new Date()
	// defaultTime.setHours(5,30,1)

	return (
		<div className={styles.operational_hours}>
			<div className={styles.operational_hours_content}>
				{/* Default Appointment Duration Section */}
				<section className={styles.sec_holder}>
					<div className={styles.title}>
						<h3>{Tr.tr("doctor:OnBoarding:Default_Appointment_Duration")}</h3>
						<p>{Tr.tr("doctor:OnBoarding:Choose_Long_Appointments")}</p>
					</div>

					{/* Duration */}
					<div className={styles.select_duration}>
						<div className="row">
							<div className="col-lg-3">
								<SelectDropdownWithRadioBtn
									inputLabel={Tr.tr("doctor:OnBoarding:Duration")}
									inputName="duration"
									placeHolder={Tr.tr("doctor:OnBoarding:Select_Duration")}
									options={durations}
									inputVal={duration}
									changeHandler={(e) => setDuration(e.value)}
									appendTo="self"
								/>
							</div>
						</div>
					</div>

					{/* Consult Patients */}
					<div
						className={`${styles.consult_patients} ${
							consultOnline ? styles.mb24 : ""
						}`}>
						<SwitchInput
							checked={consultOnline}
							changeHandler={(e) => setConsultOnline(e.value)}
							label={
								<div className={styles.consult_online}>
									<h5>
										{Tr.tr(
											"doctor:OnBoarding:Consult_Patients_Online_By_Video_Consult"
										)}
									</h5>
									<h6>
										{Tr.tr(
											"doctor:OnBoarding:Enable_Video_Consult_Start_Online"
										)}
									</h6>
								</div>
							}
						/>
					</div>

					{/* Video Consult Duration */}
					{consultOnline && (
						<div className={styles.video_duration}>
							<div className="row">
								<div className="col-lg-3">
									<SelectDropdownWithRadioBtn
										inputLabel={Tr.tr(
											"doctor:OnBoarding:Video_Consult_Duration"
										)}
										inputName="videoDuration"
										placeHolder={Tr.tr("doctor:OnBoarding:Select_Duration")}
										options={videoConsultDurations}
										inputVal={videoDuration}
										changeHandler={(e) => setVideoDuration(e.value)}
										appendTo="self"
									/>
								</div>
							</div>
						</div>
					)}
				</section>

				{/* Practice Days Section */}
				<section className={styles.sec_holder}>
					<div className={styles.title}>
						<h3>{Tr.tr("doctor:OnBoarding:Add_Practice_Days")}</h3>
						<p>{Tr.tr("doctor:OnBoarding:Specify_Days_Practising")}</p>
					</div>

					<div className={styles.practice_days}>
						<div className="row">
							<div className="col-xl-9">
								{practiceDays.map((data) => (
									<div className={styles.day_row} key={data.id}>
										<div className="row">
											<div className="col-md-2">{data.day}</div>
											<div className="col-md-2">
												<SwitchInput
													checked={data.opened}
													changeHandler={() => toggleSwitch(data.id)}
													label={
														data.opened
															? Tr.tr("doctor:OnBoarding:Open")
															: Tr.tr("doctor:OnBoarding:Closed")
													}
													classes={data.opened ? "" : styles.closed}
												/>
											</div>
											<div className="col-md-8">
												<div className={styles.times_picker}>
													<TimePicker
														inputLabel=""
														inputName={`start-${data.id}`}
														placeHolder={Tr.tr("doctor:OnBoarding:Start_Time")}
														inputVal={data.start}
														changeHandler={(e) =>
															handleStartTimeChange(data.id, e.target.value)
														}
														appendTo="self"
														disabled={!data.opened}
														classes={data.opened ? "" : "closed"}
													/>
													<span className={styles.to}>
														{Tr.tr("doctor:OnBoarding:To")}
													</span>
													<TimePicker
														inputLabel=""
														inputName={`end-${data.id}`}
														placeHolder={Tr.tr("doctor:OnBoarding:End_Time")}
														inputVal={data.end}
														changeHandler={(e) =>
															handleEndTimeChange(data.id, e.target.value)
														}
														appendTo="self"
														disabled={!data.opened}
														classes={data.opened ? "" : "closed"}
													/>
												</div>
											</div>
										</div>
									</div>
								))}
							</div>
						</div>
					</div>
				</section>
			</div>
			<div className={styles.footer_actions}>
				<GradientButton
					label={Tr.tr("basic:Next")}
					buttonClicked={handleAllData}
					btn={true}
					redirectTo="/doctor/on-boardign/practice-staff"
					classes="p712"
				/>
			</div>
		</div>
	);
};

export default OperationalHours;

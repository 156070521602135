import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
import { useFormik } from "formik";

// Components
import GrayButton from "components/Common/UI/GrayButton/GrayButton";
import GradientButton from "components/Common/UI/GradientButton/GradientButton";
import { TextAreaInput } from "components/Common/Form/Inputs";

// Utils
import { getFormErrorMessage } from "utils/FormField";

// Translation
import { useTranslation, withTranslation } from "react-i18next";
import { Tr } from "utils/i18n";

// Styles
import styles from "./AddAnswerDrawer.module.scss";
import { useMutation, useQueryClient } from "react-query";
import { addAnswersAPI } from "api/answers";
import toast from "react-hot-toast";

const AddAnswerDrawer = ({ ...props }) => {
    const { i18n } = useTranslation();
    const currLang = i18n.language;
    const queryClient = useQueryClient();

    const { mutate: addAnswers, isSuccess, isError } = useMutation(addAnswersAPI, {
        onSuccess: (data) => {
            toast.success("Answer has been added Successfully");
            queryClient.invalidateQueries('answers');
            // props.updateView();
            props.hideHandler();
        },
        onError: (error) => {
            toast.error(error?.response?.data?.message);
        },
    });

    // Formik
    const formik = useFormik({
        initialValues: {
            name: "",
            patient_question_id: props.question.id
        },
        validate: (values) => {
            let errors = {};

            if (!values.name) {
                errors.name = "Your answer is required.";
            }

            return errors;
        },
        onSubmit: async (values, { setSubmitting }) => {
            await addAnswers(values);
            if (isSuccess) {
                setSubmitting(false);
                formik.resetForm();
            }
        },
    });

    return (
        <Sidebar
            visible={props.visible}
            position={currLang === "ar" ? "left" : "right"}
            onHide={props.hideHandler}
            className={styles.add_drawer_holder}
            maskClassName={styles.add_drawer}
            blockScroll={true}>
            <div className={styles.drawer_header}>
                <h2>{Tr.tr("doctor:QuestionAnswer:Question_Answer")}</h2>
                <Button
                    type="button"
                    icon="pi pi-times"
                    className={styles.close_btn}
                    onClick={props.hideHandler}></Button>
            </div>

            <form className={styles.drawer_form} onSubmit={formik.handleSubmit}>
                <div className={styles.drawer_content}>
                    <div className={styles.qty_box}>
                        <h5>{props.question.doctor_specialty_id.name}</h5>
                        <div className={styles.qty_date}>
                            <h3>{props.question.name}</h3>
                            {/* <h6>September 18, 2023</h6> */}
                        </div>
                        <p>
                            {props.question.description}
                        </p>
                    </div>

                    {/* Your Answer */}
                    <TextAreaInput
                        inputLabel={Tr.tr("doctor:QuestionAnswer:Your_Answer")}
                        inputName="name"
                        placeHolder={Tr.tr("doctor:QuestionAnswer:Add_Answer")}
                        inputVal={formik.values.name}
                        changeHandler={formik.handleChange}
                        inputError={getFormErrorMessage("name", formik)}
                    />
                </div>

                <div className={styles.drawer_footer}>
                    <GrayButton
                        btn
                        type="button"
                        label={Tr.tr("basic:Discard")}
                        outlined
                        classes="p712"
                        buttonClicked={props.hideHandler}
                    />
                    <GradientButton
                        btn
                        type="submit"
                        label={Tr.tr("basic:Submit")}
                        classes="p712"
                    />
                </div>
            </form>
        </Sidebar>
    );
};

export default withTranslation()(AddAnswerDrawer);

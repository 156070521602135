export const en = {
	OnBoarding: {
		Good_Morning: "Good Morning",
		Dr: "Dr.",
		Thank_You_Joining_Tiryaaq:
			"Thank you for joining Tiryaaq. To get started, here are some elements you should take a look",
		Get_Started_With_Tiryaaq: "Get started with Tiryaaq",
		Operational_Hours: "Operational Hours",
		Practice_Staff: "Practice Staff",
		Pricing: "Pricing",
		Communication: "Communication",
		Default_Appointment_Duration: "Default Appointment Duration",
		Choose_Long_Appointments:
			"Choose how long your appointments typically go on for.",
		Duration: "Duration",
		Select_Duration: "Select Duration",
		Consult_Patients_Online_By_Video_Consult:
			"Consult your patients online by Video Consult",
		Enable_Video_Consult_Start_Online:
			"Enable the video consult solution for your clinic and start consulting your patients online.",
		Video_Consult_Duration: "Video Consult Duration",
		Add_Practice_Days: "Add Practice Days",
		Specify_Days_Practising:
			"Specify the days you'll be practising and the operating hours of your clinic.",
		Open: "Open",
		Closed: "Closed",
		Start_Time: "Start Time",
		To: "To",
		End_Time: "End Time",
		Add_Doctors: "Add Doctors",
		Add_Doctors_Practice_Your_Clinic: "Add Doctors who practice in your clinic",
		Doctor: "Doctor",
		Specialty: "Specialty",
		Mobile_Number: "Mobile Number",
		Email_Address: "Email Address",
		Add_A_Doctor: "Add a doctor",
		Add_Staff: "Add Staff",
		Add_Your_Practice_Staff: "Add your Practice staff from here.",
		Information: "Information",
		Choose_Your_Specialty: "Choose your specialty",
		Notifications: "Notifications",
		Permissions: "Permissions",
		Examination_Fees_In_Clinic: "Examination fees in the clinic",
		Price: "Price",
		Video_Consulting_Fees: "Video consulting fees",
		Patient_Communication_Preferences: "Patient Communication Preferences",
	},
	Dashboard: {
		Good_Morning: "Good Morning",
		Dr: "Dr.",
		Today_Appointments: "Today Appointments",
		Income: "Income",
		Appointments_Requests: "Appointments Requests",
		Next_Patient_Details: "Next Patient Details",
		Vitals_Signs: "Vitals Signs",
		Clinical_Notes: "Clinical Notes",
		Prescriptions: "Prescriptions",
		Lab_Test: "Lab Test",
	},
	Appointments: {
		Patient_Details: "Patient Details",
		Patient_Name: "Patient Name",
		Enter_Patient_Name: "Enter patient name",
		Mobile_Number: "Mobile Number",
		Enter_Mobile_Number: "Enter mobile number",
		Email_Address: "Email Address",
		Enter_Email_Address: "Enter email address",
		Doctor: "Doctor",
		Choose_A_Doctor: "Choose a doctor",
		Doctor_Will_Be_Notiftied: "Doctor will be notiftied",
		Appointment_Details: "Appointment Details",
	},
	Patients: {
		Patients: "Patients",
		Search_Patients: "Search Patients",
		Patient_Details: "Patient Details",
		Patient_Name: "Patient Name",
		Enter_Patient_Name: "Enter patient name",
	},
	PatientsDetails: {
		Add_Visit: "Add Visit",
		Doctor: "Doctor",
		Choose_A_Doctor: "Choose a doctor",
		Doctor_Will_Be_Notiftied: "Doctor will be notiftied",
		Appointment_Details: "Appointment Details",
	},
	Messages: {
		Select_Someone_To_Star_cthat: "Select someone to start a chat",
		Type_Your_Message: "Type Your message",
		Send: "Send",
	},
	QuestionAnswer: {
		Search_Patients: "Search Patients",
		Specialization: "Specialization",
		Not_Answered: "Not answered",
		My_Answers: "My Answers",
		Questions: "Questions",
		Sort_By: "Sort By",
		Answer_It: "Answer It",
		Question_Answer: "Question Answer",
		Your_Answer: "Your Answer",
		Add_Answer: "Add Answer",
	},
	Reports: {
		Earnings: "Earnings",
		Appointments: "Appointments",
		Ratings: "Ratings",
		VS_LAST_YEAR: "VS LAST YEAR",
		Service_Type: "Service Type",
		Summary: "Summary",
		Gross_Earnings: "Gross Earnings",
		Cost: "Cost",
		Tiryaaq_Fees: "Tiryaaq Fees",
		Net_Earnings: "Net Earnings",
		View_Payment_History: "View payment history",
		Details: "Details",
		ID: "ID",
		Date: "Date",
		Invoice_Number: "Invoice Number",
		Patient: "Patient",
		Total: "Total",
		Doctor: "Doctor",
		Scheduled_At: "Scheduled At",
		Check_In_At: "Check-in At",
		Waited_For: "Waited For",
		Checkout_At: "Checkout At",
		Average_Rating_Report: "Average Rating Report",
		Average_Rating: "Average Rating",
		Total_Reviews: "Total Reviews",
		Stars: "Stars",
	},
	Settings: {
		Settings: "Settings",
		Practice_Details: "Practice Details",
		Practice_Staff: "Practice Staff",
		Calendar: "Calendar",
		Communication: "Communication",
		Pricing_Catalog: "Pricing Catalog",
		Drug_Catalog: "Drug Catalog",
		Billing: "Billing",
		My_Documents: "My documents",
		Account_Settings: "Account Settings",
		Profile_Photo_Logo: "Profile Photo or logo",
		Profile_Img: "Profile",
		Upload_Your_Photo_Logo: "Upload your photo or logo",
		Photos_Help_Your_Teammates_Recognize_You:
			"Photos help your teammates recognize you",
		Title: "Title",
		Name: "Name",
		Specialities: "Specialities",
		Choose_Your_Specialties: "Choose your specialties",
		Languages: "Languages",
		Please_Choose_Languages_You_Communicating_In:
			"Please choose the languages you are communicating in.",
		Years_Of_Experience: "Years of Experience",
		Select_Number_Of_Years: "Select number of years",
		City: "City",
		Select_City: "Select city",
		Gender: "Gender",
		Select_Gender: "Select gender",
		Date_Of_Birth: "Date of Birth",
		About_Me: "About Me",
		Save_Changes: "Save Changes",
		Add_Doctors: "Add Doctors",
		Add_Doctors_Practice_In_Your_Clinic:
			"Add Doctors who practice in your clinic",
		Add_A_Doctor: "Add a doctor",
		Doctor: "Doctor",
		Specialty: "Specialty",
		Mobile_Number: "Mobile Number",
		Email_Address: "Email Address",
		Status: "Status",
		Active: "Active",
		Inactive: "Inactive",
		Edit: "Edit",
		Delete: "Delete",
		Add_Staff: "Add Staff",
		Add_Your_Practice_Staff_From_Here: "Add your Practice staff from here.",
		Male: "Male",
		Female: "Female",
		Default_Appointment_Duration: "Default Appointment Duration",
		Choose_How_Long_Appointments_Typically_Go_On_For:
			"Choose how long your appointments typically go on for.",
		Duration: "Duration",
		Select_Duration: "Select Duration",
		Consult_Patients_Online_By_Video_Consult:
			"Consult your patients online by Video Consult",
		Enable_Video_Consult_Solution_For_Clinic_And_Start_Consulting_Patients_Online:
			"Enable the video consult solution for your clinic and start consulting your patients online.",
		Video_Consult_Duration: "Video Consult Duration",
		Add_Practice_Days: "Add Practice Days",
		Specify_Days_You_Practising_And_Operating_Hours_Of_Clinic:
			"Specify the days you'll be practising and the operating hours of your clinic.",
		Open: "Open",
		Closed: "Closed",
		Start_Time: "Start Time",
		End_Time: "End Time",
		To: "To",
		Patient_Communication_Preferences: "Patient Communication Preferences",
		Enter_Mobile_Number: "Enter mobile number",
		Enter_Email_Address: "Enter email address",
		SMS_Language: "SMS Language",
		Select_SMS_Language: "Select SMS Language",
		Clinic_Name: "Clinic Name",
		Enter_Clinic_Name: "Enter clinic name",
		Examination_Fees_In_Clinic: "Examination fees in the clinic",
		Price: "Price",
		Video_Consulting_Fees: "Video consulting fees",
		Search_Drugs: "Search Drugs",
		Type: "Type",
        Add_Drug: "Add Drug",
        Edit_Drug: "Edit Drug",
		Strength: "Strength",
		Instructions: "Instructions",
		Results_Per_Page: "Results per page",
		Enter_Drug_Name: "Enter drug name",
		Select_Type: "Select type",
		My_Invoices: "My Invoices",
		Billing_Information: "Billing information",
		Overview: "Overview",
		Total_Balance: "Total Balance",
		Pending_Balance: "Pending Balance",
		Available_Balance: "Available Balance",
		Transaction_History: "Transaction history",
		Withdrawal_Money: "Withdrawal Money",
		Ref_ID: "Ref ID",
		Account: "Account",
		Amount: "Amount",
		Date: "Date",
		Download_Invoices: "Download invoices",
		Minimum_Withdrawal_Amount: "Minimum withdrawal amount",
		Document_Deleted_Successfully: "Document deleted successfully",
		Search_Documents: "Search Documents",
		Upload: "Upload",
		File_Name: "File Name",
		Issue_Date: "Issue Date",
		Valid_From: "Valid from",
		Expiration_Date: "Expiration Date",
		Uploaded: "Uploaded",
		Upload_Document: "Upload Document",
		Document_Type: "Document Type",
		Click_Upload_Drag_Drop: "Click to upload or drag and drop",
		Close: "Close",
		Reset_Upload: "Reset & Upload",
		Password: "Password",
		Change_Email_Address_And_Phone_Number_For_Account:
			"Change email address and phone number for your account",
		Phone_Number: "Phone Number",
		Enter_Phone_Number: "Enter phone number",
		Change_Password_For_Account: "Change the password for your account",
		Current_Password: "Current Password",
		New_Password: "New Password",
		Confirm_New_Password: "Confirm New Password",
		Password_Must_Be_Characters_Longer:
			"Password must be {{num}} characters or longer",
		Current_Password_Is_Incorrect: "Current Password is incorrect",
		Current_Password_Is_Required: "Current Password is required.",
		New_Password_Is_Required: "New Password is required.",
		Passwords_Must_Match: "Passwords must match.",
	},
};

export const ar = {
	OnBoarding: {
		Good_Morning: "صباح الخير",
		Dr: "د.",
		Thank_You_Joining_Tiryaaq:
			"شكرا لانضمامك إلى طرياك. للبدء ، إليك بعض العناصر التي يجب أن تلقي نظرة عليها",
		Get_Started_With_Tiryaaq: "ابدأ مع ترياق",
		Operational_Hours: "ساعات العمل",
		Practice_Staff: "طاقم العمل",
		Pricing: "التسعير",
		Communication: "التواصل",
		Default_Appointment_Duration: "مدة الموعد الافتراضية",
		Choose_Long_Appointments: "اختر المدة التي تستغرقها مواعيدك عادة.",
		Duration: "المدة",
		Select_Duration: "اختر المدة",
		Consult_Patients_Online_By_Video_Consult:
			"استشر مرضاك عبر الإنترنت عن طريق استشارة الفيديو",
		Enable_Video_Consult_Start_Online:
			"قم بتمكين حل استشارة الفيديو لعيادتك وابدأ في استشارة مرضاك عبر الإنترنت.",
		Video_Consult_Duration: "مدة استشارة الفيديو",
		Add_Practice_Days: "إضافة أيام التدريب",
		Specify_Days_Practising: "حدد الأيام التي ستتدرب فيها وساعات عمل عيادتك.",
		Open: "مفتوح",
		Closed: "مغلق",
		Start_Time: "وقت البدء",
		To: "إلي",
		End_Time: "وقت الانتهاء",
		Add_Doctors: "إضافة أطباء",
		Add_Doctors_Practice_Your_Clinic: "إضافة الأطباء الذين يمارسون في عيادتك",
		Doctor: "الطبيب",
		Specialty: "التخصص",
		Mobile_Number: "رقم الموبايل",
		Email_Address: "عنوان البريد الإلكتروني",
		Add_A_Doctor: "أضف طبيب",
		Add_Staff: "إضافة موظفين",
		Add_Your_Practice_Staff: "أضف طاقم الممارسة الخاص بك من هنا.",
		Information: "معلومات",
		Choose_Your_Specialty: "اختر تخصصك",
		Notifications: "الإشعارات",
		Permissions: "الأذونات",
		Examination_Fees_In_Clinic: "رسوم الفحص في العيادة",
		Price: "السعر",
		Video_Consulting_Fees: "رسوم استشارات الفيديو",
		Patient_Communication_Preferences: "تفضيلات التواصل مع المريض",
	},
	Dashboard: {
		Good_Morning: "صباح الخير",
		Dr: "د.",
		Today_Appointments: "مواعيد اليوم",
		Income: "الإيرادات",
		Appointments_Requests: "طلبات المواعيد",
		Next_Patient_Details: "تفاصيل المريض التالي",
		Vitals_Signs: "علامات حيوية",
		Clinical_Notes: "ملاحظات سريرية",
		Prescriptions: "وصفات",
		Lab_Test: "الفحوصات المخبرية",
	},
	Appointments: {
		Patient_Details: "تفاصيل المريض",
		Patient_Name: "اسم المريض",
		Enter_Patient_Name: "أدخل اسم المريض",
		Mobile_Number: "رقم الموبايل",
		Enter_Mobile_Number: "أدخل رقم الجوال",
		Email_Address: "عنوان البريد الإلكتروني",
		Enter_Email_Address: "أدخل عنوان البريد الإلكتروني",
		Doctor: "الطبيب",
		Choose_A_Doctor: "اختر الطبيب",
		Doctor_Will_Be_Notiftied: "سيتم إخطار الطبيب",
		Appointment_Details: "تفاصيل الموعد",
	},
	Patients: {
		Patients: "مرضى",
		Search_Patients: "البحث عن المرضى",
		Patient_Details: "تفاصيل المريض",
		Patient_Name: "اسم المريض",
		Enter_Patient_Name: "أدخل اسم المريض",
	},
	PatientsDetails: {
		Add_Visit: "أضف زيارة",
		Doctor: "الطبيب",
		Choose_A_Doctor: "اختر الطبيب",
		Doctor_Will_Be_Notiftied: "سيتم إخطار الطبيب",
		Appointment_Details: "تفاصيل الموعد",
	},
	Messages: {
		Select_Someone_To_Star_cthat: "حدد شخصا لبدء محادثة",
		Type_Your_Message: "اكتب رسالتك",
		Send: "ارسال",
	},
	QuestionAnswer: {
		Search_Patients: "البحث عن المرضى",
		Specialization: "التخصص",
		Not_Answered: "لم تتم الإجابة عليه",
		My_Answers: "إجاباتي",
		Questions: "اسئله",
		Sort_By: "ترتيب حسب",
		Answer_It: "أجب عليه",
		Question_Answer: "إجابة السؤال",
		Your_Answer: "إجابتك",
		Add_Answer: "اضف اجابة",
	},
	Reports: {
		Earnings: "الارباح",
		Appointments: "المواعيد",
		Ratings: "التقييمات",
		VS_LAST_YEAR: "مقابل العام الماضي",
		Service_Type: "نوع الخدمة",
		Summary: "الملخص",
		Gross_Earnings: "إجمالي الأرباح",
		Cost: "التكلفه",
		Tiryaaq_Fees: "رسوم ترياق",
		Net_Earnings: "صافي الأرباح",
		View_Payment_History: "عرض سجل الدفع",
		Details: "التفاصيل",
		ID: "الرقم التعريفي",
		Date: "التاريخ",
		Invoice_Number: "رقم الفاتورة",
		Patient: "المريض",
		Total: "الإجمالي",
		Doctor: "الطبيب",
		Scheduled_At: "مجدول في",
		Check_In_At: "تسجيل الوصول في",
		Waited_For: "انتظرت لمدة",
		Checkout_At: "الخروج في",
		Average_Rating_Report: "تقرير متوسط التقييم",
		Average_Rating: "متوسط التقييم",
		Total_Reviews: "إجمالي المراجعات",
		Stars: "نجوم",
	},
	Settings: {
		Settings: "الاعدادات",
		Practice_Details: "تفاصيل الممارسة",
		Practice_Staff: "طاقم الممارسة",
		Calendar: "التقويم",
		Communication: "التواصل",
		Pricing_Catalog: "كتالوج الأسعار",
		Drug_Catalog: "كتالوج الأدوية",
		Billing: "الفواتير",
		My_Documents: "المستندات الخاصة بي",
		Account_Settings: "إعدادات الحساب",
		Profile_Photo_Logo: "صورة الملف الشخصي أو الشعار",
		Profile_Img: "الصورة الشخصية",
		Upload_Your_Photo_Logo: "قم بتحميل صورتك أو شعارك",
		Photos_Help_Your_Teammates_Recognize_You:
			"تساعد الصور زملائك في الفريق على التعرف عليك",
		Title: "اللقب",
		Name: "الاسم",
		Specialities: "التخصصات",
		Choose_Your_Specialties: "اختر تخصصاتك",
		Languages: "اللغات",
		Please_Choose_Languages_You_Communicating_In:
			"يرجى اختيار اللغات التي تتواصل بها.",
		Years_Of_Experience: "سنوات الخبرة",
		Select_Number_Of_Years: "حدد عدد السنوات",
		City: "المدينه",
		Select_City: "اختر المدينة",
		Gender: "النوع",
		Select_Gender: "اختر النوع",
		Date_Of_Birth: "تاريخ الميلاد",
		About_Me: "معلومات عني",
		Save_Changes: "حفظ التغييرات",
		Add_Doctors: "إضافة أطباء",
		Add_Doctors_Practice_In_Your_Clinic:
			"إضافة الأطباء الذين يمارسون في عيادتك",
		Add_A_Doctor: "أضف طبيب",
		Doctor: "الطبيب",
		Specialty: "التخصص",
		Mobile_Number: "رقم الموبايل",
		Email_Address: "البريد الإلكتروني",
		Status: "الحاله",
		Active: "نشط",
		Inactive: "غير نشط",
		Edit: "تعديل",
		Delete: "حذف",
		Add_Staff: "إضافة موظفين",
		Add_Your_Practice_Staff_From_Here: "أضف طاقم الممارسة الخاص بك من هنا.",
		Male: "ذكر",
		Female: "أنثى",
		Default_Appointment_Duration: "مدة الموعد الافتراضية",
		Choose_How_Long_Appointments_Typically_Go_On_For:
			"اختر المدة التي تستغرقها مواعيدك عادة.",
		Duration: "المدة",
		Select_Duration: "اختر المدة",
		Consult_Patients_Online_By_Video_Consult:
			"استشر مرضاك عبر الإنترنت عن طريق الفيديو",
		Enable_Video_Consult_Solution_For_Clinic_And_Start_Consulting_Patients_Online:
			"قم بتمكين حل استشارة الفيديو لعيادتك وابدأ في استشارة مرضاك عبر الإنترنت.",
		Video_Consult_Duration: "مدة استشارة الفيديو",
		Add_Practice_Days: "إضافة أيام التدريب",
		Specify_Days_You_Practising_And_Operating_Hours_Of_Clinic:
			"حدد الأيام التي ستتدرب فيها وساعات عمل عيادتك.",
		Open: "مفتوح",
		Closed: "مغلق",
		Start_Time: "وقت البدء",
		End_Time: "وقت الانتهاء",
		To: "إلي",
		Patient_Communication_Preferences: "تفضيلات التواصل مع المريض",
		Enter_Mobile_Number: "أدخل رقم الجوال",
		Enter_Email_Address: "أدخل عنوان البريد الإلكتروني",
		SMS_Language: "لغة الرسائل القصيرة",
		Select_SMS_Language: "اختر لغة الرسائل القصيرة",
		Clinic_Name: "اسم العيادة",
		Enter_Clinic_Name: "أدخل اسم العيادة",
		Examination_Fees_In_Clinic: "رسوم الفحص في العيادة",
		Price: "السعر",
		Video_Consulting_Fees: "رسوم استشارات الفيديو",
		Search_Drugs: "البحث عن الأدوية",
		Type: "النوع",
        Add_Drug: "إضافة دواء",
        Edit_Drug: "تعديل دواء",
		Strength: "القوة",
		Instructions: "الارشادات",
		Results_Per_Page: "النتائج لكل صفحة",
		Enter_Drug_Name: "أدخل اسم الدواء",
		Select_Type: "اختر النوع",
		My_Invoices: "فواتيري",
		Billing_Information: "معلومات الفوترة",
		Overview: "نظره عامه",
		Total_Balance: "إجمالي الرصيد",
		Pending_Balance: "الرصيد المعلق",
		Available_Balance: "الرصيد المتاح",
		Transaction_History: "سجل المعاملات",
		Withdrawal_Money: "سحب الأموال",
		Ref_ID: "رقم المرجع",
		Account: "الحساب",
		Amount: "المبلغ",
		Date: "التاريخ",
		Download_Invoices: "تنزيل الفواتير",
		Minimum_Withdrawal_Amount: "الحد الأدنى لمبلغ السحب",
		Document_Deleted_Successfully: "تم حذف المستند بنجاح",
		Search_Documents: "البحث في المستندات",
		Upload: "رفع",
		File_Name: "اسم الملف",
		Issue_Date: "تاريخ الإصدار",
		Valid_From: "صالح من",
		Expiration_Date: "تاريخ انتهاء الصلاحية",
		Uploaded: "تم الرفع",
		Upload_Document: "رفع المستندات",
		Document_Type: "نوع المستند",
		Click_Upload_Drag_Drop: "انقر للتحميل أو السحب والإفلات",
		Close: "غلق",
		Reset_Upload: "إعادة تعيين وتحميل",
		Password: "كلمه المرور",
		Change_Email_Address_And_Phone_Number_For_Account:
			"تغيير عنوان البريد الإلكتروني ورقم الهاتف لحسابك",
		Phone_Number: "رقم الهاتف",
		Enter_Phone_Number: "أدخل رقم الهاتف",
		Change_Password_For_Account: "تغيير كلمة المرور لحسابك",
		Current_Password: "كلمة المرور الحالية",
		New_Password: "كلمة مرور جديدة",
		Confirm_New_Password: "تأكيد كلمة المرور الجديدة",
		Password_Must_Be_Characters_Longer:
			"يجب أن تتكون كلمة المرور من {{num}} أحرف أو أكثر",
		Current_Password_Is_Incorrect: "كلمة المرور الحالية غير صحيحة",
		Current_Password_Is_Required: "كلمة المرور الحالية مطلوبة.",
		New_Password_Is_Required: "مطلوب كلمة مرور جديدة.",
		Passwords_Must_Match: "يجب أن تتطابق كلمات المرور.",
	},
};

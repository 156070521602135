// Components
import BreadCrumbWrapper from "components/Common/UI/BreadCrumbWrapper/BreadCrumbWrapper";
import DoctorInfo from "./components/DoctorInfo/DoctorInfo";
import SpecialityDoctors from "./components/SpecialityDoctors/SpecialityDoctors";
import { useParams } from "react-router-dom";

// Styles
import styles from "./DoctorDetails.module.scss";
import DownloadApp from "components/Patient/DownloadApp/DownloadApp";
import { useState } from "react";
import { getDoctorAPI } from "api/doctors";
import { useQuery } from "react-query";

const DoctorDetails = () => {

	const params = useParams();


	// console.log(params.id);
	// react query
	const { data: doctorData, isLoading: doctorLoading } = useQuery(
		["doctor", params.id],
		getDoctorAPI,
		{
			select: (response) => {
				// console.log(response?.data);
				// setDoctorRouter(response.data.doctor_specialty_ids[0].id,  response.data.doctor_specialty_ids[0].name , response.data.state_id.name+', '+response.data.country_id.name , response.data.name)
				return response?.data;
			},
		}
	);

	return (
		<div className={styles.doctor_details}>
			{/* Bread Crumb */}
			<BreadCrumbWrapper
				items={[
					{ label: doctorData?.state_id?.name + ", " + doctorData?.country_id?.name },
					{ label: doctorData?.name },
				]}
				homeItem={{
					label: doctorData?.doctor_specialty_ids[0]?.name,
					url: "/patient/find-a-doctor?doctor_specialty_ids=" + doctorData?.doctor_specialty_ids[0]?.id,
				}}
			/>
			{/* Doctor Info [Top Section] */}
			<DoctorInfo doctorData={doctorData} doctorLoading={doctorLoading} doctorId={params.id} />

			{/* Doctors in pediatrics */}
			<SpecialityDoctors />

			{/* Download App */}
			<DownloadApp />
		</div>
	);
};

export default DoctorDetails;

import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
import { useFormik } from "formik";

// Components
import GradientButton from "components/Common/UI/GradientButton/GradientButton";
import {
    SelectDropdown,
    TextAreaInput,
    TextInput,
} from "components/Common/Form/Inputs";
import GrayButton from "components/Common/UI/GrayButton/GrayButton";

// Utils
import { getFormErrorMessage } from "utils/FormField";

// Translation
import { useTranslation, withTranslation } from "react-i18next";
import { Tr } from "utils/i18n";

// Styles
import styles from "./AddDrugDrawer.module.scss";
import { useMutation, useQueryClient } from "react-query";
import { addDrugsCatalogsAPI, editDrugsCatalogsAPI } from "api/drugs";
import toast from "react-hot-toast";

const AddDrugDrawer = ({ ...props }) => {
    const { i18n } = useTranslation();
    const currLang = i18n.language;
    const queryClient = useQueryClient();
    const { mutate: addDrugCatalog, isSuccess, isError } = useMutation(addDrugsCatalogsAPI, {
        onSuccess: (data) => {
            toast.success("the drug has been added Successfully");
            queryClient.invalidateQueries('drug-catalogs');
            // props.updateView();
            props.hideHandler();
        },
        onError: (error) => {
            toast.error(error?.response?.data?.message);
        },
    });

    const { mutate: editDrugCatalog, isSuccess: editSuccess, isError: editFail } = useMutation(editDrugsCatalogsAPI, {
        onSuccess: (data) => {
            toast.success("the drug has been edited Successfully");
            queryClient.invalidateQueries('drug-catalogs');
            // props.updateView();
            props.hideHandler();
        },
        onError: (error) => {
            toast.error(error?.response?.data?.message);
        },
    });

    // Types
    const types = [
        { label: "Tablet", value: "tablet" },
        { label: "Capsule", value: "capsule" },
    ];

    // Formik
    const formik = useFormik({
        initialValues: {
            name: props?.editableDrug?.name || "",
            type: props?.editableDrug?.type || null,
            strength: props?.editableDrug?.strength || 0,
            instructions: props?.editableDrug?.instructions || "",
        },
        validate: (data) => {
            let errors = {};

            if (!data.name) {
                errors.name = "Name is required.";
            }

            return errors;
        },
        onSubmit: (data, { setSubmitting }) => {
            if (props.flag == 'edit') {
                editDrugCatalog({id: props.editableDrug.id, data: data})
                if (editSuccess) {
                    setSubmitting(false)
                }
            } else {
                addDrugCatalog(data)
                if (isSuccess) {
                    setSubmitting(false)
                }
            }
            // setTimeout(() => {
            // 	setSubmitting(false);
            // 	formik.resetForm();
            // }, 2000);
        },
    });

    return (
        <Sidebar
            visible={props.visible}
            position={currLang === "ar" ? "left" : "right"}
            onHide={props.hideHandler}
            className={styles.add_drawer_holder}
            maskClassName={styles.add_drawer}
            blockScroll={true}>
            <div className={styles.drawer_header}>
                <h2>{props.flag == 'edit' ? Tr.tr("doctor:Settings:Edit_Drug") : Tr.tr("doctor:Settings:Add_Drug")}</h2>
                <Button
                    icon="pi pi-times"
                    className={styles.close_btn}
                    onClick={props.hideHandler}></Button>
            </div>
            <form className={styles.drawer_form} onSubmit={formik.handleSubmit}>
                <div className={styles.drawer_content}>
                    <div className="row">
                        {/* Name */}
                        <div className="col-md-12 mb-4">
                            <TextInput
                                inputLabel={Tr.tr("doctor:Settings:Name")}
                                inputName="name"
                                placeHolder={Tr.tr("doctor:Settings:Enter_Drug_Name")}
                                inputVal={formik.values.name}
                                changeHandler={formik.handleChange}
                                inputError={getFormErrorMessage("name", formik)}
                            />
                        </div>

                        {/* Type */}
                        <div className="col-md-12 mb-4">
                            <SelectDropdown
                                inputLabel={Tr.tr("doctor:Settings:Type")}
                                inputName="type"
                                placeHolder={Tr.tr("doctor:Settings:Select_Type")}
                                options={types}
                                inputVal={formik.values.type}
                                changeHandler={(e) => formik.setFieldValue("type", e.value)}
                                appendTo="self"
                            />
                        </div>

                        {/* Strength */}
                        <div className="col-md-12 mb-4">
                            <TextInput
                                inputLabel={Tr.tr("doctor:Settings:Strength")}
                                inputName="strength"
                                placeHolder="0"
                                inputVal={formik.values.strength}
                                changeHandler={formik.handleChange}
                                inputError={getFormErrorMessage("strength", formik)}
                            />
                        </div>

                        {/* Instructions */}
                        <div className="col-md-12 mb-4">
                            <TextAreaInput
                                inputLabel={Tr.tr("doctor:Settings:Instructions")}
                                inputName="instructions"
                                placeHolder="0"
                                inputVal={formik.values.instructions}
                                changeHandler={formik.handleChange}
                                inputError={getFormErrorMessage("instructions", formik)}
                            />
                        </div>
                    </div>
                </div>

                <div className={styles.drawer_footer}>
                    <GrayButton
                        btn
                        type="button"
                        label={Tr.tr("basic:Discard")}
                        outlined
                        classes="p712"
                        buttonClicked={() => props.hideHandler()}
                    />
                    <GradientButton
                        btn
                        type="submit"
                        label={props.flag == 'edit' ? Tr.tr("basic:Edit") : Tr.tr("basic:Add")}
                        classes={`p712 ${styles.done_btn}`}
                    />
                </div>
            </form>
        </Sidebar>
    );
};

export default withTranslation()(AddDrugDrawer);

import { useState } from "react";

// Components
import { SelectDropdown } from "components/Common/Form/Inputs";
import GradientButton from "components/Common/UI/GradientButton/GradientButton";
import AddAnswerDrawer from "../AddAnswerDrawer/AddAnswerDrawer";

// Translation
import { Tr } from "utils/i18n.js";

// Styles
import Pagination from "components/Common/UI/Pagination/Pagination";
import styles from "./NotAnswered.module.scss";

const NotAnswered = ({ notAnsweredQuestionsData }) => {
    // Sort By [Options]
    const sortByOptions = [
        {
            label: "Most Recent",
            value: "Most Recent",
        },
    ];

    const [sortBy, setSortBy] = useState(sortByOptions[0].value);

    // Show Add Answer Drawer
    const [answerDrawer, setAnswerDrawer] = useState({ status: false, question: null });

    return (
        <>
            <div className={styles.not_answered}>
                <div className={styles.not_answered_head}>
                    <div className={styles.qty_count}>
                        {notAnsweredQuestionsData?.number_of_items} {Tr.tr("doctor:QuestionAnswer:Questions")}
                    </div>

                    {/* Sort By */}
                    <div className={styles.sort_by}>
                        <div className={styles.sort_lbl}>
                            {Tr.tr("doctor:QuestionAnswer:Sort_By")}:
                        </div>
                        <SelectDropdown
                            inputLabel=""
                            inputName="sortBy"
                            placeHolder={Tr.tr("doctor:QuestionAnswer:Sort_By")}
                            options={sortByOptions}
                            inputVal={sortBy}
                            changeHandler={(e) => setSortBy(e.value)}
                            appendTo="self"
                        />
                    </div>
                </div>

                <div className={styles.not_answered_qtys}>
                    {notAnsweredQuestionsData?.data.map((qty) => (
                        <div className={styles.qty_box} key={qty.id}>
                            <div className={styles.txt}>
                                <h5>{qty.doctor_specialty_id.name}</h5>
                                <div className={styles.qty_date}>
                                    <h3>{qty.name}</h3>
                                    {/* <h6>{qty.date}</h6> */}
                                </div>
                                <p>{qty.description}</p>
                            </div>
                            <GradientButton
                                btn
                                type="button"
                                label={Tr.tr("doctor:QuestionAnswer:Answer_It")}
                                classes="p712 flex-shrink-0"
                                buttonClicked={() => setAnswerDrawer({ status: true, question: qty })}
                            />
                        </div>
                    ))}
                </div>
            </div>

            <Pagination
                number_of_pages={notAnsweredQuestionsData?.number_of_pages}
                number_of_products={notAnsweredQuestionsData?.number_of_items}
            />

            {/* Add Answer Drawer */}
            {answerDrawer.status && <AddAnswerDrawer
                visible={answerDrawer.status}
                hideHandler={() => {
                    setAnswerDrawer({ status: false, question: null });
                }}
                question={answerDrawer.question}
            />}
        </>
    );
};

export default NotAnswered;

import { useFormik } from "formik";
import { OverlayPanel } from "primereact/overlaypanel";
import { TabPanel, TabView } from "primereact/tabview";
import { useContext, useEffect, useRef, useState } from "react";

// Components
import { CheckBox, SearchInput } from "components/Common/Form/Inputs";
import GradientButton from "components/Common/UI/GradientButton/GradientButton";
import MyAnswers from "./components/MyAnswers/MyAnswers";
import NotAnswered from "./components/NotAnswered/NotAnswered";

// Context
import { SidebarContext } from "context/DoctorSidebarContext";

// Translation
import { Tr } from "utils/i18n";

// Styles
import { answersAPI } from "api/answers";
import { getAllQuestionsAPI } from "api/questions";
import { specialtiesAPI } from "api/specialties";
import { useQuery } from "react-query";
import styles from "./DoctorQuestionAnswer.module.scss";

const DoctorQuestionAnswer = () => {
    const { setSmallSize } = useContext(SidebarContext);

    // Search
    // const [searchQuery, setSearchQuery] = useState("");
    const [filtersObj, setFiltersObj] = useState({
        page: 1,
        search: '',
        order: '',
        filters: { doctor_specialty_id: [] }
    });

    // fetch all answers
    const { data: MyAnswersData, } = useQuery(
        ["answers"],
        answersAPI,
        {
            select: (response) => {
                return response.data;
            },
        }
    );

    //get questions 
    const { data: notAnsweredQuestionsData, refetch } = useQuery(
        ["questions", filtersObj],
        getAllQuestionsAPI,
        {
            select: (response) => {
                return response.data;
            },
        }
    );

    // Specialization Dropdown
    const op = useRef(null);

    useEffect(() => {
        setSmallSize(false);
    }, [setSmallSize]);

    // Specializations
    const { data: specialitiesData, isLoading } = useQuery("specialities", specialtiesAPI);

    const [selectedspecializations, setSelectedspecializations] = useState([]);
    const [selectedspecializationsIds, setSelectedspecializationsIds] = useState([]);

    // Form
    const formik = useFormik({
        initialValues: {
            search: "",
            specialization: [],
        },
        validate: (data) => {
            let errors = {};

            if (!data.search) {
                errors.search = "Search is required.";
            }

            return errors;
        },
        onSubmit: (data, { setSubmitting }) => {
            console.log("submitted");
            setTimeout(() => {
                setSubmitting(false);
                formik.resetForm();
            }, 2000);
        },
    });

    // Checkbox selection Change
    const onSelectionChange = (e, selectedOptions) => {
        let _selectedOptions = [...selectedOptions];
        let _selectedOptionsIds = [...selectedspecializationsIds];

        if (e.checked) {
            _selectedOptions.push(e.value);
            _selectedOptionsIds.push(e.value.id);
        } else {
            for (let i = 0; i < _selectedOptions.length; i++) {
                const selectedOption = _selectedOptions[i];
                if (selectedOption.id === e.value.id) {
                    _selectedOptions.splice(i, 1);
                    _selectedOptionsIds.splice(i, 1);
                    break;
                }
            }
        }

        setSelectedspecializations(_selectedOptions);
        // setSelectedspecializationsIds(_selectedOptionsIds);
        setFiltersObj({ ...filtersObj, filters: { doctor_specialty_id: _selectedOptionsIds } })
    };

    // Tabview Header
    const headerTemplate = (options, count) => {
        return (
            <button
                type="button"
                onClick={options.onClick}
                className={`${options.className} tab_btn`}>
                <span className="title">{options.titleElement}</span>
                <span className="count">{count}</span>
            </button>
        );
    };

    return (
        <div className={styles.doctor_qty_answer}>
            <div className={styles.head}>
                <h1>{Tr.tr("basic:Question_Answer")}</h1>
                <div className={styles.head_actions}>
                    {/* Search */}
                    <SearchInput
                        inputLabel=""
                        inputName="search"
                        placeHolder={Tr.tr("doctor:QuestionAnswer:Search_Patients")}
                        inputVal={filtersObj.search}
                        changeHandler={(e) => setFiltersObj({ ...filtersObj, search: e.target.value })}
                    />

                    {/* Specialization */}
                    <div className={styles.specialization_dropdown}>
                        <GradientButton
                            btn
                            type="button"
                            label={Tr.tr("doctor:QuestionAnswer:Specialization")}
                            outlined
                            withIconImg
                            icon="/img/arrow-drop-down.svg"
                            buttonClicked={(e) => op.current.toggle(e)}
                            rightPos
                            classes="p712"
                        />

                        <OverlayPanel ref={op} className={styles.filter_overlay}>
                            <form
                                className={styles.form_holder}
                                onSubmit={formik.handleSubmit}>
                                {/* Search */}
                                <SearchInput
                                    inputLabel=""
                                    inputName="search"
                                    placeHolder={Tr.tr("basic:Search")}
                                    inputVal={formik.values.search}
                                    changeHandler={formik.handleChange}
                                />

                                {/* Options */}
                                <div className={styles.checkboxes}>
                                    {specialitiesData?.data?.map((s, index) => (
                                        <div className={styles.CheckBox_row} key={index}>
                                            <CheckBox
                                                name="specialization"
                                                label={s.name}
                                                value={s}
                                                handleChange={(e) =>
                                                    onSelectionChange(e, selectedspecializations)
                                                }
                                                checked={selectedspecializations.some(
                                                    (item) => item.id === s.id
                                                )}
                                                key={s.id}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </form>
                        </OverlayPanel>
                    </div>
                </div>
            </div>

            <div className={styles.answer_tabs}>
                <TabView>
                    <TabPanel
                        header={Tr.tr("doctor:QuestionAnswer:Not_Answered")}
                        headerTemplate={(options) => headerTemplate(options, notAnsweredQuestionsData?.number_of_items)}>
                        <NotAnswered notAnsweredQuestionsData={notAnsweredQuestionsData} />
                    </TabPanel>
                    <TabPanel
                        header={Tr.tr("doctor:QuestionAnswer:My_Answers")}
                        headerTemplate={(options) => headerTemplate(options, MyAnswersData?.number_of_items)}>
                        <MyAnswers MyAnswersData={MyAnswersData} />
                    </TabPanel>
                </TabView>
            </div>
        </div>
    );
};

export default DoctorQuestionAnswer;

import { useState } from "react";
import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { toast } from "react-hot-toast";

// Components
import {
	CheckBox,
	PasswordInput,
	SelectDropdown,
	TextInput,
} from "components/Common/Form/Inputs";
import GradientButton from "components/Common/UI/GradientButton/GradientButton";

// Utils
import { getFormErrorMessage } from "utils/FormField";

// Api
import { signupAPI } from "api/auth";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./CreateAccount.module.scss";

const CreateAccount = () => {
	const navigate = useNavigate();

	const { mutate } = useMutation(signupAPI, {
		onSuccess: async (data) => {
			// console.log(data);
			navigate("/doctor/sign-up/account-verification");
		},
	});

	// Phones
	const phones = [
		{ label: "+966", value: "+966" },
		{ label: "+20", value: "+20" },
		{ label: "+90", value: "+90" },
		{ label: "+40", value: "+40" },
	];

	const [phone, setPhone] = useState(phones[0].value);

	// Formik
	const formik = useFormik({
		initialValues: {
			name: "",
			email: "",
			phone: "",
			password: "",
			agree: false,
		},
		validate: (data) => {
			let errors = {};

			if (!data.name) {
				errors.name = "Name is required.";
			}

			if (!data.email) {
				errors.email = "Email is required.";
			} else if (
				!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)
			) {
				errors.email = "Invalid email address. E.g. user@example.com";
			}

			// if (!data.phone) {
			// 	errors.phone = "Phone number is required.";
			// } else if (!/^[0-9]{9,15}$/g.test(data.phone)) {
			// 	errors.phone = "Incorrect phone number";
			// }

			if (!data.password) {
				errors.password = "Password is required.";
			}

			return errors;
		},
		onSubmit: async (data, { setSubmitting }) => {
			// setTimeout(() => {
			// 	setSubmitting(false);
			// 	formik.resetForm();
			// }, 2000);
			let payload = {
				...data,
				tiryaaq_type: "doctor",
				phone: phone + data.phone,
			};
			localStorage.setItem("countryCode", phone);
			localStorage.setItem("phoneNumber", data.phone);
			localStorage.setItem("user_name", data.name);
			// TODO : To be edited
			// localStorage.setItem("email", data.email);
			localStorage.setItem("password", data.password);
			await mutate(payload);
		},
	});

	return (
		<div className={styles.create_account}>
			<div className={styles.head}>
				<h2>{Tr.tr("basic:Create_account")}</h2>
				<p>This is a description.</p>
			</div>

			<form className={styles.form_holder} onSubmit={formik.handleSubmit}>
				<div className={styles.inputs_wrapper}>
					<div className="row">
						{/* Name */}
						<div className="col-md-12 m-b-20">
							<TextInput
								inputLabel={Tr.tr("basic:Name")}
								inputName="name"
								placeHolder={Tr.tr("basic:Name")}
								inputVal={formik.values.name}
								changeHandler={formik.handleChange}
								inputError={getFormErrorMessage("name", formik)}
								required={true}
							/>
						</div>

						{/* Email */}
						<div className="col-md-12 m-b-20">
							<TextInput
								inputLabel={Tr.tr("basic:Email_Address")}
								inputName="email"
								placeHolder={Tr.tr("basic:Email_Address")}
								inputVal={formik.values.email}
								changeHandler={formik.handleChange}
								inputError={getFormErrorMessage("email", formik)}
								required={true}
							/>
						</div>

						{/* Phone Number */}
						<div className="col-md-12 m-b-20">
							<h6 className={styles.inpt_lbl}>{Tr.tr("basic:Phone_Number")}</h6>
							<div className="row">
								<div className="col-5 col-lg-4 col-xl-3">
									<SelectDropdown
										inputLabel=""
										inputName="country_code"
										placeHolder="+966"
										options={phones}
										inputVal={phone}
										changeHandler={(e) => {
											setPhone(e.value);
										}}
										appendTo="self"
									/>
								</div>
								<div className="col-7 col-lg-8 col-xl-9">
									<TextInput
										inputLabel=""
										inputName="phone"
										placeHolder={Tr.tr("basic:Enter_Phone_Number")}
										inputVal={formik.values.phone}
										changeHandler={formik.handleChange}
										inputError={getFormErrorMessage("phone", formik)}
									/>
								</div>
							</div>
						</div>

						{/* Password */}
						<div className="col-md-12">
							<PasswordInput
								inputLabel={Tr.tr("basic:Password")}
								inputName="password"
								placeHolder={Tr.tr("basic:Password")}
								inputVal={formik.values.password}
								changeHandler={formik.handleChange}
								inputError={getFormErrorMessage("password", formik)}
								required={true}
							/>
						</div>
					</div>
				</div>

				{/* Agree Terms & Conditions */}
				<div className={styles.agree}>
					<CheckBox
						name="agree"
						label={
							<div className={styles.agree_label}>
								{Tr.tr("basic:I_Agree_To")} <Link to="">{Tr.tr("basic:Terms_Of_Use")}</Link> {Tr.tr("basic:And")}{" "}
								<Link to="">{Tr.tr("basic:Privacy_Policy")}</Link>
							</div>
						}
						value={formik.values.agree}
						handleChange={formik.handleChange}
						checked={formik.values.agree}
					/>
				</div>

				<GradientButton
					btn
					type="submit"
					label={Tr.tr("basic:Sign_Up")}
					disabled={
						!(formik.isValid && formik.dirty) ||
						formik.isSubmitting ||
						formik.values.agree === false
					}
					isLoading={formik.isSubmitting}
					classes="w-100"
				/>
			</form>
		</div>
	);
};

export default CreateAccount;

import { useState } from "react";
import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import { SelectButton } from "primereact/selectbutton";

// Components
import {
	CalendarInput,
	CheckBox,
	SelectDropdown,
	TextAreaInput,
	TimePicker,
} from "components/Common/Form/Inputs";
import GrayButton from "components/Common/UI/GrayButton/GrayButton";
import GradientButton from "components/Common/UI/GradientButton/GradientButton";

// Utils
import { getFormErrorMessage } from "utils/FormField";

// Translation
import { useTranslation, withTranslation } from "react-i18next";
import { Tr } from "utils/i18n";

// Styles
import styles from "./AddVisitDrawer.module.scss";

const AddVisitDrawer = ({ ...props }) => {
	const { i18n } = useTranslation();
	const currLang = i18n.language;

	// Selection Options
	const options = [
		{
			icon: "/img/hospital.svg",
			label: Tr.tr("basic:In_Clinic"),
			value: "inClinic",
		},
		{
			icon: "/img/video.svg",
			label: Tr.tr("basic:Video_Call"),
			value: "videoCall",
		},
	];
	const [value, setValue] = useState(options[0].value);

	// Doctors
	const doctors = [
		{
			name: "AG",
			label: "First option",
			desc: "Description",
			value: "option1",
		},
		{
			name: "AG",
			label: "Second option",
			desc: "Description",
			value: "option2",
		},
		{
			name: "AG",
			label: "Third option",
			desc: "Description",
			value: "option3",
		},
		{
			name: "AG",
			label: "Fourth option",
			desc: "Description",
			value: "option4",
		},
		{
			name: "AG",
			label: "Another option",
			desc: "Description",
			value: "option5",
		},
	];

	const [selectedMethod, setSelectedMethod] = useState([]);

	// Notifications
	const notificationMethods = [
		{
			label: Tr.tr("basic:SMS"),
			value: "SMS",
		},
		{
			label: Tr.tr("basic:Email"),
			value: "Email",
		},
	];

	// Formik
	const formik = useFormik({
		initialValues: {
			patientName: "",
			mobileNumber: "",
			emailAddress: "",
			doctor: null,
			scheduledOn: "",
			at: "",
			notes: "",
		},
		validate: (data) => {
			let errors = {};

			if (!data.patientName) {
				errors.patientName = "Patient name is required.";
			}

			return errors;
		},
		onSubmit: (data, { setSubmitting }) => {
			console.log("submitted");
			setTimeout(() => {
				setSubmitting(false);
				formik.resetForm();
			}, 2000);
		},
	});

	// Handle Notofication Methods Checkboxes
	const onSelectionChange = (e, selectedOptions) => {
		let _selectedOptions = [...selectedOptions];

		if (e.checked) {
			_selectedOptions.push(e.value);
		} else {
			for (let i = 0; i < _selectedOptions.length; i++) {
				const selectedOption = _selectedOptions[i];

				if (selectedOption.value === e.value.value) {
					_selectedOptions.splice(i, 1);
					break;
				}
			}
		}

		setSelectedMethod(_selectedOptions);
	};

	// Selection Button Options
	const optionsTemplate = (option) => {
		return (
			<>
				<img src={option.icon} alt="" />
				<span className="btn_lbl">{option.label}</span>
			</>
		);
	};

	return (
		<Sidebar
			visible={props.visible}
			position={currLang === "ar" ? "left" : "right"}
			onHide={props.hideHandler}
			className={styles.add_drawer_holder}
			maskClassName={styles.add_drawer}
			blockScroll={true}>
			<div className={styles.drawer_header}>
				<h2>{Tr.tr("basic:Add_Appointment")}</h2>
				<Button
					type="button"
					icon="pi pi-times"
					className={styles.close_btn}
					onClick={props.hideHandler}></Button>
			</div>
			<form className={styles.drawer_form} onSubmit={formik.handleSubmit}>
				<div className={styles.drawer_content}>
					{/* Doctor */}
					<div className={styles.form_sec}>
						<h3>{Tr.tr("doctor:PatientsDetails:Doctor")}</h3>
						<div className="row">
							{/* Doctor Dropdown */}
							<div className="col-md-12 mb-4">
								<SelectDropdown
									inputLabel={Tr.tr("doctor:PatientsDetails:Doctor")}
									inputName="doctor"
									placeHolder={Tr.tr("doctor:PatientsDetails:Choose_A_Doctor")}
									options={doctors}
									inputVal={formik.values.doctor}
									changeHandler={(e) => formik.setFieldValue("doctor", e.value)}
									appendTo={document.body}
									composedOptopn={true}
								/>
							</div>

							<div className="col-md-12">
								<div className={styles.choices_rows}>
									<div className="row">
										<div className="col-md-7">
											<div className={styles.notify_type}>
												{Tr.tr(
													"doctor:PatientsDetails:Doctor_Will_Be_Notiftied"
												)}
											</div>
										</div>
										<div className="col-md-5">
											<div className={styles.check_boxes}>
												{notificationMethods.map((method, index) => (
													<CheckBox
														key={index}
														name="notificationMethods"
														label={method.label}
														value={method}
														handleChange={(e) =>
															onSelectionChange(e, selectedMethod)
														}
														checked={selectedMethod.some(
															(item) => item.value === method.value
														)}
													/>
												))}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					{/* Appointment Details */}
					<div className={styles.form_sec}>
						<h3>{Tr.tr("doctor:PatientsDetails:Appointment_Details")}</h3>
						<div className={styles.selection_btns}>
							<SelectButton
								value={value}
								onChange={(e) => setValue(e.value)}
								options={options}
								unselectable={false}
								itemTemplate={optionsTemplate}
							/>
						</div>

						<div className="row">
							{/* Scheduled On */}
							<div className="col-md-6 mb-4">
								<CalendarInput
									inputLabel={Tr.tr("basic:Scheduled_On")}
									inputName="scheduledOn"
									placeHolder="Mon, Oct 16, 2023"
									inputVal={formik.values.scheduledOn}
									changeHandler={(e) =>
										formik.setFieldValue("scheduledOn", e.value)
									}
									appendTo={document.body}
									customP
									showIcon={true}
									view="date"
									dateFormat="mm/dd/yy"
								/>
							</div>

							{/* At */}
							<div className="col-md-6 mb-4">
								<TimePicker
									inputLabel={Tr.tr("basic:At")}
									inputName="at"
									placeHolder="10:00AM"
									inputVal={formik.values.at}
									changeHandler={(e) => formik.setFieldValue("at", e.value)}
									appendTo={document.body}
									downIcon
								/>
							</div>

							{/* Notes */}
							<div className="col-md-12">
								<TextAreaInput
									inputLabel={Tr.tr("basic:Notes")}
									inputName="notes"
									placeHolder={Tr.tr("basic:Add_Notes")}
									inputVal={formik.values.notes}
									changeHandler={formik.handleChange}
									inputError={getFormErrorMessage("notes", formik)}
								/>
							</div>
						</div>
					</div>
				</div>

				<div className={styles.drawer_footer}>
					<GrayButton
						btn
						type="button"
						label={Tr.tr("basic:Discard")}
						outlined
						classes="p712"
						buttonClicked={props.hideHandler}
					/>
					<GradientButton
						btn
						type="submit"
						label={Tr.tr("basic:Next")}
						classes="p712"
					/>
				</div>
			</form>
		</Sidebar>
	);
};

export default withTranslation()(AddVisitDrawer);

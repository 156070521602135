import { getWishlist } from 'api/wishlist';
import DoctorListCard from 'components/Patient/DoctorListCard/DoctorListCard';
import PageHead from 'components/Patient/PageHead/PageHead';
import React, { useEffect } from 'react';
import { useMutation } from 'react-query';
import useWishlistStore from 'store/wishlist';
import { Tr } from 'utils/i18n';

const Wishlist = () => {
    const { wishlist } = useWishlistStore()
    // const {mutate} = useMutation(getWishlist,{})

    console.log({ wishlist })
    // useEffect(()=>{mutate() },[])
    return (
        <div>
            <PageHead
                title={Tr.tr("patient:PatientProfile:Wishlist")}
                mb="16px"
            />
            {wishlist &&
                wishlist?.map((doctor, idx) => (
                    <DoctorListCard data={doctor} key={doctor.id} idx={idx} />
                ))}
        </div>
    );
}

export default Wishlist;
import { useFormik } from "formik";
import { Button } from "primereact/button";

// Components
import DialogWrapper from "components/Patient/DialogWrapper/DialogWrapper";
import { TextInput } from "components/Common/Form/Inputs";
import GradientButton from "components/Common/UI/GradientButton/GradientButton";

// Utils
import { getFormErrorMessage } from "utils/FormField";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./WithdrawalMoneyModal.module.scss";

const WithdrawalMoneyModal = ({ ...props }) => {
	// Formik
	const formik = useFormik({
		initialValues: {
			withdrawalAmount: "",
		},
		validate: (data) => {
			let errors = {};

			if (!data.withdrawalAmount) {
				errors.withdrawalAmount = "Withdrawal Amount is required.";
			}

			return errors;
		},
		onSubmit: (data, { setSubmitting }) => {
			console.log("submitted");
			setTimeout(() => {
				setSubmitting(false);
				formik.resetForm();
			}, 2000);
		},
	});

	return (
		<DialogWrapper
			header={Tr.tr("doctor:Settings:Withdrawal_Money")}
			visible={props.visible}
			width="42vw"
			hideHandler={props.hideHandler}>
			<form onSubmit={formik.handleSubmit}>
				<div className="modal_body">
					<div className="row">
						{/* Balance */}
						<div className="col-md-6 m-b-20">
							<div className={styles.balance_box}>
								<h6>{Tr.tr("doctor:Settings:Available_Balance")}</h6>
								<h4 className={styles.green}>0.00 {Tr.tr("basic:SAR")}</h4>
							</div>
						</div>
						<div className="col-md-6 m-b-20">
							<div className={styles.balance_box}>
								<h6>{Tr.tr("doctor:Settings:Minimum_Withdrawal_Amount")}</h6>
								<h4 className={styles.black}>0.00 {Tr.tr("basic:SAR")}</h4>
							</div>
						</div>

						{/* Withdrawal Amount */}
						<div className="col-md-12 m-b-20">
							<TextInput
								inputLabel={Tr.tr("doctor:Settings:Withdrawal_Money")}
								inputName="withdrawalAmount"
								placeHolder="0.00"
								inputVal={formik.values.withdrawalAmount}
								changeHandler={formik.handleChange}
								inputError={getFormErrorMessage("withdrawalAmount", formik)}
							/>
						</div>

						{/* Account */}
						<div className="col-md-12">
							<div className={styles.account_holder}>
								<h6>{Tr.tr("doctor:Settings:Account")}</h6>
								<div className={styles.account_row}>
									<img
										src="/img/Paypal.svg"
										alt=""
										className={styles.account_icon}
									/>
									<p className={styles.account_text}>
										PayPal (ahmedali@tiryaaq.com)
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="modal_footer">
					<Button
						type="button"
						label={Tr.tr("basic:Cancel")}
						className="discard_btn withBorder"
						onClick={props.hideHandler}
					/>
					<GradientButton
						btn
						type="submit"
						label={Tr.tr("doctor:Settings:Withdrawal_Money")}
						classes="done_btn"
					/>
				</div>
			</form>
		</DialogWrapper>
	);
};

export default WithdrawalMoneyModal;

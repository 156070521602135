import { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useFormik } from "formik";

// Components
import { SearchInput, TextAreaInput } from "components/Common/Form/Inputs";
import WhiteBlackButton from "components/Common/UI/WhiteBlackButton/WhiteBlackButton";
import GradientButton from "components/Common/UI/GradientButton/GradientButton";

// JSON
import patientsData from "json/patientsData.json";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./MessagesModal.module.scss";

const MessagesModal = ({ ...props }) => {
	// Search
	const [searchQuery, setSearchQuery] = useState("");

	// Patients List
	const [patients, setPatients] = useState([]);

	// Selected Patient
	const [selectedPatient, setSelectedPatient] = useState(null);

	// Selected Patient Id
	const [selectedPatientId, setSelectedPatientId] = useState(null);

	// Formik
	const formik = useFormik({
		initialValues: {
			message: "",
		},
		validate: (data) => {
			let errors = {};

			if (!data.message) {
				errors.message = "Message is required.";
			}

			return errors;
		},
		onSubmit: (data, { setSubmitting }) => {
			console.log("submitted");
			setTimeout(() => {
				setSubmitting(false);
				formik.resetForm();
			}, 2000);
		},
	});

	useEffect(() => {
		// Simulate importing data from a JSON file
		setPatients(patientsData);
	}, []);

	const getSelectedPatient = (patient) => {
		setSelectedPatientId(patient.id);

		// Get Selected Patient
		const fountPatient = patients.find((p) => p.id === patient.id);

		setSelectedPatient(fountPatient);
	};

	return (
		<Dialog
			visible={props.visible}
			modal
			style={{ width: "77.5vw" }}
			onHide={props.hideHandler}
			className={`${styles.messages_dialog}`}
			draggable={false}
			closable={false}>
			<div className="row gx-0">
				<div className="col-lg-5 col-xxl-4">
					<div className={styles.messages_sidebar}>
						<div className={styles.msg_list_head}>
							<Button
								type="button"
								text
								className={styles.close_modal}
								onClick={props.hideHandler}>
								<img src="/img/close-black.svg" alt="" />
							</Button>

							<h1>{Tr.tr("basic:Messages")}</h1>

							{/* Search */}
							<SearchInput
								inputLabel=""
								inputName="search"
								placeHolder={Tr.tr("basic:Search")}
								inputVal={searchQuery}
								changeHandler={(e) => setSearchQuery(e.target.value)}
							/>
						</div>

						<div className={styles.msg_list_body}>
							{patients.map((patient) => (
								<Button
									text
									className={`${styles.patient_btn} ${
										selectedPatientId === patient.id ? styles.active : ""
									}`}
									key={patient.id}
									onClick={() => getSelectedPatient(patient)}>
									<div className={styles.patient_data}>
										<div className={styles.patient_img}>
											<img src={patient.image} alt="" />
										</div>
										<div className={styles.info}>
											<h4>{patient.name}</h4>
											<h5>{patient.bio}</h5>
										</div>
									</div>
									{patient.messages > 0 && (
										<div className={styles.msg_count}>{patient.messages}</div>
									)}
								</Button>
							))}
						</div>
					</div>
				</div>
				<div className="col-lg-7 col-xxl-8">
					<div className={styles.messages_chat}>
						{selectedPatientId ? (
							<div className={styles.chat_holder}>
								<div className={styles.chat_head}>
									<div className={styles.patient_info}>
										<div className={styles.img}>
											<img src={selectedPatient.image} alt="" />
										</div>
										<div className={styles.patient_data}>
											<h5>{selectedPatient.name}</h5>
											<p>This is a subtitle</p>
										</div>
									</div>

									<WhiteBlackButton
										label={Tr.tr("basic:View_Profile")}
										withIconImg
										icon="/img/eye-black.svg"
										outlined
										classes="p712"
										redirectTo={`/doctor/patients/${selectedPatient.id}`}
									/>
								</div>
								<div className={styles.chat_body}>
									<div className={styles.date}>Nov 15, 2023</div>
									<div className={styles.chat_boxes}>
										<div className={`${styles.chat_box} ${styles.receiver}`}>
											<div className={styles.img}></div>
											<div className={`${styles.chat_bubble} ${styles.left}`}>
												<div className={styles.text}>Hallo, Dr Amr</div>
												<div className={styles.time}>5:00 PM</div>
											</div>
										</div>
										<div className={`${styles.chat_box} ${styles.receiver}`}>
											<div className={styles.img}>
												<img src={selectedPatient.image} alt="" />
											</div>
											<div className={`${styles.chat_bubble} ${styles.left}`}>
												<div className={styles.text}>
													I have a question about my next appointment
												</div>
												<div className={styles.time}>5:00 PM</div>
											</div>
										</div>
									</div>
									<div className={styles.date}>Nov 14, 2023</div>
									<div className={styles.chat_boxes}>
										<div className={`${styles.chat_box} ${styles.sender}`}>
											<div className={`${styles.chat_bubble} ${styles.right}`}>
												<div className={styles.text}>Hi 👋, Ahmed</div>
												<div className={styles.time}>5:00 PM</div>
											</div>
										</div>
									</div>
								</div>
								<form
									className={styles.chat_footer}
									onSubmit={formik.handleSubmit}>
									<TextAreaInput
										inputLabel=""
										inputName="message"
										placeHolder={Tr.tr("doctor:Messages:Type_Your_Message")}
										inputVal={formik.values.message}
										changeHandler={formik.handleChange}
									/>
									<GradientButton
										btn
										type="submit"
										label={Tr.tr("doctor:Messages:Send")}
										classes="send_btn"
									/>
								</form>
							</div>
						) : (
							<div className={styles.no_chat}>
								{Tr.tr("doctor:Messages:Select_Someone_To_Star_cthat")}
							</div>
						)}
					</div>
				</div>
			</div>
		</Dialog>
	);
};

export default MessagesModal;

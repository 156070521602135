import { useContext, useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import { Accordion, AccordionTab } from "primereact/accordion";

// Components
import { SearchInput } from "components/Common/Form/Inputs";
import FilterPatients from "components/Doctor/FilterPatients/FilterPatients";
import WhiteBlackButton from "components/Common/UI/WhiteBlackButton/WhiteBlackButton";
import GradientButton from "components/Common/UI/GradientButton/GradientButton";
import VitalsSignsAccordion from "components/Doctor/VitalsSignsAccordion/VitalsSignsAccordion";
import ClinicalNotesAccordion from "components/Doctor/ClinicalNotesAccordion/ClinicalNotesAccordion";
import Prescriptions from "components/Doctor/Prescriptions/Prescriptions";
import LabTestAccordion from "components/Doctor/LabTestAccordion/LabTestAccordion";
import AddVisitDrawer from "./components/AddVisitDrawer/AddVisitDrawer";
import AccordionHeader from "components/Common/UI/AccordionHeader/AccordionHeader";

// Context
import { SidebarContext } from "context/DoctorSidebarContext";

// JSON
import patientsData from "json/patientsData.json";

// Translation
import { useTranslation } from "react-i18next";
import { Tr } from "utils/i18n";

// Styles
import styles from "./DoctorPatientsDetails.module.scss";
import { useQuery } from "react-query";
import { getPatientLabResults } from "api/labResults";
import { getDoctorPatientsAPI, getPatientsDetailsAPI } from "api/doctors";
import { getMeApi } from "api/auth";
import DialogWrapper from "components/Patient/DialogWrapper/DialogWrapper";

const DoctorPatientsDetails = () => {
    const { setSmallSize } = useContext(SidebarContext);

    const { i18n } = useTranslation();
    const currLang = i18n.language;

    // id
    const { id } = useParams();

    // Search
    const [searchQuery, setSearchQuery] = useState("");

    // Patients List
    const [patients, setPatients] = useState([]);
    const [patientDetails, setPatientDetails] = useState({});
    const [labResults, setLapResults] = useState([]);

    // Selected Patient
    const [selectedPatient, setSelectedPatient] = useState(null);
    const [visible, setVisible] = useState(false);
    const [selectedLabResult, setSelectedLabResult] = useState(null);

    // Show Add Visit Drawer
    const [visitVisible, setVisitVisible] = useState(false);

    // Lab Results
    // const labResults = [
    // 	{
    // 		id: 1,
    // 		name: "X-Ray",
    // 		lastUpdated: "10 days ago",
    // 	},
    // 	{
    // 		id: 2,
    // 		name: "Allergen-specific IgE",
    // 		lastUpdated: "10 days ago",
    // 	},
    // 	{
    // 		id: 3,
    // 		name: "Nasal Endoscopy",
    // 		lastUpdated: "10 days ago",
    // 	},
    // 	{
    // 		id: 4,
    // 		name: "CT",
    // 		lastUpdated: "10 days ago",
    // 	},
    // ];

    const {
        data: userData,
        isLoading: isUserDataLoading,
        isError: isUserDataError,
    } = useQuery("userData", getMeApi);

    const userId = userData?.data?.id;

    const {
        data: allPatients,
        isLoading: isPatientLoading,
    } = useQuery(["patients", userId],
        () => getDoctorPatientsAPI({ doctor_id: userId }),
        {
            onSuccess: (res) => {
                setPatients(res.data?.data);
            },
            enabled: !!userId,
        }
    );

    const {
        data: patient,
        isLoading: detailsLoading,
        isError: detailsError,
    } = useQuery(["patients", id],
        () => getPatientsDetailsAPI({ patient_id: id }),
        {
            onSuccess: (data) => {
                // setPatients(data?.data);
                setPatientDetails(data?.data)
            },
        }
    );

    const {
        data,
        isLoading,
        isError,
    } = useQuery(["lab_results", id],
        () => getPatientLabResults({ patient_id: id }),
        {
            onSuccess: (data) => {
                setLapResults(data?.data);
                // console.log(data, '=====> lab results');
            },
        }
    );

    // Record Dropdown
    const menu = useRef(null);

    const items = [
        {
            label: <span className={"added"}>{Tr.tr("basic:Vitals_Signs")}</span>,
            command: () => { },
        },
        {
            label: <span className={"added"}>{Tr.tr("basic:Clinical_Notes")}</span>,
            command: () => { },
        },
        {
            label: <span>{Tr.tr("basic:Prescriptions")}</span>,
            command: () => { },
        },
        {
            label: <span>{Tr.tr("basic:Lab_Test")}</span>,
            command: () => { },
        },
    ];

    // Accordion Tabs
    const [tabs] = useState([
        {
            header: <AccordionHeader accordionLabel="Vitals_Signs" />,
            children: <VitalsSignsAccordion />,
        },
        {
            header: <AccordionHeader accordionLabel="Clinical_Notes" />,
            children: <ClinicalNotesAccordion />,
        },
        {
            header: <AccordionHeader accordionLabel="Prescriptions" />,
            children: <Prescriptions />,
        },
        {
            header: <AccordionHeader accordionLabel="Lab_Test" />,
            children: <LabTestAccordion />,
        },
    ]);

    // Dynamic Accordion Tabs
    const createDynamicTabs = () => {
        return tabs.map((tab, i) => {
            return (
                <AccordionTab
                    key={tab.header}
                    header={tab.header}
                    disabled={tab.disabled}>
                    {tab.children}
                </AccordionTab>
            );
        });
    };

    useEffect(() => {
        setSmallSize(true);

        // Simulate importing data from a JSON file
        // setPatients(patientsData);

        // Get Selected Patient
        // const fountPatient = patients.find((patient) => patient.id === Number(id));

        // setSelectedPatient(fountPatient);
    }, [setSmallSize, id, patients]);
    console.log(selectedLabResult);
    return (
        <>
            <div className={styles.doctor_patients_details}>
                <div className={styles.patients_list}>
                    <div className={styles.patients_list_head}>
                        <h1>{Tr.tr("basic:Patients")}</h1>
                        <div className={styles.head_action}>
                            {/* Search */}
                            <SearchInput
                                inputLabel=""
                                inputName="search"
                                placeHolder={Tr.tr("doctor:Patients:Search_Patients")}
                                inputVal={searchQuery}
                                changeHandler={(e) => setSearchQuery(e.target.value)}
                            />

                            {/* Filter */}
                            <FilterPatients withoutLabel />
                        </div>
                        <p className={styles.patients_count}>
                            500 {Tr.tr("doctor:Patients:Patients")}
                        </p>
                    </div>
                    <div className={styles.patients_list_body}>
                        {patients.map((patient) => (
                            <Link
                                to={`/doctor/patients/${patient.id}`}
                                className={`${styles.patients_box} ${patient.id === Number(id) ? styles.active : ""
                                    }`}
                                key={patient.id}>
                                <div className={styles.patient_img}>
                                    <img src={patient.image} alt="" />
                                </div>
                                <div className={styles.patient_info}>
                                    <h5>{patient.name}</h5>
                                    <p>
                                        {Tr.tr("basic:Last_Updated")}: {patient.lastUpdated}
                                    </p>
                                </div>
                            </Link>
                        ))}
                    </div>
                </div>
                <div className={styles.patients_data}>
                    <div className="row">
                        <div className="col-md-4">
                            <div className={styles.patient_medical_record}>
                                {/* Profile */}
                                <div className={styles.record_row}>
                                    <div className={styles.patient_img}>
                                        <img src={patientDetails?.image} alt="" />
                                    </div>
                                    <div className={styles.name_lastUpdated}>
                                        <h5>{patientDetails?.name}</h5>
                                        <p>
                                            {Tr.tr("basic:Last_Updated")}:{" "}
                                            {patientDetails?.lastUpdated}
                                        </p>
                                    </div>
                                    <div className={styles.patient_contacts}>
                                        <WhiteBlackButton
                                            btn
                                            type="button"
                                            label={Tr.tr("basic:Call")}
                                            withIconImg
                                            icon="/img/call-gray.svg"
                                            outlined
                                            classes={`p712 w-100 ${styles.call_btn}`}
                                        />
                                        <WhiteBlackButton
                                            btn
                                            type="button"
                                            label={Tr.tr("basic:Send_Mail")}
                                            withIconImg
                                            icon="/img/mail-gray.svg"
                                            outlined
                                            classes={`p712 w-100 ${styles.mail_btn}`}
                                        />
                                    </div>
                                </div>

                                {/* Information */}
                                <div className={styles.record_row}>
                                    <h5 className={styles.record_title}>
                                        {Tr.tr("basic:Information")}
                                    </h5>
                                    <div className={styles.information_rows}>
                                        <div className={styles.information_row}>
                                            <div className={styles.lbl}>
                                                <img
                                                    src="/img/birthday-gray.svg"
                                                    alt=""
                                                    className={styles.icon}
                                                />
                                                <p className={styles.txt}>{Tr.tr("basic:Age")}</p>
                                            </div>
                                            <div className={styles.val}>{patientDetails?.age}</div>
                                        </div>
                                        <div className={styles.information_row}>
                                            <div className={styles.lbl}>
                                                <img
                                                    src="/img/face-gray.svg"
                                                    alt=""
                                                    className={styles.icon}
                                                />
                                                <p className={styles.txt}>{Tr.tr("basic:Gender")}</p>
                                            </div>
                                            <div className={styles.val}>
                                                {patientDetails?.gender}
                                            </div>
                                        </div>
                                        <div className={styles.information_row}>
                                            <div className={styles.lbl}>
                                                <img
                                                    src="/img/blood-gray.svg"
                                                    alt=""
                                                    className={styles.icon}
                                                />
                                                <p className={styles.txt}>
                                                    {Tr.tr("basic:Blood_Type")}
                                                </p>
                                            </div>
                                            <div className={styles.val}>{patientDetails?.blood_type}</div>
                                        </div>
                                        <div className={styles.information_row}>
                                            <div className={styles.lbl}>
                                                <img
                                                    src="/img/weight-gray.svg"
                                                    alt=""
                                                    className={styles.icon}
                                                />
                                                <p className={styles.txt}>{Tr.tr("basic:Weight")}</p>
                                            </div>
                                            <div className={styles.val}>{patientDetails?.weight} kg</div>
                                        </div>
                                        <div className={styles.information_row}>
                                            <div className={styles.lbl}>
                                                <img
                                                    src="/img/height-gray.svg"
                                                    alt=""
                                                    className={styles.icon}
                                                />
                                                <p className={styles.txt}>{Tr.tr("basic:Height")}</p>
                                            </div>
                                            <div className={styles.val}>{patientDetails?.height} cm</div>
                                        </div>
                                    </div>
                                </div>

                                {/* Vitals */}
                                <div className={styles.record_row}>
                                    <div className={styles.vitals_title}>
                                        <h5 className={styles.record_title}>
                                            {Tr.tr("basic:Vitals")}
                                        </h5>
                                        <p>
                                            {Tr.tr("basic:Last_Updated")}:{" "}
                                            {patientDetails?.lastUpdated}
                                        </p>
                                    </div>
                                    <div className={styles.information_rows}>
                                        <div className={styles.information_row}>
                                            <div className={styles.lbl}>
                                                <img
                                                    src="/img/love-gray.svg"
                                                    alt=""
                                                    className={styles.icon}
                                                />
                                                <p className={styles.txt}>
                                                    {Tr.tr("basic:Blood_Pressure")}
                                                </p>
                                            </div>
                                            <div className={styles.val}>{patientDetails?.blood_pressure}</div>
                                        </div>
                                        <div className={styles.information_row}>
                                            <div className={styles.lbl}>
                                                <img
                                                    src="/img/pulse-gray.svg"
                                                    alt=""
                                                    className={styles.icon}
                                                />
                                                <p className={styles.txt}>{Tr.tr("basic:Pulse")}</p>
                                            </div>
                                            <div className={styles.val}>{patientDetails?.pulse} BPM</div>
                                        </div>
                                    </div>
                                </div>

                                {/* Lab Results */}
                                <div className={styles.record_row}>
                                    <h5 className={styles.record_title}>
                                        {Tr.tr("basic:Lab_Results")}
                                    </h5>
                                    <div className={styles.lab_results_rows}>
                                        {labResults.map((labRes) => (
                                            <div className={styles.lab_results_row} key={labRes.id}>
                                                <div className={styles.results_data}>
                                                    <img
                                                        src="/img/file-gradient.svg"
                                                        alt=""
                                                        className={styles.icon}
                                                    />
                                                    <div className={styles.name_desc}>
                                                        <h5>{labRes.name}</h5>
                                                        <p>
                                                            {Tr.tr("basic:Last_Updated")}:{" "}
                                                            {labRes.lastUpdated}
                                                        </p>
                                                    </div>
                                                </div>
                                                <Button type="button" text className={styles.view_btn} onClick={() => { setVisible(true); setSelectedLabResult(labRes) }}>
                                                    <img src="/img/eye-gradient.svg" alt="" />
                                                </Button>
                                            </div>
                                        ))}
                                        {/* Upload Modal */}
                                        <DialogWrapper
                                            header={Tr.tr("patient:PatientProfile:Upload_Lab_Result")}
                                            visible={visible}
                                            width="42vw"
                                            hideHandler={() => setVisible(false)}>
                                            <div style={{minHeight: '10rem'}}>
                                                {selectedLabResult !== null ?
                                                    selectedLabResult.file.mimetype.includes('image') ? <img src={selectedLabResult.file.url} alt="" /> :
                                                        <h3>file type not an image</h3>
                                                    : <h3>select lab result to show </h3>}
                                            </div>
                                        </DialogWrapper>
                                    </div>
                                </div>

                                {/* Current Medications */}
                                <div className={styles.record_row}>
                                    <h5 className={styles.record_title}>
                                        {Tr.tr("basic:Current_Medications")}
                                    </h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className={styles.medical_history}>
                                <div className={styles.medical_history_head}>
                                    <h2>{Tr.tr("basic:Medical_History")}</h2>
                                    <GradientButton
                                        btn
                                        type="button"
                                        label={Tr.tr("doctor:PatientsDetails:Add_Visit")}
                                        withIconImg
                                        icon="/img/add.svg"
                                        classes="p712"
                                        buttonClicked={() => setVisitVisible(true)}
                                    />
                                </div>
                                <div className={styles.medical_history_body}>
                                    <div className={styles.history_holder}>
                                        <div className={styles.date}>
                                            <span>15</span>
                                            <span>Aug</span>
                                        </div>
                                        <div className={styles.history_box}>
                                            <div className={styles.history_box_head}>
                                                <div className={styles.txt}>
                                                    <h3>Doctor Maged El Wakeel</h3>
                                                    <p>In Clinic</p>
                                                </div>
                                                <div className={styles.addRecord_dropdown}>
                                                    <Menu
                                                        model={items}
                                                        popup
                                                        ref={menu}
                                                        id="popup_menu"
                                                        className={styles.record_menu}
                                                        popupAlignment={
                                                            currLang === "ar" ? "left" : "right"
                                                        }
                                                    />
                                                    <Button
                                                        type="button"
                                                        className={styles.menu_btn}
                                                        onClick={(event) => menu.current.toggle(event)}
                                                        aria-controls="popup_menu"
                                                        aria-haspopup>
                                                        <span className={styles.btn_content}>
                                                            <span>{Tr.tr("basic:Add_Records")}</span>
                                                            <img src="/img/arrow-drop-down.svg" alt="" />
                                                        </span>
                                                    </Button>
                                                </div>
                                            </div>

                                            <div className={styles.records_holder}>
                                                <Accordion>{createDynamicTabs()}</Accordion>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={styles.history_holder}>
                                        <div className={styles.date}>
                                            <span>1</span>
                                            <span>Aug</span>
                                        </div>
                                        <div className={styles.history_box}>
                                            <div className={styles.history_box_head}>
                                                <div className={styles.txt}>
                                                    <h3>Doctor Maged El Wakeel</h3>
                                                    <p>In Clinic</p>
                                                </div>
                                                <div className={styles.addRecord_dropdown}>
                                                    <Menu
                                                        model={items}
                                                        popup
                                                        ref={menu}
                                                        id="popup_menu"
                                                        className={styles.record_menu}
                                                        popupAlignment={
                                                            currLang === "ar" ? "left" : "right"
                                                        }
                                                    />
                                                    <Button
                                                        type="button"
                                                        className={styles.menu_btn}
                                                        onClick={(event) => menu.current.toggle(event)}
                                                        aria-controls="popup_menu"
                                                        aria-haspopup>
                                                        <span className={styles.btn_content}>
                                                            <span>{Tr.tr("basic:Add_Records")}</span>
                                                            <img src="/img/arrow-drop-down.svg" alt="" />
                                                        </span>
                                                    </Button>
                                                </div>
                                            </div>

                                            <div className={styles.records_holder}>
                                                <Accordion>{createDynamicTabs()}</Accordion>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Add Visit Drawer */}
            <AddVisitDrawer
                visible={visitVisible}
                hideHandler={() => {
                    setVisitVisible(false);
                }}
            />
        </>
    );
};

export default DoctorPatientsDetails;

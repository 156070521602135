import { Fragment, useState, useEffect } from "react";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";

// import Swiper core and required modules
import { Navigation } from "swiper/modules";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// Components
import GradientButton from "components/Common/UI/GradientButton/GradientButton";
import SpecialtiesLoadingSkeleton from "./SpecialtiesLoadingSkeleton";

// Translation
import { useTranslation } from "react-i18next";
import { Tr } from "utils/i18n";

// APIs
import { useQuery } from "react-query";
import { specialtiesAPI } from "api/specialties";

// Styles
import styles from "./Specialties.module.scss";

const hexToRGBA = (hex, alpha = 1) => {
	if (hex)
	{
		let r = parseInt(hex.slice(1, 3), 16);
		let g = parseInt(hex.slice(3, 5), 16);
		let b = parseInt(hex.slice(5, 7), 16);
		return `rgba(${r}, ${g}, ${b}, ${alpha})`
	}
	return `rgba(14,50, 225, ${alpha})`
};

const Specialties = () => {
	const { i18n } = useTranslation();
	const currLang = i18n.language;

	const [specialities, setSpecialities] = useState([]);

	const { data: specialitiesData, isLoading } = useQuery(
		"specialities",
		specialtiesAPI
	);
	// console.log(specialitiesData.data)
	useEffect(() => {
		if (specialitiesData && specialitiesData.data) {
			console.log(specialitiesData.data);
			setSpecialities(specialitiesData.data);
		}
	}, [specialitiesData]);

	return (
		<section className={styles.specialties}>
			<div className="container">
				<div className="sec_head">
					<div className="txt">
						<h2>{Tr.tr("patient:Home:Specialties")}</h2>
						<p>Lorem ipsum dolor sit amet consectetur. Neque blandit</p>
					</div>
					<GradientButton
						label={Tr.tr("patient:Home:View_All_Specialities")}
						redirectTo=""
					/>
				</div>

				{/* Specialties Slider */}
				<div className={styles.specialties_slider}>
					<Swiper
						key={currLang} // To re-render this component with language change
						dir={currLang === "ar" ? "rtl" : "ltr"}
						// install Swiper modules
						modules={[Navigation]}
						spaceBetween={24}
						slidesPerView={6.4}
						navigation={{
							nextEl: ".review-swiper-button-next",
							prevEl: ".review-swiper-button-prev",
						}}
						// onSlideChange={() => console.log("slide change")}
						// onSwiper={(swiper) => console.log(swiper)}
						breakpoints={{
							// when window width is >= 1200px
							1200: {
								slidesPerView: 6.4,
							},
							// when window width is >= 1024px
							1024: {
								slidesPerView: 5.4,
							},
							768: {
								slidesPerView: 4.4,
							},
							575: {
								slidesPerView: 3.4,
							},
							400: {
								slidesPerView: 2.4,
							},
							0: {
								slidesPerView: 1.4,
							},
						}}>
						{specialities ? (
							specialities.map((specialty, index) => (
								<SwiperSlide key={index}>
									<div className={styles.specialty_box}>
										<Link
											to={`/patient/find-a-doctor?doctor_specialty_ids=${specialty.id}`}
											style={{ textDecoration: "none" }}>
											<div
												className={styles.specialty_img}
												style={{
													backgroundImage: `linear-gradient(165deg, ${hexToRGBA(
														specialty.color,
														0.6
													)} 60%, ${hexToRGBA(specialty.color, 0.8)} 60%)`,
												}}>
												<img src={specialty.image} alt="" />
											</div>
											<div className={styles.name}>
												{Tr.tr(`patient:Home:Specialities:${specialty.name}`)}
											</div>
										</Link>
									</div>
								</SwiperSlide>
							))
						) : (
							<Fragment>
								<SpecialtiesLoadingSkeleton />
								<SpecialtiesLoadingSkeleton />
								<SpecialtiesLoadingSkeleton />
								<SpecialtiesLoadingSkeleton />
							</Fragment>
						)}
					</Swiper>

					{/* Navigation */}
					<Button icon="pi-angle-left" className="review-swiper-button-prev" />
					<Button icon="pi-angle-right" className="review-swiper-button-next" />
				</div>
			</div>
		</section>
	);
};

export default Specialties;

import axios from "../lib/axios";

export const answersAPI = async (filters) => {
    return await axios.post(`/users/me/answers`, {});
};


export const questionDetailsAPI = async (id) => {
    return await axios.post(`/users/me/answers/${id?.queryKey[1]}`, {
        question_id: id?.queryKey[1],
    });
};

export const addAnswersAPI = async (params) => {
    return await axios.post(`/users/me/answers/add`, params);
};

export const editQuestionsAPI = async (params) => {
    const { id, data } = params;
    return await axios.post(`/users/me/answers/${id}/update`, data);
};

export const deleteQuestionsAPI = async (id) => {
    return await axios.post(`/users/me/answers/${id}/delete`, {});
};

import { useState } from "react";
import { SelectButton } from "primereact/selectbutton";
import ReactApexChart from "react-apexcharts";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./RatingsChart.module.scss";

const RatingsChart = () => {
	// Duration [Weekly, Monthly, Yearly]
	const options = ["Weekly", "Monthly", "Yearly"];
	const [value, setValue] = useState(options[0]);

	// Chart
	const chartSeries = [
		{
			name: "Ratings",
			data: [3.2, 4.2, 4.4, 4.3, 4.9, 4.7, 5],
		},
	];

	// Define your custom y-axis values
	const customYAxisValues = [1, 2, 3, 4, 5]; // Example custom values

	// Custom X Axis data for each point
	const customTooltipXAxisData = [
		{ date: "Mon, Nov 8, 2023" },
		{ date: "Tue, Nov 8, 2023" },
		{ date: "Wed, Nov 8, 2023" },
		{ date: "Thu, Nov 8, 2023" },
		{ date: "Fri, Nov 8, 2023" },
		{ date: "Sat, Nov 8, 2023" },
		{ date: "Sun, Nov 8, 2023" },
	];

	const chartOptions = {
		chart: {
			type: "line",
			toolbar: {
				show: false, // Hide all toolbar functions
				menu: {
					show: false, // Hide the menu button
				},
			},
			zoom: {
				enabled: false, // Disable zoom functionality
			},
		},
		xaxis: {
			categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
			tooltip: {
				enabled: false,
			},
		},
		yaxis: {
			// min: 0,
			// max: 5,
			// Specify the custom y-axis values
			min: customYAxisValues[0].toFixed(0),
			max: customYAxisValues[customYAxisValues.length - 1].toFixed(0),
			tickAmount: customYAxisValues.length - 1,
			labels: {
				formatter: function (value) {
					return value.toFixed(0);
				},
			},
		},
		colors: ["#CC9A06"],
		stroke: {
			curve: "straight",
			width: 2,
			colors: ["#FFC107"],
		},
		grid: {
			// Customize the grid lines
			strokeDashArray: 4, // Set the dash array for the grid lines
			borderColor: "#E5E5EF", // Set the color of the grid lines
			row: {
				colors: ["#fff"], // Set the colors for alternating rows
				opacity: 0, // Set the opacity of the rows
			},
		},
		tooltip: {
			x: {
				formatter: function (series, seriesIndex, dataPointIndex, val) {
					const data = customTooltipXAxisData[series - 1];
					return data.date;
				},
			},
			y: {
				title: {
					formatter: (seriesName) => "", // Hide the series name in the tooltip
				},
				formatter: (value) =>
					`<span>${value}</span> <img src="/img/star-yellow.svg" alt="" /> <span>${Tr.tr(
						"doctor:Reports:Average_Rating"
					)}</span>`, // Display the tooltip value
				offsetY: -15, // Adjust the y-offset to position the tooltip above the data point
				style: {
					fontSize: "12px", // Adjust the font size of the tooltip
				},
			},
			marker: {
				show: false,
			},
		},
	};

	return (
		<section className={styles.chart_holder}>
			<div className={styles.chart_head}>
				<h2>{Tr.tr("doctor:Reports:Average_Rating_Report")}</h2>
				<div className={styles.duration_selection}>
					<SelectButton
						value={value}
						onChange={(e) => setValue(e.value)}
						options={options}
						unselectable={false}
					/>
				</div>
			</div>

			<div className={styles.chart_wrapper}>
				<ReactApexChart
					options={chartOptions}
					series={chartSeries}
					type="line"
					height={350}
				/>
			</div>
		</section>
	);
};

export default RatingsChart;

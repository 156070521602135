import { useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { ProgressSpinner } from "primereact/progressspinner";

// Components
import OnBoardingAppointments from "./components/OnBoardingAppointments/OnBoardingAppointments";

// Utils
import { SidebarContext } from "context/DoctorSidebarContext";

// APIs
import { getDoctorDashboardSummary } from "api/doctors";
import { getMeApi } from "api/auth";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./DoctorDashboard.module.scss";

const DoctorDashboard = () => {
	const { setSmallSize } = useContext(SidebarContext);

	// get appointments

	const [doctorDashboardSummary, setDoctorDashboardSummary] = useState();

	const {
		data: userData,
		isLoading: isUserDataLoading,
		isError: isUserDataError,
	} = useQuery("userData", getMeApi);

	const userId = userData?.data?.id;
	const {
		data,
		isLoading: isDashboardLoading,
		isError,
	} = useQuery(
		["dashboardSummary", userId],
		() => getDoctorDashboardSummary({ doctor_id: userId }),
		{
			onSuccess: (data) => {
				console.log(data);
				setDoctorDashboardSummary(data.data);
			},
			enabled: !!userId,
		}
	);

	useEffect(() => {
		setSmallSize(false);
	}, [setSmallSize]);

	if (isUserDataLoading || isDashboardLoading) {
		return (
			<div className={styles.spinnerContainer}>
				<ProgressSpinner />
			</div>
		);
	}

	return (
		<div className={styles.doctor_onBoarding}>
			<h1>
				{Tr.tr("doctor:Dashboard:Good_Morning")} 👋{" "}
				{Tr.tr("doctor:Dashboard:Good_Morning")}{" "}
				{localStorage.getItem("user_name")}
			</h1>

			{/* Statistcs */}
			<div className="row mb-4">
				<div className="col-md-6 col-lg-3 mb-3 mb-lg-0">
					<div className={styles.statistic_box}>
						<img src="/img/users-gray.svg" alt="" className={styles.icon} />
						<div className={styles.info}>
							<h5>{Tr.tr("basic:Patients")}</h5>
							<h3>{doctorDashboardSummary?.patient_count}</h3>
						</div>
					</div>
				</div>
				<div className="col-md-6 col-lg-3 mb-3 mb-lg-0">
					<div className={styles.statistic_box}>
						<img src="/img/time-gray.svg" alt="" className={styles.icon} />
						<div className={styles.info}>
							<h5>{Tr.tr("doctor:Dashboard:Today_Appointments")}</h5>
							<h3>{doctorDashboardSummary?.today_appointment_count}</h3>
						</div>
					</div>
				</div>
				<div className="col-md-6 col-lg-3 mb-3 mb-lg-0">
					<div className={styles.statistic_box}>
						<img src="/img/calendar-gray.svg" alt="" className={styles.icon} />
						<div className={styles.info}>
							<h5>{Tr.tr("basic:Appointments")}</h5>
							<h3>{doctorDashboardSummary?.number_of_appointments}</h3>
						</div>
					</div>
				</div>
				<div className="col-md-6 col-lg-3 mb-3 mb-lg-0">
					<div className={styles.statistic_box}>
						<img src="/img/dollar-gray.svg" alt="" className={styles.icon} />
						<div className={styles.info}>
							<h5>{Tr.tr("doctor:Dashboard:Income")}</h5>
							<h3>{doctorDashboardSummary?.income} SAR</h3>
						</div>
					</div>
				</div>
			</div>

			{/* Appointments */}
			<OnBoardingAppointments />
		</div>
	);
};

export default DoctorDashboard;

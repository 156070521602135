import axios from "../lib/axios";

export const getAllQuestionsAPI = async (params) => {
    const filters = params.queryKey[1]
    return await axios.post(`/questions`, {...filters});
};

export const getMyQuestionsAPI = async (params) => {
    const filters = params.queryKey[1]
    return await axios.post(`/users/me/questions`, {...filters});
};

export const questionDetailsAPI = async (id) => {
    return await axios.post(`/users/me/questions/${id?.queryKey[1]}`, {
        question_id: id?.queryKey[1],
    });
};

export const addQuestionsAPI = async (params) => {
    return await axios.post(`/users/me/questions/add`, params);
};

export const editQuestionsAPI = async (params) => {
    const { id, data } = params;
    return await axios.post(`/users/me/questions/${id}/update`, data);
};

export const deleteQuestionsAPI = async (id) => {
    return await axios.post(`/users/me/questions/${id}/delete`, {});
};

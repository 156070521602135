import { useState } from "react";
import { Slider } from "primereact/slider";

// Styles
import styles from "./FilterRange.module.scss";

export function FilterRange({ from, to,initMinMax ,setFees, ...props }) {
	const [value, setValue] = useState([initMinMax?.fees?.min, initMinMax?.fees?.max]);
	return (
		<div className={styles.filter_range}>
			<Slider
				value={value}
				min={from}
				max={to}
				onChange={(e) => {
					setValue(e.value)
					setFees(value)
				}}
				range
			/>
			<div className={styles.from_to}>
				<div className={styles.from_to_box}>
					<div className={styles.txt}>From</div>
					<div className={styles.val}>{from}</div>
				</div>
				<div className={styles.from_to_box}>
					<div className={styles.txt}>To</div>
					<div className={styles.val}>{to}</div>
				</div>
			</div>
		</div>
	);
}

import axios from "../lib/axios";

export const getAllChannelsAPI = async (filters) => {
    return await axios.post(`/messaging/channels`, {});
};

export const getChannelMessagesAPI = async (params) => {
    const { channel_id } = params.queryKey[1]
    return await axios.post(`/messaging/channels/${channel_id}/messages`, {});
};

export const sendMessagesAPI = async (params) => {
    const { channel_id, data } = params;
    return await axios.post(`/messaging/channels/${channel_id}/send-message`, data);
};

import { Link } from "react-router-dom";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./ConsultationCall.module.scss";

const ConsultationCall = ({ ...props }) => {
	return (
		<div
			className={`${styles.consultation_call} ${
				props.withoutBorder ? styles.without_border : ""
			}`}>
			<img
				src="/img/consultation-call.svg"
				alt=""
				className={styles.consultation_call_img}
			/>
			<div className={styles.consultation_call_txt}>
				<div className={styles.txt_title}>
					{Tr.tr("basic:Consultation_Call")}
				</div>
				<div className={styles.sub_title}>
					{Tr.tr("basic:Service_Only_Available_On_App")}
				</div>
			</div>
			{/* Apps */}
			<div className={styles.download_btns}>
				<Link to="" className={styles.app_btn}>
					<img src="/img/app-store.svg" alt="" />
				</Link>
				<Link to="" className={styles.app_btn}>
					<img src="/img/play-store.svg" alt="" />
				</Link>
			</div>
		</div>
	);
};

export default ConsultationCall;

// Styles
import styles from "./ReminderBox.module.scss";

const ReminderBox = ({ data }) => {
	return (
		<div className={styles.reminder_box} key={data.id}>
			<div className={styles.time}>{data.time}</div>
			<div className={styles.reminder_info}>
				<img src={data.icon} alt="" className={styles.icon} />
				<div className={styles.txt}>
					<h5>{data.name}</h5>
					<p>{data.desc}</p>
				</div>
			</div>
		</div>
	);
};

export default ReminderBox;

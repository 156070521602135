// Styles
import { Outlet } from "react-router-dom";
import styles from "./DoctorAuthLayout.module.scss";

const DoctorAuthLayout = () => {
	return (
		<div className={styles.doctor_authLayout}>
			<Outlet />
		</div>
	);
};

export default DoctorAuthLayout;

import { InputNumber } from "primereact/inputnumber";

// Components
import ErrorUI from "components/Common/UI/ErrorUI/ErrorUI";

// Styles
import styles from "./NumericInput.module.scss";

export function NumericInput({ ...props }) {
	return (
		<div className="input_holder">
			<div className={styles.field_holder}>
				{props.inputLabel && (
					<h6 className={props.required ? "required" : ""}>
						{props.inputLabel}
					</h6>
				)}
				<div
					className={`w-100 ${styles.input_box} ${
						props.inputError ? "p-invalid" : ""
					} ${props.disabled ? styles.disabled : ""}`}
				>
					<InputNumber
						name={props.inputName}
						value={props.inputVal}
						placeholder={props.placeHolder}
						onChange={(e) =>
							props.changeHandler({
								target: {
									name: props.inputName,
									value: e.value,
								},
							})
						}
						className="w-100"
						disabled={props.disabled}
						mode="decimal"
						minFractionDigits={props.minFractionDigits || 0}
						maxFractionDigits={props.maxFractionDigits || 0}
						max={props.maxValue}
					/>
				</div>
			</div>
			{props.inputError && (
				<ErrorUI errorMsg={props.inputError} withBG={props.withBG} />
			)}
		</div>
	);
}

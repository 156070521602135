import { useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";

// Components
import { CustomDropdown, SearchInput } from "components/Common/Form/Inputs";

// Query
import { useQuery } from "react-query";

// Api
import { specialtiesAPI } from "api/specialties";
import { citiesAPI, countriesAPI } from "api/location";
import { doctorsAPI } from "api/doctors";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./FindTheCare.module.scss";

const FindTheCare = ({ globalFilter, globalSearch, appendToURL }) => {
	const [selectedSpeciality, setSelectedSpeciality] = useState(null);

	const [selectedCountry, setSelectedCountry] = useState(null);

	const ref = useRef(false);

	const [selectedCity, setSelectedCity] = useState(null);
	const [filterData, setFilterData] = useState();
	// Search
	const [search, setSearch] = useState("");

	const { data: specialties, isLoading: specialtiesLoading } = useQuery(
		"specialties",
		specialtiesAPI,
		{
			select: (response) => {
				return response.data.map((spec) => ({
					...spec,
					label: spec.name,
					value: spec.name,
				}));
			},
		}
	);
	const { data: countries, isLoading: countriesLoading } = useQuery(
		"countries",
		countriesAPI,
		{
			select: (response) => {
				return response.data.map((spec) => ({
					...spec,
					label: spec.name,
					value: spec.name,
				}));
			},
		}
	);
	const { data: cities, isLoading: citiesLoading } = useQuery(
		["cities", selectedCountry],
		citiesAPI,
		{
			select: (response) => {
				return response.data.map((spec) => ({
					...spec,
					label: spec.name,
					value: spec.name,
				}));
			},
		}
	);

	useEffect(() => {
		if (ref.current) {
			return;
		}
		if (citiesLoading || countriesLoading || specialtiesLoading) {
			ref.current = false;
		} else {
			ref.current = true;
		}

		const urlParams = new URLSearchParams(window.location.search);
		let speciality = urlParams.get("doctor_specialty_ids");
		let country = urlParams.get("countryId");
		let state = urlParams.get("stateId");
		const search = urlParams.get("search");
		if (speciality) {
			speciality = +speciality;
			setFilterData({
				...filterData,
				doctor_specialty_ids: speciality,
			});
			setSelectedSpeciality(
				specialties?.find((spec) => spec?.id === speciality)?.name
			);
		}
		if (country) {
			country = +country;
			setFilterData({
				...filterData,
				country_id: +country,
			});
			setSelectedCountry({
				value: countries?.find((cn) => cn?.id === country)?.name,
				id: +country,
			});
		}
		if (state) {
			state = +state;
			setSelectedCity(state);
			if (state) {
				setFilterData({
					...filterData,
					state_id: +state,
				});
				setSelectedCity({
					...selectedCity,
					value: cities?.find((city) => city?.id === +state)?.name,
				});
			}
		}
		if (search) {
			setSearch(search);
			globalSearch(search);
		}
	}, [
		selectedCity,
		selectedCountry,
		selectedSpeciality,
		citiesLoading,
		countriesLoading,
		specialtiesLoading,
		filterData,
	]);

	const handleSubmit = (e) => {
		e.preventDefault();

		if (selectedSpeciality) {
			let ids = +specialties.find((spec) => spec.value === selectedSpeciality)
				?.id;
			setFilterData({
				// ...filterData,
				doctor_specialty_ids: ids,
				country_id: selectedCountry?.id,
			});
			appendToURL("doctor_specialty_ids", ids);
		}
		if (selectedCountry) {
			// const id = countries.find( country => country.name === e.value).id
			// setSelectedCountry({value : e.value , id : id})
			setFilterData({
				...filterData,
				country_id: selectedCountry?.id,
			});
			appendToURL("countryId", selectedCountry?.id);
		}

		if (selectedCity) {
			// const id = +cities.find( city => city.name === e.value).id
			// setSelectedCity({value : e.value , id})
			setFilterData({
				...filterData,
				state_id: selectedCity?.id,
			});
			appendToURL("stateId", selectedCity?.id);
		}
		if (search) {
			appendToURL("search", search);
			globalSearch(search);
		}
	};
	useEffect(() => {
		globalFilter(filterData);
	}, [filterData]);

	return (
		<section className={styles.find_care}>
			<div className="container">
				<div className={styles.sec_head}>
					<h1>{Tr.tr("patient:FindADoctor:Find_The_Care")}</h1>
					<p>{Tr.tr("patient:FindADoctor:Search_Medical_Provider")}</p>
				</div>
				<div className={styles.search_holder}>
					<form onSubmit={handleSubmit}>
						<div className="row d-flex">
							<div className="col-md-10 col-lg-10 col-xl-11 mb-4 mb-md-0">
								<div className="row">
									{/* Specialities */}
									<div className="col-md-6 col-lg-4 col-xl-3 mb-4 mb-xl-0">
										<CustomDropdown
											inputName="doctor_specialty_ids"
											inputLabel={Tr.tr("patient:FindADoctor:Specialities")}
											inputVal={selectedSpeciality}
											options={specialties}
											placeHolder={Tr.tr(
												"patient:FindADoctor:Search_Specialities"
											)}
											changeHandler={(e) => {
												setSelectedSpeciality(e.value);
											}}
											dropdownIcon="/img/specialty-icon.svg"
										/>
									</div>
									{/* Country */}
									<div className="col-md-6 col-lg-4 col-xl-3 mb-4 mb-xl-0">
										<CustomDropdown
											inputName="countryId"
											inputLabel={Tr.tr("patient:FindADoctor:Country")}
											inputVal={selectedCountry?.value}
											options={countries}
											placeHolder={Tr.tr("patient:FindADoctor:Search_Country")}
											changeHandler={(e) => {
												// getting the id of the selected country
												const id = countries.find(
													(country) => country.name === e.value
												)?.id;
												setSelectedCountry({ value: e.value, id: id });
												// setFilterData({
												// 	...filterData,
												// 	country_id : +id
												// })
												// appendToURL("countryId" , id)
											}}
											dropdownIcon="/img/place-icon.svg"
										/>
									</div>

									{/* City */}
									<div className="col-md-6 col-lg-4 col-xl-3 mb-4 mb-xl-0">
										<CustomDropdown
											disable={selectedCountry ? false : true}
											inputName="stateId"
											inputLabel={Tr.tr("patient:FindADoctor:City")}
											inputVal={selectedCity?.value}
											options={cities}
											placeHolder={Tr.tr("patient:FindADoctor:Search_City")}
											changeHandler={(e) => {
												const id = +cities.find((city) => city.name === e.value)
													?.id;
												setSelectedCity({ value: e.value, id });
												// setFilterData({
												// 	...filterData,
												// 	state_id : id
												// })
												// appendToURL("stateId" , id)
											}}
											dropdownIcon="/img/place-icon.svg"
										/>
									</div>

									<div className="col-md-6 col-lg-4 col-xl-3 mb-4 mb-xl-0">
										<SearchInput
											inputLabel={Tr.tr("patient:FindADoctor:Search")}
											inputName="search"
											placeHolder={Tr.tr(
												"patient:FindADoctor:Search_Doctors_Clinics_Hospitals"
											)}
											inputVal={search}
											changeHandler={(e) => {
												setSearch(e.target.value);
											}}
										/>
									</div>
								</div>
							</div>
							<div className="col-md-2 col-lg-2 col-xl-1 mt-0 mt-md-4">
								<Button
									type="submit"
									label={Tr.tr("patient:FindADoctor:Search")}
									className={styles.search_btn}
								/>
							</div>
						</div>
					</form>
				</div>
			</div>
		</section>
	);
};

export default FindTheCare;

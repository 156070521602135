// Components
import GradientButton from "components/Common/UI/GradientButton/GradientButton";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./ConsultOnline.module.scss";

const ConsultOnline = () => {
	const consultations = [
		{
			image: "/img/consult1.png",
			name: "Period doubts or Pregnancy",
		},
		{
			image: "/img/consult2.png",
			name: "Acne, pimple or skin issues",
		},
		{
			image: "/img/consult3.png",
			name: "Performance issues in bed",
		},
		{
			image: "/img/consult4.png",
			name: "Cold, cough or fever",
		},
		{
			image: "/img/consult5.png",
			name: "Child not feeling well",
		},
		{
			image: "/img/consult6.png",
			name: "Depression or anxiety",
		},
	];

	return (
		<section className={styles.consult_online}>
			<div className="container">
				<div className="sec_head">
					<div className="txt">
						<h2>{Tr.tr("patient:Home:Consult_Doctors_Online")}</h2>
						<p>Lorem ipsum dolor sit amet consectetur. Neque blandit</p>
					</div>
					<GradientButton label={Tr.tr("basic:View_All")} redirectTo="" />
				</div>
				<div className={styles.consult_boxes}>
					{consultations.map((consult, index) => (
						<div className={styles.consult_box} key={index}>
							<div className={styles.consult_img}>
								<img src={consult.image} alt="" />
							</div>
							<div className={styles.consult_name}>
								{Tr.tr(`patient:Home:ConsultOnline:${consult.name}`)}
							</div>
						</div>
					))}
				</div>
			</div>
		</section>
	);
};

export default ConsultOnline;

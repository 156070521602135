import axios from "../lib/axios";

export const addToWishlist = async (params) => {
	return await axios.post(`/doctors/${params?.id}/add-to-favorite`,{});
};

export const removeFromWishlist = async (params) => {
	return await axios.post(`/doctors/${params?.id}/remove-from-favorite`,{});
};

export const getWishlist = async () => {
	return await axios.post(`/users/me/favorite-doctors`,{});
};


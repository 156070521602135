// Styles
import styles from "./TitleWithLine.module.scss";

const TitleWithLine = ({ title, ...props }) => {
	return (
		<div
			className={`${styles.title_with_line}`}
			style={{ marginBottom: props.mb }}>
			{title}
		</div>
	);
};

export default TitleWithLine;

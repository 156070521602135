import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import { useMutation } from "react-query";
import toast from "react-hot-toast";

// Components
import {
	PasswordInput,
	SelectDropdown,
	TextInput,
} from "components/Common/Form/Inputs";
import GradientButton from "components/Common/UI/GradientButton/GradientButton";

// Utils
import { getFormErrorMessage } from "utils/FormField";
import { country_codes } from "utils/country_codes";

// API
import { signupAPI } from "api/auth";

// Transaltion
import { Tr } from "utils/i18n";

// Styles
import styles from "./SignUp.module.scss";

const SignUp = () => {
	const [phoneNumber, setPhoneNumber] = useState({
		phone: "",
	});
	const [error, setError] = useState("");

	const navigate = useNavigate();

	// const country_codes = [
	// 	{ label: "+966", value: "+966" },
	// 	{ label: "+20", value: "+20" },
	// 	{ label: "+90", value: "+90" },
	// 	{ label: "+40", value: "+40" },
	// ];

	const [phone, setPhone] = useState(country_codes[0]);

	const formik = useFormik({
		initialValues: {
			firstName: "",
			lastName: "",
			email: "",
			phone: "",
			password: "",
			tiryaaq_type: "patient",
		},
		validate: (data) => {
			let errors = {};

			if (!data.firstName) {
				errors.firstName = `${Tr.tr("basic:First_Name")} ${Tr.tr(
					"basic:Is_Required"
				)}.`;
			}

			if (!data.lastName) {
				errors.lastName = `${Tr.tr("basic:Last_Name")} ${Tr.tr(
					"basic:Is_Required"
				)}.`;
			}

			if (!data.email) {
				errors.email = Tr.tr("basic:Email_Required");
			} else if (
				!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)
			) {
				errors.email = Tr.tr("basic:Email_Invalid");
			}

			// if (!data.phone) {
			// 	errors.phone = "Phone number is required.";
			// } else if (!/^[0-9]{9,15}$/g.test(data.phone)) {
			// 	errors.phone = "Incorrect phone number";
			// }

			if (!data.password) {
				errors.password = Tr.tr("basic:Password_Required");
			}

			return errors;
		},
		onSubmit: async (data, { setSubmitting }) => {
			// wait 2s
			setTimeout(() => {
				setSubmitting(false);
				// formik.resetForm();
			}, 2000);
			// data.phone = phone + data.phone;
			setPhoneNumber({
				phone: phone + data.phone,
			});
			data.tiryaaq_type = "patient";
			data.country_code = phone;

			await mutate(data);
			// console.log("submitted");
			// setTimeout(() => {
			// 	setSubmitting(false);
			// 	navigate("/patient/account-verification");
			// 	formik.resetForm();
			// }, 2000);
		},
	});

	const { mutate, isLoading, isError } = useMutation(signupAPI, {
		onSuccess: (data) => {
			navigate(
				"/patient/account-verification/" + phone + formik.values.phone + ""
			);
		},
		// onError: (error) => {
		// 	// console.log(error.response.data);
		// 	// setError(error.response.data.message)
		// 	toast.error(error.response.data.message);
		// },
	});

	return (
		<div className={styles.log_P}>
			<div className="row">
				<div className={`col-lg-6 d-none d-lg-block ${styles.bgGragient}`}>
					<div className={styles.gradient_side}>
						<div className={styles.top_sec}>
							<Link to="/" className={styles.logo}>
								<img src="/img/logo-white.svg" alt="" />
							</Link>
							<h1>{Tr.tr("basic:Feel_Better_Finding_Healthcare")}</h1>
							<p>
								It is a long established fact that a reader will be distracted
								by the readable content
							</p>
						</div>
						<img
							src="/img/hero-img.png"
							alt=""
							className={styles.gradientImg}
						/>
					</div>
				</div>
				<div className="col-lg-6">
					<div className={styles.log_form}>
						<div className={styles.top_sec}>
							<Link to="/" className={`d-flex d-lg-none ${styles.logo}`}>
								<img src="/img/logo.svg" alt="" />
							</Link>
							<div className={styles.f_txt}>
								<h3>{Tr.tr("basic:Create_account")}</h3>
								<p>
									<span>{Tr.tr("basic:Already_Have_account")}</span>
									<Link to="/patient/login">{Tr.tr("basic:Login")}</Link>
								</p>
							</div>
							{/* <div className={styles.social_btns}>
								<Button className={styles.social_btn}>
									<img src="/img/google-log.svg" alt="" />
									<span>Continue With Google</span>
								</Button>
								<Button className={styles.social_btn}>
									<img src="/img/facebook-log.svg" alt="" />
									<span>Continue With Facebook</span>
								</Button>
							</div> */}
							{/* <div className={styles.or}>
								<span>Or</span>
							</div> */}

							<form
								className={styles.form_holder}
								onSubmit={formik.handleSubmit}>
								<div className={styles.inputs_wrapper}>
									<div className="row">
										{/* First Name */}
										<div className="col-md-6 m-b-20">
											<TextInput
												inputLabel={Tr.tr("basic:First_Name")}
												inputName="firstName"
												placeHolder={Tr.tr("basic:First_Name")}
												inputVal={formik.values.firstName}
												changeHandler={formik.handleChange}
												inputError={getFormErrorMessage("firstName", formik)}
												required
											/>
										</div>

										{/* Last Name */}
										<div className="col-md-6 m-b-20">
											<TextInput
												inputLabel={Tr.tr("basic:Last_Name")}
												inputName="lastName"
												placeHolder={Tr.tr("basic:Last_Name")}
												inputVal={formik.values.lastName}
												changeHandler={formik.handleChange}
												inputError={getFormErrorMessage("lastName", formik)}
												required
											/>
										</div>

										{/* Email */}
										<div className="col-md-12 m-b-20">
											<TextInput
												inputLabel={Tr.tr("basic:Email_Address")}
												inputName="email"
												placeHolder={Tr.tr("basic:Email_Address")}
												inputVal={formik.values.email}
												changeHandler={formik.handleChange}
												inputError={getFormErrorMessage("email", formik)}
												required
											/>
										</div>

										{/* Phone Number */}
										<div className="col-md-12 m-b-20">
											<h6 className={styles.inpt_lbl}>
												{Tr.tr("basic:Phone_Number")}
											</h6>
											<div className="row">
												<div className="col-5 col-md-3">
													<SelectDropdown
														inputLabel=""
														inputName="phone"
														placeHolder="+966"
														options={country_codes}
														inputVal={phone}
														changeHandler={(e) => setPhone(e.value)}
														appendTo="self"
													/>
												</div>
												<div className="col-7 col-md-9">
													<TextInput
														inputLabel=""
														inputName="phone"
														placeHolder={`${Tr.tr("basic:Enter")} ${Tr.tr(
															"basic:Phone_Number"
														)}`}
														inputVal={formik.values.phone}
														changeHandler={formik.handleChange}
														inputError={getFormErrorMessage("phone", formik)}
													/>
												</div>
											</div>
										</div>

										{/* Password */}
										<div className="col-md-12">
											<PasswordInput
												inputLabel={Tr.tr("basic:Password")}
												inputName="password"
												placeHolder={Tr.tr("basic:Password")}
												inputVal={formik.values.password}
												changeHandler={formik.handleChange}
												inputError={getFormErrorMessage("password", formik)}
												required
											/>
										</div>
									</div>
								</div>
								<GradientButton
									btn
									type="submit"
									label={Tr.tr("basic:Sign_Up")}
									// disabled={
									// 	!(formik.isValid && formik.dirty) || formik.isSubmitting
									// }
									isLoading={formik.isSubmitting}
									classes="w-100"
								/>
								<div className="col-md-12 mt-3">
									{error && <div className="alert alert-danger">{error}</div>}
								</div>
							</form>
						</div>

						<div className={styles.auth_footer}>
							<Link to="/terms">{Tr.tr("basic:Terms")}</Link>
							<Link to="/privacy">{Tr.tr("basic:Privacy")}</Link>
							<Link to="/help-center">{Tr.tr("basic:Help_Center")}</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SignUp;

import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useMutation } from "react-query";

// Components
import { SelectDropdown, TextInput } from "components/Common/Form/Inputs";
import GradientButton from "components/Common/UI/GradientButton/GradientButton";

// Utils
import { getFormErrorMessage } from "utils/FormField";
import { country_codes } from "utils/country_codes";

// Api
import { forgetPasswordAPI } from "api/auth";

// Transaltion
import { Tr } from "utils/i18n";

// Styles
import styles from "./ForgetPassword.module.scss";

const ForgetPassword = () => {
	const navigate = useNavigate();
	const [phone, setPhone] = useState(country_codes[0]);

	const { mutate: forgetPassword } = useMutation(forgetPasswordAPI, {
		onSuccess: (data) => {
			console.log(data);
			navigate("/patient/otp-verification/" + phone + formik.values.phone);
		},
		onError: (error) => {
			console.log(error);
		},
	});

	const formik = useFormik({
		initialValues: {
			phone: "",
		},
		validate: (data) => {
			let errors = {};

			if (!data.phone) {
				errors.phone = "phone is required.";
			}

			return errors;
		},
		onSubmit: (data, { setSubmitting }) => {
			console.log("submitted");
			setTimeout(async () => {
				setSubmitting(false);
				console.log(phone);
				await forgetPassword({
					phone: phone.value?.startsWith("+")
						? phone.value + data.phone
						: phone + data.phone,
				});
				// navigate("/patient/otp-verification/" + data.phone);
				formik.resetForm();
			}, 2000);
		},
	});

	return (
		<div className={styles.log_P}>
			<div className="container">
				<div className={styles.log_P_content}>
					<div className={styles.auth_header}>
						<Link to="/" className={styles.logo}>
							<img src="/img/logo.svg" alt="" />
						</Link>
					</div>
					<div className={styles.content_holder}>
						<div className={styles.card_box}>
							<Link to="/patient/login" className={styles.back_btn}>
								<img src="/img/arrow-left-gradient.svg" alt="" />
								<span>{Tr.tr("basic:Back")}</span>
							</Link>
							<div className={styles.f_txt}>
								<h3>{Tr.tr("basic:Forgot_Password")}</h3>
								<p>{Tr.tr("basic:No_Problem")}</p>
							</div>
							<form
								className={styles.form_holder}
								onSubmit={formik.handleSubmit}>
								<div className={styles.inputs_wrapper}>
									<div className="row">
										{/* Phone */}
										<div className="col-md-4 mt-4 col-7 ">
											<SelectDropdown
												inputLabel=""
												inputName="phone"
												placeHolder="Saudi Arabia"
												options={country_codes}
												inputVal={phone}
												changeHandler={(e) => setPhone(e.value)}
												appendTo="self"
											/>
										</div>
										<div className="col-md-8 col-5">
											<TextInput
												inputLabel={Tr.tr("basic:Phone_Number")}
												inputName="phone"
												placeHolder={Tr.tr("basic:Phone_Number")}
												inputVal={formik.values.phone}
												changeHandler={formik.handleChange}
												inputError={getFormErrorMessage("phone", formik)}
												withBG
											/>
										</div>
									</div>
								</div>
								<GradientButton
									btn
									type="submit"
									label={Tr.tr("basic:Send_Code")}
									disabled={
										!(formik.isValid && formik.dirty) || formik.isSubmitting
									}
									isLoading={formik.isSubmitting}
									classes="w-100"
								/>
							</form>
							<Link to="/patient/login" className={styles.back_to}>
								{Tr.tr("basic:Back_To_Login")}
							</Link>
						</div>
					</div>
					<div className={styles.auth_footer}>
						<Link to="/terms">{Tr.tr("basic:Terms")}</Link>
						<Link to="/privacy">{Tr.tr("basic:Privacy")}</Link>
						<Link to="/help-center">{Tr.tr("basic:Help_Center")}</Link>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ForgetPassword;

import { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useMutation } from "react-query";
import { toast } from "react-hot-toast";

// Components
import PageHead from "components/Patient/PageHead/PageHead";
import { SelectDropdown, TextInput } from "components/Common/Form/Inputs";
import GradientButton from "components/Common/UI/GradientButton/GradientButton";

// Utils
import { getFormErrorMessage } from "utils/FormField";

// APIs
import { getMeApi, updateMeApi } from "api/auth";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./AccountTab.module.scss";

const extractLocalPhoneNumber = (fullPhoneNumber, countryCode) => {
	if (fullPhoneNumber.startsWith(countryCode)) {
		return fullPhoneNumber.slice(countryCode.length);
	}
	return fullPhoneNumber;
};

const AccountTab = () => {
	const [me, setMe] = useState();

	// data fetching
	const { mutate: getMe } = useMutation(getMeApi, {
		onSuccess: (data) => {
			setMe(data.data);
		},
		// onError: (error) => {
		// 	toast.error(error.response.data.message);
		// },
	});

	const { mutate: updateProfile } = useMutation(updateMeApi, {
		onSuccess: (data) => {
			toast.success(data.message);
		},
		// onError: (error) => {
		// 	toast.error(error.response.data.message);
		// },
	});
	// Phones
	const countryCodes = [
		{ label: "+966", value: "+966" },
		{ label: "+20", value: "+20" },
		{ label: "+90", value: "+90" },
		{ label: "+40", value: "+40" },
	];

	const [countryCode, setCountryCode] = useState(
		localStorage.getItem("countryCode") || countryCodes[0].value
	);

	// Formik
	const formik = useFormik({
		initialValues: {
			email: me?.email || "",
			phoneNumber: me?.phone || "",
		},
		validate: (data) => {
			let errors = {};

			if (data.email !== me?.email || !me?.email) {
				if (!data.email) {
					errors.email = "Email is required.";
				} else if (
					!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)
				) {
					errors.email = "Invalid email address. E.g. user@example.com";
				}
			}
			if (data.phoneNumber !== me?.phone) {
				// PhoneNumber regex
			}

			return errors;
		},
		onSubmit: async (data, { setSubmitting }) => {
			const payload = {
				email: data.email,
				phone: countryCode + data.phoneNumber,
			};
			localStorage.setItem("countryCode", countryCode);
			await updateProfile(payload);
		},
	});

	useEffect(() => {
		if (me) {
			const localPhoneNumber = extractLocalPhoneNumber(me?.phone, countryCode);
			formik.setFieldValue("email", me.email);
			if (localPhoneNumber.startsWith("+")) {
				formik.setFieldValue("phoneNumber", "");
			} else {
				formik.setFieldValue("phoneNumber", localPhoneNumber);
			}
		} else {
			getMe();
		}
	}, [me, countryCode]);
	return (
		<div className={styles.account_tab}>
			<PageHead
				title={Tr.tr("doctor:Settings:Account_Settings")}
				subTitle={Tr.tr(
					"doctor:Settings:Change_Email_Address_And_Phone_Number_For_Account"
				)}
				mb="32px"
				titleSize="16px"
			/>

			<form className={styles.form_holder} onSubmit={formik.handleSubmit}>
				<div className={styles.inputs_wrapper}>
					<div className="row">
						<div className="col-lg-6">
							<div className="row">
								{/* Email */}
								<div className="col-md-12 m-b-20">
									<TextInput
										inputLabel={Tr.tr("doctor:Settings:Email_Address")}
										inputName="email"
										placeHolder={Tr.tr("doctor:Settings:Email_Address")}
										inputVal={formik.values.email}
										changeHandler={formik.handleChange}
										inputError={getFormErrorMessage("email", formik)}
									/>
								</div>

								{/* Phone Number */}
								<div className="col-md-12">
									<h6 className={styles.inpt_lbl}>
										{Tr.tr("doctor:Settings:Phone_Number")}
									</h6>
									<div className="row">
										<div className="col-md-5 col-lg-4 mb-3 mb-md-0">
											<SelectDropdown
												inputLabel=""
												inputName="phone"
												placeHolder="+966"
												options={countryCodes}
												inputVal={countryCode}
												changeHandler={(e) => setCountryCode(e.value)}
												appendTo="self"
											/>
										</div>
										<div className="col-md-7 col-lg-8">
											<TextInput
												inputLabel=""
												inputName="phoneNumber"
												placeHolder={Tr.tr(
													"doctor:Settings:Enter_Phone_Number"
												)}
												inputVal={formik.values.phoneNumber}
												changeHandler={formik.handleChange}
												inputError={getFormErrorMessage("phoneNumber", formik)}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<GradientButton
					btn
					type="submit"
					label={Tr.tr("doctor:Settings:Save_Changes")}
					classes="p712"
				/>
			</form>
		</div>
	);
};

export default AccountTab;

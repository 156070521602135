import axios from "../lib/axios";

export const getAllDrugsCatalogsAPI = async (params) => {
    const filters = params.queryKey[1]
    return await axios.post(`/drug-catalogs`, { ...filters });
};

export const addDrugsCatalogsAPI = async (params) => {
    return await axios.post(`/drug-catalogs/add`, params);
};

export const editDrugsCatalogsAPI = async (params) => {
    const { id, data } = params;
    return await axios.post(`/drug-catalogs/${id}/update`, data);
};

export const deleteDrugsCatalogsAPI = async (id) => {
    return await axios.post(`/drug-catalogs/${id}/delete`, {});
};

import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Menu } from "primereact/menu";
import { Button } from "primereact/button";
import { Accordion, AccordionTab } from "primereact/accordion";

// Components
import DialogWrapper from "components/Patient/DialogWrapper/DialogWrapper";
import GradientButton from "components/Common/UI/GradientButton/GradientButton";
import VitalsSignsAccordion from "components/Doctor/VitalsSignsAccordion/VitalsSignsAccordion";
import ClinicalNotesAccordion from "components/Doctor/ClinicalNotesAccordion/ClinicalNotesAccordion";
import Prescriptions from "components/Doctor/Prescriptions/Prescriptions";
import LabTestAccordion from "components/Doctor/LabTestAccordion/LabTestAccordion";

// Translation
import { useTranslation } from "react-i18next";
import { Tr } from "utils/i18n";

// Styles
import styles from "./AddRecordsModal.module.scss";

const AddRecordsModal = ({ ...props }) => {
	const { i18n } = useTranslation();
	const currLang = i18n.language;

	// Accordion Header
	const accordionHeader = (accordionLabel) => {
		return (
			<div className="head_content">
				<span className="accordion_label">{accordionLabel}</span>
				<div className="accordion_actions">
					<Button type="button" text onClick={(e) => e.stopPropagation()}>
						<img src="/img/edit-gray.svg" alt="" />
					</Button>
					<Button type="button" text onClick={(e) => e.stopPropagation()}>
						<img src="/img/delete-gray.svg" alt="" />
					</Button>
				</div>
			</div>
		);
	};

	// Accordion Tabs
	const [tabs] = useState([
		{
			header: accordionHeader("Vitals Signs"),
			children: <VitalsSignsAccordion />,
		},
		{
			header: accordionHeader("Clinical Notes"),
			children: <ClinicalNotesAccordion />,
		},
		{
			header: accordionHeader("Prescriptions"),
			children: <Prescriptions />,
		},
		{
			header: accordionHeader("Lab Test"),
			children: <LabTestAccordion />,
		},
	]);

	// Record Dropdown
	const menu = useRef(null);

	const items = [
		{
			label: (
				<span className={"added"}>
					{Tr.tr("doctor:Dashboard:Vitals_Signs")}
				</span>
			),
			command: () => {},
		},
		{
			label: (
				<span className={"added"}>
					{Tr.tr("doctor:Dashboard:Clinical_Notes")}
				</span>
			),
			command: () => {},
		},
		{
			label: <span>{Tr.tr("doctor:Dashboard:Prescriptions")}</span>,
			command: () => {},
		},
		{
			label: <span>{Tr.tr("doctor:Dashboard:Lab_Test")}</span>,
			command: () => {},
		},
	];

	// Add Record Header
	const addRecordHeader = () => {
		return (
			<div className={styles.addRecord_head}>
				<div className={styles.img}>
					<img src="/img/event-doctor.png" alt="" />
				</div>
				<div className={styles.info}>
					<h3>Ahmed Ali</h3>
					<Link to={"#"} className={styles.view_profile}>
						{Tr.tr("basic:View_Profile")}
					</Link>
				</div>
			</div>
		);
	};

	// Dynamic Accordion Tabs
	const createDynamicTabs = () => {
		return tabs.map((tab, i) => {
			return (
				<AccordionTab
					key={tab.header}
					header={tab.header}
					disabled={tab.disabled}>
					{tab.children}
				</AccordionTab>
			);
		});
	};

	return (
		<DialogWrapper
			header={addRecordHeader}
			visible={props.visible}
			width="60vw"
			hideHandler={props.hideHandler}>
			<form>
				<div className="modal_body p-0">
					<div className={styles.doctor_appointment}>
						<div className={styles.left}>
							<div className={styles.date_box}>
								<span>15</span>
								<span>Aug</span>
							</div>
							<div className={styles.info}>
								<h3>Appointment with Dr Maged El Wakeel</h3>
								<p>10:00 AM - 11:00 AM / In Clinic</p>
							</div>
						</div>
						<div className={styles.addRecord_dropdown}>
							<Menu
								model={items}
								popup
								ref={menu}
								id="popup_menu"
								className={styles.record_menu}
								popupAlignment={currLang === "ar" ? "left" : "right"}
							/>
							<Button
								type="button"
								className={styles.menu_btn}
								onClick={(event) => menu.current.toggle(event)}
								aria-controls="popup_menu"
								aria-haspopup>
								<span className={styles.btn_content}>
									<span>{Tr.tr("basic:Add_Records")}</span>
									<img src="/img/arrow-drop-down.svg" alt="" />
								</span>
							</Button>
						</div>
					</div>

					<div className={styles.records_holder}>
						<Accordion>{createDynamicTabs()}</Accordion>
					</div>
				</div>
				<div className="modal_footer">
					<Button
						type="button"
						label={Tr.tr("basic:Discard")}
						className="discard_btn withBorder"
						onClick={props.hideHandler}
					/>
					<GradientButton
						btn
						type="submit"
						label={Tr.tr("basic:Save")}
						classes="done_btn"
					/>
				</div>
			</form>
		</DialogWrapper>
	);
};

export default AddRecordsModal;

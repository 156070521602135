import { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

// Components
import GradientButton from "components/Common/UI/GradientButton/GradientButton";
import GrayButton from "components/Common/UI/GrayButton/GrayButton";
import WithdrawalMoneyModal from "./components/WithdrawalMoneyModal/WithdrawalMoneyModal";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./TransactionHistory.module.scss";

const TransactionHistory = () => {
	// Withdrawal Money Modal Visible
	const [withdrawalMoneyModalVisible, setWithdrawalMoneyModalVisible] =
		useState(false);

	// Drugs Table
	const [transactions, setTransactions] = useState([
		{
			refId: 1,
			account: "Lorem ipsum dolor sit amet, vince adipiscing elit",
			amount: 200,
			date: "November 8, 2023",
		},
		{
			refId: 2,
			account: "Lorem ipsum dolor sit amet, vince adipiscing elit",
			amount: 200,
			date: "November 8, 2023",
		},
		{
			refId: 3,
			account: "Lorem ipsum dolor sit amet, vince adipiscing elit",
			amount: 200,
			date: "November 8, 2023",
		},
		{
			refId: 4,
			account: "Lorem ipsum dolor sit amet, vince adipiscing elit",
			amount: 200,
			date: "November 8, 2023",
		},
		{
			refId: 5,
			account: "Lorem ipsum dolor sit amet, vince adipiscing elit",
			amount: 200,
			date: "November 8, 2023",
		},
		{
			refId: 6,
			account: "Lorem ipsum dolor sit amet, vince adipiscing elit",
			amount: 200,
			date: "November 8, 2023",
		},
		{
			refId: 7,
			account: "Lorem ipsum dolor sit amet, vince adipiscing elit",
			amount: 200,
			date: "November 8, 2023",
		},
		{
			refId: 8,
			account: "Lorem ipsum dolor sit amet, vince adipiscing elit",
			amount: 200,
			date: "November 8, 2023",
		},
		{
			refId: 9,
			account: "Lorem ipsum dolor sit amet, vince adipiscing elit",
			amount: 200,
			date: "November 8, 2023",
		},
		{
			refId: 10,
			account: "Lorem ipsum dolor sit amet, vince adipiscing elit",
			amount: 200,
			date: "November 8, 2023",
		},
	]);

	// [Table] Amount Cell Template
	const amountBodyTemplate = (rowData) => {
		return rowData.amount.toFixed(2);
	};

	// [Table] Action Cell Template
	const actionBodyTemplate = (rowData) => {
		return (
			<GrayButton
				btn
				type="button"
				label={Tr.tr("doctor:Settings:Download_Invoices")}
				classes="p610"
				outlined
			/>
		);
	};

	return (
		<>
			<section className={styles.transaction_history}>
				<div className={styles.transaction_history_head}>
					<h2>{Tr.tr("doctor:Settings:Transaction_History")}</h2>
					<GradientButton
						btn
						type="button"
						label={Tr.tr("doctor:Settings:Withdrawal_Money")}
						withIconImg
						icon="/img/add.svg"
						classes="p712"
						buttonClicked={() => setWithdrawalMoneyModalVisible(true)}
					/>
				</div>

				{/* Transaction History Table */}
				<div className={`table_holder`}>
					<DataTable
						className={styles.table_holder}
						value={transactions}
						tableStyle={{ minWidth: "100%" }}
						paginator
						rows={10}
						totalRecords={transactions.length}
						paginatorClassName="p_pagination"
						paginatorTemplate="RowsPerPageDropdown PrevPageLink PageLinks NextPageLink CurrentPageReport"
						rowsPerPageOptions={[10, 20, 30]}
						paginatorLeft={`${Tr.tr("doctor:Settings:Results_Per_Page")}:`}
						currentPageReportTemplate="Showing {last} of {totalRecords} results">
						<Column
							field="refId"
							header={Tr.tr("doctor:Settings:Ref_ID")}></Column>
						<Column
							field="account"
							header={Tr.tr("doctor:Settings:Account")}></Column>
						<Column
							field="amount"
							header={Tr.tr("doctor:Settings:Amount")}
							body={amountBodyTemplate}></Column>
						<Column
							field="date"
							header={Tr.tr("doctor:Settings:Date")}></Column>
						<Column header="Text" body={actionBodyTemplate}></Column>
					</DataTable>
				</div>
			</section>

			{/* Withdrawal Money Modal */}
			<WithdrawalMoneyModal
				visible={withdrawalMoneyModalVisible}
				hideHandler={() => setWithdrawalMoneyModalVisible(false)}
			/>
		</>
	);
};

export default TransactionHistory;

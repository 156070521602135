import { useState } from "react";

// Components
import { TextAreaInput } from "components/Common/Form/Inputs";
import { Button } from "primereact/button";
import WhiteButton from "components/Common/UI/WhiteButton/WhiteButton";

// Translation
import { withTranslation } from "react-i18next";
import { Tr } from "utils/i18n";

// Styles
import styles from "./ClinicalNotesAccordion.module.scss";

const ClinicalNotesAccordion = () => {
	// Is Edit
	const [isEdit, setIsEdit] = useState(false);

	return (
		<div className={styles.clinical_notes}>
			{!isEdit ? (
				<>
					<div className={styles.clinical_notes_row}>
						<h5>{Tr.tr("basic:Complaints")}</h5>
						<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
					</div>
					<div className={styles.clinical_notes_row}>
						<h5>{Tr.tr("basic:Observations")}</h5>
						<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
					</div>
				</>
			) : (
				<>
					<div className={styles.edit_rows}>
						{/* Add Complaints */}
						<div className={styles.edit_row}>
							<TextAreaInput
								inputLabel={Tr.tr("basic:Complaints")}
								inputName="complaints"
								placeHolder={Tr.tr("basic:Add_Complaints")}
								inputVal={""}
								changeHandler={() => {}}
								inputError={""}
							/>
							<Button type="button" text className="p-1 flex-shrink-0">
								<img src="/img/delete-gray.svg" alt="" />
							</Button>
						</div>

						{/* Add Observations */}
						<div className={styles.edit_row}>
							<TextAreaInput
								inputLabel={Tr.tr("basic:Observations")}
								inputName="observations"
								placeHolder={Tr.tr("basic:Add_Observations")}
								inputVal={""}
								changeHandler={() => {}}
								inputError={""}
							/>
							<Button type="button" text className="p-1 flex-shrink-0">
								<img src="/img/delete-gray.svg" alt="" />
							</Button>
						</div>

						{/* Add Diagnoses */}
						<WhiteButton
							btn
							type="button"
							label={Tr.tr("basic:Add_Diagnoses")}
						/>

						{/* Add Notes */}
						<WhiteButton btn type="button" label={Tr.tr("basic:Add_Notes")} />
					</div>
				</>
			)}
		</div>
	);
};

export default withTranslation()(ClinicalNotesAccordion);

import { useContext, useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { toast } from "react-hot-toast";

//Apis
import DoctorUploadDocumentService from "features/doctors/uploadDocumentService";

// Components
import TitleWithLine from "components/Common/UI/TitleWithLine/TitleWithLine";
import { SearchInput, SelectDropdown } from "components/Common/Form/Inputs";
import GradientButton from "components/Common/UI/GradientButton/GradientButton";
import StatusBadge from "components/Common/UI/StatusBadge/StatusBadge";
import UploadDocumentDrawer from "./Components/UploadDocumentDrawer/UploadDocumentDrawer";

// Context
import { SidebarContext } from "context/DoctorSidebarContext";

// Translation
import { useTranslation, withTranslation } from "react-i18next";
import { Tr } from "utils/i18n";

// Styles
import styles from "./SettingsMyDocuments.module.scss";

const SettingsMyDocuments = () => {
	const { i18n } = useTranslation();
	const currLang = i18n.language;

	const [documents, setDocuments] = useState([]);
	const [filteredDocuments, setFilteredDocuments] = useState([]);

	const { setSmallSize } = useContext(SidebarContext);

	const { data: documentsFetched, refetch } =
		DoctorUploadDocumentService.useFetchDoctorDocuments();
	const { mutate: deleteDocument } =
		DoctorUploadDocumentService.useDeleteDoctorDocument();

	// Search
	const [searchQuery, setSearchQuery] = useState("");

	// Type
	const [type, setType] = useState(null);

	const types = [
		{ label: "All", value: "all" },
		{ label: "ID Document", value: "id_document" },
		{ label: "Residence Certificate", value: "residence_certificate" },
	];

	// Show Upload Document Drawer
	const [visible, setVisible] = useState(false);

	useEffect(() => {
		setSmallSize(true);
		if (documentsFetched) {
			const mappedDocuments = documentsFetched.map((doc) => ({
				id: doc.id,
				type: types.find((t) => t.value === doc.type)?.label || "Unknown",
				issueDate: doc.issue_date || "Not Available",
				validFrom: doc.valid_from || "Not Available",
				expirationDate: doc.expiration_date || "Not Available",
				uploaded: doc.create_date.split(" ")[0],
				status: doc.status.charAt(0).toUpperCase() + doc.status.slice(1),
				name: doc.file.name,
			}));
			setDocuments(mappedDocuments);
		}
	}, [documentsFetched, setSmallSize]);

	// [Table] Status Cell Template
	const statusBodyTemplate = (rowData) => {
		return <StatusBadge status={rowData.status} />;
	};

	// Action Items
	const menu = useRef(null);

	const items = [
		{
			template: (item, options) => {
				return (
					<Button
						onClick={(e) => options.onClick(e)}
						className={`${options.className}`}>
						<img src="/img/edit-gray.svg" alt="" />
						<span>{Tr.tr("doctor:Settings:Edit")}</span>
					</Button>
				);
			},
		},
		{
			template: (item, options) => {
				return (
					<Button
						onClick={(e) => options.onClick(e)}
						className={`${options.className}`}>
						<img src="/img/delete-gray.svg" alt="" />
						<span>{Tr.tr("doctor:Settings:Delete")}</span>
					</Button>
				);
			},
		},
	];

	// [Table] Action Cell Template
	// const actionBodyTemplate = (rowData) => {
	// 	return (
	// 		<div className={styles.table_action}>
	// 			<Menu
	// 				model={items}
	// 				popup
	// 				ref={menu}
	// 				id="popup_menu"
	// 				popupAlignment={currLang === "ar" ? "left" : "right"}
	// 				className={styles.action_menu}
	// 			/>
	// 			<Button
	// 				icon="pi pi-ellipsis-v"
	// 				onClick={(event) => menu.current.toggle(event)}
	// 				aria-controls="popup_menu"
	// 				aria-haspopup
	// 				className={styles.action_btn}
	// 			/>
	// 		</div>
	// 	);
	// };
	const handleDelete = (documentId) => {
		deleteDocument(documentId, {
			onSuccess: () => {
				toast.success(`${Tr.tr("Document_Deleted_Successfully")}!`);
			},
		});
	};
	const actionBodyTemplate = (rowData) => {
		return (
			<div className={styles.table_action}>
				<Button
					icon="pi pi-trash"
					className={styles.action_btn}
					onClick={() => handleDelete(rowData.id)}
					aria-label="Delete"
				/>
			</div>
		);
	};
	useEffect(() => {
		let filtered = documents;

		if (type && type !== "all") {
			filtered = filtered.filter(
				(doc) => types.find((t) => t.label === doc.type)?.value === type
			);
		}

		if (searchQuery) {
			filtered = filtered.filter((doc) =>
				doc.name.toLowerCase().includes(searchQuery.toLowerCase())
			);
		}

		setFilteredDocuments(filtered);
	}, [searchQuery, type, documents]);
	return (
		<>
			<div className={styles.settings_myDocuments}>
				<TitleWithLine
					title={Tr.tr("doctor:Settings:My_Documents")}
					mb="32px"
				/>

				{/* Table Head */}
				<div className={styles.table_head}>
					<div className="row d-flex align-items-center">
						<div className="col-lg-6 mb-3 mb-lg-0">
							<div className="row">
								{/* Search */}
								<div className="col-md-6">
									<SearchInput
										inputLabel=""
										inputName="search"
										placeHolder={Tr.tr("doctor:Settings:Search_Documents")}
										inputVal={searchQuery}
										changeHandler={(e) => setSearchQuery(e.target.value)}
									/>
								</div>

								{/* Select Type */}
								<div className="col-md-6">
									<SelectDropdown
										inputLabel=""
										inputName="type"
										placeHolder={Tr.tr("doctor:Settings:Type")}
										options={types}
										inputVal={type}
										changeHandler={(e) => setType(e.target.value)}
										appendTo="self"
									/>
								</div>
							</div>
						</div>
						<div className="col-lg-6">
							<GradientButton
								btn
								type="button"
								label={Tr.tr("doctor:Settings:Upload")}
								withIconImg
								icon="/img/upload-white.svg"
								classes="p712 mis-auto"
								buttonClicked={() => setVisible(true)}
							/>
						</div>
					</div>
				</div>

				{/* Documents Table */}
				<div className={`table_holder`}>
					<DataTable
						className={styles.table_holder}
						value={filteredDocuments}
						tableStyle={{ minWidth: "100%" }}>
						<Column
							field="type"
							header={Tr.tr("doctor:Settings:Type")}></Column>
						<Column
							field="name"
							header={Tr.tr("doctor:Settings:File_Name")}></Column>
						<Column
							field="issueDate"
							header={Tr.tr("doctor:Settings:Issue_Date")}></Column>
						<Column
							field="validFrom"
							header={Tr.tr("doctor:Settings:Valid_From")}></Column>
						<Column
							field="expirationDate"
							header={Tr.tr("doctor:Settings:Expiration_Date")}></Column>
						<Column
							field="uploaded"
							header={Tr.tr("doctor:Settings:Uploaded")}></Column>
						<Column
							field="status"
							header={Tr.tr("doctor:Settings:Status")}
							body={statusBodyTemplate}></Column>
						<Column body={actionBodyTemplate}></Column>
					</DataTable>
				</div>
			</div>

			{/* Upload Document Drawer */}
			<UploadDocumentDrawer
				visible={visible}
				hideHandler={() => {
					setVisible(false);
				}}
			/>
		</>
	);
};

export default withTranslation()(SettingsMyDocuments);

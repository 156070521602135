import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Button } from "primereact/button";
import { useMutation } from "react-query";
import Cookies from "js-cookie";

// Apis
import { getMeApi } from "api/auth";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./ProfileSideBar.module.scss";

const ProfileSideBar = () => {
	const [userData, setUserData] = useState(null);

	const { mutate: getMe } = useMutation(getMeApi, {
		onSuccess: (data) => {
			setUserData(data.data);
		},
	});

	useEffect(() => {
		getMe();
	}, []);

	return (
		<div className={styles.profile_sideBar}>
			<div className={styles.user_info}>
				<div className={styles.user_img}>
					<img
						src={
							userData?.image
								? userData.image + `&session_id=${Cookies.get("session_id")}`
								: "/img/profile-img.png"
						}
						alt=""
					/>
				</div>
				<div className={styles.txt}>
					<h5>{userData?.name}</h5>
					<p>{userData?.email}</p>
				</div>
			</div>
			<div className={styles.profile_links}>
				<NavLink to="dashboard" className={styles.profile_link}>
					<img src="/img/grid-gray.svg" alt="" className={styles.gray} />
					<img
						src="/img/grid-gradient.svg"
						alt=""
						className={styles.gradient}
					/>
					<span>{Tr.tr("patient:PatientProfile:Dashboard")}</span>
				</NavLink>
				<NavLink to="my-appointments" className={styles.profile_link}>
					<img src="/img/history-gray.svg" alt="" className={styles.gray} />
					<img
						src="/img/history-gradient.svg"
						alt=""
						className={styles.gradient}
					/>
					<span>{Tr.tr("patient:PatientProfile:My_Appointments")}</span>
				</NavLink>
				<NavLink to="reminders" className={styles.profile_link}>
					<img src="/img/time-gray.svg" alt="" className={styles.gray} />
					<img
						src="/img/time-gradient-icon.svg"
						alt=""
						className={styles.gradient}
					/>
					<span>{Tr.tr("patient:PatientProfile:Reminders")}</span>
				</NavLink>
				<NavLink to="wishlist" className={styles.profile_link}>
					<img  src="/img/fav-outlined.svg" alt="" className={styles.gray} />
					<img
						src="/img/fav-outlined.svg"
						alt=""
						className={styles.gradient}
					/>
					<span>{Tr.tr("patient:PatientProfile:Wishlist")}</span>
				</NavLink>

				<NavLink to="medical-profile" className={styles.profile_link}>
					<img src="/img/doctor-gray.svg" alt="" className={styles.gray} />
					<img
						src="/img/doctor-gradient.svg"
						alt=""
						className={styles.gradient}
					/>
					<span>{Tr.tr("patient:PatientProfile:Medical_Profile")}</span>
				</NavLink>
				<NavLink to="question-answer" className={styles.profile_link}>
					<img src="/img/message-gray.svg" alt="" className={styles.gray} />
					<img
						src="/img/message-gradient.svg"
						alt=""
						className={styles.gradient}
					/>
					<span>{Tr.tr("patient:PatientProfile:Question_Answer")}</span>
				</NavLink>
				<NavLink to="account-settings" className={styles.profile_link}>
					<img src="/img/settings-gray.svg" alt="" className={styles.gray} />
					<img
						src="/img/settings-gradient.svg"
						alt=""
						className={styles.gradient}
					/>
					<span>{Tr.tr("patient:PatientProfile:Account_Settings")}</span>
				</NavLink>
			</div>

			<Button className={styles.logout_btn}>
				<img src="/img/log-out.svg" alt="" />
				<span>{Tr.tr("patient:PatientProfile:Logout")}</span>
			</Button>
		</div>
	);
};

export default ProfileSideBar;

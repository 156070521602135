// Components
import EarningsChart from "./components/EarningsChart/EarningsChart";
import EarningsSummary from "./components/EarningsSummary/EarningsSummary";
import EarningsDetails from "./components/EarningsDetails/EarningsDetails";

// Styles
import styles from "./EarningsReports.module.scss";

const EarningsReports = () => {
	return (
		<div className={styles.earnings_reports}>
			{/* Chart */}
			<EarningsChart />

			{/* Summart */}
			<EarningsSummary />

			{/* Details */}
			<EarningsDetails />
		</div>
	);
};

export default EarningsReports;

// Components
import HeroSection from "./components/HeroSection/HeroSection";
import CheckSymptoms from "./components/CheckSymptoms/CheckSymptoms";
import OurServices from "./components/OurServices/OurServices";
import FeelBetter from "./components/FeelBetter/FeelBetter";
import Specialties from "./components/Specialties/Specialties";
import ConsultOnline from "./components/ConsultOnline/ConsultOnline";
import TopArticles from "./components/TopArticles/TopArticles";
import CustomersStories from "./components/CustomersStories/CustomersStories";
import Achievements from "./components/Achievements/Achievements";
import DownloadApp from "components/Patient/DownloadApp/DownloadApp";

// Styles
import styles from "./Home.module.scss";

const Home = () => {
	return (
		<div className={styles.home}>
			<HeroSection />
			<CheckSymptoms />
			<OurServices />
			<FeelBetter />
			<Specialties />
			<ConsultOnline />
			<TopArticles />
			<CustomersStories />
			<Achievements />
			<DownloadApp />
		</div>
	);
};

export default Home;

import { Button } from "primereact/button";

// Components
import DialogWrapper from "components/Patient/DialogWrapper/DialogWrapper";
import PageHead from "components/Patient/PageHead/PageHead";
import GrayButton from "components/Common/UI/GrayButton/GrayButton";
import GradientButton from "components/Common/UI/GradientButton/GradientButton";

// Styles
import styles from "./PaypalModal.module.scss";

const PaypalModal = ({ ...props }) => {
	return (
		<DialogWrapper
			header="Add a Billing Method"
			visible={props.visible}
			width="42vw"
			hideHandler={props.hideHandler}>
			<form>
				<div className="modal_body">
					<PageHead
						title="Add PayPal"
						subTitle="You will be redirected to PayPal"
						mb="20px"
						titleSize="16px"
					/>

					<div className="row">
						<div className="col-md-4">
							<GrayButton
								label="PayPal"
								withIconImg
								icon="/img/Paypal.svg"
								leftPos
								outlined
								classes="w-100 singleBoxShadow"
							/>
						</div>
					</div>
				</div>

				<div className="modal_footer">
					<Button
						type="button"
						label="Cancel"
						className="discard_btn withBorder"
						onClick={props.hideHandler}
					/>
					<GradientButton btn type="submit" label="Save" classes="done_btn" />
				</div>
			</form>
		</DialogWrapper>
	);
};

export default PaypalModal;

import { useRef } from "react";
import { useMutation } from "react-query";
import { toast } from "react-hot-toast";

// Components
import AppointmentBox from "components/Doctor/AppointmentBox/AppointmentBox";
import GrayButton from "components/Common/UI/GrayButton/GrayButton";
import { Menu } from "primereact/menu";
import { Button } from "primereact/button";

// Translation
import { useTranslation, withTranslation } from "react-i18next";

// APIs
import { rejectAppointmentAPI } from "api/appointments";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./TodayAppointments.module.scss";

const TodayAppointments = ({
	appointments,
	setConfirmedTodayAppointmentsData,
}) => {
	const { i18n } = useTranslation();
	const currLang = i18n.language;

	const menu = useRef(null);

	const { mutate: cancelAppointment } = useMutation(rejectAppointmentAPI, {
		onSuccess: (data) => {
			toast.success("Appointment canceled successfully");
			setConfirmedTodayAppointmentsData((prev) =>
				prev.filter((appt) => appt.id !== data.data.id)
			);
		},
	});

	// Ellipses Menu
	return (
		<div className={styles.today_appointments}>
			<div className={styles.head}>
				<h3>{Tr.tr("doctor:Dashboard:Today_Appointments")}</h3>
				<p>
					{appointments?.length} {Tr.tr("basic:Appointments")}
				</p>
			</div>
			{appointments && appointments.length > 0 ? (
				appointments.map((appointment) => {
					const items = [
						{
							template: (item, options) => {
								return (
									<Button
										onClick={(e) => options.onClick(e)}
										className={`${options.className}`}>
										<img src="/img/calendar-gray.svg" alt="" />
										<span>{Tr.tr("basic:Reschedule")}</span>
									</Button>
								);
							},
						},
						{
							template: (item, options) => {
								return (
									<Button
										onClick={() => cancelAppointment({ id: appointment.id })}
										className={`${options.className}`}>
										<img src="/img/close-black.svg" alt="" />
										<span>{Tr.tr("basic:Cancel_Appointment")}</span>
									</Button>
								);
							},
						},
					];

					return (
						<div className={styles.appointments_boxes} key={appointment.id}>
							<AppointmentBox data={appointment}>
								<GrayButton
									label={Tr.tr("basic:View_Profile")}
									outlined
									redirectTo={`/doctor/patients/${appointment.partner_id.id}`}
									classes={`p610 ${styles.view_p}`}
								/>
								<Menu
									model={items}
									popup
									ref={menu}
									id={`popup_menu_${appointment.id}`}
									popupAlignment={currLang === "ar" ? "left" : "right"}
									className={styles.action_menu}
								/>
								<GrayButton
									btn
									type="button"
									outlined
									withIconImg
									icon="/img/ellipses-vertical.svg"
									buttonClicked={(event) => menu.current.toggle(event)}
									classes={styles.action_btn}
								/>
							</AppointmentBox>
						</div>
					);
				})
			) : (
				<div></div>
			)}
		</div>
	);
};

export default withTranslation()(TodayAppointments);

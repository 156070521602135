import { Link } from "react-router-dom";

// Components
import GradientButton from "components/Common/UI/GradientButton/GradientButton";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./OurServices.module.scss";
import { useState } from "react";
import ConsultationCallModal from "components/Patient/ConsultationCallModal/ConsultationCallModal";

const OurServices = () => {
	const [consultationCallModalVisible, setConsultationCallModalVisible] =
		useState(false);

	const services = [
		{
			id: 1,
			image: "/img/service1.png",
			name: Tr.tr("patient:Home:Find_Doctors_Need_You"),
		},
		{
			id: 2,
			image: "/img/service2.png",
			name: Tr.tr("patient:Home:Video_Call"),
		},
		{
			id: 3,
			image: "/img/service3.png",
			name: Tr.tr("patient:Home:Consultation_Call"),
		},
		{
			id: 4,
			image: "/img/service4.png",
			name: Tr.tr("patient:Home:Lab_Tests"),
		},
		{
			id: 5,
			image: "/img/service5.png",
			name: Tr.tr("patient:Home:Surgeries"),
		},
	];
	return (
		<section className={styles.our_services}>
			<div className="container">
				<div className="row">
					<div className="col-xl-4 col-xxl-5 mb-5 mb-xl-0">
						<div className={styles.txt_side}>
							<div className={styles.txt}>
								<h2>{Tr.tr("patient:Home:Our_services")}</h2>
								<p>Lorem ipsum dolor sit amet consectetur. Neque blandit</p>
							</div>
							<GradientButton label={Tr.tr("basic:View_All")} redirectTo="" />
						</div>
					</div>
					<div className="col-xl-8 col-xxl-7">
						<div className="row d-flex justify-content-center">
							{services.map((service) => (
								<div className="col-sm-6 col-md-4 col-lg-4" key={service.id}>
									<div onClick={() => setConsultationCallModalVisible(true)} className={styles.service_box}>
										<div className={styles.service_img}>
											<img src={service.image} alt="" />
										</div>
										<div className={styles.service_name}>{service.name}</div>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
			<ConsultationCallModal
				visible={consultationCallModalVisible}
				hideHandler={() => setConsultationCallModalVisible(false)}
			/>
		</section>
	);
};

export default OurServices;

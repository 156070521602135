import { useState } from "react";
import { Button } from "primereact/button";

// Components
import DialogWrapper from "components/Patient/DialogWrapper/DialogWrapper";
import GradientButton from "components/Common/UI/GradientButton/GradientButton";
import { CheckBox } from "components/Common/Form/Inputs";

// Styles
import styles from "./RescheduleAppointmentModal.module.scss";

const RescheduleAppointmentModal = ({ ...props }) => {
	// Selected Notifications Values
	const [selectedNotificationsOptions, setSelectedNotificationsOptions] =
		useState({});

	// Notifications
	const notifications = [
		{
			id: 1,
			title: "Notify patient via",
			options: [
				{ label: "SMS", value: "SMS" },
				{ label: "Email", value: "Email" },
			],
		},
		{
			id: 2,
			title: "Notify doctor via",
			options: [
				{ label: "SMS", value: "SMS" },
				{ label: "Email", value: "Email" },
			],
		},
	];

	// Handle Notifications Checkboxes
	const handleCheckboxChange = (notificationId, optionValue) => {
		setSelectedNotificationsOptions((prevSelectedOptions) => ({
			...prevSelectedOptions,
			[notificationId]: {
				...(prevSelectedOptions[notificationId] || {}),
				[optionValue]: !prevSelectedOptions[notificationId]?.[optionValue],
			},
		}));
	};

	return (
		<DialogWrapper
			header="Reschedule Appointment"
			visible={props.visible}
			width="42vw"
			hideHandler={props.hideHandler}>
			<form>
				<div className="modal_body">
					<div className={styles.appointment_head}>
						From {props.eventDate.oldDate} To {props.eventDate.newDate}
					</div>

					<div className={styles.choices_rows}>
						{notifications.map((notification) => (
							<div className={styles.choices_row} key={notification.id}>
								<div className="row">
									<div className="col-md-8">
										<div className={styles.notify_type}>
											{notification.title}
										</div>
									</div>
									<div className="col-md-4">
										<div className={styles.check_boxes}>
											{notification.options.map((option, index) => (
												<CheckBox
													key={index}
													name={`option-${notification.id}`}
													label={option.label}
													value={option.value}
													handleChange={() =>
														handleCheckboxChange(notification.id, option.value)
													}
													checked={
														selectedNotificationsOptions[notification.id]?.[
															option.value
														] || false
													}
												/>
											))}
										</div>
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
				<div className="modal_footer">
					<Button
						type="button"
						label="Cancel"
						className="discard_btn withBorder"
						onClick={props.hideHandler}
					/>
					<GradientButton btn type="submit" label="Save" classes="done_btn" />
				</div>
			</form>
		</DialogWrapper>
	);
};

export default RescheduleAppointmentModal;

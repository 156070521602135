// Components
import GradientButton from "components/Common/UI/GradientButton/GradientButton";
import ReminderBox from "components/Patient/ReminderBox/ReminderBox";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./Reminders.module.scss";

const Reminders = () => {
	const reminders = [
		{
			id: 1,
			time: "7:00 AM",
			icon: "/img/tablet.svg",
			name: "Aspirin",
			desc: "Lutein 40mg",
		},
		{
			id: 2,
			time: "8:00 AM",
			icon: "/img/medication.svg",
			name: "Diet Pill",
			desc: "Green Tea Extract",
		},
	];

	return (
		<div className={styles.reminders}>
			<div className={styles.head}>
				<h2>{Tr.tr("patient:PatientProfile:Reminders")}</h2>
				<GradientButton
					label={Tr.tr("basic:View_All")}
					redirectTo="/patient/my-profile/reminders"
					classes="p616"
				/>
			</div>

			<div className={styles.reminders_boxes}>
				{reminders.map((data) => (
					<ReminderBox data={data} key={data.id} />
				))}
			</div>
		</div>
	);
};

export default Reminders;

import React from "react"
import ContentLoader from "react-content-loader"

const FindDoctorLoadingSkeleton = (props) => (
  <ContentLoader
    speed={2}
    width={966} // Adjust width to fit your card (966)
    height={282} // Adjust height to fit your card (282)
    viewBox={`0 0 966 282`}
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    {/* Customize your loader elements based on your design */}
    {/* Example elements */}
    {/* <circle cx="31" cy="31" r="15" /> */}
    {/* <rect x="58" y="18" rx="2" ry="2" width="140" height="10" /> */}
    {/* <rect x="58" y="34" rx="2" ry="2" width="140" height="10" /> */}
    <rect x="0" y="60" rx="2" ry="2" width="966" height="222" /> {/* Adjust dimensions */}
  </ContentLoader>
)

export default FindDoctorLoadingSkeleton
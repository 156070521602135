// Components
import WhiteButton from "components/Common/UI/WhiteButton/WhiteButton";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./AppointmentDate.module.scss";

const AppointmentDate = ({ appointmentInfo, appointmentWay, redirectTo }) => {
	return (
		<div className={styles.appointment_date}>
			<h2>{Tr.tr("patient:DoctorDetails:Appointment_Date")}</h2>

			<div className={styles.appointment_date_content}>
				<div className={styles.left}>
					<div className={styles.icon}>
						{appointmentWay === "inClinic" && (
							<img src="/img/hospital-white.svg" alt="" />
						)}
						{appointmentWay === "videoCall" && (
							<img src="/img/video-white.svg" alt="" />
						)}
					</div>
					<div className={styles.appointment_data}>
						<div className={styles.place}>{appointmentInfo.appointmentWay}</div>
						<div className={styles.date}>{appointmentInfo.date}</div>
					</div>
				</div>

				<WhiteButton
					redirectTo={redirectTo}
					btn
					type="button"
					label={Tr.tr("patient:DoctorDetails:Change_Date_Time")}
				/>
			</div>
		</div>
	);
};

export default AppointmentDate;

import { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";

// Components
import GrayButton from "components/Common/UI/GrayButton/GrayButton";
import GradientButton from "components/Common/UI/GradientButton/GradientButton";
import StatusBadge from "components/Common/UI/StatusBadge/StatusBadge";
import AddDoctorModal from "components/Common/UI/AddDoctorModal/AddDoctorModal";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./PracticeStaff.module.scss";

const PracticeStaff = () => {
	// Doctors Table
	const [doctors, setDoctors] = useState([
		{
			id: 1,
			image: "/img/doctor-image.png",
			name: "Dr. Amr Mostfa (You)",
			email: "your@mail.com",
			specialty: "Professor of Pediatrics",
			mobile: "Text",
			emailAddress: "Text",
			status: "Active",
		},
	]);

	// [Table] Doctor Cell Template
	const doctorBodyTemplate = (rowData) => {
		return (
			<div className={styles.doctor_info}>
				<div className={styles.img}>
					<img src={rowData.image} alt="" />
				</div>
				<div className={styles.info}>
					<h5>{rowData.name}</h5>
					<h6>{rowData.email}</h6>
				</div>
			</div>
		);
	};

	// [Table] Action Cell Template
	const actionBodyTemplate = () => {
		return (
			<Button className={styles.table_action}>
				<img src="/img/ellipses-vertical.svg" alt="" />
			</Button>
		);
	};

	// [Table] Status Cell Template
	const statusBodyTemplate = (rowData) => {
		return <StatusBadge status={rowData.status} />;
	};

	// Doctor Modal Visible
	const [doctorModalVisible, setDoctorModalVisible] = useState(false);

	return (
		<>
			<div className={styles.practice_staff}>
				<div className={styles.practice_staff_content}>
					{/* Add Doctor Section */}
					<section className={styles.sec_holder}>
						<div className={styles.title}>
							<h3>{Tr.tr("doctor:OnBoarding:Add_Doctors")}</h3>
							<p>
								{Tr.tr("doctor:OnBoarding:Add_Doctors_Practice_Your_Clinic")}
							</p>
						</div>

						{/* Doctors Table */}
						<div className={`table_holder ${styles.doctors_table}`}>
							<DataTable
								value={doctors}
								tableStyle={{ maxWidth: "100%" }}
								scrollable>
								<Column
									header={Tr.tr("doctor:OnBoarding:Doctor")}
									body={doctorBodyTemplate}
									style={{ minWidth: "300px" }}></Column>
								<Column
									field="specialty"
									header={Tr.tr("doctor:OnBoarding:Specialty")}
									style={{ minWidth: "200px" }}></Column>
								<Column
									field="mobile"
									header={Tr.tr("doctor:OnBoarding:Mobile_Number")}
									style={{ minWidth: "200px" }}></Column>
								<Column
									field="emailAddress"
									header={Tr.tr("doctor:OnBoarding:Email_Address")}
									style={{ minWidth: "200px" }}></Column>
								<Column
									header="Text"
									body={statusBodyTemplate}
									style={{ minWidth: "70px" }}></Column>
								<Column
									body={actionBodyTemplate}
									style={{ minWidth: "30px" }}></Column>
							</DataTable>
						</div>

						{/* Add Doctor */}
						<GrayButton
							btn
							type="button"
							label={Tr.tr("doctor:OnBoarding:Add_A_Doctor")}
							withIconImg
							icon="/img/add-black.svg"
							outlined
							leftPos
							classes="p712 boxShadow"
							buttonClicked={() => setDoctorModalVisible(true)}
						/>
					</section>

					{/* Add Staff Section */}
					<section className={styles.sec_holder}>
						<div className={styles.title}>
							<h3>{Tr.tr("doctor:OnBoarding:Add_Staff")}</h3>
							<p>{Tr.tr("doctor:OnBoarding:Add_Your_Practice_Staff")}</p>
						</div>

						{/* Add Staff */}
						<GrayButton
							btn
							type="button"
							label={Tr.tr("doctor:OnBoarding:Add_Staff")}
							withIconImg
							icon="/img/add-black.svg"
							outlined
							leftPos
							classes="p712 boxShadow"
						/>
					</section>
				</div>

				{/* Footer Actions */}
				<div className={styles.footer_actions}>
					<GrayButton
						label={Tr.tr("basic:Back")}
						redirectTo="/doctor/on-boarding/operational-hours"
						outlined
						classes="p712"
					/>
					<GradientButton
						label={Tr.tr("basic:Next")}
						redirectTo="/doctor/on-boarding/pricing"
						classes="p712"
					/>
				</div>
			</div>

			{/* Add Doctor Modal */}
			<AddDoctorModal
				visible={doctorModalVisible}
				hideHandler={() => setDoctorModalVisible(false)}
			/>
		</>
	);
};

export default PracticeStaff;

import * as Yup from 'yup';

const creditCardSchema = Yup.object().shape({
    cardNumber: Yup.string()
        .required('Credit card number is required')
        .matches(/^[0-9]{16}$/, 'Credit card number must be exactly 16 digits'),
    expiryDate: Yup.string().required('Expiration date is required'),
    cvv: Yup.string()
        .required('CVV is required')
        .matches(/^[0-9]{3}$/, 'CVV must be 3 digits'),
    cardHolder:
        Yup.string()
            .matches(/^[A-Za-z ]*$/, "Name can only contain letters and spaces")
            .test(
                'two-words',
                'Name must contain at least two words',
                (value) => value && value.trim().split(/\s+/).length >= 2
            )
            .max(26, "Name cannot exceed 26 characters")
            .required("Cardholder name is required"),
});

export default creditCardSchema;

import { useFormik } from "formik";
import { Button } from "primereact/button";

// Components
import DialogWrapper from "components/Patient/DialogWrapper/DialogWrapper";
import PageHead from "components/Patient/PageHead/PageHead";
import {
	CalendarInput,
	SelectDropdown,
	TextInput,
} from "components/Common/Form/Inputs";
import GradientButton from "components/Common/UI/GradientButton/GradientButton";

// Utils
import { getFormErrorMessage } from "utils/FormField";

// Styles
import styles from "./PaymentCardModal.module.scss";

const PaymentCardModal = ({ ...props }) => {
	// Countries
	const countries = [
		{ label: "Saudi Arabia", value: "Saudi Arabia" },
		{ label: "Egypt", value: "Egypt" },
	];

	// Formik
	const formik = useFormik({
		initialValues: {
			firstName: "Ahmed",
			lastName: "Ali",
			expiresOnMonth: "",
			expiresOnYear: "",
			securityCode: "",
			country: countries[0].value,
			address1: "",
			address2: "",
		},
		validate: (data) => {
			let errors = {};

			if (!data.firstName) {
				errors.firstName = "First Name is required.";
			}

			if (!data.lastName) {
				errors.lastName = "Last Name is required.";
			}

			return errors;
		},
		onSubmit: (data, { setSubmitting }) => {
			console.log("submitted");
			setTimeout(() => {
				setSubmitting(false);
				formik.resetForm();
			}, 2000);
		},
	});

	return (
		<DialogWrapper
			header="Add a Billing Method"
			visible={props.visible}
			width="42vw"
			hideHandler={props.hideHandler}>
			<form onSubmit={formik.handleSubmit}>
				<div className="modal_body">
					<PageHead
						title="Payment card"
						subTitle="Lorem ipsum dolor sit amet, vince adipiscing elit"
						mb="20px"
						titleSize="16px"
					/>

					<div className="row">
						{/* First Name */}
						<div className="col-md-6 mb-3">
							<TextInput
								inputLabel="First Name"
								inputName="firstName"
								placeHolder="First Name"
								inputVal={formik.values.firstName}
								changeHandler={formik.handleChange}
								inputError={getFormErrorMessage("firstName", formik)}
							/>
						</div>

						{/* Last Name */}
						<div className="col-md-6 mb-3">
							<TextInput
								inputLabel="Last name"
								inputName="lastName"
								placeHolder="Last name"
								inputVal={formik.values.lastName}
								changeHandler={formik.handleChange}
								inputError={getFormErrorMessage("lastName", formik)}
							/>
						</div>

						{/* Expires On */}
						<div className="col-md-6 mb-3">
							<h6 className={styles.input_lbl}>Expires on</h6>
							<div className="row">
								<div className="col-md-6 mb-3 mb-md-0">
									<CalendarInput
										inputLabel=""
										inputName="expiresOnMonth"
										placeHolder="MM"
										inputVal={formik.values.expiresOnMonth}
										changeHandler={(e) =>
											formik.setFieldValue("expiresOnMonth", e.value)
										}
										appendTo={null}
										showIcon={false}
										view="month"
										dateFormat="mm"
										menuClasses={styles.calendar_menu}
									/>
								</div>
								<div className="col-md-6 mb-3 mb-md-0">
									<CalendarInput
										inputLabel=""
										inputName="expiresOnYear"
										placeHolder="YY"
										inputVal={formik.values.expiresOnYear}
										changeHandler={(e) =>
											formik.setFieldValue("expiresOnYear", e.value)
										}
										appendTo={null}
										showIcon={false}
										view="year"
										dateFormat="yy"
										menuClasses={styles.calendar_menu}
									/>
								</div>
							</div>
						</div>

						{/* Security Code */}
						<div className="col-md-6 mb-3">
							<TextInput
								inputLabel="Security Code"
								inputName="securityCode"
								placeHolder="Enter security code"
								inputVal={formik.values.securityCode}
								changeHandler={formik.handleChange}
								inputError={getFormErrorMessage("securityCode", formik)}
							/>
						</div>

						<h3 className={styles.address_title}>Billing Address</h3>

						{/* Country */}
						<div className="col-md-12 mb-3">
							<SelectDropdown
								inputLabel="Country"
								inputName="country"
								placeHolder="Choose your country"
								options={countries}
								inputVal={formik.values.country}
								changeHandler={(e) => formik.setFieldValue("country", e.value)}
								appendTo={document.body}
							/>
						</div>

						{/* Address line 1 */}
						<div className="col-md-12 mb-3">
							<TextInput
								inputLabel="Address line 1"
								inputName="address1"
								placeHolder="Enter address"
								inputVal={formik.values.address1}
								changeHandler={formik.handleChange}
								inputError={getFormErrorMessage("address1", formik)}
							/>
						</div>

						{/* Address line 2 (optional) */}
						<div className="col-md-12 mb-3">
							<TextInput
								inputLabel="Address line 2 (optional)"
								inputName="address2"
								placeHolder="Enter address"
								inputVal={formik.values.address2}
								changeHandler={formik.handleChange}
								inputError={getFormErrorMessage("address2", formik)}
							/>
						</div>
					</div>
				</div>
				<div className="modal_footer">
					<Button
						type="button"
						label="Cancel"
						className="discard_btn withBorder"
						onClick={props.hideHandler}
					/>
					<GradientButton btn type="submit" label="Save" classes="done_btn" />
				</div>
			</form>
		</DialogWrapper>
	);
};

export default PaymentCardModal;

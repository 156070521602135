export const en = {
	Header: {
		Find_A_Doctor: "Find a Doctor",
		Video_Consult: "Video Consult",
		Consultation_Call: "Consultation Call",
		Customer_Support: "Customer Support",
		Hello_Login: "Hello, Log in",
		Profile: "Profile",
		My_Appointments: "My Appointments",
		Account_Settings: "Account Settings",
		Logout: "Logout",
	},
	Footer: {
		Tiryaaq: "Tiryaaq",
		For_Patients: "For Patients",
		For_Doctor: "For Doctor",
		For_Hospitals: "For hospitals",
		More: "More",
		About: "About",
		Blog: "Blog",
		Careers: "Careers",
		Contact_Us: "Contact Us",
		Find_Doctors: "Find Doctors",
		Video_Call: "Video Call",
		Consultation_Call: "Consultation Call",
		Lab_Tests: "Lab Tests",
		Surgeries: "Surgeries",
		Tiryaaq_Profile: "Tiryaaq Profile",
		Login: "Login",
		Join_With_Us: "Join with us",
		Privacy_Policy: "Privacy Policy",
		Terms_Conditions: "Terms & Conditions",
		Help: "Help",
	},
	Home: {
		Check_Your_Symptoms: "Check your symptoms Get it now 45% Off",
		Our_services: "Our services",
		Find_Doctors_Need_You: "Find Doctors need you",
		Video_Call: "Video Call",
		Consultation_Call: "Consultation Call",
		Lab_Tests: "Lab Tests",
		Surgeries: "Surgeries",
		Specialties: "Specialties",
		View_All_Specialities: "View All Specialities",
		Consult_Doctors_Online: "Consult top doctors online for any health concern",
		Read_Top_Articles: "Read top articles from health experts",
		See_All_Articles: "See all articles",
		Real_Stories_Customers: "Real Stories from Real Customers",
		Real_Stories_Customers_Inspired:
			"Real Stories from Real Customers Get inspired by these stories.",
		More_Years_Achievements: "More than 3 years of achievements",
		Hospitals: "Hospitals",
		Doctors: "Doctors",
		Happy_Clients: "Happy Clients",
		hero: {
			desc: "It is a long established fact that a reader will be distracted by the readable content",
		},
		Specialities: {
			Dermatology: "Dermatology",
			"Neurology (Brain & Nerves)": "Neurology (Brain & Nerves)",
			"Chest & Respiratory": "Chest & Respiratory",
			"Dentistry (Teeth)": "Dentistry (Teeth)",
			"Cardiology & Vascular Disease": "Cardiology & Vascular Disease",
			Urologyy: "Urology",
			"Stomach & digestion": "Stomach & digestion",
		},
		ConsultOnline: {
			"Period doubts or Pregnancy": "Period doubts or Pregnancy",
			"Acne, pimple or skin issues": "Acne, pimple or skin issues",
			"Performance issues in bed": "Performance issues in bed",
			"Cold, cough or fever": "Cold, cough or fever",
			"Child not feeling well": "Child not feeling well",
			"Depression or anxiety": "Depression or anxiety",
		},
		CustomersStories: {
			"Very good app. Well thought out about booking/rescheduling/canceling an appointment. Also, Doctor's feedback mechanism is good and describes all the basics in a good way":
				"Very good app. Well thought out about booking/rescheduling/canceling an appointment. Also, Doctor's feedback mechanism is good and describes all the basics in a good way",
		},
	},
	FindADoctor: {
		Find_The_Care: "Find the care you need",
		Search_Medical_Provider:
			"Search for a medical provider and book an appointment now!",
		Specialities: "Specialities",
		Country: "Country",
		Search_Specialities: "Search Specialities",
		Search_Country: "Search country",
		City: "City",
		Search_City: "Search city",
		Search: "Search",
		Search_Doctors_Clinics_Hospitals: "Search doctors, clinics, hospitals",
		Doctors_Available: "Doctors Available",
		Consultation_Call: "Consultation Call",
		Video_Consult: "Video Consult",
		Sort_By: "Sort By:",
		Title: "Title",
		Language: "Language",
		Availability: "Availability",
		Nationality: "Nationality",
		Gender: "Gender",
		Price_Range: "Price Range",
		Review: "Review",
		View_Profile: "View Profile",
		Book_Now: "Book Now",
		Call_Now: "Coll Now",
		Reviews: "Reviews",
	},
	DoctorDetails: {
		Add_To_Favorite: "Add to favorite",
		Remove_From_Favorite: "Remove from favorite",
		Share: "Share",
		About_The_Doctor: "About The Doctor",
		Booking_Information: "Booking Information",
		Patients_Reviews: "Patients’ Reviews",
		Ratings: "ratings",
		Excellent: "Excellent",
		Very_Good: "Very Good",
		Average: "Average",
		Poor: "Poor",
		Terrible: "Terrible",
		Show_All_Reviews: "Show all reviews",
		Show_Less_Reviews: "Show less reviews",
		No_Reviews: "There is not any reviews",
		Book_Your_Appointment: "Book Your Appointment",
		In_Clinic: "In Clinic",
		Video_Call: "Video Call",
		Examination_Fees_IN_Clinic: "Examination fees in the clinic",
		Video_Call_Fees: "Video call fees",
		SAR: "SAR",
		Not_Available_Times: "Not Available Times",
		Book_Now: "Book Now",
		Doctors_In_Pediatrics: "Doctors in pediatrics",
		Reviews: "Reviews",
		Confirm_Appointment_Booking: "Confirm appointment booking",
		Appointment_Date: "Appointment Date",
		Change_Date_Time: "Change Date & Time",
		Medical_Interpreter: "Medical interpreter",
		Do_You_Want_Medical_Interpreter: "Do you want a medical interpreter",
		Payment_Method: "Payment Method",
		Booking_Confirmation: "Booking Confirmation",
		Summary: "Summary",
		Examination_Fees_In_Clinic: "Examination fees in the clinic",
		Add_Credit_Debit_Card: "Add a credit or debit card",
		Pay_At_Clinic: "Pay at the clinic",
		Credit_Card_Visa: "Credit Card / Visa",
		Ending_In: "Ending in",
	},
	PatientProfile: {
		My_Profile: "My Profile",
		Wishlist: "Wishlist",
		Dashboard: "Dashboard",
		My_Appointments: "My Appointments",
		Reminders: "Reminders",
		Medical_Profile: "Medical Profile",
		Question_Answer: "Question & Answer",
		Account_Settings: "Account Settings",
		Logout: "Logout",
		Track_Healthy_Daily: "Let’s track your health daily!",
		Quick_Links: "Quick Links",
		Upcoming_Appointment: "Upcoming Appointment",
		All: "All",
		In_Clinic: "In Clinic",
		Video_Consult: "Video Consult",
		Upcoming: "Upcoming",
		Completed: "Completed",
		Canceled: "Canceled",
		Appointments: "Appointments",
		Search_By_Doctor_Name: "Search by doctor name",
		Add_Medicine: "Add medicine",
		Medications: "Medications",
		Morning: "Morning",
		Afternoon: "Afternoon",
		Evening: "Evening",
		Dosage: "Dosage",
		Days: "Days",
		Start: "Start",
		End: "End",
		Title: "Title",
		Add_Title: "Add Title",
		Tablet: "Tablet",
		Capsule: "Capsule",
		Drops: "Drops",
		Injection: "Injection",
		Dosage_Per_Day: "Dosage (per day)",
		Add_Time: "Add time",
		Duration: "Duration",
		Alarm: "Alarm",
		Add_Schedule: "Add Schedule",
		Information: "Information",
		Age: "Age",
		Gender: "Gender",
		Blood_Type: "Blood Type",
		Weight: "Weight",
		Height: "Height",
		Vitals: "Vitals",
		Select_Gender: "Select gender",
		Select_Blood_Type: "Select blood type",
		Blood_Pressure: "Blood Pressure",
		Pulse: "Pulse",
		Lab_Results: "Lab results",
		Upload_Lab_Result: "Upload lab result",
		Type: "Type",
		Select_Type: "Select type",
		Current_Medications: "Current medications",
		Add_Question: "Add a question",
		Edit_Question: "Edit a question",
		View_By: "View by",
		Questions: "Questions",
		No_Answer_Yet: "No answer yet",
		Answer_From: "Answer From",
		View_Profile: "View Profile",
		Question: "Question",
		Specialties: "Specialties",
		Edit_Account_Settings_And_Change_Password:
			"Edit your account settings and change your password here.",
		Change_Password: "Change Password",
		Profile: "Profile",
		Add_Information_About_Yourself: "Add information about yourself",
		Upload_Photo_Logo: "Upload your photo or logo",
		Photos_Help_Teammates: "Photos help your teammates recognize you",
		Waiting_For_Confirmation: "Waiting for confirmation",
		Reservation_Cancelled: "Reservation Cancelled",
		Professor_Of: "Professor of",
		Online_Video_Call: "Online video call",
		Phone_Call: "Phone call",
		View_Prescription: "View Prescription",
		Payment_Methods: "Payment Methods",
		Your_Saved_Payment_Methods: "Your saved payment methods",
		Add_Payment_Method: "Add Payment Method",
		Add_Payment: "Add Payment",
		Card_Number: "Card Number",
		Expiry_Date: "Expiry Date",
		CVV: "CVV",
		Card_Holder: "Card Holder",
		Add_Your_Feedback: "Add Your Feedback",
		Message: "Message",
	},
	CheckSymptoms: {
		Check_Your_Symptoms: "Check your symptoms",
		Patient: "Patient",
		Symptoms: "Symptoms",
		Interview: "Interview",
		Results: "Results",
		Start_New_Checkup: "Start new checkup",
		Consult_Doctor: "Consult a doctor",
		Medical_Evaluation:
			"Your symptoms may require medical evaluation. Schedule an appointment with your doctor. If your symptoms get worse, see a doctor immediately.",
		Recommended_Doctor: "Recommended doctor",
		Recommended_Appointment_Type: "Recommended appointment type",
		Possible_Conditions: "Possible conditions",
		Possible_Guidance:
			"This list of possible diseases may not be complete, and its sole purpose is to provide guidance and insight into what could be causing these symptoms.",
		Your_Answers: "Your answers",
	},
};

export const ar = {
	Header: {
		Find_A_Doctor: "ابحث عن طبيب",
		Video_Consult: "استشارة عبر الفيديو",
		Consultation_Call: "مكالمة صوتية",
		Customer_Support: "دعم العملاء",
		Hello_Login: "مرحبا، تسجيل الدخول",
		Profile: "الصفحة الشخصية",
		My_Appointments: "مواعيدي",
		Account_Settings: "إعدادات الحساب",
		Logout: "تسجيل الخروج",
	},
	Footer: {
		Tiryaaq: "ترياق",
		For_Patients: "للمرضى",
		For_Doctor: "للطبيب",
		For_Hospitals: "للمستشفيات",
		More: "المزيد",
		About: "حول",
		Blog: "المدونة",
		Careers: "وظائف",
		Contact_Us: "اتصل بنا",
		Find_Doctors: "البحث عن الأطباء",
		Video_Call: "مكالمة فيديو",
		Consultation_Call: "مكالمة صوتية",
		Lab_Tests: "الفحوصات المخبرية",
		Surgeries: "العمليات الجراحيه",
		Tiryaaq_Profile: "لمحة تعريفية عن ترياق",
		Login: "تسجيل الدخول",
		Join_With_Us: "انضم إلينا",
		Privacy_Policy: "سياسة الخصوصية",
		Terms_Conditions: "الشروط والأحكام",
		Help: "المساعده",
	},
	Home: {
		Check_Your_Symptoms: "تحقق من أعراضك احصل عليه الآن خصم 45٪",
		Our_services: "خدماتنا",
		Find_Doctors_Need_You: "البحث عن الأطباء بحاجة إليك",
		Video_Call: "مكالمة فيديو",
		Consultation_Call: "مكالمة صوتية",
		Lab_Tests: "الفحوصات المخبرية",
		Surgeries: "العمليات الجراحيه",
		Specialties: "التخصصات",
		View_All_Specialities: "عرض جميع التخصصات",
		Consult_Doctors_Online: "استشر أفضل الأطباء عبر الإنترنت لأي مشكلة صحية",
		Read_Top_Articles: "اقرأ أهم المقالات من خبراء الصحة",
		See_All_Articles: "عرض جميع المقالات",
		Real_Stories_Customers: "قصص حقيقية من عملاء حقيقيين",
		Real_Stories_Customers_Inspired:
			"قصص حقيقية من عملاء حقيقيين احصل على الإلهام من هذه القصص.",
		More_Years_Achievements: "أكثر من 3 سنوات من الإنجازات",
		Hospitals: "المستشفيات",
		Doctors: "اطباء",
		Happy_Clients: "عملاء سعداء",
		hero: {
			desc: "إنها حقيقة مثبتة منذ زمن طويل أن المحتوى المقروء سيلهي القارئ",
		},
		Specialities: {
			Dermatology: "الأمراض الجلدية",
			"Neurology (Brain & Nerves)": "طب الأعصاب (المخ والأعصاب)",
			"Chest & Respiratory": "الصدر والجهاز التنفسي",
			"Dentistry (Teeth)": "طب الأسنان (الأسنان)",
			"Cardiology & Vascular Disease": "أمراض القلب والأوعية الدموية",
			Urologyy: "جراحة المسالك البولية",
			"Stomach & digestion": "المعدة والهضم",
		},
		ConsultOnline: {
			"Period doubts or Pregnancy": "الشك في الدورة الشهرية أو الحمل",
			"Acne, pimple or skin issues": "حب الشباب أو البثور أو مشاكل الجلد",
			"Performance issues in bed": "مشاكل الأداء في السرير",
			"Cold, cough or fever": "البرد أو السعال أو الحمى",
			"Child not feeling well": "الطفل لا يشعر بخير",
			"Depression or anxiety": "الاكتئاب أو القلق",
		},
		CustomersStories: {
			"Very good app. Well thought out about booking/rescheduling/canceling an appointment. Also, Doctor's feedback mechanism is good and describes all the basics in a good way":
				"تطبيق جيد جدا. مدروس جيدًا بشأن حجز / إعادة جدولة / إلغاء موعد. كما أن آلية التغذية الراجعة للطبيب جيدة وتصف جميع الأساسيات بطريقة جيدة",
		},
	},
	FindADoctor: {
		Find_The_Care: "اعثر على الرعاية التي تحتاجها",
		Search_Medical_Provider: "ابحث عن مقدم رعاية طبية واحجز موعدا الآن!",
		Specialities: "التخصصات",
		Country: "البلد",
		Search_Specialities: "البحث عن التخصصات",
		Search_Country: "البحث عن البلد",
		City: "المدينة",
		Search_City: "البحث عن المدينة",
		Search: "بحث",
		Search_Doctors_Clinics_Hospitals: "البحث عن الأطباء والعيادات والمستشفيات",
		Doctors_Available: "الأطباء المتاحون",
		Consultation_Call: "مكالمة صوتية",
		Video_Consult: "استشارة عبر الفيديو",
		Sort_By: "ترتيب حسب:",
		Title: "اللقب",
		Language: "اللغة",
		Availability: "التوافر",
		Nationality: "الجنسية",
		Gender: "النوع",
		Price_Range: "نطاق السعر",
		Review: "التقييم",
		View_Profile: "عرض الملف الشخصي",
		Book_Now: "احجز الآن",
		Call_Now: "اتصل الآن",
		Reviews: "التقييمات",
	},
	DoctorDetails: {
		Add_To_Favorite: "اضف الى المفضلة",
		Share: "مشاركه",
		About_The_Doctor: "نبذة عن الطبيب",
		Booking_Information: "معلومات الحجز",
		Patients_Reviews: "تقييمات المرضى",
		Ratings: "تقييمات",
		Excellent: "ممتاز",
		Very_Good: "جيد جداً",
		Average: "متوسط",
		Poor: "ضعيف",
		Terrible: "سيئ",
		Show_All_Reviews: "عرض جميع التقييمات",
		Show_Less_Reviews: "عرض تقييمات أقل",
		No_Reviews: "لا يوجد أي تعليقات",
		Book_Your_Appointment: "احجز موعدك",
		In_Clinic: "في العيادة",
		Video_Call: "مكالمة فيديو",
		Examination_Fees_IN_Clinic: "رسوم الفحص في العيادة",
		Video_Call_Fees: "رسوم مكالمة الفيديو",
		SAR: "ريال سعودي",
		Not_Available_Times: "الأوقات غير متوفرة",
		Book_Now: "احجز الآن",
		Doctors_In_Pediatrics: "أطباء في طب الأطفال",
		Reviews: "تقييمات",
		Confirm_Appointment_Booking: "تأكيد حجز الموعد",
		Appointment_Date: "تاريخ الموعد",
		Change_Date_Time: "تغيير التاريخ والوقت",
		Medical_Interpreter: "مترجم طبي",
		Do_You_Want_Medical_Interpreter: "هل تريد مترجم طبي",
		Payment_Method: "طريقة الدفع",
		Booking_Confirmation: "تأكيد الحجز",
		Summary: "الملخص",
		Examination_Fees_In_Clinic: "رسوم الفحص في العيادة",
		Add_Credit_Debit_Card: "إضافة بطاقة ائتمان أو بطاقة سحب آلي",
		Pay_At_Clinic: "الدفع في العيادة",
		Credit_Card_Visa: "بطاقة الائتمان / فيزا",
		Ending_In: "تنتهي في",
	},
	PatientProfile: {
		My_Profile: "ملفي الشخصي",
		Dashboard: "لوحه القياده",
		My_Appointments: "مواعيدي",
		Reminders: "رسائل التذكير",
		Medical_Profile: "الملف الطبي",
		Question_Answer: "سؤال وجواب",
		Account_Settings: "إعدادات الحساب",
		Logout: "تسجيل الخروج",
		Track_Healthy_Daily: "دعنا نتتبع صحتك يوميا!",
		Quick_Links: "روابط سريعة",
		Upcoming_Appointment: "الموعد القادم",
		All: "الكل",
		In_Clinic: "في العيادة",
		Video_Consult: "استشارة الفيديو",
		Upcoming: "القادمه",
		Completed: "المكتمله",
		Canceled: "تم الغاؤه",
		Appointments: "المواعيد",
		Search_By_Doctor_Name: "البحث بواسط اسم الطبيب",
		Add_Medicine: "إضافة دواء",
		Medications: "الأدويه",
		Morning: "صباحًا",
		Afternoon: "بعد الظهر",
		Evening: "مساءً",
		Dosage: "الجرعه",
		Days: "الأيام",
		Start: "البدء",
		End: "انتهاء",
		Title: "اللقب",
		Add_Title: "إضافة لقب",
		Tablet: "قرص",
		Capsule: "كبسول",
		Drops: "قطرات",
		Injection: "حقن",
		Dosage_Per_Day: "الجرعة (يوميا)",
		Add_Time: "إضافة وقت",
		Duration: "المدة",
		Alarm: "انذار",
		Add_Schedule: "إضافة جدول",
		Information: "معلومات",
		Age: "السن",
		Gender: "النوع",
		Blood_Type: "فصيلة الدم",
		Weight: "الوزن",
		Height: "الطول",
		Vitals: "الحيويه",
		Select_Gender: "اختر النوع",
		Select_Blood_Type: "اختر فصيلة الدم",
		Blood_Pressure: "ضغط الدم",
		Pulse: "النبض",
		Lab_Results: "نتائج المختبر",
		Upload_Lab_Result: "رفع نتائج المختبر",
		Type: "النوع",
		Select_Type: "اختر النوع",
		Current_Medications: "الأدوية الحالية",
		Add_Question: "إضافة سؤال",
		Edit_Question: "تعديل سؤال",
		View_By: "عرض بواسطة",
		Questions: "الاسئله",
		No_Answer_Yet: "لا جواب حتى الآن",
		Answer_From: "إجابة من",
		View_Profile: "عرض الملف الشخصي",
		Question: "السؤال",
		Specialties: "التخصصات",
		Edit_Account_Settings_And_Change_Password:
			"قم بتحرير إعدادات حسابك وتغيير كلمة المرور الخاصة بك هنا.",
		Change_Password: "تغيير كلمة المرور",
		Profile: "ملف تعريف",
		Add_Information_About_Yourself: "إضافة معلومات عن نفسك",
		Upload_Photo_Logo: "قم بتحميل صورتك أو شعارك",
		Photos_Help_Teammates: "تساعد الصور زملائك في الفريق على التعرف عليك",
		Waiting_For_Confirmation: "في انتظار التأكيد",
		Reservation_Cancelled: "تم إلغاء الحجز",
		Professor_Of: "أستاذ",
		Online_Video_Call: "مكالمة فيديو عبر الإنترنت",
		Phone_Call: "مكالمة هاتفية",
		View_Prescription: "عرض الوصفة الطبية",
		Payment_Methods: "طرق الدفع",
		Your_Saved_Payment_Methods: "طرق الدفع المحفوظة لديك",
		Add_Payment_Method: "إضافة طريقة دفع",
		Add_Payment: "إضافة الدفع",
		Card_Number: "رقم البطاقة",
		Expiry_Date: "تاريخ الانتهاء",
		CVV: "CVV",
		Card_Holder: "حامل البطاقة",
		Add_Your_Feedback: "أضف ملاحظاتك",
		Message: "مراسلة",
	},
	CheckSymptoms: {
		Check_Your_Symptoms: "تحقق من الأعراض الخاصة بك",
		Patient: "المريض",
		Symptoms: "الأعراض",
		Interview: "المقابله",
		Results: "النتائج",
		Start_New_Checkup: "بدء فحص جديد",
		Consult_Doctor: "استشر الطبيب",
		Medical_Evaluation:
			"قد تتطلب أعراضك تقييما طبيا. تحديد موعد مع طبيبك. إذا تفاقمت الأعراض ، فاستشر الطبيب على الفور.",
		Recommended_Doctor: "الطبيب الموصى به",
		Recommended_Appointment_Type: "نوع الموعد الموصى به",
		Possible_Conditions: "الظروف الممكنة",
		Possible_Guidance:
			"قد لا تكون قائمة الأمراض المحتملة هذه كاملة ، والغرض الوحيد منها هو توفير التوجيه والتبصر حول ما يمكن أن يسبب هذه الأعراض.",
		Your_Answers: "إجاباتك",
	},
};

// Styles
// import styles from "./FormatEventTime.module.scss";

const FormatEventTime = ({ arg }) => {
	return (
		<div
			className={`event_box ${
				arg.event.extendedProps.location.toLowerCase() === "in clinic"
					? "inClinic"
					: "videoCall"
			}`}>
			<p className="event_title">{arg.event.title}</p>
			<p className="event_desc">{arg.event.extendedProps.location}</p>
		</div>
	);
};

export default FormatEventTime;

import axios from "../lib/axios";

export const loginAPI = async (params) => {
	while (params?.phone?.startsWith("0")) {
		let phone = params.phone;
		phone = phone.substring(1);
		params.phone = phone;
	}
	return await axios.post("/login", params);
};

export const signupAPI = async (params) => {
	if (params?.tiryaaq_type === "doctor") {
		const payload = {
			...params,
			phone: params.phone,
			name: params.name,
		};
		delete payload.agree;
		return await axios.post(`/signup`, payload);
	}

	const data = {
		...params,
		phone: params.country_code + params.phone,
		name: params.firstName,
		password_confirmation: params.password,
	};
	// * that will be removed after backend fix the issue
	delete data.firstName;
	delete data.lastName;
	delete data.password_confirmation;
	delete data.country_code;

	return await axios.post(`/signup`, data);
};

export const sendVerificationCodeAPI = async (params) => {

	const data = {
		phone: params,
	};
	return await axios.post(`/send_verification_code`, data);
};

export const activeAPI = async (params) => {

	return await axios.post(`/verify_verification_code`, params);
};

export const forgetPasswordAPI = async (params) => {
	return await axios.post(`/forgot-password`, params);
};

export const verifyForgotPasswordCode = async (params) => {
	return await axios.post(`/verify-forgot-password-code`, params);
};

export const checkForgetPasswordOTPAPI = async (params) => {
	console.log(params);
	return await axios.post(`/check_otp_forget_password`, params);
};

export const resetPasswordAPI = async (params) => {
	return await axios.post(`/reset-password`, params);
};

export const getProfileSettingsAPI = async () => {
	return await axios.get(`/my/settings`);
};

export const postProfileSettingsAPI = async (params) => {
	return await axios.patch(`/my/settings`, params);
};

export const getPictureSettingsAPI = async () => {
	return await axios.get(`/my/settings/photo`);
};

export const postPictureSettingsAPI = async (params) => {
	return await axios.post(`/my/settings/injaah_upload_photo`, params);
};

export const postResetPasswordSettingsAPI = async (params) => {
	return await axios.post(`/my/settings/reset-password`, params);
};

export const changePasswordSettingsAPI = async (params) => {
	return await axios.post(`/change-password`, params);
};

export const getMeApi = async () => {

	return await axios.post(`/users/me`, {});
};

export const updateMeApi = async (params) => {

	return await axios.post(`/users/update/me`, params);
};

export const uploadDocumentApi = async (params) => {


	return await axios.post(
		`/users/me/documents/upload/`,
		params,
		{
			headers: {
				"Content-Type": "multipart/form-data",
			},
		}
	);
};

export const deleteDocumentApi = async (documentId) => {

	return await axios.post(
		`/users/me/documents/delete/${documentId}`,
		{},
		{
			headers: {
				"Content-Type": "application/json",
			},
		}
	);
};

export const getDocumentsApi = async () => {

	return await axios.post(`/users/me/documents`, {});
};

import React, { useState } from "react";
import { useMutation } from "react-query";
import { toast } from "react-hot-toast";
import moment from "moment";
import { Button } from "primereact/button";

// Components
import AppointmentBox from "components/Doctor/AppointmentBox/AppointmentBox";

// APIs
import { approveAppointmentAPI, rejectAppointmentAPI } from "api/appointments";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./AppointmentsRequests.module.scss";

const formatDateString = (dateFrom) => {
	const day = moment(dateFrom).format("dddd");
	const month = moment(dateFrom).format("D MMM");
	return `${day} ${month}`;
};

const formatDay = (dateString) => {
	const dateFrom = moment.utc(dateString).local();
	const date = formatDateString(dateFrom);
	return date;
};

const groupAppointmentsByDay = (appointments) => {
	const grouped = {};
	if (appointments && appointments.length > 0) {
		appointments.forEach((appointment) => {
			const key = formatDay(appointment.date_from);
			if (!grouped[key]) {
				grouped[key] = [];
			}
			grouped[key].push(appointment);
		});
	}
	return grouped;
};

const AppointmentsRequests = ({
	appointments,
	setRequestedAppointmentsData,
	setConfirmedTodayAppointmentsData,
	nextConfirmedAppointmentData,
	setNextConfirmedAppointmentData,
}) => {
	console.log(nextConfirmedAppointmentData);
	const groupedAppointments = groupAppointmentsByDay(appointments);

	const { mutate: confirmAppointment } = useMutation(approveAppointmentAPI, {
		onSuccess: (data) => {
			toast.success("Appointment confirm Successfully");
			setRequestedAppointmentsData((prev) =>
				prev.filter((appt) => appt.id !== data.data.id)
			);

			const appointmentDate = new Date(data.data.date_from);
			const today = new Date();
			const startOfDay = new Date(
				today.getFullYear(),
				today.getMonth(),
				today.getDate()
			);
			const endOfDay = new Date(
				today.getFullYear(),
				today.getMonth(),
				today.getDate(),
				23,
				59,
				59
			);

			const isToday =
				appointmentDate >= startOfDay && appointmentDate <= endOfDay;

			if (isToday) {
				setConfirmedTodayAppointmentsData((prev) => [...prev, data.data]);
			}

			const isSooner =
				!nextConfirmedAppointmentData ||
				appointmentDate < new Date(nextConfirmedAppointmentData.date_from);

			if (isSooner) {
				setNextConfirmedAppointmentData(data.data);
			}
		},
	});
	const { mutate: rejectAppointment } = useMutation(rejectAppointmentAPI, {
		onSuccess: (data) => {
			toast.success("Appointment reject Successfully");
			setRequestedAppointmentsData((prev) =>
				prev.filter((appt) => appt.id !== data.data.id)
			);
		},
	});

	const handleApprove = (id, action) => {
		if (action === 0) {
			rejectAppointment({ id: id });
		} else {
			confirmAppointment({ id: id });
		}
	};

	return (
		<div className={styles.appointments_requests}>
			<div className={styles.head}>
				<h3>{Tr.tr("doctor:Dashboard:Appointments_Requests")}</h3>
				<p>
					{appointments.length} {Tr.tr("basic:Appointments")}
				</p>
			</div>

			<div className={styles.appointments}>
				{Object.keys(groupedAppointments).map((date) => (
					<div key={date} className={styles.day_container}>
						<div className={styles.date}>{date}</div>
						{groupedAppointments[date].map((appointment) => (
							<div className={styles.appointment_row} key={appointment.id}>
								<div className={styles.appointments_boxes}>
									<AppointmentBox data={appointment}>
										<Button
											onClick={() => handleApprove(appointment.id, 0)}
											type="button"
											className={styles.reject}>
											<img src="/img/close-red.svg" alt="" />
										</Button>
										<Button
											onClick={() => handleApprove(appointment.id, 1)}
											type="button"
											className={styles.approve}>
											<img src="/img/check-white.svg" alt="" />
										</Button>
									</AppointmentBox>
								</div>
							</div>
						))}
					</div>
				))}
			</div>
		</div>
	);
};

export default AppointmentsRequests;


// Components
import DoctorNotifications from "../DoctorHeader/components/DoctorNotifications/DoctorNotifications";
import UserDropdown from "../DoctorHeader/components/UserDropdown/UserDropdown";
import LanguageBtn from "components/Common/UI/LanguageBtn/LanguageBtn";

// Styles
import styles from "./DoctorVideoRoomHeader.module.scss";

const DoctorVideoRoomHeader = () => {
    // Search

    return (
        <div className={styles.doctor_header}>
            <div className={styles.logo}>
                <img src="/img/logo.svg" alt="Logo" />
            </div>

            <div className={styles.right}>

                {/* Notifications */}
                <DoctorNotifications />

                {/* User Dropdown */}
                <UserDropdown />

                {/* Language Button */}
                <LanguageBtn />
            </div>
        </div>
    );
};

export default DoctorVideoRoomHeader;

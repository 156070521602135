import { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Menu } from "primereact/menu";
import { Button } from "primereact/button";

import { getMeApi } from "api/auth";
import { useQuery } from "react-query";

// Cookies
import Cookies from "js-cookie";

// Translation
import { useTranslation } from "react-i18next";
import { Tr } from "utils/i18n";

// Styles
import styles from "./UserDropdown.module.scss";

const UserDropdown = () => {
	const { i18n } = useTranslation();
	const navigate = useNavigate();
	const menu = useRef(null);

	const currLang = i18n.language;

	const [userName, setUserName] = useState(
		localStorage.getItem("user_name" || "")
	);

	const { data: userData, isSuccess, refetch } = useQuery("userData", getMeApi);
	const image =
		isSuccess && userData.data.image
			? userData.data.image + `&session_id=${Cookies.get("session_id")}`
			: "/img/doctor-pic.png";

	// User Dropdown
	const items = [
		// {
		// 	label: "Profile",
		// 	icon: "pi pi-user",
		// 	command: () => {
		// 		navigate("/patient/my-profile");
		// 	},
		// },
		{
			label: Tr.tr("basic:My_Appointments"),
			icon: "pi pi-calendar",
			command: () => {
				navigate("/doctor/appointments");
			},
		},
		{
			label: Tr.tr("basic:Settings"),
			icon: "pi pi-cog",
			command: () => {
				navigate("/doctor/settings");
			},
		},
		{
			label: Tr.tr("basic:Logout"),
			icon: "pi pi-sign-in",
			command: () => {
				Cookies.remove("session_id");
				localStorage.clear();
				navigate("/doctor/login");
			},
		},
	];

	return (
		<div className={styles.user_dropdown}>
			<Menu
				model={items}
				popup
				ref={menu}
				id="popup_menu"
				className={styles.user_menu}
				popupAlignment={currLang === "ar" ? "left" : "right"}
			/>
			<Button
				className={styles.menu_btn}
				onClick={(event) => menu.current.toggle(event)}
				aria-controls="popup_menu"
				aria-haspopup>
				<div className={styles.profile_icon}>
					<img src={image} alt="" />
				</div>
				<div className={styles.name_arrow}>
					<span className={styles.user_name}>
						Dr. {localStorage.getItem("user_name")}
					</span>
					<i className="pi pi-angle-down"></i>
				</div>
			</Button>
		</div>
	);
};

export default UserDropdown;

import { useState } from "react";
import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
import { useFormik } from "formik";

// Components
import {
    CalendarInput,
    CustomSelectionButton,
    MultiSelectDropdown,
    SelectDropdown,
    SwitchInput,
    TextAreaInput,
    TextInput,
} from "components/Common/Form/Inputs";
import GradientButton from "components/Common/UI/GradientButton/GradientButton";

// Utils
import { getFormErrorMessage } from "utils/FormField";

// Translation
import { useTranslation, withTranslation } from "react-i18next";
import { Tr } from "utils/i18n";

// Styles
import styles from "./AddMedicineDrawer.module.scss";
import { addMedicineAPI, editMedicineAPI } from "api/medicines";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-hot-toast";
import { convertFrom12To24 } from "utils/convertToFullTimeStamp";

const AddMedicineDrawer = ({ ...props }) => {
    const { i18n } = useTranslation();
    const currLang = i18n.language;
    const queryClient = useQueryClient();

    const { mutate: addMedicine } = useMutation(addMedicineAPI, {
        onSuccess: (data) => {
            toast.success("Medicine has been added Successfully");
            queryClient.invalidateQueries('medications');
            // props.updateView();
            props.hideHandler();
        },
        onError: (error) => {
            toast.error(error?.response?.data?.message);
        },
    });

    const { mutate: editMedicine } = useMutation(editMedicineAPI, {
        onSuccess: (data) => {
            toast.success("Medicine has been Updated Successfully");
            // props.updateView();
            queryClient.invalidateQueries('medications');
            props.hideHandler();
        },
        onError: (error) => {
            toast.error(error?.response?.data?.message);
        },
    })

    // Type
    const justifyOptions = [
        {
            icon: "/img/tablet-gray.svg",
            label: Tr.tr("patient:PatientProfile:Tablet"),
            value: "tablet",
        },
        {
            icon: "/img/medication-gray.svg",
            label: Tr.tr("patient:PatientProfile:Capsule"),
            value: "capsule",
        },
        {
            icon: "/img/drops-gray.svg",
            label: Tr.tr("patient:PatientProfile:Drops"),
            value: "drops",
        },
        {
            icon: "/img/injection-gray.svg",
            label: Tr.tr("patient:PatientProfile:Injection"),
            value: "injection",
        },
    ];

    const justifyTemplate = (option) => {
        return (
            <>
                <img src={option.icon} alt="" />
                <span>{option.label}</span>
            </>
        );
    };
    // const [dosages,setDosages] = useState([])
    // Dosage
    const dosages = [
        { label: "12:00 PM", value: "12:00 PM" },
        { label: "1:00 PM", value: "1:00 PM" },
        { label: "2:00 PM", value: "2:00 PM" },
        { label: "3:00 PM", value: "3:00 PM" },
        { label: "4:00 PM", value: "4:00 PM" },
        { label: "5:00 PM", value: "5:00 PM" },
        { label: "6:00 PM", value: "6:00 PM" },
        { label: "7:00 PM", value: "7:00 PM" },
        { label: "8:00 PM", value: "8:00 PM" },
        { label: "9:00 PM", value: "9:00 PM" },
        { label: "10:00 PM", value: "10:00 PM" },
        { label: "11:00 PM", value: "11:00 PM" },
        { label: "12:00 AM", value: "12:00 AM" },
        { label: "1:00 AM", value: "1:00 AM" },
        { label: "2:00 AM", value: "2:00 AM" },
        { label: "3:00 AM", value: "3:00 AM" },
        { label: "4:00 AM", value: "4:00 AM" },
        { label: "5:00 AM", value: "5:00 AM" },
        { label: "6:00 AM", value: "6:00 AM" },
        { label: "7:00 AM", value: "7:00 AM" },
        { label: "8:00 AM", value: "8:00 AM" },
        { label: "9:00 AM", value: "9:00 AM" },
        { label: "10:00 AM", value: "10:00 AM" },
        { label: "11:00 AM", value: "11:00 AM" },

    ];
    // const [days,setDays] = useState([])
    // Days
    // const [durations,setDurations] = useState([])
    const days = [
        { label: "Every Day", value: "everyday" },
        { label: "Saturday", value: "saturday" },
        { label: "Sunday", value: "sunday" },
        { label: "Monday", value: "monday" },
        { label: "Tuesday", value: "tuesday" },
        { label: "Wednesday", value: "wednesday" },
        { label: "Thursday", value: "thursday" },
        { label: "Friday", value: "friday" },
    ];

    // Duration
    const durations = [
        { label: "1 Month", value: 1 },
        { label: "2 Month", value: 2 },
        { label: "3 Month", value: 3 },
    ];

    // Alarm
    const [alarm, setAlarm] = useState(false);

    // Formik
    const formik = useFormik({
        initialValues: {
            title: props.flag ? props?.editableMedic?.name : "",
            type: justifyOptions[0].value,
            dosages: null,
            start: "",
            days: null,
            duration: null,
            description: "",
        },
        validate: (data) => {
            let errors = {};

            if (!data.title) {
                errors.title = "Title is required.";
            }

            if (!data.description) {
                errors.description = "Description is required.";
            }

            return errors;
        },
        onSubmit: async (data, { setSubmitting }) => {
            if (props.flag) {
                await editMedicine({
                    id: props.editableMedic.id, data: {
                        name: data.title,
                        type: data.type,
                        duration: data.duration,
                        dosages: data.dosages.map(dosage => convertFrom12To24(dosage)),
                        start_date: data.start,
                        days: data.days,
                        alarm: data.alarm,
                        description: data.description
                    }
                });
            } else {
                await addMedicine({
                    name: data.title,
                    type: data.type,
                    duration: data.duration,
                    dosages: data.dosages.map(dosage => convertFrom12To24(dosage)),
                    start_date: data.start,
                    days: data.days,
                    alarm: data.alarm,
                    description: data.description

                });
            }
        },
    });

    return (
        <Sidebar
            visible={props.visible}
            position={currLang === "ar" ? "left" : "right"}
            onHide={props.hideHandler}
            className={styles.add_drawer_holder}
            maskClassName={styles.add_drawer}
            blockScroll={true}>
            <div className={styles.drawer_header}>
                {props.flag == 'edit' ? <h2>Edit medicine</h2> : <h2>{Tr.tr("patient:PatientProfile:Add_Medicine")}</h2>}
                <Button
                    type="button"
                    icon="pi pi-times"
                    className={styles.close_btn}
                    onClick={props.hideHandler}></Button>
            </div>
            <form className={styles.drawer_form} onSubmit={formik.handleSubmit}>
                <div className={styles.drawer_content}>
                    <div className="row">
                        {/* Title */}
                        <div className="col-md-12 m-b-20">
                            <TextInput
                                inputLabel={Tr.tr("patient:PatientProfile:Title")}
                                inputName="title"
                                placeHolder={Tr.tr("patient:PatientProfile:Add_Title")}
                                inputVal={formik.values.title}
                                changeHandler={formik.handleChange}
                                inputError={getFormErrorMessage("title", formik)}
                            />
                        </div>

                        {/* Dosage (per day) */}
                        <div className="col-md-12 m-b-20">
                            <CustomSelectionButton
                                inputLabel="Type"
                                inputName="type"
                                justifyOptions={justifyOptions}
                                inputVal={formik.values.type}
                                changeHandler={(e) => formik.setFieldValue("type", e.value)}
                                justifyTemplate={justifyTemplate}
                            />
                        </div>

                        {/* Dosage (per day) */}
                        <div className="col-md-12 m-b-20">
                            {/* <SelectDropdown
								inputLabel={Tr.tr("patient:PatientProfile:Dosage_Per_Day")}
								inputName="dosage"
								placeHolder="8:00 AM"
								options={dosages}
								inputVal={formik.values.dosage}
								changeHandler={(e) => formik.setFieldValue("dosage", e.value)}
								appendTo="self"
							/> */}

                            <div className="col-md-12 m-b-20">
                                <MultiSelectDropdown
                                    inputLabel={Tr.tr("patient:PatientProfile:Dosage_Per_Day")}
                                    inputName="dosages"
                                    placeHolder="8:00 AM"
                                    options={dosages}
                                    inputVal={formik.values.dosages}
                                    changeHandler={(e) => formik.setFieldValue("dosages", e.value)}
                                />
                            </div>

                            {/* Add Time */}
                            {/* <GradientButton
								btn
								type="button"
								label={Tr.tr("patient:PatientProfile:Add_Time")}
								withIconImg
								icon="/img/add.svg"
								classes={`p610 ${styles.mt16}`}
							/> */}
                        </div>

                        {/* Start */}
                        <div className="col-md-12 m-b-20">
                            <CalendarInput
                                inputLabel={Tr.tr("patient:PatientProfile:Start")}
                                inputName="start"
                                placeHolder="Today"
                                inputVal={formik.values.start}
                                changeHandler={(e) => formik.setFieldValue("start", e.value)}
                                appendTo={document.body}
                                showIcon={true}
                                view="date"
                                dateFormat="mm/dd/yy"
                            />
                        </div>

                        {/* Days */}
                        <div className="col-md-12 m-b-20">
                            <MultiSelectDropdown
                                inputLabel={Tr.tr("patient:PatientProfile:Days")}
                                inputName="days"
                                placeHolder="Every Day"
                                options={days}
                                inputVal={formik.values.days}
                                changeHandler={(e) => formik.setFieldValue("days", e.value)}
                            />
                        </div>

                        {/* Duration */}
                        <div className="col-md-12 m-b-20">
                            <SelectDropdown
                                inputLabel={Tr.tr("patient:PatientProfile:Duration")}
                                inputName="duration"
                                placeHolder="1 Month"
                                options={durations}
                                inputVal={formik.values.duration}
                                changeHandler={(e) => formik.setFieldValue("duration", e.value)}
                                appendTo="self"
                            />
                        </div>

                        {/* Alarm */}
                        <div className="col-md-12 m-b-20">
                            <div className={styles.alarm}>
                                <h3>{Tr.tr("patient:PatientProfile:Alarm")}</h3>
                                <SwitchInput
                                    checked={alarm}
                                    changeHandler={(e) => setAlarm(e.value)}
                                />
                            </div>
                        </div>

                        {/* Description */}
                        <div className="col-md-12 m-b-20">
                            <TextAreaInput
                                inputName="description"
                                placeHolder={Tr.tr("basic:Description")}
                                inputVal={formik.values.description}
                                changeHandler={formik.handleChange}
                                inputError={getFormErrorMessage("description", formik)}
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.drawer_footer}>
                    <Button
                        type="button"
                        label={Tr.tr("basic:Discard")}
                        className={styles.discard_btn}
                    />
                    <GradientButton
                        btn
                        type="submit"
                        label={Tr.tr("patient:PatientProfile:Add_Schedule")}
                        classes={styles.done_btn}
                    />
                </div>
            </form>
        </Sidebar>
    );
};

export default withTranslation()(AddMedicineDrawer);

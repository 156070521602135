import { Outlet } from "react-router-dom";

// Components
import ProfileSideBar from "./components/ProfileSideBar/ProfileSideBar";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./MyProfile.module.scss";

const MyProfile = () => {
	return (
		<div className={styles.my_profile}>
			<div className="container">
				<h1>{Tr.tr("patient:PatientProfile:My_Profile")}</h1>

				<div className="row">
					<div className="col-md-12 col-lg-4 col-xl-3 mb-4 mb-lg-0">
						<ProfileSideBar />
					</div>
					<div className="col-md-12 col-lg-8 col-xl-9">
						<div className={styles.outlet_wrapper}>
							<Outlet />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MyProfile;

export function splitFromLastSlash(inputString) {
	const lastSlashIndex = inputString.lastIndexOf("/");

	if (lastSlashIndex !== -1) {
		const parts = inputString.split("/");
		const lastPart = parts.slice(-1)[0];

		return lastPart;
	} else {
		// Return the input as is if there is no '/'
		return [inputString];
	}
}

import { useMutation, useQuery, useQueryClient } from "react-query";

import { deleteDocumenLabResultstApi, getLabResults, uploadDocumentLabResultsApi } from 'api/labResults';

const useUploadLabResultsDocument = () => {
    const queryClient = useQueryClient();

    return useMutation(uploadDocumentLabResultsApi, {
        onSuccess: (data) => {
            const documentDetails = data.data;
            queryClient.setQueryData("lab_results", (oldDocuments) => {
                const documentsArray = Array.isArray(oldDocuments) ? oldDocuments : [];
                return [...documentsArray, documentDetails];
            });
        },
        onError: (error) => {
            console.log(error);
        },
    });
};

export const useFetchLabResultsDocuments = (options) => {
    const queryClient = useQueryClient();

    return useQuery(
        ["lab_results"],
        async () => {
            const response = await getLabResults({});
            // console.log(response)
            return response.data;
        },
        {
            ...options,
            onSuccess: (data) => {
                queryClient.setQueryData(["lab_results"], data);
            },
        }
    );
};

const useDeleteLabResultsDocument = () => {
    const queryClient = useQueryClient();

    return useMutation((documentId) => deleteDocumenLabResultstApi(documentId), {
        onSuccess: () => {
            queryClient.invalidateQueries("lab_results");
        },
        onError: (error) => {
            console.error("Error deleting the document:", error);
        },
    });
};

const LabResultsUploadDocumentService = {
    useUploadLabResultsDocument,
    useFetchLabResultsDocuments,
    useDeleteLabResultsDocument,
};

export default LabResultsUploadDocumentService;

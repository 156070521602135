import { useContext, useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Menu } from "primereact/menu";
import { Button } from "primereact/button";

// Components
import TitleWithLine from "components/Common/UI/TitleWithLine/TitleWithLine";
import { SearchInput, SelectDropdown } from "components/Common/Form/Inputs";
import GradientButton from "components/Common/UI/GradientButton/GradientButton";
import AddDrugDrawer from "./components/AddDrugDrawer/AddDrugDrawer";

// Context
import { SidebarContext } from "context/DoctorSidebarContext";

// Translation
import { useTranslation, withTranslation } from "react-i18next";
import { Tr } from "utils/i18n";

// Styles
import styles from "./SettingsDrugCatalog.module.scss";
import { deleteDrugsCatalogsAPI, getAllDrugsCatalogsAPI } from "api/drugs";
import { useMutation, useQuery, useQueryClient } from "react-query";
import toast from "react-hot-toast";

const SettingsDrugCatalog = () => {
    const { i18n } = useTranslation();
    const currLang = i18n.language;

    const { setSmallSize } = useContext(SidebarContext);

    // Search
    // const [searchQuery, setSearchQuery] = useState("");

    // Type
    // const [type, setType] = useState(null);

    // Show Add Drug Drawer
    const [visible, setVisible] = useState(false);
    const [flag, setFlag] = useState('');

    // Drugs Table
    const [drugs, setDrugs] = useState([]);
    const [selectedDrug, setSelectedDrug] = useState(null);

    const [filtersObj, setFiltersObj] = useState({
        page: 1,
        search: '',
        order: '',
        filters: { type: '' }
    });


    const { data, isLoading, isError } = useQuery(["drug-catalogs", filtersObj], getAllDrugsCatalogsAPI,
        {
            select: (response) => {
                return response.data;
            },
        });

    useEffect(() => {
        setDrugs(data?.data)
    }, [data])

    const types = [
        { label: "Tablet", value: "tablet" },
        { label: "Capsule", value: "capsule" },
    ];

    useEffect(() => {
        setSmallSize(true);
    }, [setSmallSize]);

    // [Table] Type Cell Template
    const typeBodyTemplate = (rowData) => {
        return (
            <div className={styles.type_cell}>
                {rowData.type.toLowerCase() === "tablet" ? (
                    <img src="/img/tablet.svg" alt="" />
                ) : (
                    <img src="/img/medication.svg" alt="" />
                )}
                <span>{rowData.type}</span>
            </div>
        );
    };

    // Action Items
    const menu = useRef(null);

    const queryClient = useQueryClient();

    const { mutate: deleteDrug } = useMutation(deleteDrugsCatalogsAPI, {
        onSuccess: (data) => {
            toast.success("Drug has been Deleted Successfully");
            queryClient.invalidateQueries('drug-catalogs')
        },
        onError: (error) => {
            toast.error(error?.response?.data?.message);
        },
    })

    const handleEdit = () => {
        setVisible(true);
        setFlag('edit');
    }


    const items = [
        {
            template: (item, options) => {
                return (
                    <Button
                        onClick={(e) => { options.onClick(e); handleEdit() }}
                        className={`${options.className}`}>
                        <img src="/img/edit-gray.svg" alt="" />
                        <span>{Tr.tr("doctor:Settings:Edit")}</span>
                    </Button>
                );
            },
        },
        {
            template: (item, options) => {
                return (
                    <Button
                        onClick={(e) => {
                            options.onClick(e);
                            deleteDrug(selectedDrug?.id);
                        }}
                        className={`${options.className}`}>
                        <img src="/img/delete-gray.svg" alt="" />
                        <span>{Tr.tr("doctor:Settings:Delete")}</span>
                    </Button>
                );
            },
        },
    ];

    // [Table] Action Cell Template
    const actionBodyTemplate = (rowData) => {
        return (
            <div className={styles.table_action}>
                <Menu
                    model={items}
                    popup
                    ref={menu}
                    id="popup_menu"
                    popupAlignment={currLang === "ar" ? "left" : "right"}
                    className={styles.action_menu}
                />
                <Button
                    icon="pi pi-ellipsis-v"
                    onClick={(event) => { menu.current.toggle(event); setSelectedDrug(rowData) }}
                    aria-controls="popup_menu"
                    aria-haspopup
                    className={styles.action_btn}
                />
            </div>
        );
    };

    return (
        <>
            <div className={styles.settings_drugCatalog}>
                <TitleWithLine
                    title={Tr.tr("doctor:Settings:Drug_Catalog")}
                    mb="32px"
                />

                {/* Table Head */}
                <div className={styles.table_head}>
                    <div className="row d-flex align-items-center">
                        <div className="col-lg-5 mb-3 mb-lg-0">
                            <div className="row">
                                {/* Search */}
                                <div className="col-md-7">
                                    <SearchInput
                                        inputLabel=""
                                        inputName="search"
                                        placeHolder={Tr.tr("doctor:Settings:Search_Drugs")}
                                        inputVal={filtersObj.search}
                                        // changeHandler={(e) => setSearchQuery(e.target.value)}
                                        changeHandler={(e) => setFiltersObj({ ...filtersObj, search: e.target.value })}
                                    />
                                </div>

                                {/* Select Type */}
                                <div className="col-md-5">
                                    <SelectDropdown
                                        inputLabel=""
                                        inputName="type"
                                        placeHolder={Tr.tr("doctor:Settings:Type")}
                                        options={types}
                                        inputVal={filtersObj.filters.type}
                                        // changeHandler={(e) => setType(e.value)}
                                        changeHandler={(e) => setFiltersObj({ ...filtersObj, filters: { type: e.value } })}
                                        appendTo="self"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <GradientButton
                                btn
                                type="button"
                                label={Tr.tr("doctor:Settings:Add_Drug")}
                                withIconImg
                                icon="/img/add.svg"
                                classes="p712 mis-auto"
                                buttonClicked={() => setVisible(true)}
                            />
                        </div>
                    </div>
                </div>

                {/* Drug Table */}
                <div className={`table_holder`}>
                    <DataTable
                        className={styles.table_holder}
                        value={drugs}
                        tableStyle={{ minWidth: "100%" }}
                        paginator
                        rows={10}
                        totalRecords={drugs?.length}
                        paginatorClassName="p_pagination"
                        paginatorTemplate="RowsPerPageDropdown PrevPageLink PageLinks NextPageLink CurrentPageReport"
                        rowsPerPageOptions={[10, 20, 30]}
                        paginatorLeft={`${Tr.tr("doctor:Settings:Results_Per_Page")}:`}
                        currentPageReportTemplate="Showing {last} of {totalRecords} results">
                        <Column
                            field="name"
                            header={Tr.tr("doctor:Settings:Name")}
                            style={{ minWidth: "200px" }}></Column>
                        <Column
                            field="type"
                            header={Tr.tr("doctor:Settings:Type")}
                            body={typeBodyTemplate}
                            style={{ minWidth: "200px" }}></Column>
                        <Column
                            field="strength"
                            header={Tr.tr("doctor:Settings:Strength")}
                            style={{ minWidth: "200px" }}></Column>
                        <Column
                            field="instructions"
                            header={Tr.tr("doctor:Settings:Instructions")}
                            style={{ minWidth: "200px" }}></Column>
                        <Column
                            body={actionBodyTemplate}
                            style={{ minWidth: "50px" }}

                        ></Column>
                    </DataTable>
                </div>
            </div>

            {/* Add Drug Drawer */}
            {visible && <AddDrugDrawer
                visible={visible}
                hideHandler={() => {
                    setVisible(false);
                    setFlag('');
                }}
                flag={flag}
                editableDrug={selectedDrug}
            />}
        </>
    );
};

export default withTranslation()(SettingsDrugCatalog);

import axios from "../lib/axios";

export const addPayment = async (data) => {
    return await axios.post(`/users/me/payment-methods/add`, data);
};

export const verifyPaymentMethod = async (data) => {
    return await axios.post(`/users/me/payment-methods/${data?.payment_id}/verify`, {})
}


export const getPaymentMethod = async (data) => {
    return await axios.post(`/users/me/payment-methods`, {})
}

export const deletePaymentMethod = async (data) => {
    return await axios.post(`/users/me/payment-methods/${data?.id}/delete`, {})
}
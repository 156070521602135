import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { Button } from "primereact/button";

// Components
import LanguageBtn from "components/Common/UI/LanguageBtn/LanguageBtn";
import Notifications from "./components/Notifications/Notifications";
import UserDropdown from "./components/UserDropdown/UserDropdown";
import ResponsiveHeader from "./components/ResponsiveHeader/ResponsiveHeader";
import ConsultationCallModal from "components/Patient/ConsultationCallModal/ConsultationCallModal";

// Cookies
import Cookies from "js-cookie";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./Header.module.scss";

const Header = () => {
	// Show Side Bar
	const [visible, setVisible] = useState(false);
	const [token] = useState(Cookies.get("session_id"));
	const [user_name] = useState(localStorage.getItem("user_name"));

	// Consultation Call
	const [consultationCallModalVisible, setConsultationCallModalVisible] =
		useState(false);

	// Token
	// const token = false;

	const consultationCallHandler = (e) => {
		e.preventDefault();
		setConsultationCallModalVisible(true);
	};

	return (
		<>
			<header className={styles.app_header}>
				<div className="container">
					<div className={styles.header_content}>
						<div className={styles.header_left}>
							<Link to="/" className={styles.app_logo}>
								<img src="/img/logo.svg" alt="" />
							</Link>

							<ul className={`${styles.menu_links} d-none d-lg-flex`}>
								<li className={styles.menu_link}>
									<NavLink to="/patient/find-a-doctor">
										{Tr.tr("patient:Header:Find_A_Doctor")}
									</NavLink>
								</li>
								<li className={styles.menu_link}>
									<NavLink to="/patient/find-a-doctor?can_make_video_consult=true">
										{Tr.tr("patient:Header:Video_Consult")}
									</NavLink>
								</li>
								<li className={styles.menu_link}>
									<NavLink to="" onClick={consultationCallHandler}>
										{Tr.tr("patient:Header:Consultation_Call")}
									</NavLink>
								</li>
								<li className={styles.menu_link}>
									<NavLink to="/patient/customer-support">
										{Tr.tr("patient:Header:Customer_Support")}
									</NavLink>
								</li>
							</ul>

							<div className={`${styles.header_right} d-flex d-lg-none`}>
								{!token ? (
									<Link to="/patient/login" className={styles.link_box}>
										<img src="/img/profile.svg" alt="" />
										<span>{Tr.tr("patient:Header:Hello_Login")}</span>
									</Link>
								) : (
									<div className={styles.after_login}>
										{/* Notifications */}
										<Notifications />

										{/* User Dropdown */}
										<UserDropdown user_name={user_name} />
									</div>
								)}
							</div>
						</div>

						{/* Hello User */}
						<div className={`${styles.header_right} d-none d-lg-flex`}>
							{!token ? (
								<Link to="/patient/login" className={styles.link_box}>
									<img src="/img/profile.svg" alt="" />
									<span>{Tr.tr("patient:Header:Hello_Login")}</span>
								</Link>
							) : (
								<div className={styles.after_login}>
									{/* Notifications */}
									<Notifications />

									{/* User Dropdown */}
									<UserDropdown user_name={user_name} />
								</div>
							)}
							{/* Language Btn */}
							<LanguageBtn classes="d-none d-lg-flex" />
						</div>

						{/* Hamburger Button */}
						<Button
							icon="pi pi-bars"
							className={`${styles.hamburger_btn} d-flex d-lg-none`}
							onClick={() => setVisible(true)}
						/>
					</div>
				</div>
			</header>

			<ResponsiveHeader
				visible={visible}
				hideHandler={() => setVisible(false)}
			/>

			{/* Consultation Call Modal */}
			<ConsultationCallModal
				visible={consultationCallModalVisible}
				hideHandler={() => setConsultationCallModalVisible(false)}
			/>
		</>
	);
};

export default Header;

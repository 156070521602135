// Components
import GradientButton from "components/Common/UI/GradientButton/GradientButton";

// Translation
import { Tr } from "utils/i18n";
import { Button } from "primereact/button";

// Styles
import styles from "./Vitals.module.scss";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { getMeApi, updateMeApi } from "api/auth";
import {toast} from "react-hot-toast";
import DialogWrapper from "components/Patient/DialogWrapper/DialogWrapper";
import { useFormik } from "formik";
import {  TextInput } from "components/Common/Form/Inputs";
import { getFormErrorMessage } from "utils/FormField";


const Vitals = () => {
	const [visible, setVisible] = useState(false);
	const[vitals, setVitals] = useState(null)
	const { mutate: getMe } = useMutation(getMeApi, {
		onSuccess: (data) => {
			setVitals(data.data)
			formik.setFieldValue("systolic",  Number(data.data.blood_pressure?.split('/')[0]));
			formik.setFieldValue("diastolic", Number(data.data.blood_pressure?.split('/')[1]));
			formik.setFieldValue("pulse", data.data.pulse);

		},
		// onError: (error) => {
		// 	toast.error(error.response.data.message);
		// },
	});

	const { mutate: updateProfile } = useMutation(updateMeApi, {
		onSuccess: (data) => {
			getMe()
			toast.success("Vitals has been updated.");
		},
		// onError: (error) => {
		// 	toast.error(error.response.data.message);
		// },
	});
	useEffect(() => {
		getMe()
	},[])

	// Formik
	const formik = useFormik({
		initialValues: {
			systolic: 0,
			diastolic: 0,
			pulse: 0
		},

		onSubmit: async (data, { setSubmitting }) => {
			await updateProfile({
			blood_pressure: `${data.systolic}/${data.diastolic}`,
			pulse: data.pulse,
			})
			console.log("submitted");
			// setTimeout(() => {
			// 	setSubmitting(false);
			// 	formik.resetForm();
			// }, 2000);
		},
	});
	return (
		<section className={styles.vitals}>
			{/* Section Head */}
			<div className="title_action">
				<h2>{Tr.tr("patient:PatientProfile:Vitals")}</h2>
				<GradientButton
					btn
					type="button"
					label={Tr.tr("basic:Edit")}
					buttonClicked={() => setVisible(true)}
					withIconImg
					icon="/img/edit-white.svg"
					classes="p610"
				/>
			</div>

			{/* Vitals Cards */}
			<div className="row">
				<div className="col-md-6 mb-4 mb-md-0">
					<div className={styles.vitals_card}>
						<img src="/img/love-gradient.svg" alt="" />
						<h6>{Tr.tr("patient:PatientProfile:Blood_Pressure")}</h6>
						<h5>{vitals?.blood_pressure}</h5>
					</div>
				</div>
				<div className="col-md-6">
					<div className={styles.vitals_card}>
						<img src="/img/pulse-gradient.svg" alt="" />
						<h6>{Tr.tr("patient:PatientProfile:Pulse")}</h6>
						{vitals?.pulse && <h5>{vitals.pulse} BPM</h5>}
					</div>
				</div>
			</div>
				{/* Edit Modal */}
			<DialogWrapper
				header={Tr.tr("patient:PatientProfile:Information")}
				visible={visible}
				width="42vw"
				hideHandler={() => setVisible(false)}>
				<form onSubmit={formik.handleSubmit}>
					<div className="modal_body">
						<div className="row">
							{/* Age */}
							<div className="col-md-12 m-b-20">
								<TextInput
									inputLabel="Systolic blood pressure"
									inputName="systolic"
									placeHolder="0"
									inputVal={formik.values.systolic}
									changeHandler={formik.handleChange}
									inputError={getFormErrorMessage("systolic", formik)}
								/>
							</div>
						</div>

							{/* Age */}
							<div className="col-md-12 m-b-20">
								<TextInput
									inputLabel="Diastolic blood pressure"
									inputName="diastolic"
									placeHolder="0"
									inputVal={formik.values.diastolic}
									changeHandler={formik.handleChange}
									inputError={getFormErrorMessage("diastolic", formik)}
								/>
							</div>
						<div className="col-md-12 m-b-20">
								<TextInput
									inputLabel={Tr.tr("patient:PatientProfile:Pulse")}
									inputName="pulse"
									placeHolder="0"
									inputVal={formik.values.pulse}
									changeHandler={formik.handleChange}
									inputError={getFormErrorMessage("pulse", formik)}
								/>
							</div>
					</div>
					<div className="modal_footer">
						<Button
							type="button"
							label={Tr.tr("basic:Discard")}
							className="discard_btn"
							onClick={() => setVisible(false)}
						/>
						<GradientButton
							btn
							type="submit"
							label={Tr.tr("basic:Save")}
							classes="done_btn"
						/>
					</div>
				</form>
			</DialogWrapper>
		</section>
		
	);
};

export default Vitals;

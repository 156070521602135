import { useState } from "react";

// Components
import GradientButton from "components/Common/UI/GradientButton/GradientButton";
import DoctorGridCard from "components/Patient/DoctorGridCard/DoctorGridCard";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./SpecialityDoctors.module.scss";

const SpecialityDoctors = () => {
	const [doctors, setDoctors] = useState([
		{
			id: 1,
			image: "/img/doctor4.png",
			name: "Dr. Maged El Wakeel",
			speciality: "Doctors in pediatrics",
			rate: 4,
			reviews: 500,
			location: "Riyadh, Saudi Arabia",
			waitingTime: "15 Minutes",
			fees: "00 SAR",
			isFavourite: false,
		},
		{
			id: 2,
			image: "/img/doctor4.png",
			name: "Dr. Maged El Wakeel",
			speciality: "Doctors in pediatrics",
			rate: 4,
			reviews: 500,
			location: "Riyadh, Saudi Arabia",
			waitingTime: "15 Minutes",
			fees: "00 SAR",
			isFavourite: false,
		},
		{
			id: 3,
			image: "/img/doctor4.png",
			name: "Dr. Maged El Wakeel",
			speciality: "Doctors in pediatrics",
			rate: 4,
			reviews: 500,
			location: "Riyadh, Saudi Arabia",
			waitingTime: "15 Minutes",
			fees: "00 SAR",
			isFavourite: false,
		},
		{
			id: 4,
			image: "/img/doctor4.png",
			name: "Dr. Maged El Wakeel",
			speciality: "Doctors in pediatrics",
			rate: 4,
			reviews: 500,
			location: "Riyadh, Saudi Arabia",
			waitingTime: "15 Minutes",
			fees: "00 SAR",
			isFavourite: false,
		},
	]);

	// Is Favourite Handler
	const isFavHandler = (doctor) => {
		// Create a copy of the original array
		const updatedData = [...doctors];

		// Find the index of the item with the specified ID
		const index = updatedData.findIndex((data) => data.id === doctor.id);

		// If the item with the specified ID is found, update its value
		if (index !== -1) {
			updatedData[index].isFav = !doctor.isFav;

			// Update the state with the modified array
			setDoctors(updatedData);
		}
	};

	return (
		<section className={styles.speciality_doctors}>
			<div className="container">
				<div className="sec_head">
					<div className="txt">
						<h2>{Tr.tr("patient:DoctorDetails:Doctors_In_Pediatrics")}</h2>
						<p>Lorem ipsum dolor sit amet consectetur. Neque blandit</p>
					</div>
					<GradientButton label={Tr.tr("basic:View_All")} redirectTo="" />
				</div>

				<div className="row">
					{doctors.map((doctor) => (
						<div
							className="col-md-6 col-lg-4 col-xl-3 mb-4 mb-xl-0"
							key={doctor.id}>
							<DoctorGridCard
								data={doctor}
								isFavHandler={() => isFavHandler(doctor)}
							/>
						</div>
					))}
				</div>
			</div>
		</section>
	);
};

export default SpecialityDoctors;

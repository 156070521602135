import { useFormik } from "formik";

// Components
import DialogWrapper from "../DialogWrapper/DialogWrapper";
import WhiteBlackButton from "components/Common/UI/WhiteBlackButton/WhiteBlackButton";
import GradientButton from "components/Common/UI/GradientButton/GradientButton";
import Rate from "components/Common/UI/Rate/Rate";
import { TextAreaInput } from "components/Common/Form/Inputs";

// Utils
import { getFormErrorMessage } from "utils/FormField";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./FeedBackModal.module.scss";

const FeedBackModal = ({ ...props }) => {
	// Formik
	const formik = useFormik({
		initialValues: {
			rate: 0,
			feedbackMsg: "",
		},
		validate: (data) => {
			let errors = {};

			if (!data.feedbackMsg) {
				errors.feedbackMsg = "Feedback is required.";
			}

			return errors;
		},
		onSubmit: (data, { setSubmitting }) => {
			console.log("submitted");
			setTimeout(() => {
				setSubmitting(false);
				formik.resetForm();
			}, 2000);
		},
	});

	return (
		<DialogWrapper
			header="Your Feedback"
			visible={props.visible}
			width="42vw"
			hideHandler={props.hideHandler}>
			<form onSubmit={formik.handleSubmit}>
				<div className="modal_body">
					<div className={styles.doctor_info}>
						<div className={styles.doctor_img}>
							<img src="/img/doctor1.png" alt="" />
						</div>
						<div className={styles.txt}>
							<h4 className={styles.doctor_name}>Doctor Maged El Wakeel</h4>
							<p className={styles.role}>Professor of Pediatrics</p>
						</div>
					</div>

					<div className={styles.rate_msg}>
						<div className={styles.rate}>
							<Rate
								rateVal={formik.values.rate}
								handleRateChange={(e) => formik.setFieldValue("rate", e.value)}
								filledIcon={false}
								smallStar={false}
							/>
						</div>
						<TextAreaInput
							inputLabel=""
							inputName="feedbackMsg"
							placeHolder={Tr.tr("patient:PatientProfile:Add_Your_Feedback")}
							inputVal={formik.values.feedbackMsg}
							changeHandler={formik.handleChange}
							inputError={getFormErrorMessage("feedbackMsg", formik)}
						/>
					</div>
				</div>
				<div className="modal_footer">
					<WhiteBlackButton
						btn
						type="button"
						label={Tr.tr("basic:Not_Now")}
						className="discard_btn"
						buttonClicked={props.hideHandler}
					/>
					<GradientButton
						btn
						type="submit"
						label={Tr.tr("basic:Submit")}
						classes="done_btn"
					/>
				</div>
			</form>
		</DialogWrapper>
	);
};

export default FeedBackModal;

import axios from "axios";
import Cookies from 'js-cookie';

// This is the base API URL
// This is the token that is used to authenticate the user
// This is the header that is used to authenticate the user

// axios.defaults.withCredentials = true;

const axClient = axios.create({
	baseURL: "https://staging-api.tiryaaq.com/api",
	// baseURL: "http://51.77.81.132:8016/api",
	headers: {
		// "Content-Type": "application/json",
		Accept: "application/json",
	},
});
axClient.interceptors.request.use((config) => {

	const front_session_id = Cookies.get('session_id');
	config.params = config.params || {};
	if (front_session_id) {
		config.params["session_id"] = front_session_id;
	}

	return config;
});

export default axClient;

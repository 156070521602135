import { useState } from "react";

// Components
import { SelectDropdown } from "components/Common/Form/Inputs";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./MyAnswers.module.scss";

const MyAnswers = ({ MyAnswersData }) => {
	// Sort By [Options]
	const sortByOptions = [
		{
			label: "Most Recent",
			value: "Most Recent",
		},
	];

	const [sortBy, setSortBy] = useState(sortByOptions[0].value);

	return (
		<div className={styles.my_answers}>
			<div className={styles.my_answers_head}>
				<div className={styles.qty_count}>
                    {MyAnswersData?.number_of_items} {Tr.tr("doctor:QuestionAnswer:Questions")}
				</div>

				{/* Sort By */}
				<div className={styles.sort_by}>
					<div className={styles.sort_lbl}>
						{Tr.tr("doctor:QuestionAnswer:Sort_By")}:
					</div>
					<SelectDropdown
						inputLabel=""
						inputName={Tr.tr("doctor:QuestionAnswer:Sort_By")}
						placeHolder="Sort by"
						options={sortByOptions}
						inputVal={sortBy}
						changeHandler={(e) => setSortBy(e.value)}
						appendTo="self"
					/>
				</div>
			</div>

			<div className={styles.my_answers_qtys}>
                {MyAnswersData?.data.map((qty) => (
					<div className={styles.qty_box} key={qty.id}>
                        <h5>{qty.patient_question_id.doctor_specialty_id.name}</h5>
						<div className={styles.qty_date}>
                            <h3>{qty.patient_question_id.name}</h3>
							<h6>{qty.date}</h6>
						</div>
						<p>{qty.description}</p>
						<div className={styles.your_answer}>
							<h2>Your Answer</h2>
							<div className={styles.answer_box}>
								<div className={styles.answer_text}>
									{qty.name}
								</div>
								{/* <div className={styles.answer_date}>September 18, 2023</div> */}
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default MyAnswers;

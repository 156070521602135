import { useFormik } from "formik";

// Components
import GradientButton from "components/Common/UI/GradientButton/GradientButton";
import {
	CustomRadioButton,
	RadioBtn,
	TextInput,
} from "components/Common/Form/Inputs";

// Utils
import { getFormErrorMessage } from "utils/FormField";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./Patient.module.scss";

const Patient = ({ ...props }) => {
	// Choices Questions
	const questions = [
		{
			id: 1,
			qty: "I’m overweight or obese",
			answers: [
				{
					label: "Yes",
					value: "Yes",
				},
				{
					label: "No",
					value: "No",
				},
				{
					label: "Don’t know",
					value: "Don’t know",
				},
			],
		},
		{
			id: 2,
			qty: "I have hypertension",
			answers: [
				{
					label: "Yes",
					value: "Yes",
				},
				{
					label: "No",
					value: "No",
				},
				{
					label: "Don’t know",
					value: "Don’t know",
				},
			],
		},
		{
			id: 3,
			qty: "I have smoked cigarettes for at least 10 years",
			answers: [
				{
					label: "Yes",
					value: "Yes",
				},
				{
					label: "No",
					value: "No",
				},
				{
					label: "Don’t know",
					value: "Don’t know",
				},
			],
		},
		{
			id: 4,
			qty: "I’ve recently suffered an injury",
			answers: [
				{
					label: "Yes",
					value: "Yes",
				},
				{
					label: "No",
					value: "No",
				},
				{
					label: "Don’t know",
					value: "Don’t know",
				},
			],
		},
	];

	// question's answers
	const selectedAnswer = {};
	questions.forEach((question) => {
		selectedAnswer[question.id] = "";
	});

	// Formik
	const formik = useFormik({
		initialValues: {
			patient: "Myself",
			sex: "Male",
			age: 26,
			...selectedAnswer,
		},
		validate: (data) => {
			let errors = {};

			return errors;
		},
		onSubmit: (data, { setSubmitting }) => {
			console.log("submitted");
			props.onNex();
		},
	});

	// question's answers [change handler]
	const handleOptionChange = (questionId, selectedOption) => {
		formik.setFieldValue(questionId, selectedOption);
		console.log(formik.values);
	};

	return (
		<form className={styles.step_content} onSubmit={formik.handleSubmit}>
			<div className={styles.top_content}>
				{/* Who Checkup For */}
				<section className={styles.patient_row}>
					<div className={styles.qty_head}>
						<h2>Who is the checkup for?</h2>
					</div>

					<div className={styles.custom_radioBtns}>
						<CustomRadioButton
							inputName="patient"
							inputVal="Myself"
							handleChange={(e) =>
								formik.setFieldValue("patient", e.target.value)
							}
							checked={formik.values.patient === "Myself"}
							inputLabel="Myself"
							icon="/img/profile-icon.svg"
						/>
						<CustomRadioButton
							inputName="patient"
							inputVal="Someone else"
							handleChange={(e) =>
								formik.setFieldValue("patient", e.target.value)
							}
							checked={formik.values.patient === "Someone else"}
							inputLabel="Someone else"
							icon="/img/users-icon.svg"
						/>
					</div>
				</section>

				{/* What is your sex */}
				<section className={styles.patient_row}>
					<div className={styles.qty_head}>
						<h2>What is your sex?</h2>
					</div>

					<div className={styles.custom_radioBtns}>
						<CustomRadioButton
							inputName="sex"
							inputVal="Male"
							handleChange={(e) => formik.setFieldValue("sex", e.target.value)}
							checked={formik.values.sex === "Male"}
							inputLabel="Male"
							icon="/img/boy.svg"
						/>
						<CustomRadioButton
							inputName="sex"
							inputVal="Female"
							handleChange={(e) => formik.setFieldValue("sex", e.target.value)}
							checked={formik.values.sex === "Female"}
							inputLabel="Female"
							icon="/img/girl.svg"
						/>
					</div>
				</section>

				{/* How old are you */}
				<section className={styles.patient_row}>
					<div className={styles.qty_head}>
						<h2>How old are you?</h2>
					</div>

					<div className="row">
						<div className="col-md-3">
							<TextInput
								inputLabel=""
								inputName="age"
								placeHolder="26"
								inputVal={formik.values.age}
								changeHandler={formik.handleChange}
								inputError={getFormErrorMessage("age", formik)}
							/>
						</div>
					</div>
				</section>

				{/* check all statements */}
				<section className={styles.patient_row}>
					<div className={styles.qty_head}>
						<h2>Please check all the statements below that apply to you</h2>
						<p>Select one answer in each row</p>
					</div>

					<div className={styles.qty_rows}>
						{questions.map((question) => (
							<div className={styles.qty_row} key={question.id}>
								<h3>{question.qty}</h3>

								<div className={styles.radioBtns_wrapper}>
									{question.answers.map((answer, index) => (
										<div key={index}>
											<RadioBtn
												name={`question-${question.id}`}
												label={answer.label}
												value={answer}
												handleChange={() =>
													handleOptionChange(question.id, answer)
												}
												checked={
													formik.values[question.id].value === answer.value
												}
											/>
										</div>
									))}
								</div>
							</div>
						))}
					</div>
				</section>
			</div>

			{/* Actions */}
			<div className={styles.step_actions}>
				{/* Back */}
				<GradientButton
					btn
					type="button"
					label={Tr.tr("basic:Back")}
					withIconImg
					icon="/img/chevron-left-gradient.svg"
					outlined
					classes={`p712 ${styles.backBtn}`}
					buttonClicked={() => {
						props.onPrevious();
					}}
				/>

				{/* Next */}
				<GradientButton
					btn
					type="submit"
					label={Tr.tr("basic:Next")}
					withIconImg
					icon="/img/chevron-right-white.svg"
					rightPos
					classes={`p712 ${styles.nextBtn}`}
				/>
			</div>
		</form>
	);
};

export default Patient;

import { useState } from "react";
import { useFormik } from "formik";

// Components
import { CheckBox, RadioBtn } from "components/Common/Form/Inputs";
import GradientButton from "components/Common/UI/GradientButton/GradientButton";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./Interview.module.scss";

const Interview = ({ ...props }) => {
	// Current Interview Question Index
	const [interviewIndex, setInterviewIndex] = useState(0);

	// Interview Questions
	const interviewQtys = [
		{
			id: 1,
			question: "Do you have any of the following symptoms?",
			answers: [
				{
					label: "Bleeding from nose",
					value: "Bleeding from nose",
				},
				{
					label: "Stuffy nose",
					value: "Stuffy nose",
				},
				{
					label: "Face pain",
					value: "Face pain",
				},
				{
					label: "Skin changes",
					value: "Skin changes",
				},
			],
			multiple: true,
		},
		{
			id: 2,
			question: "Is your entire face swollen?",
			answers: [
				{
					label: "Yes",
					value: "Yes",
				},
				{
					label: "No",
					value: "No",
				},
				{
					label: "Don't know",
					value: "Don't know",
				},
			],
			multiple: false,
		},
	];

	// Formik
	const formik = useFormik({
		initialValues: {
			items: [],
			item: "",
		},
		validate: (data) => {
			let errors = {};

			return errors;
		},
		onSubmit: (data, { setSubmitting }) => {
			console.log("submitted");
			if (interviewIndex < interviewQtys.length - 1) {
				nextQty();
			} else {
				props.onNex();
			}
		},
	});

	const onSelectionChange = (e) => {
		let _selectedOptions = [...formik.values.items];

		if (e.checked) {
			_selectedOptions.push(e.value);
		} else {
			for (let i = 0; i < _selectedOptions.length; i++) {
				const selectedOption = _selectedOptions[i];

				if (selectedOption.value === e.value.value) {
					_selectedOptions.splice(i, 1);
					break;
				}
			}
		}

		formik.setFieldValue("items", _selectedOptions);
	};

	// Next Question
	const nextQty = () => {
		setInterviewIndex(interviewIndex + 1);
	};

	// Previous Question
	const previousQty = () => {
		setInterviewIndex(interviewIndex - 1);
	};

	return (
		<form className={styles.step_content} onSubmit={formik.handleSubmit}>
			<div className={styles.top_content}>
				<div className={styles.interview_questions}>
					<div className={styles.interview_head}>
						<h2>{interviewQtys[interviewIndex].question}</h2>
						{interviewQtys[interviewIndex].multiple && (
							<p>Select all answers that apply</p>
						)}
					</div>

					<div className={styles.interview_body}>
						{interviewQtys[interviewIndex].answers.map((answer, index) => (
							<div className={styles.qty_row} key={index}>
								{interviewQtys[interviewIndex].multiple ? (
									<CheckBox
										name={`answer-${answer.value}`}
										label={answer.label}
										value={answer}
										handleChange={(e) => onSelectionChange(e)}
										checked={formik.values.items.some(
											(item) => item.value === answer.value
										)}
									/>
								) : (
									<RadioBtn
										name="choice"
										label={answer.label}
										value={answer}
										handleChange={(e) => formik.setFieldValue("item", e.value)}
										checked={formik.values.item.value === answer.value}
									/>
								)}
							</div>
						))}
					</div>
				</div>
			</div>

			{/* Actions */}
			<div className={styles.step_actions}>
				{/* Back */}
				<GradientButton
					btn
					type="button"
					label={Tr.tr("basic:Back")}
					withIconImg
					icon="/img/chevron-left-gradient.svg"
					outlined
					classes={`p712 ${styles.backBtn}`}
					buttonClicked={() => {
						if (interviewIndex >= 1) {
							previousQty();
						} else {
							props.onPrevious();
						}
					}}
				/>

				{/* Next */}
				<GradientButton
					btn
					type="submit"
					label={Tr.tr("basic:Next")}
					withIconImg
					icon="/img/chevron-right-white.svg"
					rightPos
					classes={`p712 ${styles.nextBtn}`}
				/>
			</div>
		</form>
	);
};

export default Interview;

import axios from "lib/axios";

export const getAppointmentsAPI = async (params) => {
	const filters = {};

	if (params.status) {
		filters.status = params.status;
	}
	if (params.startDate && params.endDate) {
		filters.date_from = params.startDate;
		filters.date_to = params.endDate;
	}
	if (params.type) {
		filters.type = params.type;
	}

	return await axios.post(`partners/${params.id}/appointments`, {
		filters
	});
};

export const approveAppointmentAPI = async (params) => {

	return await axios.post(
		`/appointments/${params.id}/confirm`,
		{}
	);
};

export const rejectAppointmentAPI = async (params) => {

	return await axios.post(
		`/appointments/${params.id}/decline`,
		{}
	);
};


export const payAppointment = async (params) => {

	return await axios.post(
		`/appointments/${params?.appointment_id}/pay`,
		{
			"payment_method_id": params?.payment_method_id,
			"payment_type": params?.payment_type
		}
	);
};

import { InputText } from "primereact/inputtext";

// Components
import ErrorUI from "components/Common/UI/ErrorUI/ErrorUI";

// Styles
import styles from "./SearchInput.module.scss";

export function SearchInput({ ...props }) {
	return (
		<div className={`input_holder ${props.classes}`}>
			<div className={styles.field_holder}>
				{props.inputLabel && (
					<h6 className={props.required ? "required" : ""}>
						{props.inputLabel}
					</h6>
				)}
				<div
					className={`w-100 ${styles.input_box} ${
						props.inputError ? "p-invalid" : ""
					}`}>
					<img
						src="/img/search-icon.svg"
						alt=""
						className={styles.search_icon}
					/>
					<InputText
						name={props.inputName}
						value={props.inputVal}
						placeholder={props.placeHolder}
						onChange={props.changeHandler}
						className="w-100"
					/>
				</div>
			</div>
			{props.inputError && (
				<ErrorUI errorMsg={props.inputError} withBG={props.withBG} />
			)}
		</div>
	);
}

// Components
import GradientButton from "components/Common/UI/GradientButton/GradientButton";
import WhiteButton from "components/Common/UI/WhiteButton/WhiteButton";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./MedicationCard.module.scss";
import { useMutation, useQueryClient } from "react-query";
import { deleteMedicineAPI } from "api/medicines";
import MedicineServices from "features/reminders/medicinesService";
import toast from "react-hot-toast";
import { useState } from "react";
import AddMedicineDrawer from "pages/Patient/MyProfile/pages/Reminders/components/AddMedicineDrawer/AddMedicineDrawer";

const MedicationCard = ({ data }) => {
    // Show Add Medicine Drawer
    const [visible, setVisible] = useState(false);
    const [editableMedic, setEditableMedic] = useState({});

    const queryClient = useQueryClient();

    const { mutate: deleteMedicine } = useMutation(deleteMedicineAPI, {
        onSuccess: (data) => {
            toast.success("Medicine has been Deleted Successfully");
            queryClient.invalidateQueries('medications')
        },
        onError: (error) => {
            toast.error(error?.response?.data?.message);
        },
    })

    return (
        <div className={styles.medication_card}>
            <div className={styles.icon_info}>
                <img src={data.icon} alt="" className={styles.icon} />
                <div className={styles.medication_info}>
                    <div className={styles.info_head}>
                        <h3>{data.name}</h3>
                        <p>{data.description}</p>
                    </div>
                    <div className={styles.info_body}>
                        <div className="row">
                            <div className="col-4">
                                <h5>{Tr.tr("patient:PatientProfile:Dosage")}</h5>
                            </div>
                            <div className="col-8">
                                <h6>{data.dosage}</h6>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <h5>{Tr.tr("patient:PatientProfile:Days")}</h5>
                            </div>
                            <div className="col-8">
                                <h6>{data.days}</h6>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <h5>{Tr.tr("patient:PatientProfile:Start")}</h5>
                            </div>
                            <div className="col-8">
                                <h6>{data.start}</h6>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <h5>{Tr.tr("patient:PatientProfile:End")}</h5>
                            </div>
                            <div className="col-8">
                                <h6>{data.end}</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.action}>
                <WhiteButton
                    btn
                    type="button"
                    withIconImg
                    icon="/img/delete-black.svg"
                    onClick={() => deleteMedicine(data.id)}
                />
                <GradientButton
                    btn
                    type="button"
                    label={Tr.tr("basic:Edit")}
                    withIconImg
                    icon="/img/edit-white.svg"
                    classes="p610"
                    buttonClicked={() => {
                        setVisible(true);
                        setEditableMedic(data);
                    }}
                />
            </div>
            {/* Add Medicine Drawer */}
            <AddMedicineDrawer
                visible={visible}
                hideHandler={() => {
                    setVisible(false);
                }}
                flag="edit"
                editableMedic={editableMedic}
            />
        </div>
    );
};

export default MedicationCard;

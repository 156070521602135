import { Tr } from "./i18n";

export const getCurrentWeekDays = () => {
    let curr = new Date();
    let week = [];

    for (let i = 1; i <= 7; i++) {
        let first = curr.getDate() - curr.getDay() + i

        // let dayDate = new Date(curr.setDate(first)).toISOString().slice(0, 10) // 2024-07-13
        let dayDate = new Date(curr.setDate(first)).toISOString() // 2024-07-14T16:56:06.316Z
        let day = new Date(curr.setDate(first));
        let date = day.toLocaleDateString('en-US', { day: 'numeric' });
        let dayName = day.toLocaleDateString('en-US', { weekday: 'long' });
        let monthName = day.toLocaleDateString('en-US', { month: 'short' });

        week.push({ id: i - 1, day: date, date: dayDate, dayName: Tr.tr(`basic:${dayName}`), monthName: monthName });
    }

    return week;
}
// Utils
import { NumbersFormater } from "utils/NumbersFormater";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./Achievements.module.scss";

const Achievements = () => {
	const achievements = [
		{
			count: 40000,
			title: Tr.tr("patient:Home:Happy_Clients"),
		},
		{
			count: 2000,
			title: Tr.tr("patient:Home:Doctors"),
		},
		{
			count: 300,
			title: Tr.tr("patient:Home:Hospitals"),
		},
		{
			count: 100,
			title: Tr.tr("patient:Home:Lab_Tests"),
		},
	];
	return (
		<section className={styles.achievements}>
			<div className="container">
				<div className={styles.sec_head}>
					<h2>{Tr.tr("patient:Home:More_Years_Achievements")}</h2>
					<p>Lorem ipsum dolor sit amet consectetur. Neque blandit</p>
				</div>
				<div className="row">
					{achievements.map((achievement, index) => (
						<div className="col-6 col-sm-3 col-md-3 mb-4 mb-sm-0" key={index}>
							<div className={styles.achievement_box}>
								<div className={styles.count}>
									{NumbersFormater(achievement.count)}+
								</div>
								<div className={styles.title}>{achievement.title}</div>
							</div>
						</div>
					))}
				</div>
			</div>
		</section>
	);
};

export default Achievements;

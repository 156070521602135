import { useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import momentPlugin from "@fullcalendar/moment";

import moment from "moment";

// Components
import MoreLinkPopover from "./components/MoreLinkPopover/MoreLinkPopover";
import FormatEventTime from "./components/FormatEventTime/FormatEventTime";
import CustomEventMonthContent from "./components/CustomEventMonthContent/CustomEventMonthContent";
import EventPopover from "./components/EventPopover/EventPopover";
import AddRecordsModal from "./components/AddRecordsModal/AddRecordsModal";
import RescheduleAppointmentModal from "./components/RescheduleAppointmentModal/RescheduleAppointmentModal";

// Utils
import OutsideClickHandler from "utils/OutsideClickHandler";

// Translation
import { useTranslation } from "react-i18next";
import { Tr } from "utils/i18n";

// Styles
import styles from "./FullCalendarView.module.scss";

const FullCalendarView = ({ events }) => {
	const { i18n } = useTranslation();
	const currLang = i18n.language;

	// Old & New Event Date
	const [eventDate, setEventDate] = useState({ oldDate: "", newDate: "" });

	// Add record Modal
	const [recordModalVisible, setRecordModalVisible] = useState(false);

	// Reschedule Appointment Modal
	const [
		rescheduleAppointmentModalVisible,
		setRescheduleAppointmentModalVisible,
	] = useState(false);

	// Custom Title
	const customTitle = (info) => {
		const day = moment(info.date).format("dddd");
		const month = moment(info.date).format("D MMM");

		const formattedToday = document.querySelector(".fc-toolbar-title");

		if (formattedToday)
			formattedToday.innerHTML = `<span>${day}</span> ${month}`;
	};

	const [moreLinkPopoverInfo, setMoreLinkPopoverInfo] = useState(null);

	const handleMoreLinkClick = (eventClickInfo) => {
		setMoreLinkPopoverInfo(eventClickInfo);
	};

	const closePopover = () => {
		setMoreLinkPopoverInfo(null);
	};

	const [eventPopoverRef, setEventPopoverRef] = useState(null);

	// Event Clicked
	const [userData, setUserData] = useState({});

	const handleEventClick = (clickInfo) => {
		const event = clickInfo.event;
		if (!event) {
			return;
		}
		setEventPopoverRef(clickInfo);
		const { title, start, end, extendedProps } = event;
		const { userId, email, location, phone, image, meetingId } = extendedProps;

		setUserData({
			title: title,
			id: userId,
			start: start ? start.toISOString() : "",
			end: end ? end.toISOString() : "",
			email: email,
			location: location,
			phone: phone,
			image: image,
			meetingId: meetingId,
		});
	};

	const updateEventWithUserData = (eventData, userData) => {
		setEventPopoverRef(eventData);
		setUserData(userData);
	};

	// Dragging
	const handleEventDrop = (info) => {
		console.log(info, "drag info");
		const updatedEvents = events.map((event) => {
			if (event.id === info.event.id) {
				return {
					...event,
					start: info.event.start.toISOString(),
					end: info.event.end ? info.event.end.toISOString() : null,
				};
			}
			return event;
		});

		// setEvents(updatedEvents);

		setEventDate({
			oldDate: `${moment(info.oldEvent.start).format("ddd, ll")} -
				${moment(info.oldEvent.start).format("LT")}`,
			newDate: `${moment(info.event.start).format("ddd, ll")} -
				${moment(info.event.start).format("LT")}`,
		});

		setRescheduleAppointmentModalVisible(true);
	};

	// Calendar Options
	const calendarOptions = {
		headerToolbar: {
			start: "prev title next",
			center: "today",
			end: "timeGridDay timeGridWeek dayGridMonth",
		},
		plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin, momentPlugin],
		initialView: "timeGridWeek",
		views: {
			dayGridMonth: {
				dayHeaderContent: (args) => (
					<div className="dayHeader_content">
						<span className="day">{moment(args.date).format("dddd")}</span>
					</div>
				),
				eventContent: (arg) => (
					<CustomEventMonthContent
						arg={arg}
						handleEventClick={(e) => handleEventClick(e)}
					/>
				),
			},
			dayGridWeek: {},
			dayGridDay: {},
		},
		weekends: true,
		events: events,
		dayMaxEvents: 1, // Maximum number of events to display directly in the month view
		moreLinkClick: (arg) => {
			handleMoreLinkClick(arg);
			return <div></div>;
		},
		moreLinkClassNames: "custom-more-link",
		eventContent: (arg) => <FormatEventTime arg={arg} />,
		titleFormat: customTitle,
		allDaySlot: false,
		aspectRatio: 1.5,
		direction: currLang === "ar" ? "rtl" : "ltr",
		dayHeaderContent: (args) => (
			<div
				className={`dayHeader_content ${
					args.view.type === "timeGridWeek" ? "week_type" : ""
				}`}>
				<span className="day">{moment(args.date).format("dddd")}</span>
				<span className="date">{moment(args.date).format("MMM D")}</span>
			</div>
		),
		eventClick: handleEventClick,
		eventDrop: handleEventDrop,
		editable: false, // NOTE --> This will allow doctor to extend and drag (reschedule) the appointment
	};

	return (
		<>
			<div className={styles.fullCalendar_holder}>
				<div className={styles.fullCalendar_wrapper}>
					<FullCalendar {...calendarOptions} />

					{eventPopoverRef && (
						<OutsideClickHandler
							onOutsideClick={() =>
								!recordModalVisible && setEventPopoverRef(null)
							}>
							<EventPopover
								event={eventPopoverRef}
								userData={userData}
								showRecordModal={() => setRecordModalVisible(true)}
							/>
						</OutsideClickHandler>
					)}

					{/* More Link Popover */}
					{moreLinkPopoverInfo && (
						<OutsideClickHandler
							onOutsideClick={() => {
								!eventPopoverRef && closePopover();
								!eventPopoverRef &&
									recordModalVisible &&
									setEventPopoverRef(null);
							}}>
							<MoreLinkPopover
								event={moreLinkPopoverInfo}
								onClose={closePopover}
								eventClicked={updateEventWithUserData}
							/>
						</OutsideClickHandler>
					)}
				</div>

				<div className={styles.fullCalendar_labels}>
					<div className={styles.fullCalendar_label}>
						<div className={`${styles.bullet} ${styles.inClinic}`}></div>
						<div className={styles.label}>{Tr.tr("basic:In_Clinic")}</div>
					</div>
					<div className={styles.fullCalendar_label}>
						<div className={`${styles.bullet} ${styles.videoCall}`}></div>
						<div className={styles.label}>{Tr.tr("basic:Video_Call")}</div>
					</div>
				</div>
			</div>

			{/* Add Record Modal */}
			<AddRecordsModal
				visible={recordModalVisible}
				hideHandler={() => setRecordModalVisible(false)}
			/>

			{/* Reschedule Appointment Modal */}
			<RescheduleAppointmentModal
				visible={rescheduleAppointmentModalVisible}
				hideHandler={() => setRescheduleAppointmentModalVisible(false)}
				eventDate={eventDate}
			/>
		</>
	);
};

export default FullCalendarView;

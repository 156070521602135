import { Button } from "primereact/button";

// Translation
import { useTranslation, withTranslation } from "react-i18next";

// Styles
import styles from "./LanguageBtn.module.scss";

const LanguageBtn = ({ ...props }) => {
	const { i18n } = useTranslation();
	const currLang = i18n.language;
	document.body.dir = i18n.dir();

	const changeLanguage = (lng) => {
		i18n.changeLanguage(lng);
		document.body.dir = i18n.dir();
	};

	return (
		<div className={`${styles.lang_holder} ${props.classes}`}>
			<Button
				text
				className={styles.lang_btn}
				onClick={() => changeLanguage(currLang === "ar" ? "en" : "ar")}
			>
				<img src="/img/language.svg" alt="" />
				<span>{currLang === "ar" ? "EN" : "عربي"}</span>
			</Button>
		</div>
	);
};

export default withTranslation()(LanguageBtn);

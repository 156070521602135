import { createContext, useState } from "react";

export const SidebarContext = createContext({});

export const SidebarProvider = ({ children }) => {
	const [smallSize, setSmallSize] = useState(false);

	return (
		<SidebarContext.Provider value={{ smallSize, setSmallSize }}>
			{children}
		</SidebarContext.Provider>
	);
};

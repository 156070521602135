import { Navigate, Route, Routes } from "react-router-dom";
// [Patient] Layouts
import AuthLayout from "layout/Patient/AuthLayout/AuthLayout";
import MainLayout from "layout/Patient/MainLayout/MainLayout";

// [Patient] Pages
import {
	AccountVerification,
	CheckSymptoms,
	ConfirmAppointmentsBooking,
	DoctorDetails,
	FindDoctor,
	ForgetPassword,
	Home,
	LogIn,
	MyProfile,
	OtpVerification,
	ResetPassword,
	SignUp,
} from "pages/Patient";

import StartVideo from "layout/LiveStream/StartVideo";

import Room from "layout/LiveStream/Room";

// *[Patient] My Profile [Pages]
import {
	AccountSettings,
	Dashboard,
	MedicalProfile,
	MyAppointments,
	QuestionAnswer,
	Reminders,
} from "pages/Patient/MyProfile/pages";

// [Doctor] Layouts
import DoctorAuthLayout from "layout/Doctor/DoctorAuthLayout/DoctorAuthLayout";
import DoctorMainLayout from "layout/Doctor/DoctorMainLayout/DoctorMainLayout";

// [Doctor] Pages
import {
	DoctorAppointments,
	DoctorOnBoarding,
	DoctorForgetPassword,
	DoctorLogIn,
	DoctorMessages,
	DoctorDashboard,
	DoctorOtpVerification,
	DoctorPatients,
	DoctorPatientsDetails,
	DoctorQuestionAnswer,
	DoctorReports,
	DoctorResetPassword,
	DoctorSettings,
	DoctorSignUp,
} from "pages/Doctor";

// *[Doctor] Sign Up [Pages]
import {
	CreateAccount,
	DoctorAccountVerification,
	PersonalDetails,
	RegisterationDocuments,
} from "pages/Doctor/DoctorAuth/DoctorSignUp/pages";

// *[Doctor] Dashboard [Pages]
import {
	Communication,
	OperationalHours,
	PracticeStaff,
	Pricing,
} from "pages/Doctor/DoctorOnBoarding/pages";

// *[Doctor] Settings [Pages]
import {
	DoctorAccountSettings,
	SettingsBilling,
	SettingsCalendar,
	SettingsCommunication,
	SettingsDrugCatalog,
	SettingsMyDocuments,
	SettingsPracticeDetails,
	SettingsPracticeStaff,
	SettingsPricingCatalog,
} from "pages/Doctor/DoctorSettings/pages";

// Translation
import { withTranslation } from "react-i18next";
import useBoardingStore from "store/onboarding";
import DoctorVideoRoomLayout from "layout/Doctor/DoctorVideoRoomLayout/DoctorVideoRoomLayout";
import PlainLayout from "layout/PlainLayout/PlainLayout";
import Wishlist from "pages/Patient/MyProfile/pages/Wishlist/Wishlist";
import useWishlistStore from "store/wishlist";
import { getWishlist } from '../api/wishlist';
import { useEffect } from "react";

// This to appear dahboard in first time and after that appear on boarding
// const onBoarding = false;

const AllRoutes = () => {
	const onBoarding = useBoardingStore((state) => state.onBoarding);
	const { getWishlist, } = useWishlistStore()

	useEffect(() => {
		getWishlist()
	}, [])

	return (
		<Routes>
			{/* [Patient] Authentication Routes */}
			<Route element={<AuthLayout />}>
				<Route exact strict path="/patient/login" element={<LogIn />} />
				<Route exact strict path="/patient/sign-up" element={<SignUp />} />
				<Route
					exact
					strict
					path="/patient/account-verification/:phoneNumber"
					element={<AccountVerification />}
				/>
				<Route
					exact
					strict
					path="/patient/forget-password"
					element={<ForgetPassword />}
				/>
				<Route
					exact
					strict
					path="/patient/otp-verification/:phone"
					element={<OtpVerification />}
				/>
				<Route
					exact
					strict
					path="/patient/my-profile/account-settings/update-phone/:phone"
					element={<OtpVerification updatePhoneNumber={true} />}
				/>
				<Route
					exact
					strict
					path="/patient/reset-password"
					element={<ResetPassword />}
				/>
			</Route>

			{/* Plain Layout Routes 
			Will be used for Video Call between Doctor & Patient
		*/}
			<Route element={<PlainLayout />}>
				<Route path="/videoroom/:roomID" element={<Room />} />
			</Route>

			{/* [Patient] Main Routes */}
			<Route element={<MainLayout />}>
				<Route
					path="/patient/prepareVideoCall/:appId"
					element={<StartVideo />}
				/>
				<Route path="/" element={<Navigate replace to="/patient/home" />} />
				<Route
					path="/patient"
					element={<Navigate replace to="/patient/home" />}
				/>
				<Route exact strict index path="/patient/home" element={<Home />} />
				<Route
					exact
					strict
					path="/patient/find-a-doctor"
					element={<FindDoctor />}
				/>
				<Route
					exact
					strict
					path="/patient/find-a-doctor/:id"
					element={<DoctorDetails />}
				/>
				<Route
					exact
					strict
					path="/patient/find-a-doctor/:id/confirm-booking/:date/:time/:bookingWay"
					element={<ConfirmAppointmentsBooking />}
				/>
				<Route exact strict path="/patient/my-profile" element={<MyProfile />}>
					<Route
						exact
						strict
						path="/patient/my-profile"
						element={<Navigate replace to="/patient/my-profile/dashboard" />}
					/>
					<Route exact strict index path="dashboard" element={<Dashboard />} />
					<Route
						exact
						strict
						path="my-appointments"
						element={<MyAppointments />}
					/>
					<Route
						exact
						strict
						path="wishlist"
						element={<Wishlist />}
					/>

					<Route exact strict path="reminders" element={<Reminders />} />
					<Route
						exact
						strict
						path="medical-profile"
						element={<MedicalProfile />}
					/>
					<Route
						exact
						strict
						path="question-answer"
						element={<QuestionAnswer />}
					/>
					<Route
						exact
						strict
						path="account-settings"
						element={<AccountSettings />}
					/>
				</Route>
				<Route
					exact
					strict
					path="/patient/check-symptoms"
					element={<CheckSymptoms />}
				/>
			</Route>

			{/* [Doctor] Authentication Routes */}
			<Route element={<DoctorAuthLayout />}>
				<Route
					exact
					strict
					index
					path="/doctor/login"
					element={<DoctorLogIn />}
				/>
				<Route
					exact
					strict
					path="/doctor/forget-password"
					element={<DoctorForgetPassword />}
				/>
				<Route
					exact
					strict
					path="/doctor/otp-verification"
					element={<DoctorOtpVerification />}
				/>
				<Route
					exact
					strict
					path="/doctor/reset-password"
					element={<DoctorResetPassword />}
				/>
				<Route exact strict path="/doctor/sign-up" element={<DoctorSignUp />}>
					<Route
						exact
						strict
						path="/doctor/sign-up"
						element={<Navigate replace to="/doctor/sign-up/create-account" />}
					/>
					<Route
						exact
						strict
						index
						path="create-account"
						element={<CreateAccount />}
					/>
					<Route
						exact
						strict
						path="account-verification"
						element={<DoctorAccountVerification />}
					/>
					<Route
						exact
						strict
						path="personal-details"
						element={<PersonalDetails />}
					/>
					<Route
						exact
						strict
						path="registeration-documents"
						element={<RegisterationDocuments />}
					/>
				</Route>
			</Route>

			{/* [Doctor] Main Routes */}
			<Route element={<DoctorVideoRoomLayout />}>
				<Route
					path="/doctor/prepareVideoCall/:appId"
					element={<StartVideo />}
				/>
			</Route>
			<Route element={<DoctorMainLayout />}>
				{/* This to appear dahboard in first time and after that appear on boarding */}

				{/* <Route
						exact
						strict
						path="/doctor/dashboard"
						element={
							<Navigate replace to="/doctor/on-boarding/operational-hours" />
						}
					/> */}
				<Route
					exact
					strict
					path="/doctor/dashboard"
					element={<DoctorDashboard />}></Route>
				<Route
					exact
					strict
					path="/doctor/on-boarding"
					element={<DoctorOnBoarding />}>
					<Route
						exact
						strict
						index
						path="operational-hours"
						element={<OperationalHours />}
					/>
					<Route
						exact
						strict
						path="practice-staff"
						element={<PracticeStaff />}
					/>
					<Route exact strict path="pricing" element={<Pricing />} />
					<Route
						exact
						strict
						path="communication"
						element={<Communication />}
					/>
				</Route>

				<Route
					exact
					strict
					path="/doctor/appointments"
					element={<DoctorAppointments />}></Route>
				<Route
					exact
					strict
					path="/doctor/patients"
					element={<DoctorPatients />}></Route>
				<Route
					exact
					strict
					path="/doctor/patients/:id"
					element={<DoctorPatientsDetails />}></Route>
				<Route
					exact
					strict
					path="/doctor/messages"
					element={<DoctorMessages />}></Route>
				<Route
					exact
					strict
					path="/doctor/question-answer"
					element={<DoctorQuestionAnswer />}></Route>
				<Route
					exact
					strict
					path="/doctor/reports"
					element={<DoctorReports />}></Route>
				<Route
					exact
					strict
					path="/doctor/settings"
					element={<DoctorSettings />}>
					<Route
						exact
						strict
						path="/doctor/settings"
						element={
							<Navigate replace to="/doctor/settings/practice-details" />
						}
					/>
					<Route
						exact
						strict
						index
						path="practice-details"
						element={<SettingsPracticeDetails />}
					/>
					<Route
						exact
						strict
						path="practice-staff"
						element={<SettingsPracticeStaff />}
					/>
					<Route exact strict path="calendar" element={<SettingsCalendar />} />
					<Route
						exact
						strict
						path="communication"
						element={<SettingsCommunication />}
					/>
					<Route
						exact
						strict
						path="pricing-catalog"
						element={<SettingsPricingCatalog />}
					/>
					<Route
						exact
						strict
						path="drug-catalog"
						element={<SettingsDrugCatalog />}
					/>
					<Route exact strict path="billing" element={<SettingsBilling />} />
					<Route
						exact
						strict
						path="my-documents"
						element={<SettingsMyDocuments />}
					/>
					<Route
						exact
						strict
						path="account-settings"
						element={<DoctorAccountSettings />}
					/>
				</Route>
			</Route>
		</Routes>
	);
};

export default withTranslation()(AllRoutes);

// Components
import GradientButton from "components/Common/UI/GradientButton/GradientButton";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./ConsultDoctor.module.scss";

const ConsultDoctor = () => {
	return (
		<div className={styles.consult_doctor}>
			<h2 className={styles.consult_doctor_title}>
				{Tr.tr("patient:CheckSymptoms:Consult_Doctor")}
			</h2>
			<p className={styles.consult_doctor_desc}>
				{Tr.tr("patient:CheckSymptoms:Medical_Evaluation")}
			</p>
			<div className={styles.recommendation_box}>
				<div className={styles.recommendation_box_content}>
					<div className={styles.recommendation_rows}>
						<div className={styles.recommendation_row}>
							<img src="/img/doctor-black.svg" alt="" />
							<div className={styles.recommendation_text}>
								<h5>{Tr.tr("patient:CheckSymptoms:Recommended_Doctor")}</h5>
								<h4>
									A doctor specializing in orthopedic diseases and surgery
								</h4>
							</div>
						</div>
						<div className={styles.recommendation_row}>
							<img src="/img/hospital.svg" alt="" />
							<div className={styles.recommendation_text}>
								<h5>
									{Tr.tr("patient:CheckSymptoms:Recommended_Appointment_Type")}
								</h5>
								<h4>In Clinic</h4>
							</div>
						</div>
					</div>
					<GradientButton
						label={Tr.tr("basic:Search")}
						withIconImg
						icon="/img/search-white.svg"
						redirectTo="/patient/find-a-doctor"
						classes={`w-100 ${styles.srch_btn}`}
					/>
				</div>
			</div>
		</div>
	);
};

export default ConsultDoctor;

import { useContext, useEffect } from "react";
import { NavLink, Outlet } from "react-router-dom";

// Context
import { SidebarContext } from "context/DoctorSidebarContext";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./DoctorSettings.module.scss";

const DoctorSettings = () => {
	const { setSmallSize } = useContext(SidebarContext);

	useEffect(() => {
		setSmallSize(true);
	}, [setSmallSize]);

	return (
		<div className={styles.doctor_settings}>
			<div className={styles.settings_sidebar}>
				<h1>{Tr.tr("doctor:Settings:Settings")}</h1>

				<div className={styles.settings_links}>
					<div className={styles.links_holder}>
						<NavLink to="practice-details" className={styles.setting_link}>
							{Tr.tr("doctor:Settings:Practice_Details")}
						</NavLink>
						<NavLink to="practice-staff" className={styles.setting_link}>
							{Tr.tr("doctor:Settings:Practice_Staff")}
						</NavLink>
						<NavLink to="calendar" className={styles.setting_link}>
							{Tr.tr("doctor:Settings:Calendar")}
						</NavLink>
						<NavLink to="communication" className={styles.setting_link}>
							{Tr.tr("doctor:Settings:Communication")}
						</NavLink>
						<NavLink to="pricing-catalog" className={styles.setting_link}>
							{Tr.tr("doctor:Settings:Pricing_Catalog")}
						</NavLink>
						<NavLink to="drug-catalog" className={styles.setting_link}>
							{Tr.tr("doctor:Settings:Drug_Catalog")}
						</NavLink>
						<NavLink to="billing" className={styles.setting_link}>
							{Tr.tr("doctor:Settings:Billing")}
						</NavLink>
					</div>
					<div className={styles.links_holder}>
						<NavLink to="my-documents" className={styles.setting_link}>
							{Tr.tr("doctor:Settings:My_Documents")}
						</NavLink>
						<NavLink to="account-settings" className={styles.setting_link}>
							{Tr.tr("doctor:Settings:Account_Settings")}
						</NavLink>
					</div>
				</div>
			</div>

			<div className={styles.outlet_wrapper}>
				<Outlet />
			</div>
		</div>
	);
};

export default DoctorSettings;

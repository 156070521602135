import { Tooltip } from "primereact/tooltip";
import { Button } from "primereact/button";

// Components
import TransactionHistory from "./components/TransactionHistory/TransactionHistory";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./MyInvoices.module.scss";

const MyInvoices = () => {
	return (
		<div className={styles.my_invoices}>
			<section className={styles.overview_sec}>
				<h2 className={styles.sec_title}>
					{Tr.tr("doctor:Settings:Overview")}
				</h2>
				<div className="row">
					<div className="col-md-6 col-lg-4 mb-4 mb-lg-0">
						<div className={styles.overview_box}>
							<div className={styles.text}>
								<h6>{Tr.tr("doctor:Settings:Total_Balance")}</h6>
								<h4 className={styles.black}>0.00 SAR</h4>
							</div>
							<div className={styles.info}>
								<Tooltip
									target=".total-balance"
									position="bottom"
									className={styles.tooltip_holder}>
									<h5>{Tr.tr("doctor:Settings:Total_Balance")}</h5>
									<p>
										It is a long established fact that a reader will be
										distracted by the readable content
									</p>
								</Tooltip>

								<Button className={`total-balance ${styles.info_btn}`}>
									<img src="/img/info-gray.svg" alt="" />
								</Button>
							</div>
						</div>
					</div>
					<div className="col-md-6 col-lg-4 mb-4 mb-lg-0">
						<div className={styles.overview_box}>
							<div className={styles.text}>
								<h6>{Tr.tr("doctor:Settings:Pending_Balance")}</h6>
								<h4 className={styles.yellow}>0.00 SAR</h4>
							</div>
							<div className={styles.info}>
								<Tooltip
									target=".pending-balance"
									position="bottom"
									className={styles.tooltip_holder}>
									<h5>{Tr.tr("doctor:Settings:Pending_Balance")}</h5>
									<p>
										It is a long established fact that a reader will be
										distracted by the readable content
									</p>
								</Tooltip>

								<Button className={`pending-balance ${styles.info_btn}`}>
									<img src="/img/info-gray.svg" alt="" />
								</Button>
							</div>
						</div>
					</div>
					<div className="col-md-6 col-lg-4 mb-4 mb-md-0">
						<div className={styles.overview_box}>
							<div className={styles.text}>
								<h6>{Tr.tr("doctor:Settings:Available_Balance")}</h6>
								<h4 className={styles.green}>0.00 SAR</h4>
							</div>
							<div className={styles.info}>
								<Tooltip
									target=".available-balance"
									position="bottom"
									className={styles.tooltip_holder}>
									<h5>{Tr.tr("doctor:Settings:Available_Balance")}</h5>
									<p>
										It is a long established fact that a reader will be
										distracted by the readable content
									</p>
								</Tooltip>

								<Button className={`available-balance ${styles.info_btn}`}>
									<img src="/img/info-gray.svg" alt="" />
								</Button>
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* Transaction History */}
			<TransactionHistory />
		</div>
	);
};

export default MyInvoices;

import { Link } from "react-router-dom";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./QuickLinks.module.scss";

const QuickLinks = () => {
	return (
		<div className={styles.quick_links}>
			<h2>{Tr.tr("patient:PatientProfile:Quick_Links")}</h2>
			<div className={styles.quick_row}>
				<Link to="/patient/find-a-doctor" className={styles.quick_box}>
					<img src="/img/doctor-gradient.svg" alt="" />
					<span className={styles.lbl}>
						{Tr.tr("patient:Home:Find_Doctors_Need_You")}
					</span>
				</Link>
				<Link
					to={`/find-a-doctor/1/confirm-booking/videoCall`}
					className={styles.quick_box}>
					<img src="/img/video-gradient.svg" alt="" />
					<span className={styles.lbl}>{Tr.tr("patient:Home:Video_Call")}</span>
				</Link>
				<Link
					to={`/find-a-doctor/1/consultation-call`}
					className={styles.quick_box}>
					<img src="/img/call.svg" alt="" />
					<span className={styles.lbl}>
						{Tr.tr("patient:Home:Consultation_Call")}
					</span>
				</Link>
				<Link to="" className={styles.quick_box}>
					<img src="/img/blood-test.svg" alt="" />
					<span className={styles.lbl}>{Tr.tr("patient:Home:Surgeries")}</span>
				</Link>
			</div>
		</div>
	);
};

export default QuickLinks;

// Styles
import styles from "./CustomRadioButton.module.scss";

export function CustomRadioButton({ ...props }) {
	return (
		<label
			className={`${styles.custom_radioBtn} ${
				props.checked ? styles.checked : ""
			}`}>
			<input
				type="radio"
				name={props.inputName}
				value={props.inputVal}
				onChange={props.handleChange}
				checked={props.checked}
			/>
			<div className={styles.label_icon}>
				<img src={props.icon} alt="" className={styles.custom_radioBtn_icon} />
				<span className={styles.custom_radioBtn_lbl}>{props.inputLabel}</span>
			</div>
		</label>
	);
}

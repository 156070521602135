import { Outlet } from "react-router-dom";

// Components
import DoctorHeader from "../DoctorHeader/DoctorHeader";
import DoctorSideBar from "../DoctorSideBar/DoctorSideBar";

// Provider
import { SidebarProvider } from "context/DoctorSidebarContext";

// Styles
import styles from "./DoctorMainLayout.module.scss";

const DoctorMainLayout = () => {
	return (
		<SidebarProvider>
			<div className={styles.doctor_mainLayout}>
				<DoctorSideBar />
				<main>
					<DoctorHeader />

					<div className={styles.outlet_wrapper}>
						<Outlet />
					</div>
				</main>
			</div>
		</SidebarProvider>
	);
};

export default DoctorMainLayout;

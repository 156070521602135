import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useMutation } from "react-query";
import { toast } from "react-hot-toast";
import Cookies from "js-cookie";

// Components
import LanguageBtn from "components/Common/UI/LanguageBtn/LanguageBtn";
import { PasswordInput, TextInput } from "components/Common/Form/Inputs";
import GradientButton from "components/Common/UI/GradientButton/GradientButton";

// Utils
import { getFormErrorMessage } from "utils/FormField";

// Api
import { loginAPI } from "api/auth";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./DoctorLogIn.module.scss";

const DoctorLogIn = () => {
	const { mutate, isLoading, isError } = useMutation(loginAPI, {
		onSuccess: (data) => {
			// save data in Cookies
			localStorage.setItem("user_name", data.data.user_name);
			Cookies.set("session_id", data.data.session_id, { expires: 1 });
			Cookies.set("user_type", "doctor", { expires: 7 });
			navigate("/doctor/on-boarding/operational-hours");
		},
	});

	const navigate = useNavigate();

	const formik = useFormik({
		initialValues: {
			phone: "",
			password: "",
		},
		validate: (data) => {
			let errors = {};

			if (!data.phone) {
				errors.phone = "Phone is required.";
			}
			// else if (
			// 	!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)
			// ) {
			// 	errors.email = "Invalid email address. E.g. user@example.com";
			// }

			if (!data.password) {
				errors.password = "Password is required.";
			}

			return errors;
		},
		onSubmit: async (data, { setSubmitting }) => {
			console.log("submitted");
			// setTimeout(() => {
			// 	setSubmitting(false);
			// 	navigate("/doctor/dashboard");
			// 	formik.resetForm();
			// }, 2000);
			await mutate({
				login: data.phone,
				password: data.password,
			});
		},
	});

	return (
		<div className={styles.log_p}>
			<LanguageBtn classes={styles.auth_lang} />

			<div className="row">
				<div className={`col-lg-6 d-none d-lg-block`}>
					<img src="/img/doctor-login.png" alt="" className={styles.img_side} />
				</div>
				<div className="col-lg-6">
					<div className={styles.log_form}>
						<div className={styles.top_sec}>
							<Link to="/doctor/login" className={styles.logo}>
								<img src="/img/logo.svg" alt="" />
							</Link>
							<div className={styles.f_txt}>
								<h3>{Tr.tr("basic:Welcome_back")}</h3>
								<p>{Tr.tr("basic:Please_Sign_In_To_Continue")}</p>
							</div>

							<form
								className={styles.form_holder}
								onSubmit={formik.handleSubmit}>
								<div className={styles.inputs_wrapper}>
									<div className="row">
										{/* Email */}
										<div className="col-md-12 m-b-20">
											<TextInput
												inputLabel={Tr.tr("basic:Email_Phone")}
												inputName="phone"
												placeHolder="+20123456789"
												inputVal={formik.values.phone}
												changeHandler={formik.handleChange}
												inputError={getFormErrorMessage("phone", formik)}
											/>
										</div>

										{/* Password */}
										<div className="col-md-12">
											<PasswordInput
												inputLabel={Tr.tr("basic:Password")}
												inputName="password"
												placeHolder={Tr.tr("basic:Password")}
												inputVal={formik.values.password}
												changeHandler={formik.handleChange}
												inputError={getFormErrorMessage("password", formik)}
											/>
										</div>
									</div>
								</div>

								<Link
									to="/doctor/forget-password"
									className={styles.forget_Pass}>
									{Tr.tr("basic:Forgot_Your_Password")}
								</Link>

								<GradientButton
									btn
									type="submit"
									label={Tr.tr("basic:Sign_In")}
									disabled={
										!(formik.isValid && formik.dirty) || formik.isSubmitting
									}
									isLoading={formik.isSubmitting}
									classes="w-100"
								/>
							</form>

							{/* Have Account */}
							<div className={styles.have_account}>
								<span>{Tr.tr("basic:Donot_Have_Account")}</span>
								<Link to="/doctor/sign-up">{Tr.tr("basic:Sign_Up")}</Link>
							</div>
						</div>

						<div className={styles.auth_footer}>
							<Link to="/terms">{Tr.tr("basic:Terms")}</Link>
							<Link to="/privacy">{Tr.tr("basic:Privacy")}</Link>
							<Link to="/help-center">{Tr.tr("basic:Help_Center")}</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DoctorLogIn;

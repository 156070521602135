import { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";

// Components
import { SelectDropdown, TextInput } from "components/Common/Form/Inputs";
import WhiteButton from "components/Common/UI/WhiteButton/WhiteButton";

// Translation
import { withTranslation } from "react-i18next";
import { Tr } from "utils/i18n";

// Styles
import styles from "./Prescriptions.module.scss";

const Prescriptions = () => {
	// Is Edit
	const [isEdit, setIsEdit] = useState(false);

	// Prescriptions
	const [prescriptions, setPrescriptions] = useState([
		{
			id: "1",
			drug: "Drug 1",
			dosage: 1,
			intake: "Before Food",
			duration: 5,
		},
	]);

	// Drugs
	const [selectedDrug, setSelectedDrug] = useState(null);
	const drugs = [
		{
			label: "Drug Name 1",
			value: "Drug Name 1",
		},
		{
			label: "Drug Name 2",
			value: "Drug Name 2",
		},
		{
			label: "Drug Name 3",
			value: "Drug Name 3",
		},
	];

	// Drug Template
	const drugTemplate = () => {
		return (
			<SelectDropdown
				inputLabel=""
				inputName="drug"
				placeHolder={Tr.tr("basic:Search_Drugs")}
				options={drugs}
				inputVal={selectedDrug}
				changeHandler={(e) => setSelectedDrug(e.value)}
				appendTo={document.body}
				inputError={""}
				filter={true}
				filterPlaceholder={Tr.tr("basic:Search_Drugs")}
			/>
		);
	};

	// Dosages
	const dosages = [
		{
			label: 1,
			value: 1,
		},
		{
			label: 2,
			value: 2,
		},
		{
			label: 3,
			value: 3,
		},
	];
	const [selectedDosage, setSelectedDosage] = useState(dosages[0].value);

	// Dosage Template
	const dosageTemplate = () => {
		return (
			<SelectDropdown
				inputLabel=""
				inputName="dosage"
				placeHolder={Tr.tr("basic:Select")}
				options={dosages}
				inputVal={selectedDosage}
				changeHandler={(e) => setSelectedDosage(e.value)}
				appendTo={document.body}
				inputError={""}
			/>
		);
	};

	// Intake
	const intake = [
		{
			label: "Before Food",
			value: "Before Food",
		},
		{
			label: "After Food",
			value: "After Food",
		},
		{
			label: "During Food",
			value: "During Food",
		},
	];
	const [selectedIntake, setSelectedIntake] = useState(intake[0].value);

	// Intake Template
	const intakeTemplate = () => {
		return (
			<SelectDropdown
				inputLabel=""
				inputName="intake"
				placeHolder={Tr.tr("basic:Select")}
				options={intake}
				inputVal={selectedIntake}
				changeHandler={(e) => setSelectedIntake(e.value)}
				appendTo={document.body}
				inputError={""}
			/>
		);
	};

	// Duration Template
	const durationTemplate = () => {
		return (
			<TextInput
				inputLabel=""
				inputName="duration"
				placeHolder="0"
				inputVal={""}
				changeHandler={() => {}}
				inputError={""}
			/>
		);
	};

	return (
		<div className={styles.prescriptions}>
			{!isEdit ? (
				<DataTable value={prescriptions} tableStyle={{ minWidth: "100%" }}>
					<Column field="drug" header={Tr.tr("basic:Drug")}></Column>
					<Column field="dosage" header={Tr.tr("basic:Dosage")}></Column>
					<Column field="intake" header={Tr.tr("basic:Intake")}></Column>
					<Column field="duration" header={Tr.tr("basic:Duration")}></Column>
				</DataTable>
			) : (
				<>
					<DataTable value={prescriptions} tableStyle={{ minWidth: "100%" }}>
						<Column
							field="drug"
							header={Tr.tr("basic:Drug")}
							body={drugTemplate}></Column>
						<Column
							field="dosage"
							header={Tr.tr("basic:Dosage")}
							body={dosageTemplate}></Column>
						<Column
							field="intake"
							header={Tr.tr("basic:Intake")}
							body={intakeTemplate}></Column>
						<Column
							field="duration"
							header={Tr.tr("basic:Duration")}
							body={durationTemplate}></Column>
						<Column
							body={
								<Button
									type="button"
									icon="pi pi-times"
									rounded
									text
									severity="danger"
									aria-label="Delete"
									className={styles.delete}
								/>
							}></Column>
					</DataTable>

					{/* Add Line */}
					<WhiteButton btn type="button" label={Tr.tr("basic:Add_Line")} />
				</>
			)}
		</div>
	);
};

export default withTranslation()(Prescriptions);

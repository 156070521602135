import { Dialog } from "primereact/dialog";

// Styles
import styles from "./DialogWrapper.module.scss";

const DialogWrapper = ({ children, ...props }) => {
	return (
		<Dialog
			header={props.header}
			visible={props.visible}
			style={{ width: props.width }}
			onHide={props.hideHandler}
			className={`${styles.dialog_wrapper} ${props.classes}`}
			draggable={false}>
			{children}
		</Dialog>
	);
};

export default DialogWrapper;

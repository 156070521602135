import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Menu } from "primereact/menu";
import { Button } from "primereact/button";

// Cookies
import Cookies from "js-cookie";

// Translation
import { useTranslation } from "react-i18next";
import { Tr } from "utils/i18n";

// Styles
import styles from "./UserDropdown.module.scss";

const UserDropdown = ({ user_name }) => {
	const { i18n } = useTranslation();
	const currLang = i18n.language;

	// Navigation
	const navigate = useNavigate();

	// User Dropdown
	const menu = useRef(null);
	const items = [
		{
			label: Tr.tr("patient:Header:Profile"),
			icon: "pi pi-user",
			command: () => {
				navigate("/patient/my-profile");
			},
		},
		{
			label: Tr.tr("patient:Header:My_Appointments"),
			icon: "pi pi-history",
			command: () => {
				navigate("/patient/my-profile/my-appointments");
			},
		},
		{
			label: Tr.tr("patient:Header:Account_Settings"),
			icon: "pi pi-cog",
			command: () => {
				navigate("/patient/my-profile/account-settings");
			},
		},
		{
			label: Tr.tr("patient:Header:Logout"),
			icon: "pi pi-sign-in",
			command: () => {
				Cookies.remove("session_id");
				localStorage.clear();
				navigate("/patient/login");
			},
		},
	];

	return (
		<div className={styles.user_dropdown}>
			<Menu
				model={items}
				popup
				ref={menu}
				id="popup_menu"
				className={styles.user_menu}
				popupAlignment={currLang === "ar" ? "left" : "right"}
			/>
			<Button
				className={styles.menu_btn}
				onClick={(event) => menu.current.toggle(event)}
				aria-controls="popup_menu"
				aria-haspopup>
				<img src="/img/profile.svg" alt="" className={styles.profile_icon} />
				<span className={styles.user_name}>{user_name}</span>
				<i className="pi pi-angle-down"></i>
			</Button>
		</div>
	);
};

export default UserDropdown;

import { Button } from "primereact/button";

// Components
import Rate from "components/Common/UI/Rate/Rate";

// Utils
import { stripHtmlUsingDOMParser } from "utils/stripHtmlUsingDOMParser";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./DoctorInfoCard.module.scss";
import { useMemo, useState } from "react";
import { useMutation } from "react-query";
import { addToWishlist, RemoveFromWishlist } from "api/wishlist";
import useWishlistStore from "store/wishlist";

const DoctorInfoCard = ({ info, averageRating, ...props }) => {
	const { addToWishlist, toggleWishlistLoading: isLoading, wishlist, removeFromWishlist } = useWishlistStore()
	const [isWishlist, setIsWishlist] = useState(info?.is_favorite)
	// const isWishlist = info?.is_favorite //useMemo(() => wishlist?.some(item => item.id === info?.id), [wishlist])

	const addDoctorToWishlist = () => {
		addToWishlist(info);
		setIsWishlist(true)
	}
	const removeDoctorFromWishlist = () => {
		removeFromWishlist(info)
		setIsWishlist(false)
	}

	return (
		<div className={`${styles.doctorInfo_card} ${props.classes}`}>
			<div className={styles.doctor_img}>
				<img src={info.image || "/img/doctor-img.png"} alt="" />
			</div>
			<div className={styles.info}>
				<div className={styles.name}>
					{info.title?.name} {info?.name}
				</div>
				{info?.doctor_specialty_ids.map((item, index) => {
					return (
						<div className={styles.speciality} key={index}>
							{"Professor of "}
							{item?.name}
						</div>
					);
				})}
				<div className={styles.rate_reviews}>
					<Rate rateVal={averageRating} readOnly={true} />
					<div className={styles.reviews}>{averageRating} Reviews</div>
				</div>
				<div className={styles.desc}>
					{stripHtmlUsingDOMParser(info?.description)}
				</div>
				<div className={styles.btns}>
					{isLoading ? <>loading</> : <>
						{!isWishlist && <Button onClick={addDoctorToWishlist} type="button" className={styles.add_fav}>
							<i className={"pi pi-heart"}></i>
							<span>{Tr.tr("patient:DoctorDetails:Add_To_Favorite")}</span>
						</Button>}

						{isWishlist && <Button onClick={removeDoctorFromWishlist} type="button" className={styles.add_fav}>
							<i className={"pi pi-heart-fill"}></i>
							<span>{Tr.tr("patient:DoctorDetails:Remove_From_Favorite")}</span>
						</Button>}
					</>}

					<Button type="button" className={styles.share}>
						<span>{Tr.tr("patient:DoctorDetails:Share")}</span>
					</Button>
				</div>
			</div>
		</div>
	);
};

export default DoctorInfoCard;

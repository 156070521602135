import { useRef } from "react";
import { Menu } from "primereact/menu";
import { Button } from "primereact/button";

// Translation
import { useTranslation } from "react-i18next";

// Styles
import styles from "./DoctorNotifications.module.scss";

const DoctorNotifications = () => {
	const { i18n } = useTranslation();
	const currLang = i18n.language;

	// Notifications Dropdown
	const menu = useRef(null);
	const items = [
		{
			label: "Notifications",
		},
	];

	return (
		<div className={styles.doctor_notifications}>
			<Menu
				model={items}
				popup
				ref={menu}
				id="notification_menu"
				className={styles.notifications_menu}
				popupAlignment={currLang === "ar" ? "left" : "right"}
			/>
			<Button
				className={styles.menu_btn}
				onClick={(event) => menu.current.toggle(event)}
				aria-controls="notification_menu"
				aria-haspopup>
				<img
					src="/img/notifications.svg"
					alt=""
					className={styles.notification_icon}
				/>
				<span className={styles.count}>2</span>
			</Button>
		</div>
	);
};

export default DoctorNotifications;

import { useState } from "react";

// Components
import PageHead from "components/Patient/PageHead/PageHead";
import QuickLinks from "./components/QuickLinks/QuickLinks";
import UpcomingAppointment from "./components/UpcomingAppointment/UpcomingAppointment";
import Reminders from "./components/Reminders/Reminders";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./Dashboard.module.scss";

const Dashboard = () => {
	const userName = localStorage.getItem("user_name") || "";

	return (
		<div className={styles.dashboard}>
			<PageHead
				title={`${Tr.tr("basic:Hi")} 👋 ${userName}`}
				subTitle={Tr.tr("patient:PatientProfile:Track_Healthy_Daily")}
				mb="24px"
			/>

			<QuickLinks />

			<UpcomingAppointment />

			<Reminders />
		</div>
	);
};

export default Dashboard;

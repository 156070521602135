export const toHoursAndMinutes = (totalMinutes) =>  {
    //const totalMinutes = Math.floor(totalSeconds / 60);
  
    // const seconds = totalSeconds % 60;
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    let hoursTime = "";
    let minutesTime = "";
    if(hours.toString().padStart(2,'0') !== "00"){
        hoursTime += hours.toString().padStart(1,'0') + " Hours";
    }
    if(minutes.toString().padStart(2,'0') !== "00"){
        minutesTime += minutes.toString().padStart(1,'0') + " Minutes";
    }
    if (hoursTime !== "" && minutesTime !== "") {
        return hoursTime + ", " + minutesTime;
    }

    else if (hoursTime !== "") {
        return hoursTime;
    }

    else if (minutesTime !== "") {
        return minutesTime;
    }


    
    //  return `${hours.toString().padStart(2,'0') !== "00"? hours.toString().padStart(1,'0')+ " Hours " : "" } ${minutes.toString().padStart(2,'0') !== "00" ? minutes.toString().padStart(1,'0') + " Mintues": "" }`

}
import { useFormik } from "formik";

// Components
import GrayButton from "components/Common/UI/GrayButton/GrayButton";
import GradientButton from "components/Common/UI/GradientButton/GradientButton";
import { SelectDropdown, TextInput } from "components/Common/Form/Inputs";

// Utils
import { getFormErrorMessage } from "utils/FormField";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./Communication.module.scss";

const Communication = () => {
	// SMS Langauges
	const smsLanguages = [
		{
			label: "Arabic",
			value: "Arabic",
		},
		{
			label: "English",
			value: "English",
		},
	];

	// Formik
	const formik = useFormik({
		initialValues: {
			mobile: "",
			email: "",
			smsLanguage: smsLanguages[0].value,
			smsClinicName: "",
		},
		validate: (data) => {
			let errors = {};

			if (!data.mobile) {
				errors.mobile = "Mobile Number is required.";
			}

			if (!data.email) {
				errors.email = "Email Address is required.";
			}

			if (!data.smsLanguage) {
				errors.smsLanguage = "SMS Language is required.";
			}

			if (!data.smsClinicName) {
				errors.smsClinicName = "SMS Clinic Name is required.";
			}

			return errors;
		},
		onSubmit: (data, { setSubmitting }) => {
			console.log("submitted");
			setTimeout(() => {
				setSubmitting(false);
				formik.resetForm();
			}, 2000);
		},
	});

	return (
		<div className={styles.communication_step}>
			<form onSubmit={formik.handleSubmit}>
				<div className={styles.communication_step_content}>
					<div className={styles.title}>
						<h3>
							{Tr.tr("doctor:OnBoarding:Patient_Communication_Preferences")}
						</h3>
						<p>Lorem ipsum dolor sit amet consectetur. Neque blandit</p>
					</div>

					<div className="row">
						<div className="col-md-6">
							<div className="row">
								{/* Mobile Number */}
								<div className="col-md-12 m-b-24">
									<TextInput
										inputLabel={Tr.tr("basic:Mobile_Number")}
										inputName="mobile"
										placeHolder={Tr.tr("basic:Enter_Mobile_Number")}
										inputVal={formik.values.mobile}
										changeHandler={formik.handleChange}
										inputError={getFormErrorMessage("mobile", formik)}
									/>
								</div>

								{/* Email Address */}
								<div className="col-md-12 m-b-24">
									<TextInput
										inputLabel={Tr.tr("basic:Email_Address")}
										inputName="email"
										placeHolder={Tr.tr("basic:Enter_Email_Address")}
										inputVal={formik.values.email}
										changeHandler={formik.handleChange}
										inputError={getFormErrorMessage("email", formik)}
									/>
								</div>

								{/* SMS Language */}
								<div className="col-md-12 m-b-24">
									<SelectDropdown
										inputLabel={Tr.tr("basic:SMS_Language")}
										inputName="smsLangauge"
										placeHolder={Tr.tr("basic:Choose_Your_Language")}
										options={smsLanguages}
										inputVal={formik.values.smsLanguage}
										changeHandler={(e) =>
											formik.setFieldValue("smsLanguage", e.value)
										}
										appendTo={document.body}
										inputError={getFormErrorMessage("smsLanguage", formik)}
									/>
									<div className={styles.hint}>
										{Tr.tr("basic:SMS_Patients_Language")}
									</div>
								</div>

								{/* SMS Clinic Name */}
								<div className="col-md-12 m-b-24">
									<TextInput
										inputLabel={Tr.tr("basic:SMS_Clinic_Name")}
										inputName="smsClinicName"
										placeHolder={Tr.tr("basic:Enter_Clinic_Name")}
										inputVal={formik.values.smsClinicName}
										changeHandler={formik.handleChange}
										inputError={getFormErrorMessage("smsClinicName", formik)}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>

				{/* Footer Actions */}
				<div className={styles.footer_actions}>
					<GrayButton
						label={Tr.tr("basic:Back")}
						redirectTo="/doctor/on-boarding/pricing"
						outlined
						classes="p712"
					/>
					{/* <GradientButton btn type="submit" label="Finish" classes="p712" /> */}
					<GradientButton
						redirectTo="/doctor/dashboard"
						label={Tr.tr("basic:Finish")}
						classes="p712"
					/>
				</div>
			</form>
		</div>
	);
};

export default Communication;

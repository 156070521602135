import { useEffect, useRef, useState } from "react";
import {
	Link,
	useNavigate,
	useSearchParams,
	useParams,
} from "react-router-dom";
import { Button } from "primereact/button";
import { useMutation } from "react-query";
import toast from "react-hot-toast";

// Components
import { OtpInputs } from "components/Common/Form/Inputs";
import GradientButton from "components/Common/UI/GradientButton/GradientButton";

// Api
import { activeAPI, sendVerificationCodeAPI } from "api/auth";

// Transaltion
import { Tr } from "utils/i18n";

// Styles
import styles from "./AccountVerification.module.scss";

const AccountVerification = () => {
	const [otp, setOtp] = useState("");
	const [error, setError] = useState("");

	const navigate = useNavigate();
	const { phoneNumber } = useParams();

	const { mutate: sendVerifyCode } = useMutation(sendVerificationCodeAPI, {
		onSuccess: (data) => {},
		onError: (error) => {
			// console.log(error.response.data);
			// setError(error.response.data.message)
			toast(error.response.data.message);
		},
	});

	const { mutate, isLoading, isError } = useMutation(activeAPI, {
		onSuccess: (data) => {
			navigate("/patient/login");
		},
		// onError: (error) => {
		// 	// console.log(error.response.data);
		// 	// setError(error.response.data.message)
		// 	toast.error(error.response.data.message);
		// },
	});

	const ref = useRef(false);

	useEffect(() => {
		if (ref.current) {
			return;
		}
		ref.current = true;
		const sendCodeToUser = async () => {
			await sendVerifyCode(phoneNumber);
		};
		if (phoneNumber) {
			sendCodeToUser();
		}
	}, [phoneNumber]);

	const sendCode = async () => {
		// navigate("/patient/otp-verification");

		// TODO : TO be fixed
		const newData = {
			phone: phoneNumber,
			code: otp,
		};
		await mutate(newData);
	};

	// Number Of Digits [used in translation]
	const Digits = 4;

	return (
		<div className={styles.log_P}>
			<div className="row">
				<div className={`col-lg-6 d-none d-lg-block ${styles.bgGragient}`}>
					<div className={styles.gradient_side}>
						<div className={styles.top_sec}>
							<Link to="/" className={styles.logo}>
								<img src="/img/logo-white.svg" alt="" />
							</Link>
							<h1>{Tr.tr("basic:Feel_Better_Finding_Healthcare")}</h1>
							<p>
								It is a long established fact that a reader will be distracted
								by the readable content
							</p>
						</div>
						<img
							src="/img/hero-img.png"
							alt=""
							className={styles.gradientImg}
						/>
					</div>
				</div>
				<div className="col-lg-6">
					<div className={styles.log_form}>
						<div className={styles.top_sec}>
							<Link to="/" className={`d-flex d-lg-none ${styles.logo}`}>
								<img src="/img/logo.svg" alt="" />
							</Link>
							<div className={styles.f_txt}>
								<h3>{Tr.tr("basic:Enter_Digit_Code", { Digits })}</h3>
								<p>{Tr.tr("basic:Check_Phone_Number")}</p>
							</div>

							<div className={styles.phone_num}>
								<div className={styles.phone_lbl}>
									{Tr.tr("basic:Phone_Number")}
								</div>
								<div className={styles.num}>{phoneNumber}</div>
							</div>

							<form className={styles.form_holder}>
								<div className={styles.inputs_wrapper}>
									<div className="row">
										{/* OTP */}
										<div className="col-md-12 m-b-20">
											<OtpInputs
												inputLabel={Tr.tr("basic:Verification_Code")}
												inputVal={otp}
												otpHandler={setOtp}
												inputsNum={4}
												// inputError={
												// 	otp.length !== 4 && otp !== "1234"
												// 		? "Invalid code. Please try again"
												// 		: ""
												// }
											/>
										</div>
									</div>
								</div>
								<GradientButton
									btn
									type="button"
									label={Tr.tr("basic:Verify_Your_Account")}
									disabled={otp.length < 4}
									buttonClicked={sendCode}
									classes="w-100"
								/>
							</form>

							<div className={styles.get_code}>
								<span className={styles.txt}>
									{Tr.tr("basic:Did_Not_Get_Code")}
								</span>
								<Button
									type="button"
									label={Tr.tr("basic:Resend")}
									className={styles.resend_btn}
								/>
							</div>

							{error && (
								<div className="alert alert-danger mt-3" role="alert">
									{error}
								</div>
							)}
						</div>
						<div className={styles.auth_footer}>
							<Link to="/terms">{Tr.tr("basic:Terms")}</Link>
							<Link to="/privacy">{Tr.tr("basic:Privacy")}</Link>
							<Link to="/help-center">{Tr.tr("basic:Help_Center")}</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AccountVerification;

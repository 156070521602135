import { useEffect, useState } from "react";

// Components
import { SelectDropdown, SwitchInput } from "components/Common/Form/Inputs";
import DoctorsResults from "./components/DoctorsResults/DoctorsResults";
import FilterDoctors from "./components/FilterDoctors/FilterDoctors";
import DownloadApp from "components/Patient/DownloadApp/DownloadApp";

// Query
import { useQuery } from "react-query";
import { useQueryClient } from "react-query";

// Api
import { doctorsAPI } from "api/doctors";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./DoctorsAvailable.module.scss";

const DoctorsAvailable = ({
	doctors,
	globalFilter,
	appendToURL,
	removeFromURL,
	doctorsLoading,
}) => {
	const [checkedCall, setCheckedCall] = useState(false);
	const [checkedVideo, setCheckedVideo] = useState(false);
	const [search, updateSearch] = useState("");
	const [filters, updateFilters] = useState({});
	const [editedDoctors, setEditedDoctors] = useState([]);
	useEffect(() => {
		if (!doctorsLoading) {
			setEditedDoctors(doctors?.data);
		}
	}, [doctors]);

	// const { data: doctors, isLoading: doctorsLoading } = useQuery(['doctors', search, filters], doctorsAPI, {
	// 	select: response => {
	// 		return response.data.data
	// 	}
	// })

	// Sort By [Options]
	const sortByOptions = [
		{
			label: "Price (low first)",
			value: "Price (low first)",
		},
		{
			label: "Price (high first)",
			value: "Price (high first)",
		},
	];

	const [sortBy, setSortBy] = useState(sortByOptions[0].value);
	const queryClient = useQueryClient();

	const handleUpdateFilters = (newFilters) => {
		updateFilters(newFilters);
		// Trigger the React Query to refetch data with the updated filters
		queryClient.invalidateQueries(["doctors", search, newFilters]);
	};

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const can_make_consultation_call = urlParams.get(
			"can_make_consultation_call"
		);
		const can_make_video_consult = urlParams.get("can_make_video_consult");
		if (can_make_consultation_call === "true") {
			setCheckedCall(true);
			globalFilter({
				can_make_consultation_call: true,
			});
		}
		if (can_make_video_consult === "true") {
			setCheckedVideo(true);
			globalFilter({
				can_make_video_consult: true,
			});
		} else {
			setCheckedVideo(false);
			globalFilter({
				can_make_video_consult: false,
			});
		}
	}, [window.location.search]);

	return (
		<>
			<section className={styles.doctors_available}>
				<div className="container">
					<div className={styles.sec_head}>
						<div className={styles.left_side}>
							<h2>756 {Tr.tr("patient:FindADoctor:Doctors_Available")}</h2>
							<p>This is a subtitle</p>
						</div>
						<div className={styles.right_side}>
							{/* Consultation Call */}
							<SwitchInput
								checked={checkedCall}
								changeHandler={(e) => {
									setCheckedCall(e.value);
									globalFilter({
										can_make_consultation_call: e.value,
									});
									appendToURL("can_make_consultation_call", e.value);
								}}
								label={Tr.tr("patient:FindADoctor:Consultation_Call")}
							/>

							{/* Video Consult */}
							<SwitchInput
								checked={checkedVideo}
								changeHandler={(e) => {
									setCheckedVideo(e.value);
									globalFilter({
										can_make_video_consult: e.value,
									});
									appendToURL("can_make_video_consult", e.value);
								}}
								label={Tr.tr("patient:FindADoctor:Video_Consult")}
							/>

							{/* Sort By */}
							<div className={styles.sort_by}>
								<div className={styles.sort_lbl}>
									{Tr.tr("patient:FindADoctor:Sort_By")}
								</div>
								<SelectDropdown
									inputLabel=""
									inputName="sortBy"
									placeHolder={Tr.tr("patient:FindADoctor:Sort_By")}
									options={sortByOptions}
									inputVal={sortBy}
									changeHandler={(e) => {
										setSortBy(e.value);
										if (e.value === "Price (low first)") {
											// sort by fees
											const sortedDoctors = editedDoctors.sort(
												(a, b) => a.fees - b.fees
											);
											setEditedDoctors([...sortedDoctors]);
										} else if (e.value === "Price (high first)") {
											// sort descending
											const sortedDoctors = editedDoctors.sort(
												(a, b) => b.fees - a.fees
											);
											setEditedDoctors([...sortedDoctors]);
										}
									}}
									appendTo="self"
								/>
							</div>
						</div>
					</div>
					<div className="row">
						{/* Filter Doctors */}
						<div className="col-lg-3 mb-5 mb-lg-0">
							<FilterDoctors
								appendToURL={appendToURL}
								removeFromURL={removeFromURL}
								updateFilters={handleUpdateFilters}
								globalFilter={globalFilter}
							/>
						</div>

						{/* Doctors Results */}
						<div className="col-lg-9">
							<DoctorsResults
								doctors={editedDoctors}
								number_of_products={doctors?.number_of_products}
								number_of_pages={doctors?.number_of_pages}
								filters={filters}
								doctorsLoading={doctorsLoading}
							/>
						</div>
					</div>
				</div>
			</section>

			{/* Download Apps */}
			<DownloadApp />
		</>
	);
};

export default DoctorsAvailable;

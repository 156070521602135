import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { classNames } from "primereact/utils";
import { Button } from "primereact/button";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./EarningsDetails.module.scss";

const EarningsDetails = () => {
	// [Details] Table
	const details = [
		{
			id: 1,
			date: "November 8, 2023",
			invoiceNumber: "1343565",
			patient: "Ahmed Ali",
			serviceType: "In Clinic",
			grossEarnings: "200.00",
			tiryaaqFees: "-10.00",
			netEarnings: "190.00 SAR",
		},
		{
			id: 2,
			date: "November 8, 2023",
			invoiceNumber: "1343565",
			patient: "Aya Ibrahim",
			serviceType: "In Clinic",
			grossEarnings: "200.00",
			tiryaaqFees: "-10.00",
			netEarnings: "190.00 SAR",
		},
		{
			id: 3,
			date: "November 8, 2023",
			invoiceNumber: "1343565",
			patient: "Abdel Rahman Magdy",
			serviceType: "Video Call",
			grossEarnings: "200.00",
			tiryaaqFees: "-10.00",
			netEarnings: "190.00 SAR",
		},
		{
			id: 4,
			date: "November 8, 2023",
			invoiceNumber: "1343565",
			patient: "Mohamed Ibrahim",
			serviceType: "In Clinic",
			grossEarnings: "200.00",
			tiryaaqFees: "-10.00",
			netEarnings: "190.00 SAR",
		},
		{
			id: 5,
			date: "November 8, 2023",
			invoiceNumber: "1343565",
			patient: "Yasmine Ashraf",
			serviceType: "In Clinic",
			grossEarnings: "200.00",
			tiryaaqFees: "-10.00",
			netEarnings: "190.00 SAR",
		},
		{
			id: 6,
			date: "November 8, 2023",
			invoiceNumber: "1343565",
			patient: "Mostafa El-Sayed",
			serviceType: "Video Call",
			grossEarnings: "200.00",
			tiryaaqFees: "-10.00",
			netEarnings: "190.00 SAR",
		},
		{
			id: 7,
			date: "November 8, 2023",
			invoiceNumber: "1343565",
			patient: "Khulood Ali",
			serviceType: "In Clinic",
			grossEarnings: "200.00",
			tiryaaqFees: "-10.00",
			netEarnings: "190.00 SAR",
		},
		{
			id: 8,
			date: "November 8, 2023",
			invoiceNumber: "1343565",
			patient: "Ibrahim Magdy",
			serviceType: "Video Call",
			grossEarnings: "200.00",
			tiryaaqFees: "-10.00",
			netEarnings: "190.00 SAR",
		},
		{
			id: 9,
			date: "November 8, 2023",
			invoiceNumber: "1343565",
			patient: "Adel Mostafa",
			serviceType: "In Clinic",
			grossEarnings: "200.00",
			tiryaaqFees: "-10.00",
			netEarnings: "190.00 SAR",
		},
		{
			id: 10,
			date: "November 8, 2023",
			invoiceNumber: "1343565",
			patient: "Hadia Magdy",
			serviceType: "In Clinic",
			grossEarnings: "200.00",
			tiryaaqFees: "-10.00",
			netEarnings: "190.00 SAR",
		},
	];

	// Custom Table Paginator
	const customPaginatorTemplate = {
		layout: "PrevPageLink PageLinks NextPageLink",
		PrevPageLink: (options) => {
			return (
				<Button
					type="button"
					className={classNames(options.className, "border-round")}
					onClick={options.onClick}
					disabled={options.disabled}>
					<span>{Tr.tr("basic:Previous")}</span>
				</Button>
			);
		},
		NextPageLink: (options) => {
			return (
				<Button
					type="button"
					className={classNames(options.className, "border-round")}
					onClick={options.onClick}
					disabled={options.disabled}>
					<span>{Tr.tr("basic:Next")}</span>
				</Button>
			);
		},
		PageLinks: (options) => {
			if (
				(options.view.startPage === options.page &&
					options.view.startPage !== 0) ||
				(options.view.endPage === options.page &&
					options.page + 1 !== options.totalPages)
			) {
				const className = classNames(options.className, {
					"p-disabled": true,
				});

				return (
					<span className={className} style={{ userSelect: "none" }}>
						...
					</span>
				);
			}

			return (
				<Button
					type="button"
					className={options.className}
					onClick={options.onClick}>
					<span>{options.page + 1}</span>
				</Button>
			);
		},
	};

	return (
		<section className={styles.details_sec}>
			<h2>{Tr.tr("doctor:Reports:Details")}</h2>

			<DataTable
				className={styles.table_holder}
				value={details}
				paginator
				paginatorTemplate={customPaginatorTemplate}
				rows={10}
				totalRecords={details.length}
				tableStyle={{ minWidth: "100%" }}
				paginatorClassName="p_pagination">
				<Column field="id" header={Tr.tr("doctor:Reports:ID")}></Column>
				<Column field="date" header={Tr.tr("doctor:Reports:Date")}></Column>
				<Column
					field="invoiceNumber"
					header={Tr.tr("doctor:Reports:Invoice_Number")}
					className="secondColor"></Column>
				<Column
					field="patient"
					header={Tr.tr("doctor:Reports:Patient")}
					className="secondColor"></Column>
				<Column
					field="serviceType"
					header={Tr.tr("doctor:Reports:Service_Type")}></Column>
				<Column
					field="grossEarnings"
					header={Tr.tr("doctor:Reports:Gross_Earnings")}></Column>
				<Column
					field="tiryaaqFees"
					header={Tr.tr("doctor:Reports:Tiryaaq_Fees")}></Column>
				<Column
					field="netEarnings"
					header={Tr.tr("doctor:Reports:Net_Earnings")}></Column>
			</DataTable>
		</section>
	);
};

export default EarningsDetails;

import { useEffect, useState } from "react";
import { Link, useNavigate, useParams, useRoutes } from "react-router-dom";
import { Button } from "primereact/button";
import { useMutation } from "react-query";
import toast from "react-hot-toast";

// Components
import { OtpInputs } from "components/Common/Form/Inputs";
import GradientButton from "components/Common/UI/GradientButton/GradientButton";

// Api
import { activeAPI, forgetPasswordAPI, sendVerificationCodeAPI, updateMeApi, verifyForgotPasswordCode } from "api/auth";

// Cookies
import Cookies from "js-cookie";

// Transaltion
import { Tr } from "utils/i18n";

// Styles
import styles from "./OtpVerification.module.scss";

const OtpVerification = ({updatePhoneNumber}) => {
	const [otp, setOtp] = useState("");
	const navigate = useNavigate();
	// const route = useRoutes()
	const {phone} = useParams();
	const { mutate, isLoading, isError } = useMutation(verifyForgotPasswordCode, {
		onSuccess: async (data) => {
			console.log(data);
			const { token } = data.data;
			await Cookies.set("token", token, { expires: 1 });

			navigate("/patient/reset-password");
		},
		onError: (error) => {
			toast.error(error.response.data.message);
		},
		
	});

	const { mutate: sendVerificationCode } = useMutation(sendVerificationCodeAPI);
	const { mutate: verifyCode } = useMutation(activeAPI, {
		onSuccess: async (data) => {
		
		},
	});

	
	useEffect(() => {
			const sendCode = async () => {
			await sendVerificationCode(phone);
		};
		if (phone) {
			sendCode();
		}
	}, []);

	const submitCode = () => {
		verifyCode({ phone: phone , code: otp });
	};

	const { mutate: updateProfile, isLoading:isLoadingUpdatePhone } = useMutation(updateMeApi, {
		onSuccess: (data) => {
			toast.success(Tr.tr("basic:Profile_Updated_Successfully"));
			navigate(`/patient/my-profile/account-settings`)

		},
		onError: (error) => {
			console.log(error);
			toast.error(error?.response?.data?.message);
		},
	});

	useEffect(() => {
		const sendCode = async () => {
			console.log({ phone, code: otp });
			await mutate({ phone, code: otp });
		};
		console.log(phone);
		if (phone && otp.length === 4) {
			updatePhoneNumber ?updateProfile({phone })  : sendCode();
		}

	}, [otp]);

	// Number Of Digits [used in translation]
	const Digits = 4;

	return (
		<div className={styles.log_P}>
			<div className="container">
				<div className={styles.log_P_content}>
					<div className={styles.auth_header}>
						<Link to="/" className={styles.logo}>
							<img src="/img/logo.svg" alt="" />
						</Link>
					</div>
					<div className={styles.content_holder}>
						<div className={styles.card_box}>
							<div className={styles.f_txt}>
								<h3>{Tr.tr("basic:Enter_Digit_Code", { Digits })}</h3>
								<p>{Tr.tr("basic:Check_Phone_Number")}</p>
							</div>
							<div className={styles.phone_num}>
								<div className={styles.phone_lbl}>
									{Tr.tr("basic:Phone_Number")}
								</div>
								<div className={styles.num}>{phone}</div>
							</div>
							<form className={styles.form_holder}>
								<div className={styles.inputs_wrapper}>
									<div className="row">
										{/* OTP */}
										<div className="col-md-12">
											<OtpInputs
												inputLabel={Tr.tr("basic:Verification_Code")}
												inputVal={otp}
												otpHandler={setOtp}
												inputsNum={4}
												// inputError={
												// 	otp.length === 4 && otp !== "1234"
												// 		? "Invalid code. Please try again"
												// 		: ""
												// }
											/>
										</div>
									</div>
								</div>
								<GradientButton
									btn
									type="button"
									isLoading={isLoading||isLoadingUpdatePhone}
									label={Tr.tr("basic:Verify")}
									disabled={otp.length < 4}
									buttonClicked={async () => await mutate({ phone, code: otp })}
									classes="w-100"
								/>
							</form>
							<div className={styles.get_code}>
								<span className={styles.txt}>
									{Tr.tr("basic:Did_Not_Get_Code")}
								</span>
								<Button
									type="button"
									label={Tr.tr("basic:Resend")}
									className={styles.resend_btn}
								/>
							</div>
						</div>
					</div>
					<div className={styles.auth_footer}>
						<Link to="/terms">{Tr.tr("basic:Terms")}</Link>
						<Link to="/privacy">{Tr.tr("basic:Privacy")}</Link>
						<Link to="/help-center">{Tr.tr("basic:Help_Center")}</Link>
					</div>
				</div>
			</div>
		</div>
	);
};

export default OtpVerification;

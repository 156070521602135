import { en as basic } from "./namespaces/basic";
import { en as patient } from "./namespaces/patient";
import { en as doctor } from "./namespaces/doctor";

// eslint-disable-next-line
export default {
	basic,
	patient,
	doctor,
};

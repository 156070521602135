// Utils
import { GetStatus } from "utils/GetStatus";

// Styles
import styles from "./StatusBadge.module.scss";

const StatusBadge = ({ status, ...props }) => {
	return (
		<div
			className={`${styles.status_badge} ${GetStatus(status)} ${
				props.classes
			}`}>
			{status}
		</div>
	);
};

export default StatusBadge;

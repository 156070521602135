import { createContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";

// Components
import BookedDoctor from "components/Patient/BookedDoctor/BookedDoctor";
import AppointmentDate from "./components/AppointmentDate/AppointmentDate";
import MedicalInterpreter from "./components/MedicalInterpreter/MedicalInterpreter";
import PaymentMethod from "components/Patient/PaymentMethod/PaymentMethod";
import SummaryCard from "components/Patient/SummaryCard/SummaryCard";

// Api
import { getDoctorAPI } from "api/doctors";

// Styles
import styles from "./ConfirmAppointmentsBooking.module.scss";
import { Tr } from "utils/i18n";
import { toast } from 'react-hot-toast';
import { payAppointment } from "api/appointments";

// Context
export const InterpreterContext = createContext({
	checkedInterpreter: true,
	setCheckedInterpreter: () => { },
});

const ConfirmAppointmentsBooking = () => {
	const location = useLocation();
	const navigate = useNavigate()
	const { date, time, id, bookingWay } = useParams();
	//mutate booking Appointment
	const { mutate, isLoading } = useMutation(payAppointment, {
		onSuccess: (data) => {
			console.log(data, "booking appoiment data");
			navigate("/patient/my-profile/my-appointments")
		}
	})

	// get doctor info by id
	const { data: doctor, isLoading: doctorLoading } = useQuery(
		["doctor", id],
		getDoctorAPI,
		{
			select: (response) => {
				// console.log(response?.data);
				// setDoctorRouter(response.data.doctor_specialty_ids[0].id,  response.data.doctor_specialty_ids[0].name , response.data.state_id.name+', '+response.data.country_id.name , response.data.name)
				return response?.data;
			},
		}
	);

	console.log({
		doctor
	}, 'doctor')

	// Appointment Date
	const appointmentDate = {
		appointmentWay: "Your appointment at the clinic",
		// date: "Today August 17 - From 12:00 PM To 5:55 PM",
		date: `${date} - From ${time}`,
	};

	// Medical Interpreter
	const [checkedInterpreter, setCheckedInterpreter] = useState(true);
	const [paymentMethod, setPaymentMethod] = useState({
			label: Tr.tr("patient:DoctorDetails:Credit_Card_Visa"),
			value: "Credit Card / Visa",
		})
	const confirmBooking = (appointment_id) => {
		if (!paymentMethod) {
			toast.error("Please select your credit or debit card");
			return;
		}
		if (paymentMethod?.value === "Credit Card / Visa") {
			if (paymentMethod?.id) {
				mutate({
					"payment_method_id": paymentMethod?.id,
					"payment_type": "card",
					appointment_id: appointment_id
				})
				console.log(paymentMethod)
			} else {
				toast.error("Please select your credit or debit card");
			}
			return
		} else {
			mutate({
					"payment_method_id": paymentMethod?.id || null,
					"payment_type": "cash",
					appointment_id: appointment_id
				})
			toast.success("Pay at the clinic")
		}

		console.log('confirmBooking')
	}
	return (
		<div className={styles.confirm_appointments_booking}>
			<div className="container">
				<h1>{Tr.tr("patient:DoctorDetails:Confirm_Appointment_Booking")}</h1>

				<div className="row">
					<div className="col-md-12 col-xl-7 col-xxl-8 mb-3 mb-lg-0">
						{/* Doctor Info */}
						<BookedDoctor data={doctor} />

						{/* Appointment Date */}
						<AppointmentDate
							redirectTo={`/patient/find-a-doctor/${doctor?.id}/`}
							appointmentInfo={appointmentDate}
							appointmentWay={bookingWay}
						/>

						{/* Medical Interpreter [Appears if appointment is `Video Call`] */}
						{bookingWay === "videoCall" && (
							<InterpreterContext.Provider
								value={{ checkedInterpreter, setCheckedInterpreter }}>
								<MedicalInterpreter />
							</InterpreterContext.Provider>
						)}

						{/* Payment Method */}
						<PaymentMethod paymentMethod={paymentMethod} onChangePayment={(value) => { setPaymentMethod(value) }} />
					</div>
					<div className="col-md-12 col-xl-5 col-xxl-4">
						{/* Summary */}
						<SummaryCard isLoading={isLoading} confirmBooking={confirmBooking} data={doctor} state={bookingWay}>
							{bookingWay === "inClinic" && (
								<div className={styles.summary_row}>
									<div className={styles.txt}>
										{Tr.tr("patient:DoctorDetails:Examination_Fees_In_Clinic")}
									</div>
									<div className={styles.val}>
										{doctor?.clinic_fees} {Tr.tr("patient:DoctorDetails:SAR")}
									</div>
								</div>
							)}
							{bookingWay === "videoCall" && (
								<div className={styles.summary_row}>
									<div className={styles.txt}>
										{Tr.tr("patient:DoctorDetails:Video_Call_Fees")}
									</div>
									<div className={styles.val}>
										{doctor?.video_fees} {Tr.tr("patient:DoctorDetails:SAR")}
									</div>
								</div>
							)}
							{/* Appears if Interpreter Switch Btn is checked */}
							{/* {bookingWay === "videoCall" &&
								checkedInterpreter && (
									<div className={styles.summary_row}>
										<div className={styles.txt}>Medical interpreter</div>
										<div className={styles.val}>{doctor?.clinic_fees} SAR</div>
									</div>
								)} */}

						</SummaryCard>
					</div>
				</div>
			</div>

		</div>
	);
};

export default ConfirmAppointmentsBooking;

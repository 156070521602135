import { Outlet } from "react-router-dom";

const PlainLayout = () => {
    return (
        <div >
            <div >
                <Outlet />
            </div>
        </div>
    );
};

export default PlainLayout;

import { useState } from "react";
import { Paginator } from "primereact/paginator";
import { classNames } from "primereact/utils";
import { Button } from "primereact/button";

// Store
import usePaginationStore from "store/store";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./Pagination.module.scss";

const Pagination = ({ number_of_pages, number_of_products }) => {
	const { pageNumber, setPageNumber } = usePaginationStore();
	const ROWS = 20;

	const onPageChange = (e, index) => {
		setPageNumber(e.page + 1);
	};

	const template1 = {
		layout: "PrevPageLink PageLinks NextPageLink",
		PrevPageLink: (options) => {
			return (
				<Button
					type="button"
					className={classNames(options.className, "border-round")}
					onClick={options.onClick}
					disabled={options.disabled}>
					<span>{Tr.tr("basic:Previous")}</span>
				</Button>
			);
		},
		NextPageLink: (options) => {
			return (
				<Button
					type="button"
					className={classNames(options.className, "border-round")}
					onClick={options.onClick}
					disabled={options.disabled}>
					<span>{Tr.tr("basic:Next")}</span>
				</Button>
			);
		},
		PageLinks: (options) => {
			if (
				(options.view.startPage === options.page &&
					options.view.startPage !== 0) ||
				(options.view.endPage === options.page &&
					options.page + 1 !== options.totalPages)
			) {
				const className = classNames(options.className, {
					"p-disabled": true,
				});

				return (
					<span className={className} style={{ userSelect: "none" }}>
						...
					</span>
				);
			}

			return (
				<Button
					type="button"
					className={options.className}
					onClick={options.onClick}>
					<span>{options.page + 1}</span>
				</Button>
			);
		},
	};

	return (
		<div className={styles.pagination}>
			<Paginator
				template={template1}
				// first={pageNumber * 10}
				first={(pageNumber - 1) * ROWS}
				rows={ROWS}
				totalRecords={number_of_products}
				onPageChange={(e) => onPageChange(e, 0)}
			/>
		</div>
	);
};

export default Pagination;

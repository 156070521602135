import { Button } from "primereact/button";

// Components
import Rate from "components/Common/UI/Rate/Rate";
import GradientButton from "components/Common/UI/GradientButton/GradientButton";

// Transaltion
import { Tr } from "utils/i18n";

// Styles
import styles from "./DoctorGridCard.module.scss";

const DoctorGridCard = ({ data, ...props }) => {
	return (
		<div className={styles.doctorGrid_card}>
			<div className={styles.doctor_img}>
				<img src={data.image} alt="" />
				<Button
					icon={data.isFav ? "pi pi-heart-fill" : "pi pi-heart"}
					className={styles.fav_btn}
					onClick={props.isFavHandler}
				/>
			</div>
			<div className={styles.card_body}>
				<div className={styles.top}>
					<div className={styles.name_speciality}>
						<div className={styles.name}>{data.name}</div>
						<div className={styles.speciality}>{data.doctor_specialty_ids}</div>
					</div>
					<div className={styles.rate_reviews}>
						<Rate rateVal={data.rate} readOnly={true} />
						<div className={styles.reviews}>
							{data.reviews} {Tr.tr("patient:DoctorDetails:Reviews")}
						</div>
					</div>
				</div>
				<div className={styles.info_list}>
					<div className={styles.info_row}>
						<img src="/img/place-icon.svg" alt="" />
						<span>{data.location}</span>
					</div>
					<div className={styles.info_row}>
						<img src="/img/time.svg" alt="" />
						<span>Waiting Time : {data.waitingTime}</span>
					</div>
					<div className={styles.info_row}>
						<img src="/img/dollar.svg" alt="" />
						<span>Fees: {data.clinic_fees}</span>
					</div>
				</div>
				<div className={styles.card_btns}>
					<GradientButton
						btn
						type="button"
						label={Tr.tr("patient:DoctorDetails:Book_Now")}
						classes="w-100"
					/>
					<GradientButton
						label={Tr.tr("basic:View_Profile")}
						outlined
						redirectTo={`/patient/find-a-doctor/${data.id}`}
						classes="w-100"
					/>
				</div>
			</div>
		</div>
	);
};

export default DoctorGridCard;

import { useContext, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { Button } from "primereact/button";

// Tooltip
import { Tooltip } from "react-tooltip";

// Context
import { SidebarContext } from "context/DoctorSidebarContext";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./DoctorSideBar.module.scss";

const DoctorSideBar = () => {
	const { smallSize, setSmallSize } = useContext(SidebarContext);

	useEffect(() => {
		const handleResize = () => {
			setSmallSize(window.innerWidth < 1025);
		};

		// Add event listener for resize
		window.addEventListener("resize", handleResize);
		window.addEventListener("load", handleResize);

		// Remove event listener on component unmount
		return () => {
			window.removeEventListener("resize", handleResize);
			window.removeEventListener("load", handleResize);
		};
	}, [setSmallSize]);

	return (
		<div
			className={`${styles.doctor_sideBar} ${
				smallSize ? styles.small_size : ""
			}`}>
			<div className={styles.logo_holder}>
				<Link to="/doctor/dashboard" className={styles.logo}>
					<img src="/img/logo.svg" alt="" className={styles.original_logo} />
					<img src="/img/small-logo.svg" alt="" className={styles.small_logo} />
				</Link>
			</div>
			<div className={styles.sidebar_links}>
				<div className={styles.links_holder}>
					<NavLink
						to="/doctor/dashboard"
						className={styles.sidebar_link}
						id="dashboard">
						<div className={styles.left}>
							<img src="/img/home-gray.svg" alt="" className={styles.gray} />
							<img
								src="/img/home-gradient.svg"
								alt=""
								className={styles.gradient}
							/>
							<span>{Tr.tr("basic:Dashboard")}</span>
						</div>
					</NavLink>
					{smallSize && (
						<Tooltip
							anchorSelect="#dashboard"
							place="left"
							className={styles.side_tooltip}>
							{Tr.tr("basic:Dashboard")}
						</Tooltip>
					)}

					<NavLink
						to="/doctor/appointments"
						className={styles.sidebar_link}
						id="appointments">
						<div className={styles.left}>
							<img
								src="/img/calendar-gray.svg"
								alt=""
								className={styles.gray}
							/>
							<img
								src="/img/calendar-gradient.svg"
								alt=""
								className={styles.gradient}
							/>
							<span>{Tr.tr("basic:Appointments")}</span>
						</div>
					</NavLink>
					{smallSize && (
						<Tooltip
							anchorSelect="#appointments"
							place="left"
							className={styles.side_tooltip}>
							{Tr.tr("basic:Appointments")}
						</Tooltip>
					)}

					<NavLink
						to="/doctor/patients"
						className={styles.sidebar_link}
						id="patients">
						<div className={styles.left}>
							<img src="/img/users-gray.svg" alt="" className={styles.gray} />
							<img
								src="/img/users-gradient.svg"
								alt=""
								className={styles.gradient}
							/>
							<span>{Tr.tr("basic:Patients")}</span>
						</div>
					</NavLink>
					{smallSize && (
						<Tooltip
							anchorSelect="#patients"
							place="left"
							className={styles.side_tooltip}>
							{Tr.tr("basic:Patients")}
						</Tooltip>
					)}

					<NavLink
						to="/doctor/messages"
						className={styles.sidebar_link}
						id="messages">
						<div className={styles.left}>
							<img
								src="/img/messages-gray.svg"
								alt=""
								className={styles.gray}
							/>
							<img
								src="/img/messages-gradient.svg"
								alt=""
								className={styles.gradient}
							/>
							<span>{Tr.tr("basic:Messages")}</span>
						</div>
						<div className={styles.count}>3</div>
					</NavLink>
					{smallSize && (
						<Tooltip
							anchorSelect="#messages"
							place="left"
							className={styles.side_tooltip}>
							{Tr.tr("basic:Messages")}
						</Tooltip>
					)}

					<NavLink
						to="/doctor/question-answer"
						className={styles.sidebar_link}
						id="questionAnswer">
						<div className={styles.left}>
							<img
								src="/img/messages-question-gray.svg"
								alt=""
								className={styles.gray}
							/>
							<img
								src="/img/messages-question-gradient.svg"
								alt=""
								className={styles.gradient}
							/>
							<span>{Tr.tr("basic:Question_Answer")}</span>
						</div>
					</NavLink>
					{smallSize && (
						<Tooltip
							anchorSelect="#questionAnswer"
							place="left"
							className={styles.side_tooltip}>
							{Tr.tr("basic:Question_Answer")}
						</Tooltip>
					)}

					<NavLink
						to="/doctor/reports"
						className={styles.sidebar_link}
						id="reports">
						<div className={styles.left}>
							<img src="/img/stats-gray.svg" alt="" className={styles.gray} />
							<img
								src="/img/stats-gradient.svg"
								alt=""
								className={styles.gradient}
							/>
							<span>{Tr.tr("basic:Reports")}</span>
						</div>
					</NavLink>
					{smallSize && (
						<Tooltip
							anchorSelect="#reports"
							place="left"
							className={styles.side_tooltip}>
							{Tr.tr("basic:Reports")}
						</Tooltip>
					)}
				</div>
				<div className={styles.links_holder}>
					<NavLink
						to="/doctor/settings"
						className={styles.sidebar_link}
						id="settings">
						<div className={styles.left}>
							<img
								src="/img/settings-gray.svg"
								alt=""
								className={styles.gray}
							/>
							<img
								src="/img/settings-gradient.svg"
								alt=""
								className={styles.gradient}
							/>
							<span>{Tr.tr("basic:Settings")}</span>
						</div>
					</NavLink>
					{smallSize && (
						<Tooltip
							anchorSelect="#settings"
							place="left"
							className={styles.side_tooltip}>
							{Tr.tr("basic:Settings")}
						</Tooltip>
					)}
				</div>
			</div>
			<div className={styles.sideBar_resize_btn}>
				<Button
					type="button"
					className={styles.collapse_btn}
					onClick={() => setSmallSize(!smallSize)}>
					<img src="/img/chevron-left-black.svg" alt="" />
				</Button>
			</div>
		</div>
	);
};

export default DoctorSideBar;

import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "primereact/button";

// Components
import LanguageBtn from "components/Common/UI/LanguageBtn/LanguageBtn";
import { OtpInputs } from "components/Common/Form/Inputs";
import GradientButton from "components/Common/UI/GradientButton/GradientButton";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./DoctorOtpVerification.module.scss";

const DoctorOtpVerification = () => {
	const [otp, setOtp] = useState("");

	const navigate = useNavigate();

	// Number Of Digits [used in translation]
	const Digits = 4;

	return (
		<div className={styles.log_P}>
			<LanguageBtn classes={styles.auth_lang} />

			<div className="container">
				<div className={styles.log_P_content}>
					<div className={styles.auth_header}>
						<Link to="/doctor/login" className={styles.logo}>
							<img src="/img/logo.svg" alt="" />
						</Link>
					</div>
					<div className={styles.content_holder}>
						<div className={styles.card_box}>
							<div className={styles.f_txt}>
								<h3>{Tr.tr("basic:Enter_Digit_Code", { Digits })}</h3>
								<p>{Tr.tr("basic:Check_Phone_Number")}</p>
							</div>
							<div className={styles.phone_num}>
								<div className={styles.phone_lbl}>
									{Tr.tr("basic:Phone_Number")}
								</div>
								<div className={styles.num}>+9665012345678</div>
							</div>
							<form className={styles.form_holder}>
								<div className={styles.inputs_wrapper}>
									<div className="row">
										{/* OTP */}
										<div className="col-md-12">
											<OtpInputs
												inputLabel={Tr.tr("basic:Verification_Code")}
												inputVal={otp}
												otpHandler={setOtp}
												inputsNum={4}
												inputError={
													otp.length === 4 && otp !== "1234"
														? "Invalid code. Please try again"
														: ""
												}
											/>
										</div>
									</div>
								</div>
								<GradientButton
									btn
									type="submit"
									label={Tr.tr("basic:Verify")}
									disabled={otp.length < 4}
									buttonClicked={() => navigate("/doctor/reset-password")}
									classes="w-100"
								/>
							</form>
							<div className={styles.get_code}>
								<span className={styles.txt}>
									{Tr.tr("basic:Did_Not_Get_Code")}
								</span>
								<Button
									type="button"
									label={Tr.tr("basic:Resend")}
									className={styles.resend_btn}
								/>
							</div>
						</div>
					</div>
					<div className={styles.auth_footer}>
						<Link to="/terms">{Tr.tr("basic:Terms")}</Link>
						<Link to="/privacy">{Tr.tr("basic:Privacy")}</Link>
						<Link to="/help-center">{Tr.tr("basic:Help_Center")}</Link>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DoctorOtpVerification;

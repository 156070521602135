import { useContext, useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Menu } from "primereact/menu";
import { Button } from "primereact/button";

// Components
import TitleWithLine from "components/Common/UI/TitleWithLine/TitleWithLine";
import PageHead from "components/Patient/PageHead/PageHead";
import GrayButton from "components/Common/UI/GrayButton/GrayButton";
import StatusBadge from "components/Common/UI/StatusBadge/StatusBadge";
import { RadioBtn } from "components/Common/Form/Inputs";
import AddDoctorModal from "components/Common/UI/AddDoctorModal/AddDoctorModal";

// Context
import { SidebarContext } from "context/DoctorSidebarContext";

// Translation
import { useTranslation } from "react-i18next";
import { Tr } from "utils/i18n";

// Styles
import styles from "./SettingsPracticeStaff.module.scss";

const SettingsPracticeStaff = () => {
	const { i18n } = useTranslation();
	const currLang = i18n.language;

	const { setSmallSize } = useContext(SidebarContext);

	const menu = useRef(null);

	const menuStatus = useRef(null);

	// Doctor Modal Visible
	const [doctorModalVisible, setDoctorModalVisible] = useState(false);

	// Selected Doctor Row
	const [selectedDoctor, setSelectedDoctor] = useState(null);

	// Ellipses Menu
	const items = [
		{
			template: (item, options) => {
				return (
					<>
						<Menu
							model={statusItems}
							popup
							ref={menuStatus}
							// popupAlignment={currLang === "ar" ? "left" : "right"}
							className={`${styles.action_menu} ${styles.action_menu_status}`}
							appendTo={"self"}
						/>
						<Button
							onClick={(e) => {
								// options.onClick(e);
								menuStatus.current.toggle(e);
							}}
							className={`${options.className} status_item ${
								doctors[selectedDoctor?.id - 1]?.status.toLowerCase() ===
								"active"
									? "active"
									: doctors[selectedDoctor?.id - 1]?.status.toLowerCase() ===
									  "inactive"
									? "inactive"
									: ""
							}`}>
							<div className="left">
								<span className="bullet">
									<span className="bullet_small"></span>
								</span>
								<span className="text">{Tr.tr("doctor:Settings:Status")}</span>
							</div>
							<div className="status_box">
								{doctors[selectedDoctor?.id - 1]?.status}
							</div>
						</Button>
					</>
				);
			},
		},
		{
			template: (item, options) => {
				return (
					<Button
						onClick={(e) => options.onClick(e)}
						className={`${options.className}`}>
						<img src="/img/edit-gray.svg" alt="" />
						<span>{Tr.tr("doctor:Settings:Edit")}</span>
					</Button>
				);
			},
		},
		{
			template: (item, options) => {
				return (
					<Button
						onClick={(e) => options.onClick(e)}
						className={`${options.className}`}>
						<img src="/img/delete-gray.svg" alt="" />
						<span>{Tr.tr("doctor:Settings:Delete")}</span>
					</Button>
				);
			},
		},
	];

	const [status, setStatus] = useState("active");

	// Ellipses Status Menu
	const statusItems = [
		{
			template: (item, options) => {
				return (
					<div className={`${options.className} ${styles.status_row}`}>
						<RadioBtn
							name={`status-${selectedDoctor?.id}`}
							label={Tr.tr("doctor:Settings:Active")}
							value={"active"}
							handleChange={(e) => {
								setStatus(e.value);
								changeHanlder(e.value);
							}}
							checked={
								doctors[selectedDoctor?.id - 1]?.status.toLowerCase() ===
								"active"
							}
						/>
						<span className={`${styles.status_bullet} ${styles.active}`}></span>
					</div>
				);
			},
		},
		{
			template: (item, options) => {
				return (
					<div className={`${options.className} ${styles.status_row}`}>
						<RadioBtn
							name={`status-${selectedDoctor?.id}`}
							label={Tr.tr("doctor:Settings:Inactive")}
							value={"inactive"}
							handleChange={(e) => {
								setStatus(e.value);
								changeHanlder(e.value);
							}}
							checked={
								doctors[selectedDoctor?.id - 1]?.status.toLowerCase() ===
								"inactive"
							}
						/>
						<span
							className={`${styles.status_bullet} ${styles.inactive}`}></span>
					</div>
				);
			},
		},
	];

	useEffect(() => {
		setSmallSize(true);
	}, [setSmallSize]);

	// Doctors [Table]
	const [doctors, setDoctors] = useState([
		{
			id: 1,
			image: "/img/doctor-image.svg",
			name: "Dr. Amr Mostfa (You)",
			email: "your@mail.com",
			specialty: "Professor of Pediatrics",
			mobile: "Text",
			emailAddress: "Text",
			status: "Active",
		},
		{
			id: 2,
			image: "/img/doctor-image.svg",
			name: "Dr. Amr Mostfa (You)",
			email: "your@mail.com",
			specialty: "Professor of Pediatrics",
			mobile: "Text",
			emailAddress: "Text",
			status: "Active",
		},
	]);

	// On Status Change
	const changeHanlder = (newValue) => {
		// Create a new array with the same objects as items
		const updatedDoctors = doctors.map((doctor) => {
			// If the item id matches, create a new object with the updated value
			if (doctor.id === selectedDoctor.id) {
				return { ...doctor, status: newValue }; // Updated object
			}
			return doctor; // Return the unchanged object
		});

		// Set the state with the new array
		setDoctors(updatedDoctors);
	};

	// [Table] Doctor Cell Template
	const doctorBodyTemplate = (rowData) => {
		return (
			<div className={styles.doctor_info}>
				<div className={styles.doctor_img}>
					<img src={rowData.image} alt="" />
				</div>
				<div className={styles.data}>
					<h5>{rowData.name}</h5>
					<p>{rowData.email}</p>
				</div>
			</div>
		);
	};

	// [Table] Status Cell Template
	const statusBodyTemplate = (rowData) => {
		return (
			<StatusBadge
				status={rowData.status}
				classes={`lineH-normal ${rowData.status}`}
			/>
		);
	};

	// [Table] Action Cell Template
	const actionBodyTemplate = (rowData) => {
		return (
			<div className={styles.table_action}>
				<Menu
					model={items}
					popup
					ref={menu}
					id="popup_menu"
					popupAlignment={currLang === "ar" ? "left" : "right"}
					className={styles.action_menu}
				/>
				<Button
					icon="pi pi-ellipsis-v"
					onClick={(event) => {
						menu.current.toggle(event);
						setSelectedDoctor(rowData);
					}}
					aria-controls="popup_menu"
					aria-haspopup
					className={styles.action_btn}
				/>
			</div>
		);
	};

	return (
		<>
			<div className={styles.settings_practiceStaff}>
				<TitleWithLine
					title={Tr.tr("doctor:Settings:Practice_Staff")}
					mb="32px"
				/>

				{/* Add Doctors */}
				<div className={styles.settings_practiceStaff_holder}>
					<PageHead
						title={Tr.tr("doctor:Settings:Add_Doctors")}
						subTitle={Tr.tr(
							"doctor:Settings:Add_Doctors_Practice_In_Your_Clinic"
						)}
						mb="32px"
						titleSize="16px">
						<GrayButton
							btn
							type="button"
							label={Tr.tr("doctor:Settings:Add_A_Doctor")}
							withIconImg
							icon="/img/add-black.svg"
							leftPos
							outlined
							classes="p712"
							buttonClicked={() => {
								setDoctorModalVisible(true);
							}}
						/>
					</PageHead>

					{/* Doctors Table */}
					<DataTable
						className={styles.table_holder}
						value={doctors}
						tableStyle={{ minWidth: "100%" }}>
						<Column
							header={Tr.tr("doctor:Settings:Doctor")}
							body={doctorBodyTemplate}></Column>
						<Column
							field="specialty"
							header={Tr.tr("doctor:Settings:Specialty")}></Column>
						<Column
							field="mobile"
							header={Tr.tr("doctor:Settings:Mobile_Number")}></Column>
						<Column
							field="emailAddress"
							header={Tr.tr("doctor:Settings:Email_Address")}></Column>
						<Column
							header={Tr.tr("doctor:Settings:Status")}
							body={statusBodyTemplate}></Column>
						<Column body={actionBodyTemplate}></Column>
					</DataTable>
				</div>

				{/* Add Staff */}
				<div className={styles.settings_practiceStaff_holder}>
					<PageHead
						title={Tr.tr("doctor:Settings:Add_Staff")}
						subTitle={Tr.tr(
							"doctor:Settings:Add_Your_Practice_Staff_From_Here"
						)}
						mb="32px">
						<GrayButton
							btn
							type="button"
							label={Tr.tr("doctor:Settings:Add_Staff")}
							withIconImg
							icon="/img/add-black.svg"
							leftPos
							outlined
							classes="p712"
							buttonClicked={() => {
								// setStaffModalVisible(true);
							}}
						/>
					</PageHead>
				</div>
			</div>

			{/* Add Doctor Modal */}
			<AddDoctorModal
				visible={doctorModalVisible}
				hideHandler={() => setDoctorModalVisible(false)}
			/>
		</>
	);
};

export default SettingsPracticeStaff;

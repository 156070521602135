// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./HeroSection.module.scss";

const HeroSection = () => {
	return (
		<section className={styles.hero_section}>
			<div className="container">
				<div className="row">
					<div className="col-lg-6">
						<div className={styles.hero_txt}>
							<h1>{Tr.tr("basic:Feel_Better_Finding_Healthcare")}</h1>
							<div className={styles.line_divider}></div>
							<p>{Tr.tr("patient:Home:hero:desc")}</p>
						</div>
					</div>
					<div className="col-lg-6">
						<div className={styles.hero_img}>
							<img src="/img/hero-img.png" alt="" />
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default HeroSection;

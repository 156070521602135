import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";

// Components
import LanguageBtn from "components/Common/UI/LanguageBtn/LanguageBtn";
import { PasswordInput } from "components/Common/Form/Inputs";
import GradientButton from "components/Common/UI/GradientButton/GradientButton";

// Utils
import { getFormErrorMessage } from "utils/FormField";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./DoctorResetPassword.module.scss";

const DoctorResetPassword = () => {
	const navigate = useNavigate();

	const formik = useFormik({
		initialValues: {
			newPassword: "",
			confirmPassword: "",
		},
		validate: (data) => {
			let errors = {};

			if (!data.newPassword) {
				errors.newPassword = "New Password is required.";
			}

			if (!data.confirmPassword) {
				errors.confirmPassword = "Confirm Password is required.";
			}

			if (data.confirmPassword !== data.newPassword) {
				errors.confirmPassword = "This passwords do not match";
			}

			return errors;
		},
		onSubmit: (data, { setSubmitting }) => {
			console.log("submitted");
			setTimeout(() => {
				setSubmitting(false);
				navigate("/doctor/login");
				formik.resetForm();
			}, 2000);
		},
	});

	return (
		<div className={styles.log_P}>
			<LanguageBtn classes={styles.auth_lang} />

			<div className="container">
				<div className={styles.log_P_content}>
					<div className={styles.auth_header}>
						<Link to="/doctor/login" className={styles.logo}>
							<img src="/img/logo.svg" alt="" />
						</Link>
					</div>
					<div className={styles.content_holder}>
						<div className={styles.card_box}>
							<div className={styles.f_txt}>
								<h3>{Tr.tr("basic:Reset_Password")}</h3>
								<p>{Tr.tr("basic:Protect_Your_Account")}</p>
							</div>
							<form
								className={styles.form_holder}
								onSubmit={formik.handleSubmit}>
								<div className={styles.inputs_wrapper}>
									<div className="row">
										{/* New Password */}
										<div className="col-md-12 m-b-20">
											<PasswordInput
												inputLabel={Tr.tr("basic:New_Password")}
												inputName="newPassword"
												placeHolder={Tr.tr("basic:Password")}
												inputVal={formik.values.newPassword}
												changeHandler={formik.handleChange}
												inputError={getFormErrorMessage("newPassword", formik)}
												required
											/>
										</div>

										{/* Confirm Password */}
										<div className="col-md-12">
											<PasswordInput
												inputLabel={Tr.tr("basic:Confirm_Password")}
												inputName="confirmPassword"
												placeHolder={Tr.tr("basic:Password")}
												inputVal={formik.values.confirmPassword}
												changeHandler={formik.handleChange}
												inputError={getFormErrorMessage(
													"confirmPassword",
													formik
												)}
												required
											/>
										</div>
									</div>
								</div>
								<GradientButton
									btn
									type="submit"
									label={Tr.tr("basic:Reset_Password")}
									disabled={
										!(formik.isValid && formik.dirty) || formik.isSubmitting
									}
									isLoading={formik.isSubmitting}
									classes="w-100"
								/>
							</form>
						</div>
					</div>
					<div className={styles.auth_footer}>
						<Link to="/terms">{Tr.tr("basic:Terms")}</Link>
						<Link to="/privacy">{Tr.tr("basic:Privacy")}</Link>
						<Link to="/help-center">{Tr.tr("basic:Help_Center")}</Link>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DoctorResetPassword;

import { Calendar } from "primereact/calendar";

// Components
import ErrorUI from "components/Common/UI/ErrorUI/ErrorUI";

// Styles
import styles from "./TimePicker.module.scss";
import { useEffect } from "react";

export function TimePicker({ ...props }) {
	// useEffect( () =>{
	// 	console.log("Data value ==> ",props)
	// },[props] )
	return (
		<div className="input_holder">
			<div className={`${styles.field_holder} ${props.classes}`}>
				{props.inputLabel && (
					<h6 className={props.required ? "required" : ""}>
						{props.inputLabel}
					</h6>
				)}
				<div className={styles.input_box_arrow}>
					<div
						className={`w-100 ${styles.input_box} ${
							props.inputError ? "p-invalid" : ""
						}`}
					>
						<Calendar
							name={props.inputName}
							value={props.inputVal}
							placeholder={props.placeHolder}
							onChange={props.changeHandler}
							className="w-100"
							appendTo={props.appendTo}
							timeOnly
							hourFormat="12"
							readOnlyInput
							disabled={props.disabled}
						/>
					</div>
					{props.downIcon ? (
						<img
							src="/img/arrow-drop-down-gray.svg"
							alt=""
							className={styles.time_icon}
						/>
					) : (
						<img
							src="/img/arrow-down-up.svg"
							alt=""
							className={styles.time_icon}
						/>
					)}
				</div>
			</div>
			{props.inputError && (
				<ErrorUI errorMsg={props.inputError} withBG={props.withBG} />
			)}
		</div>
	);
}

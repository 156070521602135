import { SelectButton } from "primereact/selectbutton";

// Styles
import styles from "./CustomSelectionButton.module.scss";

export function CustomSelectionButton({ ...props }) {
	return (
		<div className="input_holder">
			<div className={styles.field_holder}>
				<div className={`w-100 ${styles.input_box}`}>
					<SelectButton
						name={props.inputName}
						value={props.inputVal}
						onChange={props.changeHandler}
						optionLabel="value"
						options={props.justifyOptions}
						itemTemplate={props.justifyTemplate}
						unselectable={false}
						className={styles.custom_selectBtn}
					/>
				</div>
			</div>
		</div>
	);
}

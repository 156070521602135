import { useContext, useEffect } from "react";
import { useFormik } from "formik";

// Components
import TitleWithLine from "components/Common/UI/TitleWithLine/TitleWithLine";
import PageHead from "components/Patient/PageHead/PageHead";
import { SelectDropdown, TextInput } from "components/Common/Form/Inputs";
import GradientButton from "components/Common/UI/GradientButton/GradientButton";

// Context
import { SidebarContext } from "context/DoctorSidebarContext";

// Utils
import { getFormErrorMessage } from "utils/FormField";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./SettingsCommunication.module.scss";

const SettingsCommunication = () => {
	const { setSmallSize } = useContext(SidebarContext);

	// Gender
	const smsLanguages = [
		{
			label: "Arabic",
			value: "Arabic",
		},
		{
			label: "English",
			value: "English",
		},
	];

	useEffect(() => {
		setSmallSize(true);
	}, [setSmallSize]);

	// Formik
	const formik = useFormik({
		initialValues: {
			mobile: "",
			email: "",
			smsLanguage: smsLanguages[0].value,
			clinicName: "",
		},
		validate: (data) => {
			let errors = {};

			if (!data.mobile) {
				errors.mobile = "Mobile is required.";
			}

			if (!data.email) {
				errors.email = "Email Address is required.";
			}

			return errors;
		},
		onSubmit: (data, { setSubmitting }) => {
			console.log("submitted");
			setTimeout(() => {
				setSubmitting(false);
				formik.resetForm();
			}, 2000);
		},
	});

	return (
		<div className={styles.settings_communication}>
			<TitleWithLine title={Tr.tr("doctor:Settings:Communication")} mb="32px" />

			<PageHead
				title={Tr.tr("doctor:Settings:Patient_Communication_Preferences")}
				subTitle="Lorem ipsum dolor sit amet consectetur. Neque blandit"
				mb="24px"
				titleSize="16px"
			/>

			<form onSubmit={formik.handleSubmit}>
				<div className={styles.inputs_wrapper}>
					<div className="row">
						<div className="col-lg-7">
							<div className="row">
								<div className="col-md-12 mb-4">
									<TextInput
										inputLabel={Tr.tr("doctor:Settings:Mobile_Number")}
										inputName="mobile"
										placeHolder={Tr.tr("doctor:Settings:Enter_Mobile_Number")}
										inputVal={formik.values.mobile}
										changeHandler={formik.handleChange}
										inputError={getFormErrorMessage("mobile", formik)}
									/>
								</div>
								<div className="col-md-12 mb-4">
									<TextInput
										inputLabel={Tr.tr("doctor:Settings:Email_Address")}
										inputName="email"
										placeHolder={Tr.tr("doctor:Settings:Enter_Email_Address")}
										inputVal={formik.values.email}
										changeHandler={formik.handleChange}
										inputError={getFormErrorMessage("email", formik)}
									/>
								</div>
								<div className="col-md-12 mb-4">
									<SelectDropdown
										inputLabel={Tr.tr("doctor:Settings:SMS_Language")}
										inputName="smsLanguage"
										placeHolder={Tr.tr("doctor:Settings:Select_SMS_Language")}
										options={smsLanguages}
										inputVal={formik.values.smsLanguage}
										changeHandler={(e) =>
											formik.setFieldValue("smsLanguage", e.value)
										}
									/>
								</div>
								<div className="col-md-12">
									<TextInput
										inputLabel={Tr.tr("doctor:Settings:Clinic_Name")}
										inputName="clinicName"
										placeHolder={Tr.tr("doctor:Settings:Enter_Clinic_Name")}
										inputVal={formik.values.clinicName}
										changeHandler={formik.handleChange}
										inputError={getFormErrorMessage("clinicName", formik)}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>

				<GradientButton
					btn
					type="submit"
					label={Tr.tr("doctor:Settings:Save_Changes")}
					classes="p712 lineH-24"
				/>
			</form>
		</div>
	);
};

export default SettingsCommunication;

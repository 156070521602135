// import Swiper core and required modules
import { Pagination } from "swiper/modules";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// Components
import WhiteButton from "components/Common/UI/WhiteButton/WhiteButton";

// Translation
import { useTranslation } from "react-i18next";
import { Tr } from "utils/i18n";

// Styles
import styles from "./FeelBetter.module.scss";

const FeelBetter = () => {
	const { i18n } = useTranslation();
	const currLang = i18n.language;

	// Sliders
	const silders = [
		{
			title: Tr.tr("basic:Feel_Better_Finding_Healthcare"),
			desc: "Lorem ipsum dolor sit amet consectetur. Neque blandit",
			image: "/img/feel-better.png",
		},
		{
			title: Tr.tr("basic:Feel_Better_Finding_Healthcare"),
			desc: "Lorem ipsum dolor sit amet consectetur. Neque blandit",
			image: "/img/feel-better.png",
		},
		{
			title: Tr.tr("basic:Feel_Better_Finding_Healthcare"),
			desc: "Lorem ipsum dolor sit amet consectetur. Neque blandit",
			image: "/img/feel-better.png",
		},
	];
	return (
		<section className={styles.feel_better}>
			<div className="container">
				<Swiper
					key={currLang} // To re-render this component with language change
					dir={currLang === "ar" ? "rtl" : "ltr"}
					// install Swiper modules
					modules={[Pagination]}
					spaceBetween={50}
					slidesPerView={1}
					pagination={{ el: ".swiper-custom-pagination", clickable: true }}
					// onSlideChange={() => console.log("slide change")}
					// onSwiper={(swiper) => console.log(swiper)}
				>
					{silders.map((slider, index) => (
						<SwiperSlide key={index}>
							<div className={styles.slider_box}>
								<div className="row">
									<div className="col-lg-7">
										<div className={styles.txt_side}>
											<div className={styles.title}>{slider.title}</div>
											<div className={styles.desc}>{slider.desc}</div>
											<WhiteButton label={Tr.tr("basic:Learn_More")} />
										</div>
									</div>
									<div className="col-lg-5">
										<div className={styles.slider_img}>
											<img src={slider.image} alt="" />
										</div>
									</div>
								</div>
							</div>
						</SwiperSlide>
					))}
				</Swiper>
				{/* pagination */}
				<div className="swiper-custom-pagination"></div>
			</div>
		</section>
	);
};

export default FeelBetter;

import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";

// Components
import { TextInput } from "components/Common/Form/Inputs";
import GradientButton from "components/Common/UI/GradientButton/GradientButton";
import LanguageBtn from "components/Common/UI/LanguageBtn/LanguageBtn";

// Utils
import { getFormErrorMessage } from "utils/FormField";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./DoctorForgetPassword.module.scss";

const DoctorForgetPassword = () => {
	const navigate = useNavigate();

	const formik = useFormik({
		initialValues: {
			email: "",
		},
		validate: (data) => {
			let errors = {};

			if (!data.email) {
				errors.email = "Email is required.";
			} else if (
				!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)
			) {
				errors.email = "Invalid email address. E.g. user@example.com";
			}

			return errors;
		},
		onSubmit: (data, { setSubmitting }) => {
			console.log("submitted");
			setTimeout(() => {
				setSubmitting(false);
				navigate("/doctor/otp-verification");
				formik.resetForm();
			}, 2000);
		},
	});

	return (
		<div className={styles.log_P}>
			<LanguageBtn classes={styles.auth_lang} />

			<div className="container">
				<div className={styles.log_P_content}>
					<div className={styles.auth_header}>
						<Link to="/doctor/login" className={styles.logo}>
							<img src="/img/logo.svg" alt="" />
						</Link>
					</div>
					<div className={styles.content_holder}>
						<div className={styles.card_box}>
							<Link to="/doctor/login" className={styles.back_btn}>
								<img src="/img/arrow-left-gradient.svg" alt="" />
								<span>{Tr.tr("basic:Back")}</span>
							</Link>
							<div className={styles.f_txt}>
								<h3>{Tr.tr("basic:Forgot_Password")}</h3>
								<p>{Tr.tr("basic:No_Problem_Provide_Email_Phone")}</p>
							</div>
							<form
								className={styles.form_holder}
								onSubmit={formik.handleSubmit}>
								<div className={styles.inputs_wrapper}>
									<div className="row">
										{/* Email */}
										<div className="col-md-12">
											<TextInput
												inputLabel={Tr.tr("basic:Email_Mobile_Number")}
												inputName="email"
												placeHolder={Tr.tr("basic:Email_Mobile_Number")}
												inputVal={formik.values.email}
												changeHandler={formik.handleChange}
												inputError={getFormErrorMessage("email", formik)}
												withBG
											/>
										</div>
									</div>
								</div>
								<GradientButton
									btn
									type="submit"
									label={Tr.tr("basic:Send_Code")}
									disabled={
										!(formik.isValid && formik.dirty) || formik.isSubmitting
									}
									isLoading={formik.isSubmitting}
									classes="w-100"
								/>
							</form>
							<Link to="/doctor/login" className={styles.back_to}>
								{Tr.tr("basic:Back_To_Login")}
							</Link>
						</div>
					</div>
					<div className={styles.auth_footer}>
						<Link to="/terms">{Tr.tr("basic:Terms")}</Link>
						<Link to="/privacy">{Tr.tr("basic:Privacy")}</Link>
						<Link to="/help-center">{Tr.tr("basic:Help_Center")}</Link>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DoctorForgetPassword;

import { useFormik } from "formik";

// Components
import DialogWrapper from "components/Patient/DialogWrapper/DialogWrapper";
import { CalendarInput, TextInput } from "components/Common/Form/Inputs";
import GradientButton from "components/Common/UI/GradientButton/GradientButton";
import WhiteBlackButton from "components/Common/UI/WhiteBlackButton/WhiteBlackButton";

// Utils
import { getFormErrorMessage } from "utils/FormField";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./AddPaymentModal.module.scss";
import creditCardSchema from "./creditCardSchema";
import { useMutation } from 'react-query';
import { addPayment } from "api/payment";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import toast from "react-hot-toast";


const AddPaymentModal = ({ ...props }) => {


	const { mutate, isLoading, } = useMutation(addPayment, {
		onError: (error) => {
			console.log({ error })

		},
		onSuccess: (res) => {
			const verification_url = res?.data?.verification_url
			window.location.href = verification_url;
			// toast.success("Card has been added")
			console.log({ verification_url })
		}
	})

	// Formik
	const formik = useFormik({
		initialValues: {
			cardNumber: "",
			expiryDate: "",
			cvv: "",
			cardHolder: "",
		},
		validationSchema: creditCardSchema,
		onSubmit: (data, { setSubmitting }) => {
			console.log("window.location.href >> ", window.location.href)
			mutate({
				"name": data?.cardHolder,
				"number": data?.cardNumber,
				"month": new Date(data?.expiryDate).getMonth(),
				"year": new Date(data?.expiryDate).getFullYear(),
				"cvc": data?.cvv,
				"redirect_url": `${window.location.origin}/patient/my-profile/account-settings`
			})
			// console.log("submitted");
			// setTimeout(() => {
			// 	setSubmitting(false);
			// 	formik.resetForm();
			// }, 2000);
		},
	});

	return (
		<DialogWrapper
			header={Tr.tr("patient:PatientProfile:Add_Payment")}
			visible={props.visible}
			width="42vw"
			hideHandler={props.hideHandler}>
			<form onSubmit={formik.handleSubmit}>
				<div className="modal_body">
					<div className="row">
						<div className="col-md-12 m-b-20">
							<TextInput
								inputLabel={Tr.tr("patient:PatientProfile:Card_Number")}
								inputName="cardNumber"
								placeHolder={Tr.tr("patient:PatientProfile:Card_Number")}
								inputVal={formik.values.cardNumber}
								changeHandler={formik.handleChange}
								inputError={getFormErrorMessage("cardNumber", formik)}
							/>
						</div>
						<div className="col-md-6 m-b-20">
							<CalendarInput
								inputLabel={Tr.tr("patient:PatientProfile:Expiry_Date")}
								inputName="expiryDate"
								placeHolder="MM/YYYY"
								inputVal={formik.values.expiryDate}
								changeHandler={(e) => {
									formik.setFieldValue("expiryDate", e.value)
								}}
								appendTo={document.body}
								view="date"
								dateFormat="mm/yy"
								inputError={getFormErrorMessage("expiryDate", formik)}
							/>
						</div>
						<div className="col-md-6 m-b-20">
							<TextInput
								inputLabel={Tr.tr("patient:PatientProfile:CVV")}
								inputName="cvv"
								placeHolder={Tr.tr("patient:PatientProfile:CVV")}
								inputVal={formik.values.cvv}
								changeHandler={formik.handleChange}
								inputError={getFormErrorMessage("cvv", formik)}
							/>
						</div>
						<div className="col-md-12">
							<TextInput
								inputLabel={Tr.tr("patient:PatientProfile:Card_Holder")}
								inputName="cardHolder"
								placeHolder="Given name"
								inputVal={formik.values.cardHolder}
								changeHandler={formik.handleChange}
								inputError={getFormErrorMessage("cardHolder", formik)}
							/>
						</div>
					</div>
				</div>
				<div className="modal_footer">
					<WhiteBlackButton
						btn
						type="button"
						label={Tr.tr("basic:Cancel")}
						classes="p712"
						buttonClicked={props.hideHandler}
					/>
					<GradientButton
						btn
						type="submit"
						label={Tr.tr("patient:PatientProfile:Add_Payment")}
						classes="p712"
						isLoading={isLoading}
					/>
				</div>
			</form>
		</DialogWrapper>
	);
};

export default AddPaymentModal;

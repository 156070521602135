import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useMutation } from "react-query";
import { toast } from "react-hot-toast";
import Cookies from "js-cookie";

// Components
import { TextInput } from "components/Common/Form/Inputs";
import { ProgressSpinner } from "primereact/progressspinner";
import GradientButton from "components/Common/UI/GradientButton/GradientButton";

// Utils
import { getFormErrorMessage } from "utils/FormField";

// Api
import { getMeApi, updateMeApi } from "api/auth";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./ProfileContent.module.scss";
import { useNavigate } from "react-router-dom";

const ProfileContent = () => {
	const [userData, setUserData] = useState(null);
	const navigate = useNavigate()
	const { mutate: getMe } = useMutation(getMeApi, {
		onSuccess: (data) => {
			setUserData(data.data);
			setImage(userData?.image + `&session_id=${Cookies.get("session_id")}`);
			setTimeout(() => setIsLoading(false), 700);
		},
		onError: (error) => {
			toast.error(error.response.data.message);
			setTimeout(() => setIsLoading(false), 500);
		},
	});

	// Handle Image Change
	const [isImageLoading, setIsImageLoading] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	// Handle Image Change
	const [image, setImage] = useState(null);
	const [imageFile, setImageFile] = useState(null);
	const onImageChange = (event) => {
		if (event.target.files && event.target.files[0]) {
			let img = event.target.files[0];
			setImage(URL.createObjectURL(img));
			let reader = new FileReader();
			reader.onload = function (e) {
				// e.target.result;
				setImageFile(e.target.result);
			};
			reader.readAsDataURL(event.target.files[0]);
		} else {
			console.log("No file selected");
		}
	};

	const { mutate: updateProfile } = useMutation(updateMeApi, {
		onSuccess: (data) => {
			console.log(formik?.values?.phone !== userData?.phone, formik?.values?.phone, { newphone: formik?.values }, userData?.phone, 'formik.values')
			toast.success(Tr.tr("basic:Profile_Updated_Successfully"));
			if (formik?.values?.mobile !== userData?.phone) {
				toast.success(Tr.tr("basic:Profile_Updated_Phone"));
				navigate(`/patient/my-profile/account-settings/update-phone/${formik?.values?.mobile}`)
			}
		},
		onError: (error) => {
			console.log(error);
			toast.error(error?.response?.data?.message);
		},
	});
	console.log({ userData })

	// Formik
	const formik = useFormik({
		initialValues: {
			image: userData?.image + `&session_id=${Cookies.get("session_id")}`,
			name: userData?.name,
			email: userData?.email,
			mobile: userData?.phone,
		},
		validate: (data) => {
			let errors = {};

			if (!data.name) {
				errors.name = "Name is required.";
			}

			if (!data.email) {
				errors.email = "Email is required.";
			} else if (
				!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)
			) {
				errors.email = "Invalid email address. E.g. user@example.com";
			}

			if (!data.mobile) {
				errors.mobile = "Mobile Number is required.";
			}

			return errors;
		},
		onSubmit: async (data, { setSubmitting }) => {
			// console.log("submitted");
			// setTimeout(() => {
			// 	setSubmitting(false);
			// 	formik.resetForm();
			// }, 2000);
			console.log("update profile")
			const payload = {
				name: data.name,
				email: data.email,
				// phone: data.mobile,
				image: imageFile?.split("base64,")[1],
			};
			updateProfile(payload);
			localStorage.setItem("user_name", data.name);
			setSubmitting(false);
		},
	});

	useEffect(() => {
		formik.setFieldValue("name", userData?.name);
		formik.setFieldValue("email", userData?.email);
		formik.setFieldValue("mobile", userData?.phone);

		if (userData) return;

		setIsLoading(true);
		getMe();
	}, [userData]);

	useEffect(() => {
		const img = new Image();
		let imageUrl;

		if (userData?.image) {
			imageUrl = `${userData?.image}&session_id=${Cookies.get("session_id")}`;
		} else {
			imageUrl = "/img/profile-img.png";
		}

		img.src = imageUrl;

		const handleImageLoad = () => {
			setImage(imageUrl);
			setIsImageLoading(true);
		};

		img.onload = handleImageLoad;

		if (img.complete) {
			handleImageLoad();
		}

		return () => {
			img.onload = null;
		};
	}, [userData]);

	return (
		<div className={styles.profile_content}>
			{/* Head */}
			<div className={styles.head}>
				<h2>{Tr.tr("patient:PatientProfile:Profile")}</h2>
				<p>{Tr.tr("patient:PatientProfile:Add_Information_About_Yourself")}</p>
			</div>

			{/* Profile Form */}
			<form onSubmit={formik.handleSubmit}>
				<div className={styles.inputs_wrapper}>
					{/* Upload Photo */}
					<div className={styles.profile_wrapper}>
						{!isImageLoading || isLoading ? (
							<ProgressSpinner />
						) : (
							<label className={styles.upload_img}>
								<input type="file" name="profileImg" onChange={onImageChange} />
								<div className={styles.img_box}>
									<img
										src={image}
										alt="Profile"
										onLoad={() => setIsImageLoading(true)}
									/>
								</div>
								<div className={styles.upload_txt}>
									<h5>{Tr.tr("patient:PatientProfile:Upload_Photo_Logo")}</h5>
									<h6>
										{Tr.tr("patient:PatientProfile:Photos_Help_Teammates")}
									</h6>
								</div>
							</label>
						)}
						{/* Upload Photo */}
					</div>

					{/* Form Inputs */}
					<div className="row">
						<div className="col-md-6">
							<div className="row">
								{/* Name */}
								<div className="col-md-12 m-b-20">
									<TextInput
										inputLabel={Tr.tr("basic:Your_Name")}
										inputName="name"
										placeHolder="Ahmed Agrma"
										inputVal={formik.values.name}
										changeHandler={formik.handleChange}
										inputError={getFormErrorMessage("name", formik)}
										required={true}
									/>
								</div>

								{/* Email */}
								<div className="col-md-12 m-b-20">
									<TextInput
										inputLabel={Tr.tr("basic:Email_Address")}
										inputName="email"
										placeHolder="ahmedagrma@gmail.com"
										inputVal={formik.values.email}
										changeHandler={formik.handleChange}
										inputError={getFormErrorMessage("email", formik)}
										required={true}
									/>
								</div>

								{/* Mobile Number */}
								<div className="col-md-12 m-b-20">
									<TextInput
										inputLabel={Tr.tr("basic:Mobile_Number")}
										inputName="mobile"
										placeHolder="01015380047"
										inputVal={formik.values.mobile}
										changeHandler={formik.handleChange}
										inputError={getFormErrorMessage("mobile", formik)}
										required={true}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>

				<GradientButton
					btn
					type="submit"
					label={Tr.tr("basic:Save_Changes")}
					disabled={formik.isSubmitting}
					isLoading={formik.isSubmitting}
				/>
			</form>
		</div>
	);
};

export default ProfileContent;

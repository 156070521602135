// Utils
import { TrimInnerSpaces } from "utils/TrimInnerSpaces";

// Styles
import styles from "./AppointmentBox.module.scss";

const AppointmentBox = ({ data, children }) => {
	if (!data) return null;

	const dateFromString = data?.date_from ? new Date(data.date_from) : null;

	const padWithZero = (number) => {
		return number.toString().padStart(2, '0');
	};

	const appointmentTime = dateFromString
		? `${dateFromString.getHours()}:${padWithZero(dateFromString.getMinutes())} ${dateFromString.getHours() >= 12 ? "PM" : "AM"}`
		: "Invalid date";

	const location =
		data?.type === "in_clinic" ? "In Clinic" : "Video Call";

	return (
		<div
			className={`${styles.appointment_box} ${TrimInnerSpaces(data.type).toLowerCase() === "in_clinic"
				? styles.inClinic
				: styles.videoCall
				}`}
		>
			<div className={styles.time}>
				{TrimInnerSpaces(data.type).toLowerCase() === "in_clinic" ? (
					<img src="/img/hospital-gray.svg" alt="" />
				) : (
					<img src="/img/video-gray.svg" alt="" />
				)}
				<p>{appointmentTime}</p>
			</div>
			<div className={styles.right}>
				<div className={styles.patient_data}>
					<div className={styles.img}>
						<img src={data.partner_id.image || "/img/profile-img.png"} alt="" />
					</div>
					<div className={styles.info}>
						<h5>{data.partner_id.name}</h5>
						<p>{location}</p>
					</div>
				</div>
				<div className={styles.actions}>{children}</div>
			</div>
		</div>
	);
};

export default AppointmentBox;

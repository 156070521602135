// Styles
import styles from "./ErrorUI.module.scss";

const ErrorUI = ({ errorMsg, withBG, ...props }) => {
	return (
		errorMsg.length > 0 && (
			<div
				className={`${styles.error_ui} ${withBG ? styles.withBG : ""} ${
					props.classes
				}`}
			>
				<img src="/img/warning.svg" alt="" />
				<span>{errorMsg}</span>
			</div>
		)
	);
};

export default ErrorUI;

import { useParams } from "react-router-dom";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

// Components
import GradientButton from "components/Common/UI/GradientButton/GradientButton";
import WhiteButton from "components/Common/UI/WhiteButton/WhiteButton";

// Api
import { bookSlotAPI, bookVideoSlotAPI } from "api/doctors";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./SummaryCard.module.scss";

const SummaryCard = ({ ...props }) => {
	// Get ID from URL
	// const params = useParams();
	const navigate = useNavigate();

	const { id, date, time } = useParams();

	// booking mutation
	const { mutate: bookSlot, data: appointmentClinic } = useMutation(bookSlotAPI, {
		onSuccess: (data) => {
			toast.success("Booked Successfully");
			// navigate(`/patient/find-a-doctor/${doctorId}/confirm-booking`, {
			// 	state: { bookingWay: value },
			// });
			console.log({ data }, 'booking')
			console.log({ data }, 'booking', data?.data?.id)
			props?.confirmBooking(data?.data?.id)
			navigate("/patient/my-profile/my-appointments");
		},
		onError: (error) => {
			toast.error(error?.response?.data?.message);
		},
	});

	const { mutate: bookVideoSlot, data: appointmentVideo } = useMutation(bookVideoSlotAPI, {
		onSuccess: (data) => {
			toast.success("Booked Successfully");
			console.log({ data }, 'booking', data?.data?.id)
			props?.confirmBooking(data?.data?.id)
			// navigate(`/patient/find-a-doctor/${doctorId}/confirm-booking`, {
			// 	state: { bookingWay: value },
			// });
			navigate("/patient/my-profile/my-appointments");
		},
		onError: (error) => {
			toast.error(error?.response?.data?.message);
		},
	});

	const confirmBooking = async () => {
		console.log("clicked", props?.state);
		if (appointmentClinic || appointmentVideo) {
			const data = appointmentClinic || appointmentVideo
			props?.confirmBooking(data?.data?.id)
			return
		}
		props?.state === "inClinic"
			? bookSlot({
				doctor_id: id,
				date,
				time,
			})
			: bookVideoSlot({
				doctor_id: id,
				date,
				time,
			});
	};

	return (
		<div className={styles.summary_card}>
			<div className={styles.summary_card_head}>
				{Tr.tr("patient:DoctorDetails:Summary")}
			</div>
			<div className={styles.summary_card_body}>
				{props.children}
				<div className={`${styles.summary_row} ${styles.total}`}>
					<div className={styles.txt}>{Tr.tr("basic:Total")}</div>
					<div className={styles.val}>
						{props?.state === "inClinic"
							? props?.data?.clinic_fees
							: props?.data?.video_fees}{" "}
						{Tr.tr("patient:DoctorDetails:SAR")}
					</div>
				</div>
			</div>
			<div className={styles.summary_card_footer}>
				<GradientButton
					buttonClicked={confirmBooking}
					btn
					type="button"
					label={Tr.tr("patient:DoctorDetails:Booking_Confirmation")}
					classes="w-100"
					isLoading={props?.isLoading}
				/>
				<WhiteButton
					label={Tr.tr("basic:Cancel")}
					classes="w-100"
					redirectTo={`/patient/find-a-doctor/${id}`}
				/>
			</div>
		</div>
	);
};

export default SummaryCard;

import { TabPanel, TabView } from "primereact/tabview";
import { useState } from "react";

// Components
import GradientButton from "components/Common/UI/GradientButton/GradientButton";
import PageHead from "components/Patient/PageHead/PageHead";
import AddMedicineDrawer from "./components/AddMedicineDrawer/AddMedicineDrawer";
import MedicationsContent from "./components/MedicationsContent/MedicationsContent";
import RemindersContent from "./components/RemindersContent/RemindersContent";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./Reminders.module.scss";

const Reminders = () => {
    // Show Add Medicine Drawer
    const [visible, setVisible] = useState(false);

    const headerTemplate = (options) => {
        return (
            <button
                type="button"
                onClick={options.onClick}
                className={`${options.className} tab_btn`}>
                {options.index === 0 && (
                    <>
                        <img src="/img/bell-black.svg" alt="" className="black" />
                        <img src="/img/bell-gradient.svg" alt="" className="gradient" />
                    </>
                )}
                {options.index === 1 && (
                    <>
                        <img src="/img/medication-black.svg" alt="" className="black" />
                        <img
                            src="/img/medication-gradient.svg"
                            alt=""
                            className="gradient"
                        />
                    </>
                )}
                {options.titleElement}
            </button>
        );
    };

    return (
        <>
            <div className={styles.reminders}>
                <PageHead
                    title={Tr.tr("patient:PatientProfile:Reminders")}
                    subTitle="This is a subtitle"
                    mb="16px">
                    <GradientButton
                        btn
                        Type="button"
                        label={Tr.tr("patient:PatientProfile:Add_Medicine")}
                        withIconImg
                        icon="/img/add.svg"
                        classes="p610"
                        buttonClicked={() => {
                            setVisible(true);
                        }}
                    />
                </PageHead>

                <div className={styles.reminders_medications_tabs}>
                    <TabView>
                        <TabPanel
                            header={Tr.tr("patient:PatientProfile:Reminders")}
                            headerTemplate={(options) => headerTemplate(options)}>
                            <RemindersContent />
                        </TabPanel>
                        <TabPanel
                            header={Tr.tr("patient:PatientProfile:Medications")}
                            headerTemplate={(options) => headerTemplate(options)}>
                            <MedicationsContent />
                        </TabPanel>
                    </TabView>
                </div>
            </div>

            {/* Add Medicine Drawer */}
            <AddMedicineDrawer
                visible={visible}
                hideHandler={() => {
                    setVisible(false);
                }}
            />
        </>
    );
};

export default Reminders;

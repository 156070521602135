import { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { useFormik } from "formik";

// Components
import GradientButton from "components/Common/UI/GradientButton/GradientButton";
import DialogWrapper from "components/Patient/DialogWrapper/DialogWrapper";
import { SelectDropdown, TextInput } from "components/Common/Form/Inputs";

// Utils
import { getFormErrorMessage } from "utils/FormField";

// Styles
import styles from "./Information.module.scss";
import { Tr } from "utils/i18n";
import { useMutation, useQuery } from "react-query";
import { getMeApi, updateMeApi } from "api/auth";
import {toast} from "react-hot-toast";

const Information = () => {
	const [visible, setVisible] = useState(false);
	const [information,setInformation] = useState({});
	const { mutate: getMe } = useMutation(getMeApi, {
		onSuccess: (data) => {
			setInformation(data.data)
			formik.setFieldValue("age", data.data.age);
			formik.setFieldValue("gender", data.data.gender);
			formik.setFieldValue("bloodType", data.data.blood_type);
			formik.setFieldValue("weight", data.data.weight);
			formik.setFieldValue("height", data.data.height);
		},
		// onError: (error) => {
		// 	toast.error(error.response.data.message);
		// },
	});

	const { mutate: updateProfile } = useMutation(updateMeApi, {
		onSuccess: (data) => {
			getMe()
			toast.success("Medical Profile has been updated.");
		},
		// onError: (error) => {
		// 	toast.error(error.response.data.message);
		// },
	});
	useEffect(() => {
		getMe()
	},[])
	// const information = {
	// 	age: 26,
	// 	gender: "Male",
	// 	bloodType: "A+",
	// 	weight: "75 kg",
	// 	height: "175 cm",
	// };

	// Gender
	const genderTypes = [
		{ label: "Male", value: "male" },
		{ label: "Female", value: "female" },
	];

	// Blood Types
	const bloodTypes = [
		{ label: "A+", value: "a+" },
		{ label: "B-", value: "b-" },
		{ label: "B+", value: "b+" },
		{ label: "O+", value: "o+" },
	];

	// Formik
	const formik = useFormik({
		initialValues: {
			age: "",
			gender: null,
			bloodType: null,
			weight: "",
			height: "",
		},
		validate: (data) => {
			let errors = {};

			if (!data.age) {
				errors.age = "Age is required.";
			}

			if (!data.gender) {
				errors.gender = "Gender is required.";
			}

			if (!data.bloodType) {
				errors.bloodType = "Blood Type is required.";
			}

			if (!data.weight) {
				errors.weight = "Weight is required.";
			}

			if (!data.height) {
				errors.height = "Height is required.";
			}

			return errors;
		},
		onSubmit: async (data, { setSubmitting }) => {
			await updateProfile({
			age: data.age,
			gender: data.gender,
			blood_type: data.bloodType,
			weight: data.weight,
			height: data.height,
			})
			console.log("submitted");
			// setTimeout(() => {
			// 	setSubmitting(false);
			// 	formik.resetForm();
			// }, 2000);
		},
	});

	return (
		<>
			<section className={styles.information}>
				{/* Section Head */}
				<div className="title_action">
					<h2>{Tr.tr("patient:PatientProfile:Information")}</h2>
					<GradientButton
						btn
						type="button"
						label={Tr.tr("basic:Edit")}
						withIconImg
						icon="/img/edit-white.svg"
						classes="p610"
						buttonClicked={() => setVisible(true)}
					/>
				</div>

				{/* Information Card */}
				<div className={styles.information_card}>
					<div className={styles.information_box}>
						<img src="/img/age-gradient.svg" alt="" />
						<h6>{Tr.tr("patient:PatientProfile:Age")}</h6>
						<h5>{information?.age}</h5>
					</div>
					<div className={styles.information_box}>
						<img src="/img/gender-gradient.svg" alt="" />
						<h6>{Tr.tr("patient:PatientProfile:Gender")}</h6>
						<h5>{information?.gender}</h5>
					</div>
					<div className={styles.information_box}>
						<img src="/img/blood-gradient.svg" alt="" />
						<h6>{Tr.tr("patient:PatientProfile:Blood_Type")}</h6>
						<h5>{information?.blood_type}</h5>
					</div>
					<div className={styles.information_box}>
						<img src="/img/weight-gradient.svg" alt="" />
						<h6>{Tr.tr("patient:PatientProfile:Weight")}</h6>
						<h5>{information?.weight}</h5>
					</div>
					<div className={styles.information_box}>
						<img src="/img/height-gradient.svg" alt="" />
						<h6>{Tr.tr("patient:PatientProfile:Height")}</h6>
						<h5>{information?.height}</h5>
					</div>
				</div>
			</section>

			{/* Edit Modal */}
			<DialogWrapper
				header={Tr.tr("patient:PatientProfile:Information")}
				visible={visible}
				width="42vw"
				hideHandler={() => setVisible(false)}>
				<form onSubmit={formik.handleSubmit}>
					<div className="modal_body">
						<div className="row">
							{/* Age */}
							<div className="col-md-12 m-b-20">
								<TextInput
									inputLabel={Tr.tr("patient:PatientProfile:Age")}
									inputName="age"
									placeHolder="0"
									inputVal={formik.values.age}
									changeHandler={formik.handleChange}
									inputError={getFormErrorMessage("age", formik)}
								/>
							</div>

							{/* Gender */}
							<div className="col-md-12 m-b-20">
								<SelectDropdown
									inputLabel={Tr.tr("patient:PatientProfile:Gender")}
									inputName="gender"
									placeHolder={Tr.tr("patient:PatientProfile:Select_Gender")}
									options={genderTypes}
									inputVal={formik.values.gender}
									changeHandler={(e) => formik.setFieldValue("gender", e.value)}
									appendTo={document.body}
								/>
							</div>

							{/* Blood Type */}
							<div className="col-md-12 m-b-20">
								<SelectDropdown
									inputLabel={Tr.tr("patient:PatientProfile:Blood_Type")}
									inputName="bloodType"
									placeHolder={Tr.tr(
										"patient:PatientProfile:Select_Blood_Type"
									)}
									options={bloodTypes}
									inputVal={formik.values.bloodType}
									changeHandler={(e) =>
										formik.setFieldValue("bloodType", e.value)
									}
									appendTo={document.body}
								/>
							</div>

							{/* Weight */}
							<div className="col-md-12 m-b-20">
								<TextInput
									inputLabel={Tr.tr("patient:PatientProfile:Weight")}
									inputName="weight"
									placeHolder="0"
									inputVal={formik.values.weight}
									changeHandler={formik.handleChange}
									inputError={getFormErrorMessage("weight", formik)}
								/>
							</div>

							{/* Height */}
							<div className="col-md-12 m-b-20">
								<TextInput
									inputLabel={Tr.tr("patient:PatientProfile:Height")}
									inputName="height"
									placeHolder="0"
									inputVal={formik.values.height}
									changeHandler={formik.handleChange}
									inputError={getFormErrorMessage("height", formik)}
								/>
							</div>
						</div>
					</div>
					<div className="modal_footer">
						<Button
							type="button"
							label={Tr.tr("basic:Discard")}
							className="discard_btn"
							onClick={() => setVisible(false)}
						/>
						<GradientButton
							btn
							type="submit"
							label={Tr.tr("basic:Save")}
							classes="done_btn"
						/>
					</div>
				</form>
			</DialogWrapper>
		</>
	);
};

export default Information;

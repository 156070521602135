// Components
import GradientButton from "components/Common/UI/GradientButton/GradientButton";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./EarningsSummary.module.scss";

const EarningsSummary = () => {
	return (
		<section className={styles.summary}>
			<h2>{Tr.tr("basic:Summary")}</h2>
			<div className={styles.summary_holder}>
				<div className={styles.summary_boxes}>
					<div className="row">
						<div className="col-md-3 mb-3 mb-md-0">
							<div className={styles.summary_box}>
								<h5>{Tr.tr("doctor:Reports:Gross_Earnings")}</h5>
								<h3>0.00 SAR</h3>
							</div>
						</div>
						<div className="col-md-3 mb-3 mb-md-0">
							<div className={styles.summary_box}>
								<h5>{Tr.tr("doctor:Reports:Cost")}</h5>
								<h3>0.00 SAR</h3>
							</div>
						</div>
						<div className="col-md-3 mb-3 mb-md-0">
							<div className={styles.summary_box}>
								<h5>{Tr.tr("doctor:Reports:Tiryaaq_Fees")}</h5>
								<h3>0.00 SAR</h3>
							</div>
						</div>
						<div className="col-md-3">
							<div className={styles.summary_box}>
								<h5>{Tr.tr("doctor:Reports:Net_Earnings")}</h5>
								<h3>0.00 SAR</h3>
							</div>
						</div>
					</div>
				</div>
				<GradientButton
					label={Tr.tr("doctor:Reports:View_Payment_History")}
					redirectTo=""
					classes="p712 flex-flex-shrink-0"
				/>
			</div>
		</section>
	);
};

export default EarningsSummary;

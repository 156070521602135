import { useRef } from "react";
import { Button } from "primereact/button";
import { OverlayPanel } from "primereact/overlaypanel";
import { useFormik } from "formik";

// Components
import GradientButton from "components/Common/UI/GradientButton/GradientButton";
import WhiteBlackButton from "components/Common/UI/WhiteBlackButton/WhiteBlackButton";
import { SelectDropdown, TextInput } from "components/Common/Form/Inputs";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./FilterPatients.module.scss";

const FilterPatients = ({ ...props }) => {
	// Filter Overlay
	const op = useRef(null);

	// Gender
	const genders = [
		{
			label: "All",
			value: "All",
		},
		{
			label: "Male",
			value: "Male",
		},
		{
			label: "Female",
			value: "Female",
		},
	];

	// Blood Type
	const bloodTypes = [
		{
			label: "All",
			value: "All",
		},
		{
			label: "A+",
			value: "A+",
		},
		{
			label: "B+",
			value: "B+",
		},
	];

	// Form
	const formik = useFormik({
		initialValues: {
			ageFrom: 12,
			ageTo: 75,
			gender: "All",
			bloodType: "All",
		},
		validate: (data) => {
			let errors = {};

			if (!data.ageFrom) {
				errors.ageFrom = "Age is required.";
			}

			return errors;
		},
		onSubmit: (data, { setSubmitting }) => {
			console.log("submitted");
			setTimeout(() => {
				setSubmitting(false);
				formik.resetForm();
			}, 2000);
		},
	});

	return (
		<div className={styles.filter_patients}>
			<WhiteBlackButton
				btn
				type="button"
				label={!props.withoutLabel && Tr.tr("basic:Filter")}
				withIconImg
				icon="/img/filter.svg"
				classes="p712 boxShadow"
				outlined
				buttonClicked={(e) => op.current.toggle(e)}
			/>

			<OverlayPanel ref={op} className={styles.filter_overlay}>
				<form className={styles.form_holder} onSubmit={formik.handleSubmit}>
					<div className={styles.overlay_head}>
						<h3>{Tr.tr("basic:Filter")}</h3>
						<Button
							type="button"
							text
							className={styles.close_btn}
							onClick={(e) => op.current.hide(e)}>
							<img src="/img/close-gray.svg" alt="" />
						</Button>
					</div>

					<div className={styles.overlay_body}>
						<div className="row">
							{/* Age */}
							<div className="col-md-12 mb-3">
								<h6 className={styles.inpt_lbl}>{Tr.tr("basic:Age")}</h6>
								<div className="row">
									<div className="col-md-5">
										<TextInput
											inputLabel=""
											inputName="ageFrom"
											placeHolder="12"
											inputVal={formik.values.ageFrom}
											changeHandler={formik.handleChange}
										/>
									</div>
									<div className="col-md-2">
										<div className={styles.to}>To</div>
									</div>
									<div className="col-md-5">
										<TextInput
											inputLabel=""
											inputName="ageto"
											placeHolder="75"
											inputVal={formik.values.ageTo}
											changeHandler={formik.handleChange}
										/>
									</div>
								</div>
							</div>

							{/* Gender */}
							<div className="col-md-12 mb-3">
								<SelectDropdown
									inputLabel={Tr.tr("basic:Gender")}
									inputName="gender"
									placeHolder={Tr.tr("basic:Select_Gender")}
									options={genders}
									inputVal={formik.values.gender}
									changeHandler={(e) => formik.setFieldValue("gender", e.value)}
								/>
							</div>

							{/* Gender */}
							<div className="col-md-12 mb-3">
								<SelectDropdown
									inputLabel={Tr.tr("basic:Blood_Type")}
									inputName="bloodType"
									placeHolder={Tr.tr("basic:Select_Blood_Type")}
									options={bloodTypes}
									inputVal={formik.values.bloodType}
									changeHandler={(e) =>
										formik.setFieldValue("bloodType", e.value)
									}
								/>
							</div>
						</div>
					</div>

					<div className={styles.overlay_footer}>
						{/* Apply Filter */}
						<GradientButton
							btn
							type="submit"
							label={Tr.tr("basic:Apply_Filter")}
							isLoading={formik.isSubmitting}
							withIconImg
							icon="/img/check-white.svg"
							classes="p712"
						/>

						{/* Reset Filter */}
						<WhiteBlackButton
							btn
							type="reset"
							label={Tr.tr("basic:Reset_Filter")}
							withIconImg
							icon="/img/refresh-black.svg"
							classes="p712"
							buttonClicked={() => formik.resetForm()}
						/>
					</div>
				</form>
			</OverlayPanel>
		</div>
	);
};

export default FilterPatients;

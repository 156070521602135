import ContentLoader from "react-content-loader"

const SpecialtiesLoading = (props) => (
  <ContentLoader 
    speed={2}
    width={300}
    height={250}
    viewBox="0 0 476 340"
    backgroundColor="#cfe8f7"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="48" y="98" rx="24" ry="24" width="300" height="250" />
  </ContentLoader>
)

export default SpecialtiesLoading;
import { useMutation, useQueryClient, useQuery } from "react-query";
import {
	uploadDocumentApi,
	deleteDocumentApi,
	getDocumentsApi,
} from "api/auth";

const useUploadDoctorDocument = () => {
	const queryClient = useQueryClient();

	return useMutation(uploadDocumentApi, {
		onSuccess: (data) => {
			const documentDetails = data.data;
			queryClient.setQueryData("documents", (oldDocuments) => {
				const documentsArray = Array.isArray(oldDocuments) ? oldDocuments : [];
				return [...documentsArray, documentDetails];
			});
		},
		onError: (error) => {
			console.log(error);
		},
	});
};

export const useFetchDoctorDocuments = (options) => {
	const queryClient = useQueryClient();

	return useQuery(
		["documents"],
		async () => {
			const response = await getDocumentsApi({});
			// console.log(response)
			return response.data;
		},
		{
			...options,
			onSuccess: (data) => {
				queryClient.setQueryData(["documents"], data);
			},
		}
	);
};

const useDeleteDoctorDocument = () => {
	const queryClient = useQueryClient();

	return useMutation((documentId) => deleteDocumentApi(documentId), {
		onSuccess: () => {
			queryClient.invalidateQueries("documents");
		},
		onError: (error) => {
			console.error("Error deleting the document:", error);
		},
	});
};

const DoctorUploadDocumentService = {
	useUploadDoctorDocument,
	useFetchDoctorDocuments,
	useDeleteDoctorDocument,
};

export default DoctorUploadDocumentService;

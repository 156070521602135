import { Outlet } from "react-router-dom";

// Components
import DoctorVideoRoomHeader from "../DoctorVideoRoomHeader/DoctorVideoRoomHeader";

// Provider
import { SidebarProvider } from "context/DoctorSidebarContext";

const DoctorVideoRoomLayout = () => {
    return (
        <SidebarProvider>
            <div>
                <main>
                    <DoctorVideoRoomHeader />
                    <div>
                        <Outlet />
                    </div>
                </main>
            </div>
        </SidebarProvider>
    );
};

export default DoctorVideoRoomLayout;

import { useEffect, useState } from "react";
import { Tab, Tabs } from "react-tabs-scrollable";
import "react-tabs-scrollable/dist/rts.css";

// Components
import ReminderBox from "components/Patient/ReminderBox/ReminderBox";

// Translation
import { useTranslation } from "react-i18next";

// Styles
import RemindersService from "features/reminders/remindersService";
import { getCurrentWeekDays } from "utils/GetCurrentWeekDays";
import { Tr } from "utils/i18n";
import styles from "./RemindersContent.module.scss";

const RemindersContent = () => {
	const { i18n } = useTranslation();
	const currLang = i18n.language;

	const [activeTab, setActiveTab] = useState(0);
	const [selectedDate, setSelectedDate] = useState(new Date().toISOString());
	const [appointments, setAppointments] = useState([]);

	const onTabClick = (e, index) => {
		setActiveTab(index);
	};

	useEffect(() => {
		let curr = new Date();
		let today = curr.getDay() - 1;
		const week = getCurrentWeekDays();
		setActiveTab(today)
		setAppointments(week)
	}, [])

	const { data: reminders, refetch, isLoading } = RemindersService.useFetchReminders(selectedDate);

	useEffect(() => {
		if (selectedDate.length > 0) {
			refetch();
		}
	}, [selectedDate])

	// Reminders
	// const reminders = [
	// 	{
	// 		time: "morning",
	// 		data: [
	// 			{
	// 				id: 1,
	// 				time: "7:00 AM",
	// 				icon: "/img/tablet.svg",
	// 				name: "Aspirin",
	// 				desc: "Lutein 40mg",
	// 			},
	// 			{
	// 				id: 2,
	// 				time: "8:00 AM",
	// 				icon: "/img/medication.svg",
	// 				name: "Diet Pill",
	// 				desc: "Green Tea Extract",
	// 			},
	// 		],
	// 	},
	// 	{
	// 		time: "afternoon",
	// 		data: [
	// 			{
	// 				id: 1,
	// 				time: "1:00 PM",
	// 				icon: "/img/medication-cyan.svg",
	// 				name: "Vitamins",
	// 				desc: "Lutein 40mg",
	// 			},
	// 			{
	// 				id: 2,
	// 				time: "1:00 PM",
	// 				icon: "/img/medication.svg",
	// 				name: "Diet Pill",
	// 				desc: "Green Tea Extract",
	// 			},
	// 		],
	// 	},
	// 	{
	// 		time: "evening",
	// 		data: [
	// 			{
	// 				id: 1,
	// 				time: "7:00 PM",
	// 				icon: "/img/medication-yellow.svg",
	// 				name: "Vitamins",
	// 				desc: "Bitter Orange",
	// 			},
	// 			{
	// 				id: 2,
	// 				time: "8:00 PM",
	// 				icon: "/img/medication.svg",
	// 				name: "Diet Pill",
	// 				desc: "Green Tea Extract",
	// 			},
	// 		],
	// 	},
	// ];

	return (
		<div className={styles.reminders_content}>
			<div className={styles.appointments_tabs}>
				<Tabs
					activeTab={activeTab}
					onTabClick={onTabClick}
					rightBtnIcon={<img src="/img/chevron-right-gradient.svg" alt="" />}
					leftBtnIcon={<img src="/img/chevron-left-gradient.svg" alt="" />}
					isRTL={currLang === "ar" ? true : false}
					tabsScrollAmount={1}>
					{appointments.map((data, index) => (
						<Tab key={index} className="header_btn">
							<div className="header_btn_content"
								onClick={(event) => { setSelectedDate(data.date); onTabClick(event.target); }}>
								<span className="day">{data.dayName}</span>
								<span className="date"> {data.monthName} {data.day} </span>
							</div>
						</Tab>
					))}
				</Tabs>
			</div>

			{/* Reminders Boxes */}
			<div className={styles.reminders_boxes}>
				{reminders?.map((reminder, index) => (
					<div className={styles.reminder_row} key={index}>
						{reminder.time === "morning" && (
							<div className={styles.reminder_head}>
								<img src="/img/sunrise.svg" alt="" />
								<span>{Tr.tr("patient:PatientProfile:Morning")}</span>
							</div>
						)}
						{reminder.time === "afternoon" && (
							<div className={styles.reminder_head}>
								<img src="/img/sun.svg" alt="" />
								<span>{Tr.tr("patient:PatientProfile:Afternoon")}</span>
							</div>
						)}
						{reminder.time === "evening" && (
							<div className={styles.reminder_head}>
								<img src="/img/sunset.svg" alt="" />
								<span>{Tr.tr("patient:PatientProfile:Evening")}</span>
							</div>
						)}

						<div className={styles.reminders_wrapper}>
							{reminders[index].data.map((data) => (
								<ReminderBox data={data} key={data.id} />
							))}
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default RemindersContent;

import { useLocation, useNavigate } from "react-router-dom";

// Components
import { Interview, Patient, Results, Symptoms } from "./pages";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./CheckSymptoms.module.scss";

const CheckSymptoms = () => {
	// Location
	const location = useLocation();

	// Navigation
	const navigate = useNavigate();

	// Params
	const queryParams = new URLSearchParams(window.location.search);
	const stage = queryParams.get("stage");

	// Sidebar Steps
	const steps = [
		{
			id: 1,
			label: Tr.tr("patient:CheckSymptoms:Patient"),
		},
		{
			id: 2,
			label: Tr.tr("patient:CheckSymptoms:Symptoms"),
		},
		{
			id: 3,
			label: Tr.tr("patient:CheckSymptoms:Interview"),
		},
		{
			id: 4,
			label: Tr.tr("patient:CheckSymptoms:Results"),
		},
	];

	const onNex = () => {
		if (Number(stage) < steps.length) {
			navigate(`/patient/check-symptoms?stage=${Number(stage) + 1}`);
		}
	};

	const onPrevious = () => {
		if (Number(stage) > 1) {
			navigate(`/patient/check-symptoms?stage=${Number(stage) - 1}`);
		}
	};

	// Render different components based on the value of the "component" query parameter
	let content = null;

	switch (stage) {
		case "1":
			content = <Patient onNex={onNex} onPrevious={onPrevious} />;
			break;
		case "2":
			content = <Symptoms onNex={onNex} onPrevious={onPrevious} />;
			break;
		case "3":
			content = <Interview onNex={onNex} onPrevious={onPrevious} />;
			break;
		case "4":
			content = <Results />;
			break;
		default:
			content = <Patient onNex={onNex} onPrevious={onPrevious} />;
	}

	return (
		<div className={styles.check_your_symptoms}>
			<div className={styles.check_your_symptoms_head}>
				<div className="container">
					<h1>{Tr.tr("patient:CheckSymptoms:Check_Your_Symptoms")}</h1>
					<p>Lorem ipsum dolor sit amet consectetur. Neque blandit</p>
				</div>
			</div>

			<div className={styles.check_your_symptoms_body}>
				<div className="container">
					<div className="row">
						<div className="col-md-4 mb-4 mb-md-0">
							<div className={styles.sidebar_steps}>
								{console.log(stage, "stage")}
								<ul className={styles.steps_rows}>
									{steps.map((step) => (
										<li
											key={step.id}
											className={`${styles.step_row} ${
												location.pathname.includes("check-symptoms") &&
												step.id === Number(stage)
													? styles.current_step
													: ""
											} ${
												location.pathname.includes("check-symptoms") &&
												!(step.id > Number(stage))
													? styles.active_steps
													: ""
											}`}>
											<span>{step.label}</span>
											<img src="/img/check-gradient.svg" alt="" />
										</li>
									))}
								</ul>
							</div>
						</div>
						<div className="col-md-8">{content}</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CheckSymptoms;

import { useFormik } from "formik";
import { toast } from "react-hot-toast";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";

// Components
import { TextInput } from "components/Common/Form/Inputs";
import GradientButton from "components/Common/UI/GradientButton/GradientButton";

// Utils
import { getFormErrorMessage } from "utils/FormField";
import { PasswordInput } from "components/Common/Form/Inputs";

// APIs
import { changePasswordSettingsAPI } from "api/auth";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./ChangePassContent.module.scss";

const ChangePassContent = () => {
	const navigate = useNavigate();

	const { mutate: updatePassword } = useMutation(changePasswordSettingsAPI, {
		onSuccess: (data) => {
			toast.success(data.message);
			localStorage.clear();
			navigate("/patient/login");
		},
		onError: (error) => {
			toast.error(Tr.tr("basic:Current_Password_Incorrect"));
		},
	});

	// Formik
	const formik = useFormik({
		initialValues: {
			currentPassword: "",
			newPassword: "",
			confirmNewPassword: "",
		},
		validate: (data) => {
			let errors = {};

			if (!data.currentPassword) {
				errors.currentPassword = Tr.tr("basic:Current_Password_Required");
			}

			if (!data.newPassword) {
				errors.newPassword = Tr.tr("basic:New_Password_Required");
			}

			if (
				!data.confirmNewPassword ||
				data.confirmNewPassword !== data.newPassword
			) {
				errors.confirmNewPassword = Tr.tr("basic:Passwords_Must_Match");
			}

			return errors;
		},
		onSubmit: async (data, { setSubmitting }) => {
			// console.log("submitted");
			// setTimeout(() => {
			// 	setSubmitting(false);
			// 	formik.resetForm();
			// }, 2000);
			const payload = {
				current_password: data.currentPassword,
				new_password: data.newPassword,
			};
			// console.log(payload);
			await updatePassword(payload);
			setSubmitting(false);
		},
	});

	return (
		<div className={styles.changePass_content}>
			{/* Head */}
			<div className={styles.head}>
				<h2>{Tr.tr("basic:Password")}</h2>
				<p>{Tr.tr("basic:Change_Password_Your_Account")}</p>
			</div>

			{/* Change Password Form */}
			<form onSubmit={formik.handleSubmit}>
				<div className="row">
					<div className="col-md-6">
						<div className="row">
							{/* Current Password */}
							<div className="col-md-12 m-b-20">
								<PasswordInput
									inputLabel={Tr.tr("basic:Current_Password")}
									inputName="currentPassword"
									placeHolder={Tr.tr("basic:Current_Password")}
									inputVal={formik.values.currentPassword}
									changeHandler={formik.handleChange}
									inputError={getFormErrorMessage("currentPassword", formik)}
								/>
							</div>

							{/* New Password */}
							<div className="col-md-12 m-b-20">
								<PasswordInput
									inputLabel={Tr.tr("basic:New_Password")}
									inputName="newPassword"
									placeHolder={Tr.tr("basic:New_Password")}
									inputVal={formik.values.newPassword}
									changeHandler={formik.handleChange}
									inputError={getFormErrorMessage("newPassword", formik)}
									handleBlur={formik.handleBlur}
								/>
								<div className={styles.hint}>
									{Tr.tr("basic:Password_Must_Be")}
								</div>
							</div>

							{/* Confirm New Password */}
							<div className="col-md-12 m-b-20">
								<PasswordInput
									inputLabel={Tr.tr("basic:Confirm_New_Password")}
									inputName="confirmNewPassword"
									placeHolder={Tr.tr("basic:Confirm_New_Password")}
									inputVal={formik.values.confirmNewPassword}
									changeHandler={formik.handleChange}
									inputError={getFormErrorMessage("confirmNewPassword", formik)}
									handleBlur={formik.handleBlur}
								/>
							</div>
						</div>
					</div>
				</div>

				<GradientButton
					btn
					type="submit"
					label={Tr.tr("basic:Save_Changes")}
					disabled={formik.isSubmitting}
					isLoading={formik.isSubmitting}
				/>
			</form>
		</div>
	);
};

export default ChangePassContent;

// Components
import GradientButton from "components/Common/UI/GradientButton/GradientButton";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./AnswerCard.module.scss";

const AnswerCard = ({ data }) => {
    return (
        <div className={styles.answer_card}>
            <div className={styles.answer_card_head}>
                <div className={styles.answerer_info}>
                    <div className={styles.img}>
                        <img src={data.partner_id.image} alt={data.partner_id.name} />
                    </div>
                    <div className={styles.info}>
                        <h5>{Tr.tr("patient:PatientProfile:Answer_From")}</h5>
                        <h4 className={styles.name}>
                            {data.name}
                        </h4>
                    </div>
                </div>

                {/* View Profile Btn */}
                <GradientButton
                    label={Tr.tr("patient:PatientProfile:View_Profile")}
                    redirectTo={`/patient/find-a-doctor/${data.id}`}
                    outlined
                    classes={`p610 ${styles.view_profile}`}
                />
            </div>
            <div className={styles.answer_card_body}>
                <p className={styles.answer}>{data.answer}</p>
                <div className={styles.date}>{data.date}</div>
            </div>
        </div>
    );
};

export default AnswerCard;

import axios from "../lib/axios";

export const doctorsAPI = async ({ queryKey }) => {
	const [_, search, filters, pageNumber] = queryKey;
	// console.log(search);
	// console.log(filters);

	const payload = {
		search,
		filters: {
			...filters,
		},
		page: pageNumber,
	};
	return await axios.post(`/doctors`, payload);
};

export const getDoctorAPI = async (id) => {
	return await axios.post(`/doctors/${id?.queryKey[1]}`, {
		doctor_id: id?.queryKey[1],
	});
};

export const getRatingAPI = async (id) => {
	return await axios.post(`/doctors/${id?.queryKey[1]}/rating`, {
		doctor_id: id?.queryKey[1],
	});
};

export const doctorAvailabilityAPI = async ({ doctor_id, date }) => {
	return await axios.post(`/doctors/${doctor_id}/availability`, {
		date,
	});
};

export const bookSlotAPI = async ({ doctor_id, date, time, type }) => {
	return await axios.post(`/doctors/${doctor_id}/book-appointment`, {
		date: `${date} ${time}`,
		type: 'in_clinic'
	});
};

export const bookVideoSlotAPI = async ({ doctor_id, date, time, type }) => {
	return await axios.post(`/doctors/${doctor_id}/book-appointment`, {
		date: `${date} ${time}`,
		type: "video_call"
	});
};

export const getDoctorDashboardSummary = async ({ doctor_id }) => {
	return await axios.post(`/doctors/${doctor_id}/dashboard-summary`, {});
};

export const getDoctorPatientsAPI = async ({ doctor_id }) => {
    return await axios.post(`/doctors/${doctor_id}/patients`, {});
};

export const getPatientsDetailsAPI = async ({ patient_id }) => {
    return await axios.post(`/patients/${patient_id}`, {});
};

export const addPatientAPI = async (params) => {
    return await axios.post(`/doctors/${params?.id}/patients/add`, params?.data);
};

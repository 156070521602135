import { Fragment, useContext, useEffect, useState } from "react";
import { Button } from "primereact/button";
import { useFormik } from "formik";

// Components
import { SearchInput, TextAreaInput } from "components/Common/Form/Inputs";
import WhiteBlackButton from "components/Common/UI/WhiteBlackButton/WhiteBlackButton";
import GradientButton from "components/Common/UI/GradientButton/GradientButton";

// Context
import { SidebarContext } from "context/DoctorSidebarContext";

// JSON
import patientsData from "json/patientsData.json";

// Utils
import { getFormErrorMessage } from "utils/FormField";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./DoctorMessages.module.scss";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getAllChannelsAPI, getChannelMessagesAPI, sendMessagesAPI } from "api/messages";

const DoctorMessages = () => {
    const [userName, setUserName] = useState('')
    const { setSmallSize } = useContext(SidebarContext);

    // Search
    const [searchQuery, setSearchQuery] = useState("");

    // Patients List
    const [patients, setPatients] = useState([]);
    const [channels, setChannels] = useState(null);

    // Selected Patient
    const [selectedChannel, setSelectedChannel] = useState(null);

    // Selected Patient Id
    const [selectedPatientId, setSelectedPatientId] = useState(null);

    const { data, isLoading } = useQuery(["channels", {}], getAllChannelsAPI,
        {
            select: (response) => {
                return response.data;
            },
        });

    const { data: messages } = useQuery(["messages", { channel_id: selectedPatientId }], getChannelMessagesAPI,
        {
            select: (response) => {
                return response.data;
            },
        });

    // console.log(messages, channels, '====> messages');
    // console.log(selectedChannel, '====> selectedChannel');

    useEffect(() => {
        setChannels(data);
        setUserName(localStorage.getItem('user_name'))
    }, [data])


    useEffect(() => {
        setSmallSize(false);

        // Simulate importing data from a JSON file
        setPatients(patientsData);
    }, [setSmallSize]);

    const getSelectedChannel = (channel) => {
        // console.log(channel, '====> pa');
        setSelectedPatientId(channel.id);
        // Get Selected channel
        const foundedchannel = data?.data.find((p) => p.id === channel.id);
        setSelectedChannel(foundedchannel);
    };
    const queryClient = useQueryClient();

    const { mutate: sendMessage, isSuccess, isError } = useMutation(sendMessagesAPI, {
        onSuccess: (data) => {
            queryClient.invalidateQueries('messages');
        },
        onError: (error) => {
            console.log(error);

        },
    });

    // Formik
    const formik = useFormik({
        initialValues: {
            message: "",
        },
        validate: (data) => {
            let errors = {};

            if (!data.message) {
                errors.message = "Message is required.";
            }

            return errors;
        },
        onSubmit: (data, { setSubmitting }) => {
            // console.log(data, "submitted");
            sendMessage({ channel_id: selectedPatientId, data: data })
            if (isSuccess) {
                setTimeout(() => {
                    setSubmitting(false);
                    formik.resetForm();
                }, 2000);
            }
        },
    });

    return (
        <div className={styles.doctor_messages}>
            <div className={styles.msg_list}>
                <div className={styles.msg_list_head}>
                    <h1>{Tr.tr("basic:Messages")}</h1>

                    {/* Search */}
                    <SearchInput
                        inputLabel=""
                        inputName="search"
                        placeHolder={Tr.tr("basic:Search")}
                        inputVal={searchQuery}
                        changeHandler={(e) => setSearchQuery(e.target.value)}
                    />
                </div>

                <div className={styles.msg_list_body}>
                    {data?.data.map((patient) => (
                        <Button
                            text
                            className={`${styles.patient_btn} ${selectedPatientId === patient.id ? styles.active : ""
                                }`}
                            key={patient.id}
                            onClick={() => getSelectedChannel(patient)}>
                            <div className={styles.patient_data}>
                                <div className={styles.patient_img}>
                                    <img src={patient.avatar} alt="" />
                                </div>
                                <div className={styles.info}>
                                    <h4>{patient.name}</h4>
                                    <h5>{patient.bio}</h5>
                                </div>
                            </div>
                            {patient.messages > 0 && (
                                <div className={styles.msg_count}>{patient.messages}</div>
                            )}
                        </Button>
                    ))}
                </div>
            </div>
            <div className={styles.msg_chat}>
                {selectedPatientId ? (
                    <div className={styles.chat_holder}>
                        <div className={styles.chat_head}>
                            <div className={styles.patient_info}>
                                <div className={styles.img}>
                                    <img src={selectedChannel.image} alt="" />
                                </div>
                                <div className={styles.patient_data}>
                                    <h5>{selectedChannel.name}</h5>
                                    <p>This is a subtitle</p>
                                </div>
                            </div>

                            <WhiteBlackButton
                                label={Tr.tr("basic:View_Profile")}
                                withIconImg
                                icon="/img/eye-black.svg"
                                outlined
                                classes="p712"
                                redirectTo={`/doctor/patients/${selectedPatientId}`}
                            />
                        </div>
                        <div className={styles.chat_body}>
                            {messages?.data.length > 0 ? messages?.data.map((message, index) => (
                                <Fragment key={index}>
                                    <div className={styles.date}>{message.date}</div> {/* Nov 15, 2023 */}
                                    <div className={styles.chat_boxes}>
                                        <div className={`${styles.chat_box} ${selectedChannel?.channel_member_ids[0].name == message.author_id.name ? styles.receiver : styles.sender}`}>
                                            <div className={styles.img}></div>
                                            <div className={`${styles.chat_bubble} ${selectedChannel?.channel_member_ids[0].name == message.author_id.name ? styles.left : styles.right}`}>
                                                <div className={styles.text} dangerouslySetInnerHTML={{ __html: message.body }}></div>
                                                <div className={styles.time}>{message.date.split(' ')[1]}</div>
                                            </div>
                                        </div>
                                        {/* <div className={`${styles.chat_box} ${selectedChannel?.channel_member_ids[0].name == message.author_id.name ? styles.receiver: styles.sender}`}>
                                            <div className={styles.img}>
                                                <img src={selectedChannel.image} alt="" />
                                            </div>
                                            <div className={`${styles.chat_bubble} ${selectedChannel?.channel_member_ids[0].name == message.author_id.name ? styles.left : styles.right}`}>
                                                <div className={styles.text}>
                                                    I have a question about my next appointment
                                                </div>
                                                <div className={styles.time}>5:00 PM</div>
                                            </div>
                                        </div> */}
                                    </div>
                                </Fragment>)) : ""}
                            {/* <div className={styles.date}>Nov 15, 2023</div>
                            <div className={styles.chat_boxes}>
                                <div className={`${styles.chat_box} ${styles.receiver}`}>
                                    <div className={styles.img}></div>
                                    <div className={`${styles.chat_bubble} ${styles.left}`}>
                                        <div className={styles.text}>Hallo, Dr Amr</div>
                                        <div className={styles.time}>5:00 PM</div>
                                    </div>
                                </div>
                                <div className={`${styles.chat_box} ${styles.receiver}`}>
                                    <div className={styles.img}>
                                        <img src={selectedPatient.image} alt="" />
                                    </div>
                                    <div className={`${styles.chat_bubble} ${styles.left}`}>
                                        <div className={styles.text}>
                                            I have a question about my next appointment
                                        </div>
                                        <div className={styles.time}>5:00 PM</div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.date}>Nov 14, 2023</div>
                            <div className={styles.chat_boxes}>
                                <div className={`${styles.chat_box} ${styles.sender}`}>
                                    <div className={`${styles.chat_bubble} ${styles.right}`}>
                                        <div className={styles.text}>Hi 👋, Ahmed</div>
                                        <div className={styles.time}>5:00 PM</div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        <form className={styles.chat_footer} onSubmit={formik.handleSubmit}>
                            <TextAreaInput
                                inputLabel=""
                                inputName="message"
                                placeHolder={Tr.tr("doctor:Messages:Type_Your_Message")}
                                inputVal={formik.values.message}
                                changeHandler={formik.handleChange}
                                inputError={getFormErrorMessage("message", formik)}
                            />
                            <GradientButton
                                btn
                                type="submit"
                                label={Tr.tr("doctor:Messages:Send")}
                                classes="send_btn"
                            // buttonClicked={handleSubmit}
                            />
                        </form>
                    </div>
                ) : (
                    <div className={styles.no_chat}>
                        {Tr.tr("doctor:Messages:Select_Someone_To_Star_cthat")}
                    </div>
                )}
            </div>
        </div>
    );
};

export default DoctorMessages;

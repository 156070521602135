import { Checkbox } from "primereact/checkbox";

// Styles
// import styles from "./CheckBox.module.scss";

export function CheckBox({ ...props }) {
	return (
		<label className="check_box">
			<Checkbox
				name={props.name}
				value={props.value}
				onChange={props.handleChange}
				checked={props.checked}
				id={props.id}
				key={props.id}
			/>
			<span className="checkbox_lbl">{props.label}</span>
		</label>
	);
}

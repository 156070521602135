import { MultiSelect } from "primereact/multiselect";

// Components
import ErrorUI from "components/Common/UI/ErrorUI/ErrorUI";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./MultiSelectDropdown.module.scss";

export function MultiSelectDropdown({ ...props }) {
	return (
		<div className="input_holder">
			<div className={styles.field_holder}>
				{props.inputLabel && (
					<h6 className={props.required ? "required" : ""}>
						{props.inputLabel}
					</h6>
				)}
				<div
					className={`w-100 ${styles.input_box} ${
						props.inputError ? "p-invalid" : ""
					}`}>
					<MultiSelect
						name={props.inputName}
						value={props.inputVal}
						options={props.options}
						optionLabel="label"
						placeholder={props.placeHolder}
						onChange={props.changeHandler}
						maxSelectedLabels={3}
						className="w-100"
						panelClassName={`${styles.multi_select} ${
							props.filter ? "" : styles.no_filter
						}`}
						appendTo={props.appendTo}
						filter={props.filter}
						filterPlaceholder={Tr.tr("basic:Search")}
						display={props.display}
						removeIcon="pi pi-times"
					/>
				</div>
			</div>
			{props.inputError && (
				<ErrorUI errorMsg={props.inputError} withBG={props.withBG} />
			)}
		</div>
	);
}

// Components
import DialogWrapper from "../DialogWrapper/DialogWrapper";
import ConsultationCall from "../ConsultationCall/ConsultationCall";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./ConsultationCallModal.module.scss";

const ConsultationCallModal = ({ ...props }) => {
	return (
		<DialogWrapper
			header={Tr.tr("basic:Consultation_Call")}
			visible={props.visible}
			width="42vw"
			hideHandler={props.hideHandler}>
			<div className="modal_body">
				<ConsultationCall withoutBorder />
			</div>
		</DialogWrapper>
	);
};

export default ConsultationCallModal;

import { Rating } from "primereact/rating";

// Styles
import styles from "./Rate.module.scss";

const Rate = ({
	rateVal,
	handleRateChange,
	readOnly,
	filledIcon = true,
	smallStar = true,
}) => {
	return (
		<div className={styles.rate_holder}>
			<Rating
				value={rateVal}
				cancel={false}
				onChange={handleRateChange}
				readOnly={readOnly}
				offIcon={filledIcon ? "pi pi-star-fill" : "pi pi-star"}
				onIcon="pi pi-star-fill"
				className={!smallStar ? "bigStar" : ""}
			/>
		</div>
	);
};

export default Rate;

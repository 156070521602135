import { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

// Components
import WhiteButton from "components/Common/UI/WhiteButton/WhiteButton";
import { SelectDropdown, TextInput } from "components/Common/Form/Inputs";

// Translation
import { withTranslation } from "react-i18next";
import { Tr } from "utils/i18n";

// Styles
import styles from "./LabTestAccordion.module.scss";

const LabTestAccordion = () => {
	// Is Edit
	const [isEdit, setIsEdit] = useState(false);

	// Lab Test
	const [labTest, setLabTest] = useState([
		{
			id: "1",
			test: "Allergen-specific IgE",
			recommendedLab: "Lab Name",
		},
	]);

	// Action [View]
	const actionBodyTemplate = (rowData) => {
		return <WhiteButton label={Tr.tr("basic:View")} redirectTo="" />;
	};

	// Test Template
	const testTemplate = () => {
		return (
			<TextInput
				inputLabel=""
				inputName="test"
				placeHolder={Tr.tr("basic:Enter_Test")}
				inputVal={""}
				changeHandler={() => {}}
				inputError={""}
			/>
		);
	};

	// Recommended Labs
	const [selectedLab, setSelectedLab] = useState(null);
	const labs = [
		{
			label: "Lab Name 1",
			value: "Lab Name 1",
		},
		{
			label: "Lab Name 2",
			value: "Lab Name 2",
		},
		{
			label: "Lab Name 3",
			value: "Lab Name 3",
		},
	];

	// Recommended Lab Template
	const recommendedLabTemplate = () => {
		return (
			<SelectDropdown
				inputLabel=""
				inputName="lab"
				placeHolder={Tr.tr("basic:Search_Labs")}
				options={labs}
				inputVal={selectedLab}
				changeHandler={(e) => setSelectedLab(e.value)}
				appendTo={document.body}
				inputError={""}
				filter={true}
				filterPlaceholder={Tr.tr("basic:Search_Labs")}
			/>
		);
	};

	return (
		<div className={styles.lab_test}>
			{!isEdit ? (
				<DataTable value={labTest} tableStyle={{ minWidth: "100%" }}>
					<Column field="test" header={Tr.tr("basic:Test")}></Column>
					<Column
						field="recommendedLab"
						header={Tr.tr("basic:Recommended_Lab")}></Column>
					<Column body={actionBodyTemplate}></Column>
				</DataTable>
			) : (
				<DataTable value={labTest} tableStyle={{ minWidth: "100%" }}>
					<Column
						field="test"
						header={Tr.tr("basic:Test")}
						body={testTemplate}></Column>
					<Column
						field="recommendedLab"
						header={Tr.tr("basic:Recommended_Lab")}
						body={recommendedLabTemplate}></Column>
				</DataTable>
			)}
		</div>
	);
};

export default withTranslation()(LabTestAccordion);

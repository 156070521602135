import "./assets/scss/vars.scss";

import PrimeReact from 'primereact/api';
import "primereact/resources/themes/lara-light-indigo/theme.css"; //theme
import "primereact/resources/primereact.min.css"; //core css
import "primeicons/primeicons.css";

// import i18n (needs to be bundled ;))
import "./utils/i18n";

// Routes
import AllRoutes  from "routes"

// Styles
import "./App.scss";

function App() {
	PrimeReact.ripple = true;


	return (
		<div className="App">
			<AllRoutes />
		</div>
	);
}

export default App;

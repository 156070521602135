export const checkTimeValidity = (fromHour, toHour) => {
	const [fromHours, fromMinutes] = fromHour.split(".").map(Number);
	const [toHours, toMinutes] = toHour.split(".").map(Number);

	const fromTotalMinutes = fromHours * 60 + fromMinutes;
	const toTotalMinutes = toHours * 60 + toMinutes;

	if (fromTotalMinutes >= toTotalMinutes) {
		console.log("from_hour is greater than to_hour");
		return false;
	}
	return true;
};

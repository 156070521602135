import { useState, useMemo } from "react";
import { SelectButton } from "primereact/selectbutton";
import { useNavigate } from "react-router-dom";
import { Tabs, Tab } from "react-tabs-scrollable";
import { useQuery } from "react-query";
import "react-tabs-scrollable/dist/rts.css";
import dayjs from "dayjs";

// Components
import DoctorInfoCard from "components/Patient/DoctorInfoCard/DoctorInfoCard";
import AboutTheDoctor from "./components/AboutTheDoctor/AboutTheDoctor";
import BookingInformation from "./components/BookingInformation/BookingInformation";
import PatientsReviews from "./components/PatientsReviews/PatientsReviews";
import GradientButton from "components/Common/UI/GradientButton/GradientButton";
import ConsultationCall from "components/Patient/ConsultationCall/ConsultationCall";

// Translation
import { useTranslation } from "react-i18next";
import { Tr } from "utils/i18n";

// Styles
import styles from "./DoctorInfo.module.scss";

// APIs
import {
	bookSlotAPI,
	doctorAvailabilityAPI,
	getDoctorAPI,
	getRatingAPI,
} from "api/doctors";

import { use } from "i18next";

const DoctorInfo = ({ doctorData, doctorLoading, doctorId }) => {
	const { i18n } = useTranslation();
	const currLang = i18n.language;
	const options = [
		{
			icon: "/img/hospital.svg",
			label: Tr.tr("patient:DoctorDetails:In_Clinic"),
			value: "inClinic",
		},
		{
			icon: "/img/video.svg",
			label: Tr.tr("patient:DoctorDetails:Video_Call"),
			value: "videoCall",
		},
	];
	const [value, setValue] = useState(options[0].value);
	const [selectedTime, setSelectedTime] = useState("");
	const [selectedAppointment, setSelectedAppointment] = useState();

	const [activeTab, setActiveTab] = useState(0);

	const [availableTimes, setAvailableTimes] = useState();

	const [averageRating, setAverageRating] = useState(0);

	// Navigation
	const navigate = useNavigate();

	const dateTab = useMemo(() => {
		return Array.from({ length: 7 }, (_, i) => {
			const date = dayjs().add(i, "day");
			return {
				date: date.format("YYYY-MM-DD"),
				day: i === 0 ? "Today" : i === 1 ? "Tomorrow" : date.format("dddd"),
			};
		});
	}, []);

	const {
		data: availabilityData,
		isLoading: availabilityLoading,
		refetch,
	} = useQuery(
		["availability", doctorId, dateTab[activeTab]?.date],
		() =>
			doctorAvailabilityAPI({
				doctor_id: doctorId,
				date: dateTab[activeTab]?.date,
			}),
		{
			enabled: !!doctorId && dateTab.length > 0,
			onSuccess: (data) => {
				setAvailableTimes({
					times: data?.data,
				});
			},
		}
	);

	const { data: ratingData, isLoading: ratingLoading } = useQuery(
		["rating", doctorId],
		getRatingAPI,
		{
			select: (response) => {
				return response?.data?.data;
			},
		}
	);

	const optionsTemplate = (option) => {
		return (
			<>
				<img src={option.icon} alt="" />
				<span className="btn_lbl">{option.label}</span>
			</>
		);
	};

	// Book Now Handler
	const bookNow = () => {
		console?.log(selectedTime, 'selectedTime', selectedAppointment)
		// const appointmentId = 
		navigate(
			`/patient/find-a-doctor/${doctorId}/confirm-booking/${activeTab === 0 ? dateTab[0].date : dateTab[activeTab].date
			}/${selectedTime}/${value}`,
		);
	};
	const onTabClick = async (e, index) => {
		setActiveTab(index);
		refetch();
	};
	console.log({ doctorData })

	if (doctorLoading || ratingLoading) {
		return (
			<div className="spinner-border d-block mx-auto" role="status">
				<span className="visually-hidden">Loading...</span>
			</div>
		);
	}

	return (
		<section className={styles.doctor_info}>
			<div className="container">
				<div className="row">
					<div className="col-md-12 col-lg-6 col-xl-7 col-xxl-8 mb-3 mb-lg-0">
						{/* Doctor Info Card */}
						<DoctorInfoCard
							info={doctorData}
							averageRating={doctorData.rating.avg}
							classes="mb-3"
						/>

						{/* About The Doctor */}
						<AboutTheDoctor data={doctorData} />

						{/* Booking Information */}
						<BookingInformation bookingInfo={doctorData} />

						{/* Patients Reviews */}
						<PatientsReviews reviewers={ratingData} />
					</div>
					<div className="col-md-12 col-lg-6 col-xl-5 col-xxl-4">
						{/* Book Your Appointment */}
						<div className={styles.book_appointment}>
							<h3>{Tr.tr("patient:DoctorDetails:Book_Your_Appointment")}</h3>
							<div className={styles.selection_btns}>
								<SelectButton
									value={value}
									onChange={(e) => setValue(e.value)}
									options={options}
									unselectable={false}
									itemTemplate={optionsTemplate}
								/>
							</div>
							{value === "inClinic" && (
								<div className={styles.examiniation_fees}>
									{Tr.tr("patient:DoctorDetails:Examination_Fees_IN_Clinic")}{" "}
									<span>
										{doctorData.clinic_fees}{" "}
										{Tr.tr("patient:DoctorDetails:SAR")}
									</span>
								</div>
							)}
							{value === "videoCall" && (
								<div className={styles.examiniation_fees}>
									{Tr.tr("patient:DoctorDetails:Video_Call_Fees")}{" "}
									<span>
										{doctorData.video_fees} {Tr.tr("patient:DoctorDetails:SAR")}
									</span>
								</div>
							)}
						</div>
						{/* Appointments */}
						<div className={styles.appointments}>
							<Tabs
								activeTab={activeTab}
								onTabClick={onTabClick}
								rightBtnIcon={
									<svg
										width="18"
										height="18"
										viewBox="0 0 18 18"
										fill="none"
										xmlns="http://www.w3.org/2000/svg">
										<path
											d="M11.625 9.375C11.625 9.6 11.55 9.75 11.4 9.9L7.65 13.65C7.35 13.95 6.9 13.95 6.6 13.65C6.3 13.35 6.3 12.9 6.6 12.6L9.825 9.375L6.6 6.15C6.3 5.85 6.3 5.4 6.6 5.1C6.9 4.8 7.35 4.8 7.65 5.1L11.4 8.85C11.55 9 11.625 9.15 11.625 9.375Z"
											fill="#020E1F"
										/>
									</svg>
								}
								leftBtnIcon={
									<svg
										width="18"
										height="18"
										viewBox="0 0 18 18"
										fill="none"
										xmlns="http://www.w3.org/2000/svg">
										<path
											d="M6.375 9.375C6.375 9.15 6.45 9 6.6 8.85L10.35 5.1C10.65 4.8 11.1 4.8 11.4 5.1C11.7 5.4 11.7 5.85 11.4 6.15L8.175 9.375L11.4 12.6C11.7 12.9 11.7 13.35 11.4 13.65C11.1 13.95 10.65 13.95 10.35 13.65L6.6 9.9C6.45 9.75 6.375 9.6 6.375 9.375Z"
											fill="#B5BEC9"
										/>
									</svg>
								}
								isRTL={currLang === "ar" ? true : false}
								tabsScrollAmount={1}>
								{dateTab.map((data, index) => (
									<Tab key={index} className="header_btn">
										<span className="day">{data?.day}</span>
										<span className="date">{data?.date}</span>
									</Tab>
								))}
							</Tabs>

							{/* Times */}
							<div className={styles.times}>
								{availableTimes?.times.length === 0 ? (
									<label className={`${styles.time_box} `}>
										<input
											name="times"
											type="radio"
											value="Not Available Times"
											checked={true}
											onChange={(e) => {
												setSelectedTime(e.target.value)
											}}
										// disabled={false}
										/>
										<span className={styles.lbl_content}>
											<span className={styles.lbl_txt}>
												{Tr.tr("patient:DoctorDetails:Not_Available_Times")}
											</span>
										</span>
									</label>
								) : (
									availableTimes?.times.map((time, index) => (
										<label
											className={`${styles.time_box} ${time.is_available ? "" : styles.not_available
												}`}
											key={index}>
											<input
												name="times"
												type="radio"
												value={time.from}
												checked={time.from === selectedTime}
												onChange={(e) => { setSelectedTime(e.target.value); setSelectedAppointment(time) }}
												disabled={!time.is_available}
											/>
											<span className={styles.lbl_content}>
												<span className={styles.lbl_txt}>{time.from}</span>
											</span>
										</label>
									))
								)}
							</div>

							{/* Book Now Btn */}
							<div className={styles.book_now}>
								<GradientButton
									disabled={
										availableTimes?.times.length || selectedTime === 0
											? false
											: true
									}
									btn
									type="button"
									label={Tr.tr("patient:DoctorDetails:Book_Now")}
									classes="w-100"
									buttonClicked={() => bookNow()}
								/>
							</div>
						</div>

						{/* Consultation Call */}
						<ConsultationCall fees={doctorData.clinic_fees} />
					</div>
				</div>
			</div>
		</section>
	);
};

export default DoctorInfo;

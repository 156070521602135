import { useState } from "react";
import { SelectButton } from "primereact/selectbutton";
import ReactApexChart from "react-apexcharts";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./AppointmentsChart.module.scss";

const AppointmentsChart = () => {
	// Duration [Daily, Week, Monthly]
	const options = ["Daily", "Weekly", "Monthly"];
	const [value, setValue] = useState(options[0]);

	// Chart
	const chartSeries = [
		{
			name: Tr.tr("basic:In_Clinic"),
			data: [8, 13, 7, 5, 12, 10, 5],
		},
		{
			name: Tr.tr("basic:Video_Call"),
			data: [14, 12, 8, 5, 20, 17, 8],
		},
	];

	// Define your custom y-axis values
	const customYAxisValues = [0, 5, 10, 15, 20]; // Example custom values

	// Custom tooltip title for each point
	const customTooltipTitle = [
		"Mon, Nov 8, 2023",
		"Tue, Nov 8, 2023",
		"Wed, Nov 8, 2023",
		"Thu, Nov 8, 2023",
		"Fri, Nov 8, 2023",
		"Sat, Nov 8, 2023",
		"Sun, Nov 8, 2023",
	];

	const chartOptions = {
		chart: {
			type: "line",
			toolbar: {
				show: false, // Hide all toolbar functions
				menu: {
					show: false, // Hide the menu button
				},
			},
			zoom: {
				enabled: false, // Disable zoom functionality
			},
		},
		xaxis: {
			categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
			tooltip: {
				enabled: false,
			},
		},
		yaxis: {
			// min: 0,
			// max: 20,
			// Specify the custom y-axis values
			min: customYAxisValues[0],
			max: customYAxisValues[customYAxisValues.length - 1],
			tickAmount: customYAxisValues.length - 1,
		},
		stroke: {
			curve: "smooth",
			width: 2,
			colors: ["#29B6C2", "#DC3545"],
		},
		grid: {
			// Customize the grid lines
			strokeDashArray: 4, // Set the dash array for the grid lines
			borderColor: "#E5E5EF", // Set the color of the grid lines
			row: {
				colors: ["#fff"], // Set the colors for alternating rows
				opacity: 0, // Set the opacity of the rows
			},
		},
		colors: ["#21929B", "#B02A37"],
		tooltip: {
			x: {
				formatter: function (dataPointIndex) {
					return customTooltipTitle[dataPointIndex - 1];
				},
			},
		},
	};

	return (
		<section className={styles.chart_holder}>
			<div className={styles.chart_head}>
				<h2>
					{Tr.tr("doctor:Reports:Total")}: 271{" "}
					{Tr.tr("doctor:Reports:Appointments")}
				</h2>
				<div className={styles.duration_selection}>
					<SelectButton
						value={value}
						onChange={(e) => setValue(e.value)}
						options={options}
						unselectable={false}
					/>
				</div>
			</div>

			<div className={styles.chart_statistics}>
				<div className="row">
					<div className="col-lg-6 mb-3 mb-lg-0">
						<div className={styles.statistics_box}>
							<p>{Tr.tr("basic:In_Clinic")}</p>
							<div className={styles.data}>
								<h4>271 {Tr.tr("doctor:Reports:Appointments")}</h4>
								<h5>
									<img src="/img/grow-arrow-green.svg" alt="" />
									<span>1.3%</span>
								</h5>
								<h6>{Tr.tr("doctor:Reports:VS_LAST_YEAR")}</h6>
							</div>
						</div>
					</div>
					<div className="col-lg-6">
						<div className={styles.statistics_box}>
							<p>{Tr.tr("basic:Video_Call")}</p>
							<div className={styles.data}>
								<h4>271 {Tr.tr("doctor:Reports:Appointments")}</h4>
								<h5>
									<img src="/img/grow-arrow-green.svg" alt="" />
									<span>1.3%</span>
								</h5>
								<h6>{Tr.tr("doctor:Reports:VS_LAST_YEAR")}</h6>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className={styles.chart_wrapper}>
				<ReactApexChart
					options={chartOptions}
					series={chartSeries}
					type="line"
					height={350}
				/>
			</div>
		</section>
	);
};

export default AppointmentsChart;

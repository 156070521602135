// Components
import AppointmentsChart from "./components/AppointmentsChart/AppointmentsChart";
import AppointmentsSummary from "./components/AppointmentsSummary/AppointmentsSummary";
import AppointmentsDetails from "./components/AppointmentsDetails/AppointmentsDetails";

// Styles
import styles from "./AppointmentsReports.module.scss";

const AppointmentsReports = () => {
	return (
		<div className={styles.appointments_reports}>
			{/* Chart */}
			<AppointmentsChart />

			{/* Summary */}
			<AppointmentsSummary />

			{/* Details */}
			<AppointmentsDetails />
		</div>
	);
};

export default AppointmentsReports;

import { useRef, useState } from "react";
import { Menu } from "primereact/menu";
import { Button } from "primereact/button";

// Components
import AnswerCard from "../AnswerCard/AnswerCard";

// Translation
import { useTranslation } from "react-i18next";
import { Tr } from "utils/i18n";

// Styles
import styles from "./QuestionCard.module.scss";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { deleteQuestionsAPI, questionDetailsAPI } from "api/questions";
import toast from "react-hot-toast";
import AddQuestionDrawer from "../AddQuestionDrawer/AddQuestionDrawer";

const QuestionCard = ({ data }) => {
    const { i18n } = useTranslation();
    const currLang = i18n.language;
    // Show Edit Question Drawer
    const [visible, setVisible] = useState(false);
    const [questionId, setQuestionId] = useState('');

    const menu = useRef(null);

    const queryClient = useQueryClient();

    const { mutate: deleteQuestion } = useMutation(deleteQuestionsAPI, {
        onSuccess: (data) => {
            toast.success("Question has been Deleted Successfully");
            queryClient.invalidateQueries('questions')
        },
        onError: (error) => {
            toast.error(error?.response?.data?.message);
        },
    })

    const { data: questionData, isLoading: questionLoading } = useQuery(
        ["questions", questionId],
        questionDetailsAPI,
        {
            select: (response) => {
                return response?.data;
            },
        }
    );

    // Ellipses Menu
    const items = [
        {
            template: (item, options) => {
                return (
                    <div onClick={() => console.log(options)}>
                        <Button
                            onClick={(e) => {
                                options.onClick(e);
                                setVisible(true);
                                setQuestionId(options.props.defaultValue);
                            }}
                            className={`${options.className}`}>
                            <img src="/img/edit-black.svg" alt="" />
                            <span className="update">{Tr.tr("basic:Edit")}</span>
                        </Button>
                    </div>

                );
            },
        },
        {
            template: (item, options) => {
                return (
                    <Button
                        onClick={(e) => {
                            options.onClick(e);
                            deleteQuestion(options.props.defaultValue);
                        }}
                        className={`${options.className}`}>
                        <img src="/img/delete-red.svg" alt="" />
                        <span className="delete">{Tr.tr("basic:Delete")}</span>
                    </Button>
                );
            },
        },
    ];

    return (
        <div className={styles.qty_card}>
            <div className={styles.qty_data}>
                <h5 className={styles.specialty}>{data.doctor_specialty_id?.name}</h5>
                <div className={styles.qty_date}>
                    <h3>{data.name}</h3>
                    <h6>{data.date}</h6>
                </div>
                <p className={styles.description}>{data.description}</p>

                {/* Answers */}
                {data?.answer_ids?.length > 0 ? (
                    <div className={styles.answer_cards}>
                        {data.answer_ids.map((answer) => (
                            <AnswerCard data={answer} key={answer.id} />
                        ))}
                    </div>
                ) : (
                    <div className={styles.no_answer}>
                        {Tr.tr("patient:PatientProfile:No_Answer_Yet")}
                    </div>
                )}
            </div>

            {/* Ellipses Menu */}
            <div className={styles.qty_action}>
                <Menu
                    model={items}
                    popup
                    ref={menu}
                    id="popup_menu"
                    popupAlignment={currLang === "ar" ? "left" : "right"}
                    className={styles.action_menu}
                    defaultValue={data.id}
                />
                <Button
                    icon="pi pi-ellipsis-v"
                    onClick={(event) => menu.current.toggle(event)}
                    aria-controls="popup_menu"
                    aria-haspopup
                    className={styles.action_btn}
                />
            </div>
            {/* Add Question Drawer */}
            <AddQuestionDrawer
                visible={visible}
                hideHandler={() => {
                    setVisible(false);
                }}
                flag='edit'
                questionId ={questionId}
                questionData={questionData}
            />
        </div>
    );
};

export default QuestionCard;

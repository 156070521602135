// Components
import CircularProgress from "components/Common/UI/CircularProgress/CircularProgress";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./AppointmentsSummary.module.scss";

const AppointmentsSummary = () => {
	return (
		<section className={styles.summary}>
			<h2>{Tr.tr("doctor:Reports:Summary")}</h2>
			<div className="row">
				<div className="col-lg-6 mb-3 mb-lg-0">
					<div className={styles.summary_box}>
						<div className={styles.left}>
							<img src="/img/hospital-gray.svg" alt="" />
							<div className={styles.info}>
								<h6>{Tr.tr("basic:In_Clinic")}</h6>
								<h4>70 {Tr.tr("basic:Appointments")}</h4>
							</div>
						</div>
						<CircularProgress
							percentage={70}
							pathColor="#29B6C2"
							trailColor="#D4F0F3"
						/>
					</div>
				</div>
				<div className="col-lg-6">
					<div className={styles.summary_box}>
						<div className={styles.left}>
							<img src="/img/video-gray.svg" alt="" />
							<div className={styles.info}>
								<h6>{Tr.tr("basic:Video_Call")}</h6>
								<h4>30 {Tr.tr("basic:Appointments")}</h4>
							</div>
						</div>
						<CircularProgress
							percentage={30}
							pathColor="#DC3545"
							trailColor="#F8D7DA"
						/>
					</div>
				</div>
			</div>
		</section>
	);
};

export default AppointmentsSummary;

import { useEffect, useState } from "react";
import { useMutation } from "react-query";

// Components
import NextPatientDetails from "./components/NextPatientDetails/NextPatientDetails";
import TodayAppointments from "./components/TodayAppointments/TodayAppointments";
import AppointmentsRequests from "./components/AppointmentsRequests/AppointmentsRequests";

// APIs
import { getAppointmentsAPI } from "api/appointments";
import { getMeApi } from "api/auth";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./OnBoardingAppointments.module.scss";

const OnBoardingAppointments = () => {
	const [confirmedTodayAppointmentsData, setConfirmedTodayAppointmentsData] =
		useState([]);
	const [nextConfirmedAppointmentData, setNextConfirmedAppointmentData] =
		useState();
	const [requestedAppointmentsData, setRequestedAppointmentsData] = useState(
		[]
	);

	const { mutate: confirmedTodayAppointments } = useMutation(
		getAppointmentsAPI,
		{
			onSuccess: (data) => {
				setConfirmedTodayAppointmentsData(data.data.data);
			},
		}
	);

	const { mutate: nextConfirmedAppointment } = useMutation(getAppointmentsAPI, {
		onSuccess: (data) => {
			setNextConfirmedAppointmentData(data.data.data[0]);
		},
	});

	const { mutate: requestedAppointments } = useMutation(getAppointmentsAPI, {
		onSuccess: (data) => {
			setRequestedAppointmentsData(data.data.data);
		},
	});

	const today = new Date();

	const startOfDay = new Date(
		today.getFullYear(),
		today.getMonth(),
		today.getDate() + 1,
		0,
		0,
		0
	);
	const endOfDay = new Date(
		today.getFullYear(),
		today.getMonth(),
		today.getDate() + 1,
		23,
		59,
		59
	);

	const dateFromString = startOfDay.toISOString().split("T")[0];
	const dateToString = endOfDay.toISOString().split("T")[0];

	const { mutate: getMe } = useMutation(getMeApi, {
		onSuccess: (response) => {
			confirmedTodayAppointments({
				id: response.data.id,
				startDate: dateFromString,
				endDate: dateToString,
				status: "confirmed",
			});
			nextConfirmedAppointment({ id: response.data.id, status: "confirmed" });
			requestedAppointments({ id: response.data.id, status: "requested" });
		},
	});
	useEffect(() => {
		getMe();
	}, []);
	console.log(confirmedTodayAppointmentsData);

	return (
		<div className={styles.onBoarding_appointments}>
			<h2>{Tr.tr("basic:Appointments")}</h2>

			<div className="row">
				<div className="col-md-12 col-lg-7 mb-4 mb-lg-0">
					{/* Next Patient Details */}
					{nextConfirmedAppointmentData && (
						<NextPatientDetails
							nextAppointment={nextConfirmedAppointmentData}
						/>
					)}

					{/* Today Appointments */}
					<TodayAppointments
						appointments={confirmedTodayAppointmentsData}
						setConfirmedTodayAppointmentsData={
							setConfirmedTodayAppointmentsData
						}
					/>
				</div>

				{/* Appointments Requests */}
				<div className="col-md-12 col-lg-5">
					<AppointmentsRequests
						appointments={requestedAppointmentsData}
						setRequestedAppointmentsData={setRequestedAppointmentsData}
						setConfirmedTodayAppointmentsData={
							setConfirmedTodayAppointmentsData
						}
						nextConfirmedAppointmentData={nextConfirmedAppointmentData}
						setNextConfirmedAppointmentData={setNextConfirmedAppointmentData}
					/>
				</div>
			</div>
		</div>
	);
};

export default OnBoardingAppointments;

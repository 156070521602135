import { useContext, useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import { useMutation } from "react-query";
import { toast } from "react-hot-toast";

// Components
import TitleWithLine from "components/Common/UI/TitleWithLine/TitleWithLine";
import PageHead from "components/Patient/PageHead/PageHead";
import {
	SelectDropdownWithRadioBtn,
	SwitchInput,
	TimePicker,
} from "components/Common/Form/Inputs";
import GradientButton from "components/Common/UI/GradientButton/GradientButton";

// utils
import {
	convertToFullTimestamp,
	convertFrom12To24,
} from "utils/convertToFullTimeStamp";
import { checkTimeValidity } from "utils/checkTimeValidity";
import { getFormErrorMessage } from "utils/FormField";

// Context
import { SidebarContext } from "context/DoctorSidebarContext";

// APIs
import { getMeApi, updateMeApi } from "api/auth";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./SettingsCalendar.module.scss";

const SettingsCalendar = () => {
	const { setSmallSize } = useContext(SidebarContext);

	const { mutate: getMe } = useMutation(getMeApi, {
		onSuccess: (data) => {
			// console.log(data.data.config_availability_ids);
			formik.setFieldValue("consultOnline", data.data.can_make_video_consult);
			formik.setFieldValue("videoDuration", 0.5);
			formik.setFieldValue("duration", 1.0);

			setPracticeDays((prevPracticeDays) => {
				const updatedPracticeDays = prevPracticeDays.map((existingDay) => {
					const matchingItem = data.data.config_availability_ids?.find(
						(item) => item.day.toLowerCase() === existingDay.day.toLowerCase()
					);
					if (matchingItem) {
						return {
							...existingDay,
							day:
								matchingItem.day[0].toUpperCase() + matchingItem.day.slice(1),
							opened: true,
							start: convertToFullTimestamp(matchingItem.from_hour),
							end: convertToFullTimestamp(matchingItem.to_hour),
						};
					}
					return existingDay;
				});
				return updatedPracticeDays;
			});
		},
	});

	const { mutate: updateMe } = useMutation(updateMeApi, {
		onSuccess: (data) => {
			toast.success("Settings updated successfully");
		},
	});

	useEffect(() => {
		getMe();
	}, []);

	// Duration
	const durations = [
		{
			label: "30 minutes",
			value: 0.5,
		},
		{
			label: "1 hour",
			value: 1.0,
		},
		{
			label: "1 hour 30 minutes",
			value: 1.5,
		},
		{
			label: "2 hour",
			value: 2.0,
		},
	];

	// Video Consult Duration
	const videoConsultDurations = [
		{
			label: "30 minutes",
			value: 0.5,
		},
		{
			label: "1 hour",
			value: 1.0,
		},
		{
			label: "1 hour 30 minutes",
			value: 1.5,
		},
		{
			label: "2 hour",
			value: 2.0,
		},
	];

	// Practice Days
	const [practiceDays, setPracticeDays] = useState([
		{
			id: 1,
			day: Tr.tr("basic:Sunday"),
			opened: false,
			start: "9:00 AM",
			end: "5:00 PM",
		},
		{
			id: 2,
			day: Tr.tr("basic:Monday"),
			opened: false,
			start: "9:00 AM",
			end: "5:00 PM",
		},
		{
			id: 3,
			day: Tr.tr("basic:Tuesday"),
			opened: false,
			start: "9:00 AM",
			end: "5:00 PM",
		},
		{
			id: 4,
			day: Tr.tr("basic:Wednesday"),
			opened: false,
			start: "9:00 AM",
			end: "5:00 PM",
		},
		{
			id: 5,
			day: Tr.tr("basic:Thursday"),
			opened: false,
			start: "9:00 AM",
			end: "5:00 PM",
		},
		{
			id: 6,
			day: Tr.tr("basic:Friday"),
			opened: false,
			start: "9:00 AM",
			end: "5:00 PM",
		},
		{
			id: 7,
			day: Tr.tr("basic:Saturday"),
			opened: false,
			start: "9:00 AM",
			end: "5:00 PM",
		},
	]);

	useEffect(() => {
		setSmallSize(true);
	}, [setSmallSize]);

	const toggleSwitch = (itemId) => {
		setPracticeDays((prevItems) =>
			prevItems.map((item) =>
				item.id === itemId ? { ...item, opened: !item.opened } : item
			)
		);
	};

	const handleStartTimeChange = (itemId, newTime) => {
		setPracticeDays((prevItems) =>
			prevItems.map((item) =>
				item.id === itemId ? { ...item, start: newTime } : item
			)
		);
	};

	const handleEndTimeChange = (itemId, newTime) => {
		setPracticeDays((prevItems) =>
			prevItems.map((item) =>
				item.id === itemId ? { ...item, end: newTime } : item
			)
		);
	};

	const [initialDuration, setInitialDuration] = useState("");
	const [initialVideoDuration, setInitiaVideolDuration] = useState("");

	// Formik
	const formik = useFormik({
		initialValues: {
			duration: initialDuration,
			consultOnline: false,
			videoDuration: initialVideoDuration,
		},
		validate: (data) => {
			let errors = {};

			if (!data.duration) {
				errors.duration = "Duration is required.";
			}
			if (data.consultOnline && !data.videoDuration) {
				errors.videoDuration = "Video Duration is required.";
			}
			if (!data.consultOnline) {
				data.consultOnline = false;
				data.videoDuration = 0.0;
			}

			return errors;
		},
		onSubmit: async (data, { setSubmitting }) => {
			const availableTimes = practiceDays
				.filter((day) => day.opened)
				.map((day) => ({
					from_hour: convertFrom12To24(day.start),
					to_hour: convertFrom12To24(day.end),
					day: day.day.toLowerCase(),
				}));

			let invalidDays = [];
			availableTimes.forEach((timeslot) => {
				if (!checkTimeValidity(timeslot.from_hour, timeslot.to_hour)) {
					invalidDays.push(
						timeslot.day.charAt(0).toUpperCase() + timeslot.day.slice(1)
					);
				}
			});

			if (invalidDays.length > 0) {
				toast.error(
					`Error: Invalid time slots detected on the following days: ${invalidDays.join(
						", "
					)}.`
				);
				return;
			}

			await updateMe({
				config_availability_ids: availableTimes,
				duration_of_session: data.duration,
				duration_of_video_session: data.videoDuration,
				can_make_video_consult: data.consultOnline,
			});
			setSubmitting(false);
		},
	});

	return (
		<div className={styles.settings_calendar}>
			<TitleWithLine title={Tr.tr("doctor:Settings:Calendar")} mb="32px" />

			<form className={styles.form_holder} onSubmit={formik.handleSubmit}>
				{/* Default Appointment Duration Section */}
				<section className={styles.sec_holder}>
					<PageHead
						title={Tr.tr("doctor:Settings:Default_Appointment_Duration")}
						subTitle={Tr.tr(
							"doctor:Settings:Choose_How_Long_Appointments_Typically_Go_On_For"
						)}
						mb="24px"
						titleSize="16px"
					/>

					{/* Duration */}
					<div className={styles.select_duration}>
						<div className="row">
							<div className="col-lg-3">
								<SelectDropdownWithRadioBtn
									inputLabel={Tr.tr("doctor:Settings:Duration")}
									inputName="duration"
									placeHolder={Tr.tr("doctor:Settings:Select_Duration")}
									options={durations}
									inputVal={formik.values.duration}
									changeHandler={(e) =>
										formik.setFieldValue("duration", e.value)
									}
									appendTo="self"
									inputError={getFormErrorMessage("duration", formik)}
								/>
							</div>
						</div>
					</div>

					{/* Consult Patients */}
					<div
						className={`${styles.consult_patients} ${
							formik.values.consultOnline ? styles.mb24 : ""
						}`}>
						<SwitchInput
							checked={formik.values.consultOnline}
							changeHandler={(e) =>
								formik.setFieldValue("consultOnline", e.value)
							}
							label={
								<div className={styles.consult_online}>
									<h5>
										{Tr.tr(
											"doctor:Settings:Consult_Patients_Online_By_Video_Consult"
										)}
									</h5>
									<h6>
										{Tr.tr(
											"doctor:Settings:Enable_Video_Consult_Solution_For_Clinic_And_Start_Consulting_Patients_Online"
										)}
									</h6>
								</div>
							}
						/>
					</div>

					{/* Video Consult Duration */}
					{formik.values.consultOnline && (
						<div className={styles.video_duration}>
							<div className="row">
								<div className="col-lg-3">
									<SelectDropdownWithRadioBtn
										inputLabel={Tr.tr("doctor:Settings:Video_Consult_Duration")}
										inputName="videoDuration"
										placeHolder={Tr.tr("doctor:Settings:Select_Duration")}
										options={videoConsultDurations}
										inputVal={formik.values.videoDuration}
										changeHandler={(e) =>
											formik.setFieldValue("videoDuration", e.value)
										}
										appendTo="self"
										inputError={getFormErrorMessage("videoDuration", formik)}
									/>
								</div>
							</div>
						</div>
					)}
				</section>

				{/* Practice Days Section */}
				<section className={styles.sec_holder}>
					<PageHead
						title={Tr.tr("doctor:Settings:Add_Practice_Days")}
						subTitle={Tr.tr(
							"doctor:Settings:Specify_Days_You_Practising_And_Operating_Hours_Of_Clinic"
						)}
						mb="24px"
					/>

					<div className={styles.practice_days}>
						<div className="row">
							<div className="col-xl-9">
								{practiceDays.map((data) => (
									<div className={styles.day_row} key={data.id}>
										<div className="row">
											<div className="col-md-2">{data.day}</div>
											<div className="col-md-2">
												<SwitchInput
													checked={data.opened}
													changeHandler={() => toggleSwitch(data.id)}
													label={
														data.opened
															? Tr.tr("doctor:Settings:Open")
															: Tr.tr("doctor:Settings:Closed")
													}
													classes={data.opened ? "" : styles.closed}
												/>
											</div>
											<div className="col-md-8">
												<div className={styles.times_picker}>
													<TimePicker
														inputLabel=""
														inputName={`start-${data.id}`}
														placeHolder={Tr.tr("doctor:Settings:Start_Time")}
														inputVal={data.start}
														changeHandler={(e) =>
															handleStartTimeChange(data.id, e.target.value)
														}
														appendTo="self"
														disabled={!data.opened}
														classes={data.opened ? "" : "closed"}
													/>
													<span className={styles.to}>
														{Tr.tr("doctor:Settings:To")}
													</span>
													<TimePicker
														inputLabel=""
														inputName={`end-${data.id}`}
														placeHolder={Tr.tr("doctor:Settings:End_Time")}
														inputVal={data.end}
														changeHandler={(e) =>
															handleEndTimeChange(data.id, e.target.value)
														}
														appendTo="self"
														disabled={!data.opened}
														classes={data.opened ? "" : "closed"}
													/>
												</div>
											</div>
										</div>
									</div>
								))}
							</div>
						</div>
					</div>
				</section>

				<GradientButton
					btn
					type="submit"
					label={Tr.tr("doctor:Settings:Save_Changes")}
					classes="p712 lineH-24"
				/>
			</form>
		</div>
	);
};

export default SettingsCalendar;

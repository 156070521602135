import { Link } from "react-router-dom";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import Cookies from "js-cookie";

// Components
import { PasswordInput, TextInput } from "components/Common/Form/Inputs";
// import ErrorUI from "components/Common/UI/ErrorUI/ErrorUI";
import GradientButton from "components/Common/UI/GradientButton/GradientButton";

// Utils
import { getFormErrorMessage } from "utils/FormField";

// API
import { loginAPI } from "api/auth";

// Transaltion
import { Tr } from "utils/i18n";

// Styles
import styles from "./LogIn.module.scss";

const LogIn = () => {
	const navigate = useNavigate();

	const { mutate, isLoading, isError } = useMutation(loginAPI, {
		onSuccess: (data) => {
			// save data in Cookies
			localStorage.setItem("user_name", data.data.user_name);
			Cookies.set("session_id", data.data.session_id, { expires: 1 });
			Cookies.set("user_type", "patient", { expires: 7 });
			navigate("/");
		},
		// onError: (error) => {
		// 	toast.error(error.response.data.message)
		// },
	});

	const formik = useFormik({
		initialValues: {
			email: "",
			password: "",
		},
		validate: (data) => {
			let errors = {};

			if (!data.email) {
				errors.email = Tr.tr("basic:Email_Required");
			} else if (
				!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)
			) {
				errors.email = Tr.tr("basic:Email_Invalid");
			}

			if (!data.password) {
				errors.password = Tr.tr("basic:Password_Required");
			}

			return errors;
		},
		onSubmit: async (data, { setSubmitting }) => {
			// console.log("submitted");
			// console.log(data);
			const newData = {
				login: data.email,
				password: data.password,
			};
			await mutate(newData);
			// setTimeout(() => {
			// 	setSubmitting(false);
			// 	formik.resetForm();
			// }, 2000);
		},
	});

	return (
		<div className={styles.log_P}>
			<div className="row">
				<div className={`col-lg-6 d-none d-lg-block ${styles.bgGragient}`}>
					<div className={styles.gradient_side}>
						<div className={styles.top_sec}>
							<Link to="/" className={styles.logo}>
								<img src="/img/logo-white.svg" alt="" />
							</Link>
							<h1>{Tr.tr("basic:Feel_Better_Finding_Healthcare")}</h1>
							<p>
								It is a long established fact that a reader will be distracted
								by the readable content
							</p>
						</div>
						<img
							src="/img/hero-img.png"
							alt=""
							className={styles.gradientImg}
						/>
					</div>
				</div>
				<div className="col-lg-6">
					<div className={styles.log_form}>
						<div className={styles.top_sec}>
							<Link to="/" className={`d-flex d-lg-none ${styles.logo}`}>
								<img src="/img/logo.svg" alt="" />
							</Link>
							<div className={styles.f_txt}>
								<h3>{Tr.tr("basic:Welcome_back")}</h3>
								<p>
									<span>{Tr.tr("basic:New_To_Tiryaq")}</span>
									<Link to="/patient/sign-up">
										{Tr.tr("basic:Create_account")}
									</Link>
								</p>
							</div>
							{/* <div className={styles.social_btns}>
								<Button className={styles.social_btn}>
									<img src="/img/google-log.svg" alt="" />
									<span>Continue With Google</span>
								</Button>
								<Button className={styles.social_btn}>
									<img src="/img/facebook-log.svg" alt="" />
									<span>Continue With Facebook</span>
								</Button>
							</div> */}
							{/* <div className={styles.or}>
								<span>Or</span>
							</div> */}

							<form
								className={styles.form_holder}
								onSubmit={formik.handleSubmit}>
								<div className={styles.inputs_wrapper}>
									<div className="row">
										{/* Email */}
										<div className="col-md-12 m-b-20">
											<TextInput
												inputLabel={Tr.tr("basic:Email_Address")}
												inputName="email"
												placeHolder="user@example.com"
												inputVal={formik.values.email}
												changeHandler={formik.handleChange}
												inputError={getFormErrorMessage("email", formik)}
											/>
										</div>

										{/* Password */}
										<div className="col-md-12">
											<PasswordInput
												inputLabel={Tr.tr("basic:Password")}
												inputName="password"
												placeHolder={Tr.tr("basic:Password")}
												inputVal={formik.values.password}
												changeHandler={formik.handleChange}
												inputError={getFormErrorMessage("password", formik)}
											/>
										</div>
									</div>
								</div>
								<Link
									to="/patient/forget-password"
									className={styles.forget_Pass}>
									{Tr.tr("basic:Forgot_Your_Password")}
								</Link>

								{/* [Note:] Appear If email or password is Incorrect */}
								{/* <ErrorUI
									errorMsg="Incorrect email or password! Try again."
									withBG
									classes="mb-4"
								/> */}

								<GradientButton
									btn
									type="submit"
									label={Tr.tr("basic:Sign_In")}
									disabled={
										!(formik.isValid && formik.dirty) || formik.isSubmitting
									}
									isLoading={formik.isSubmitting}
									classes="w-100"
								/>
							</form>
						</div>
						<div className={styles.auth_footer}>
							<Link to="/terms">{Tr.tr("basic:Terms")}</Link>
							<Link to="/privacy">{Tr.tr("basic:Privacy")}</Link>
							<Link to="/help-center">{Tr.tr("basic:Help_Center")}</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default LogIn;

import axios from "../lib/axios";

export const medicinesAPI = async (filters) => {
    return await axios.post(`/users/me/medicines`, {});
};

export const addMedicineAPI = async (params) => {
    return await axios.post(`/users/me/medicines/add`, params);
};

export const editMedicineAPI = async (params) => {
    const { id, data } = params;
    return await axios.post(`/users/me/medicines/${id}/update`, data);
};

export const deleteMedicineAPI = async (id) => {
    return await axios.post(`/users/me/medicines/${id}/delete`,{});
};

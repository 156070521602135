import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { classNames } from "primereact/utils";
import { Button } from "primereact/button";

// Styles
import styles from "./AppointmentsDetails.module.scss";
import { Tr } from "utils/i18n";

const AppointmentsDetails = () => {
	// [Details] Table
	const details = [
		{
			id: 1,
			date: "November 8, 2023",
			patient: "Ahmed Ali",
			serviceType: "In Clinic",
			doctor: "Dr. Amr Mostafa",
			scheduledAt: "1:00 pm",
			checkInAt: "1:00 pm",
			waitedFor: "00:00:00",
			checkoutAt: "2:00 pm",
		},
		{
			id: 2,
			date: "November 8, 2023",
			patient: "Aya Ibrahim",
			serviceType: "In Clinic",
			doctor: "Dr. Amr Mostafa",
			scheduledAt: "1:00 pm",
			checkInAt: "1:00 pm",
			waitedFor: "00:00:00",
			checkoutAt: "2:00 pm",
		},
		{
			id: 3,
			date: "November 8, 2023",
			patient: "Abdel Rahman Magdy",
			serviceType: "Video Call",
			doctor: "Dr. Amr Mostafa",
			scheduledAt: "1:00 pm",
			checkInAt: "1:00 pm",
			waitedFor: "00:00:00",
			checkoutAt: "2:00 pm",
		},
		{
			id: 4,
			date: "November 8, 2023",
			patient: "Mohamed Ibrahim",
			serviceType: "In Clinic",
			doctor: "Dr. Amr Mostafa",
			scheduledAt: "1:00 pm",
			checkInAt: "1:00 pm",
			waitedFor: "00:00:00",
			checkoutAt: "2:00 pm",
		},
		{
			id: 5,
			date: "November 8, 2023",
			patient: "Yasmine Ashraf",
			serviceType: "In Clinic",
			doctor: "Dr. Amr Mostafa",
			scheduledAt: "1:00 pm",
			checkInAt: "1:00 pm",
			waitedFor: "00:00:00",
			checkoutAt: "2:00 pm",
		},
		{
			id: 6,
			date: "November 8, 2023",
			patient: "Mostafa El-Sayed",
			serviceType: "Video Call",
			doctor: "Dr. Amr Mostafa",
			scheduledAt: "1:00 pm",
			checkInAt: "1:00 pm",
			waitedFor: "00:00:00",
			checkoutAt: "2:00 pm",
		},
		{
			id: 7,
			date: "November 8, 2023",
			patient: "Khulood Ali",
			serviceType: "In Clinic",
			doctor: "Dr. Amr Mostafa",
			scheduledAt: "1:00 pm",
			checkInAt: "1:00 pm",
			waitedFor: "00:00:00",
			checkoutAt: "2:00 pm",
		},
		{
			id: 8,
			date: "November 8, 2023",
			patient: "Ibrahim Magdy",
			serviceType: "Video Call",
			doctor: "Dr. Amr Mostafa",
			scheduledAt: "1:00 pm",
			checkInAt: "1:00 pm",
			waitedFor: "00:00:00",
			checkoutAt: "2:00 pm",
		},
		{
			id: 9,
			date: "November 8, 2023",
			patient: "Adel Mostafa",
			serviceType: "In Clinic",
			doctor: "Dr. Amr Mostafa",
			scheduledAt: "1:00 pm",
			checkInAt: "1:00 pm",
			waitedFor: "00:00:00",
			checkoutAt: "2:00 pm",
		},
		{
			id: 10,
			date: "November 8, 2023",
			patient: "Hadia Magdy",
			serviceType: "In Clinic",
			doctor: "Dr. Amr Mostafa",
			scheduledAt: "1:00 pm",
			checkInAt: "1:00 pm",
			waitedFor: "00:00:00",
			checkoutAt: "2:00 pm",
		},
	];

	// Custom Table Paginator
	const customPaginatorTemplate = {
		layout: "PrevPageLink PageLinks NextPageLink",
		PrevPageLink: (options) => {
			return (
				<Button
					type="button"
					className={classNames(options.className, "border-round")}
					onClick={options.onClick}
					disabled={options.disabled}>
					<span>{Tr.tr("basic:Previous")}</span>
				</Button>
			);
		},
		NextPageLink: (options) => {
			return (
				<Button
					type="button"
					className={classNames(options.className, "border-round")}
					onClick={options.onClick}
					disabled={options.disabled}>
					<span>{Tr.tr("basic:Next")}</span>
				</Button>
			);
		},
		PageLinks: (options) => {
			if (
				(options.view.startPage === options.page &&
					options.view.startPage !== 0) ||
				(options.view.endPage === options.page &&
					options.page + 1 !== options.totalPages)
			) {
				const className = classNames(options.className, {
					"p-disabled": true,
				});

				return (
					<span className={className} style={{ userSelect: "none" }}>
						...
					</span>
				);
			}

			return (
				<Button
					type="button"
					className={options.className}
					onClick={options.onClick}>
					<span>{options.page + 1}</span>
				</Button>
			);
		},
	};

	return (
		<section className={styles.details_sec}>
			<h2>{Tr.tr("doctor:Reports:Details")}</h2>

			<DataTable
				className={styles.table_holder}
				value={details}
				paginator
				paginatorTemplate={customPaginatorTemplate}
				rows={10}
				totalRecords={details.length}
				tableStyle={{ minWidth: "100%" }}
				paginatorClassName="p_pagination">
				<Column field="id" header={Tr.tr("doctor:Reports:ID")}></Column>
				<Column field="date" header={Tr.tr("doctor:Reports:Date")}></Column>
				<Column
					field="patient"
					header={Tr.tr("doctor:Reports:Patient")}
					className="secondColor"></Column>
				<Column
					field="serviceType"
					header={Tr.tr("doctor:Reports:Service_Type")}></Column>
				<Column field="doctor" header={Tr.tr("doctor:Reports:Doctor")}></Column>
				<Column
					field="scheduledAt"
					header={Tr.tr("doctor:Reports:Scheduled_At")}></Column>
				<Column
					field="checkInAt"
					header={Tr.tr("doctor:Reports:Check_In_At")}></Column>
				<Column
					field="waitedFor"
					header={Tr.tr("doctor:Reports:Waited_For")}></Column>
				<Column
					field="checkoutAt"
					header={Tr.tr("doctor:Reports:Checkout_At")}></Column>
			</DataTable>
		</section>
	);
};

export default AppointmentsDetails;

import { InputSwitch } from "primereact/inputswitch";

// Styles
import styles from "./SwitchInput.module.scss";

export function SwitchInput({ ...props }) {
	return (
		<div className={`${styles.switch_input} ${props.classes}`}>
			<InputSwitch
				checked={props.checked}
				onChange={props.changeHandler}
				className={styles.switch_holder}
			/>
			{props.label && <div className={styles.switch_label}>{props.label}</div>}
		</div>
	);
}

import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";

// Components
import GrayButton from "components/Common/UI/GrayButton/GrayButton";
import GradientButton from "components/Common/UI/GradientButton/GradientButton";
import { NumericInput } from "components/Common/Form/Inputs/NumericInput/NumericInput";

// Utils
import { getFormErrorMessage } from "utils/FormField";

// Api
import { updateMeApi } from "api/auth";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./Pricing.module.scss";

const Pricing = () => {
	// Navigation
	const navigate = useNavigate();

	const { mutate } = useMutation(updateMeApi, {
		onSuccess: (data) => {
			navigate("/doctor/on-boarding/communication");
		},
	});

	// Formik
	const formik = useFormik({
		initialValues: {
			examinationFees: "",
			videoFees: "",
		},
		validate: (data) => {
			let errors = {};

			if (!data.examinationFees) {
				errors.examinationFees = "Price is required.";
			}

			if (!data.videoFees) {
				errors.videoFees = "Price is required.";
			}

			return errors;
		},
		onSubmit: async (data, { setSubmitting }) => {
			console.log("submitted");
			// setTimeout(() => {
			// 	setSubmitting(false);
			// 	navigate("/doctor/on-boarding/communication");
			// 	formik.resetForm();
			// }, 2000);
			await mutate({
				clinic_fees: data.examinationFees,
				video_fees: data.videoFees,
				configuration_stage: 3,
			});
		},
	});

	return (
		<div className={styles.pricing_step}>
			<form onSubmit={formik.handleSubmit}>
				<div className={styles.pricing_step_content}>
					{/* Examination Fees */}
					<section className={styles.sec_holder}>
						<h4 className={styles.title}>
							{Tr.tr("doctor:OnBoarding:Examination_Fees_In_Clinic")}
						</h4>

						<div className="row">
							<div className="col-md-6">
								<NumericInput
									inputLabel={
										<>
											{Tr.tr("doctor:OnBoarding:Price")}{" "}
											<span className={styles.currency}>
												({Tr.tr("basic:SAR")})
											</span>
										</>
									}
									inputName="examinationFees"
									placeHolder="0.00"
									inputVal={formik.values.examinationFees}
									changeHandler={formik.handleChange}
									inputError={getFormErrorMessage("examinationFees", formik)}
									minFractionDigits={0}
									maxFractionDigits={2}
									maxValue={10000}
								/>
							</div>
						</div>
					</section>

					{/* Video Fees */}
					<section className={styles.sec_holder}>
						<h4 className={styles.title}>
							{Tr.tr("doctor:OnBoarding:Video_Consulting_Fees")}
						</h4>

						<div className="row">
							<div className="col-md-6">
								<NumericInput
									inputLabel={
										<>
											{Tr.tr("doctor:OnBoarding:Price")}{" "}
											<span className={styles.currency}>
												({Tr.tr("basic:SAR")})
											</span>
										</>
									}
									inputName="videoFees"
									placeHolder="0.00"
									inputVal={formik.values.videoFees}
									changeHandler={formik.handleChange}
									inputError={getFormErrorMessage("videoFees", formik)}
									minFractionDigits={0}
									maxFractionDigits={2}
									maxValue={10000}
								/>
							</div>
						</div>
					</section>
				</div>

				{/* Footer Actions */}
				<div className={styles.footer_actions}>
					<GrayButton
						label={Tr.tr("basic:Back")}
						redirectTo="/doctor/on-boarding/practice-staff"
						outlined
						classes="p712"
					/>
					<GradientButton
						btn={true}
						type="submit"
						label={Tr.tr("basic:Next")}
						classes="p712"
					/>
				</div>
			</form>
		</div>
	);
};

export default Pricing;

import { NavLink } from "react-router-dom";
import { Sidebar } from "primereact/sidebar";

// Components
import LanguageBtn from "components/Common/UI/LanguageBtn/LanguageBtn";

// Translation
import { useTranslation, withTranslation } from "react-i18next";
import { Tr } from "utils/i18n";

// Styles
import styles from "./ResponsiveHeader.module.scss";

const ResponsiveHeader = ({ ...props }) => {
	const { i18n } = useTranslation();
	const currLang = i18n.language;

	return (
		<Sidebar
			visible={props.visible}
			position={currLang === "ar" ? "left" : "right"}
			onHide={props.hideHandler}
			closeIcon={currLang === "ar" ? "pi pi-arrow-left" : "pi pi-arrow-right"}
			className={styles.responsive_header}
			maskClassName={styles.sidebar_wapper}>
			<div className={styles.sidebar_content}>
				<div className={styles.content_top}>
					<ul className={styles.menu_links}>
						<li className={styles.menu_link}>
							<NavLink to="/patient/find-a-doctor">
								{Tr.tr("patient:Header:Find_A_Doctor")}
							</NavLink>
						</li>
						<li className={styles.menu_link}>
							<NavLink to="/patient/find-a-doctor?can_make_video_consult=true">
								{Tr.tr("patient:Header:Video_Consult")}
							</NavLink>
						</li>
						<li className={styles.menu_link}>
							<NavLink to="/patient/consultation-call">
								{Tr.tr("patient:Header:Consultation_Call")}
							</NavLink>
						</li>
						<li className={styles.menu_link}>
							<NavLink to="/patient/customer-support">
								{Tr.tr("patient:Header:Customer_Support")}
							</NavLink>
						</li>
					</ul>
				</div>
				<div className={styles.content_bottom}>
					{/* Language Btn */}
					<LanguageBtn />
				</div>
			</div>
		</Sidebar>
	);
};

export default withTranslation()(ResponsiveHeader);

import { Link } from "react-router-dom";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";

// Styles
import styles from "./WhiteButton.module.scss";

const WhiteButton = ({ ...props }) => {
	const navigate = useNavigate();
	const onClick = () => {
		console.log("Redirecting to: ", props.redirectTo);
		navigate(props.redirectTo);
	};

	return props.btn ? (
		<Button
			type={props.type}
			label={props.label}
			className={`${styles.white_btn} ${props.classes}`}
			onClick={props.onClick}
			disabled={props.disabled}
			loading={props.isLoading}>
			{props.withIconImg && <img src={props.icon} alt="" />}
			{props.withIcon && <i className={props.icon}></i>}
		</Button>
	) : (
		<Link
			to={props.redirectTo}
			className={`${styles.white_btn} ${props.classes}`}>
			{props.withIconImg && <img src={props.icon} alt="" />}
			{props.withIcon && <i className={props.icon}></i>}
			{props.label && <span>{props.label}</span>}
		</Link>
	);
};

export default WhiteButton;

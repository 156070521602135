import { Link } from "react-router-dom";

// Components
import GradientButton from "components/Common/UI/GradientButton/GradientButton";

// Translation
import { Tr } from "utils/i18n";
import { useTranslation } from "react-i18next";

// Styles
import styles from "./TopArticles.module.scss";

const TopArticles = () => {
	const { i18n } = useTranslation();

	// Access current language
	const currentLanguage = i18n.language;

	const articles = [
		{
			id: 1,
			image: "/img/article1.png",
			titleEn:
				"Cold Sore Prescription Treatment: How it Works and How to Request It",
			titleAr: "علاج قرحة البرد بوصفة طبية: كيف يعمل وكيفية طلبه",
			author: "Ahmed Ali",
			date: "Mar 30, 2022",
		},
		{
			id: 2,
			image: "/img/article2.png",
			titleEn: "Women’s Health: What to Look Out For As We Age",
			titleAr: "صحة المرأة: ما الذي يجب أن نبحث عنه مع تقدمنا ​​في العمر",
			author: "Ahmed Ali",
			date: "Mar 30, 2022",
		},
		{
			id: 3,
			image: "/img/article3.png",
			titleEn:
				"Cold Sore Prescription Treatment: How it Works and How to Request It",
			titleAr: "علاج قرحة البرد بوصفة طبية: كيف يعمل وكيفية طلبه",
			author: "Ahmed Ali",
			date: "Mar 30, 2022",
		},
	];
	return (
		<section className={styles.top_articles}>
			<div className="container">
				<div className={styles.txt}>
					<h2>{Tr.tr("patient:Home:Read_Top_Articles")}</h2>
					<p>Lorem ipsum dolor sit amet consectetur. Neque blandit</p>
				</div>
				<div className="row">
					{articles.map((article) => (
						<div className="col-md-6 col-lg-4 mb-4 mb-lg-0" key={article.id}>
							<div className={styles.article_box}>
								<div className={styles.article_img}>
									<img src={article.image} alt="" />
								</div>
								<div className={styles.article_body}>
									<h5>
										{currentLanguage === "ar"
											? article.titleAr
											: article.titleEn}
									</h5>
									<div className={styles.author_date}>
										<span>By {article.author}</span>
										<span>{article.date}</span>
									</div>
									<Link
										to={`/article/${article.id}`}
										className={styles.read_more}>
										<span>{Tr.tr("basic:Read_More")}</span>
										<img src="/img/arrow-right-gradient.svg" alt="" />
									</Link>
								</div>
							</div>
						</div>
					))}
				</div>
				<GradientButton
					label={Tr.tr("patient:Home:See_All_Articles")}
					redirectedTo="/articles"
				/>
			</div>
		</section>
	);
};

export default TopArticles;

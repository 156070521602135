import { createContext, useState } from "react";
import { Link, Outlet } from "react-router-dom";

// Components
import LanguageBtn from "components/Common/UI/LanguageBtn/LanguageBtn";
import DoctorSignUpSidebar from "./components/DoctorSignUpSidebar/DoctorSignUpSidebar";

// Styles
import styles from "./DoctorSignUp.module.scss";

// Step Context
export const StepContext = createContext();

const DoctorSignUp = () => {
	const [currentPath, setCurrentPath] = useState("create-account");

	return (
		<div className={styles.doctor_signup}>
			<div className={styles.signup_head}>
				<Link to="/doctor/login" className={styles.logo}>
					<img src="/img/logo.svg" alt="" />
				</Link>
				<LanguageBtn classes={styles.auth_lang} />
			</div>
			<StepContext.Provider value={{ currentPath, setCurrentPath }}>
				<div className={styles.signup_body}>
					{/* Sidebar */}
					<DoctorSignUpSidebar />

					{/* Outlet [Sign Up Pages] */}
					<div className={styles.outlet_content}>
						<Outlet />
					</div>
				</div>
			</StepContext.Provider>
		</div>
	);
};

export default DoctorSignUp;

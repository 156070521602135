import moment from "moment";

// Translation
import { useTranslation } from "react-i18next";

// Styles
import styles from "./MoreLinkPopover.module.scss";

const MoreLinkPopover = ({ event, eventClicked, onClose }) => {
	const { i18n } = useTranslation();
	const currLang = i18n.language;

	return (
		<div
			className={styles.moreLink_popover}
			style={
				currLang === "ar"
					? {
						position: "absolute",
						top: event.jsEvent.clientY + 3,
						left: event.jsEvent.clientX - 200,
					}
					: {
						position: "absolute",
						top: event.jsEvent.clientY + 3,
						left: event.jsEvent.clientX - 100,
					}
			}>
			<div className={styles.popover_head}>
				<h4>All appointments</h4>
				<h6>{moment(event.date).format("ddd, MMM D, YYYY")}</h6>
			</div>
			<div className={styles.popover_body}>
				{event.allSegs.map((seg, index) => (
					<div
						className={`${styles.event_box} ${seg.event.extendedProps.location.toLowerCase() === "in clinic"
							? styles.inClinic
							: styles.videoCall
							}`}
						key={index}
						onClick={() => {
							const data = seg.event;
							const { title, start, end, extendedProps } = data;
							const { userId, email, location, phone, image, meetingId } = extendedProps;

							const userData = {
								title: title,
								id: userId,
								start: start ? start.toISOString() : '',
								end: end ? end.toISOString() : '',
								email: email,
								location: location,
								phone: phone,
								image: image,
								meetingId: meetingId
							};
							if (!userData) {
								return;
							}
							eventClicked(event, userData);
						}}>
						<div className={styles.time}>
							{moment(seg.event.start).format("LT")}
						</div>
						<div className={styles.info}>
							<h5>{seg.event.title}</h5>
							<h6>{seg.event.extendedProps.location}</h6>
						</div>
					</div>
				))}

			</div>
		</div>
	);
};

export default MoreLinkPopover;

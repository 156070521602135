import { useContext, useState } from "react";

// Components
import { SelectDropdown, SwitchInput } from "components/Common/Form/Inputs";

// Context
import { InterpreterContext } from "pages/Patient/ConfirmAppointmentsBooking/ConfirmAppointmentsBooking";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./MedicalInterpreter.module.scss";

const MedicalInterpreter = () => {
	// Medical Interpreter [Switch Btn]
	const { checkedInterpreter, setCheckedInterpreter } =
		useContext(InterpreterContext);

	// Interpreter Languages [Options]
	const languages = [
		{
			label: "Arabic",
			value: "Arabic",
		},
		{
			label: "English",
			value: "English",
		},
	];

	const [selectedLang, setSelectedLang] = useState(languages[0].value);

	return (
		<div className={styles.medical_interpreter}>
			<h2>{Tr.tr("patient:DoctorDetails:Medical_Interpreter")}</h2>

			<div className={styles.medical_interpreter_content}>
				<div className={styles.left}>
					<SwitchInput
						checked={checkedInterpreter}
						changeHandler={(e) => setCheckedInterpreter(e.value)}
						label={Tr.tr(
							"patient:DoctorDetails:Do_You_Want_Medical_Interpreter"
						)}
					/>
				</div>
				<div className={styles.right}>
					<div className={styles.to}>To</div>
					<SelectDropdown
						inputLabel=""
						inputName="interpreterLang"
						placeHolder="Sort by"
						options={languages}
						inputVal={selectedLang}
						changeHandler={(e) => setSelectedLang(e.value)}
						appendTo="self"
					/>
					<div className={styles.cost}>
						00 {Tr.tr("patient:DoctorDetails:SAR")}
					</div>
				</div>
			</div>
		</div>
	);
};

export default MedicalInterpreter;

import { useEffect, useState } from "react";

// Components
import GrayButton from "components/Common/UI/GrayButton/GrayButton";
import GradientButton from "components/Common/UI/GradientButton/GradientButton";
import AddRecordsModal from "components/Doctor/FullCalendarView/components/AddRecordsModal/AddRecordsModal";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./NextPatientDetails.module.scss";

const NextPatientDetails = ({ nextAppointment }) => {
	// Add record Modal
	const [recordModalVisible, setRecordModalVisible] = useState(false);
	const [appointment, setAppointment] = useState();

	const dateFromString = appointment?.date_from
		? new Date(appointment.date_from)
		: null;

	const padWithZero = (number) => {
		return number.toString().padStart(2, "0");
	};

	const appointmentTime = dateFromString
		? `${dateFromString.getHours()}:${padWithZero(
				dateFromString.getMinutes()
		  )} ${dateFromString.getHours() >= 12 ? "PM" : "AM"}`
		: "Invalid date";

	const location =
		appointment?.type === "in_clinic" ? "In Clinic" : "Video Call";

	useEffect(() => {
		if (nextAppointment) {
			setAppointment(nextAppointment);
		}
	}, [nextAppointment]);
	return (
		<>
			<div className={styles.nextP_details}>
				<h3>{Tr.tr("doctor:Dashboard:Next_Patient_Details")}</h3>

				<div className={styles.details}>
					<div className={`${styles.details_row} ${styles.info}`}>
						<div className={styles.pic_name}>
							<div className={styles.img}>
								<img
									src={appointment?.partner_id?.image || "/img/profile-img.png"}
									alt=""
								/>
							</div>
							<h4 className={styles.name}>{appointment?.partner_id?.name}</h4>
						</div>
						<div className={styles.info_actions}>
							<GrayButton
								label={Tr.tr("basic:View_Profile")}
								redirectTo={`/doctor/patients/${appointment?.partner_id?.id}`}
								outlined
								classes={`p712 ${styles.view_p}`}
							/>
							<GrayButton
								btn
								type="button"
								outlined
								withIconImg
								icon="/img/call-gray.svg"
								classes="p712"
							/>
							<GrayButton
								btn
								type="button"
								outlined
								withIconImg
								icon="/img/mail-gray.svg"
								classes="p712"
							/>
						</div>
					</div>

					<div className={`${styles.details_row} ${styles.contact}`}>
						<div className={styles.contact_row}>
							<img src="/img/call-gray.svg" alt="" className={styles.icon} />
							<h5>{appointment?.partner_id?.phone}</h5>
						</div>
						<div className={styles.contact_row}>
							<img src="/img/mail-gray.svg" alt="" className={styles.icon} />
							<h5>{appointment?.partner_id?.email}</h5>
						</div>
					</div>

					<div className={`${styles.details_row} ${styles.appointment}`}>
						<img src="/img/hospital-gray.svg" alt="" className={styles.icon} />
						<div className={styles.appointment_info}>
							<h4>{appointmentTime}</h4>
							<p>{`${location}`}</p>
						</div>
					</div>

					<div className={`${styles.details_row} ${styles.actions}`}>
						<GradientButton
							btn
							type="button"
							label={Tr.tr("basic:Check_In")}
							outlined
							classes={`p712 ${styles.action_btn}`}
						/>
						<GradientButton
							btn
							type="button"
							label={Tr.tr("basic:Add_Records")}
							classes={`p712 ${styles.action_btn}`}
							buttonClicked={() => setRecordModalVisible(true)}
						/>
					</div>
				</div>
			</div>

			{/* Add Record Modal */}
			<AddRecordsModal
				visible={recordModalVisible}
				hideHandler={() => setRecordModalVisible(false)}
			/>
		</>
	);
};

export default NextPatientDetails;

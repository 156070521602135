import { RadioButton } from "primereact/radiobutton";

// Styles
import styles from "./RadioBtn.module.scss";

export function RadioBtn({ ...props }) {
	return (
		<label className={styles.radio_btn}>
			<RadioButton
				name={props.name}
				value={props.value}
				onChange={props.handleChange}
				checked={props.checked}
			/>
			<span className={styles.radioBtn_lbl}>{props.label}</span>
		</label>
	);
}

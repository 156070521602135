// Styles
import styles from "./PageHead.module.scss";

const PageHead = ({ title, subTitle, children, ...props }) => {
	return (
		<div className={styles.page_head} style={{ marginBottom: props.mb }}>
			<div className={styles.txt}>
				<h1 style={{ fontSize: props.titleSize }}>{title}</h1>
				<h5>{subTitle}</h5>
			</div>
			{children}
		</div>
	);
};

export default PageHead;

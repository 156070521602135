import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { initReactI18next, Trans } from "react-i18next";
import lang from "assets/lang";

i18n
	.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		// we init with resources
		resources: lang,
		fallbackLng: "en",
		// lng: localStorage.getItem("i18nextLng") || "ar", // default
		lng: localStorage.getItem("i18nextLng") || "en", // default
		debug: true,

		// have a common namespace used around the full app
		ns: ["basic"],
		defaultNS: "basic",

		keySeparator: true, // we use content as keys

		interpolation: {
			escapeValue: false,
		},
	});

window.document.body.dir = i18n.t(["notExists", "dir"]);

export default i18n;

const Tr = (props) => (
	<Trans i18nKey={props.tr} {...props}>
		{props.children}
	</Trans>
);

Tr.tr = (key, ...rest) => i18n.t(key, ...rest);

export { Tr };

import { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";

// Apis
import DoctorUploadDocumentService from "features/doctors/uploadDocumentService";

// Components
import ProgressBarWrapper from "components/Common/UI/ProgressBarWrapper/ProgressBarWrapper";
import GradientButton from "components/Common/UI/GradientButton/GradientButton";
import { toast } from "react-hot-toast";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./RegisterationDocuments.module.scss";

const RegisterationDocuments = () => {
	const navigate = useNavigate();
	const [uploadProgress, setUploadProgress] = useState(0);

	const [isSubmitted, setIsSubmitted] = useState(false);

	const { data: documents, refetch } =
		DoctorUploadDocumentService.useFetchDoctorDocuments();

	const { mutate: uploadDocument } =
		DoctorUploadDocumentService.useUploadDoctorDocument();
	const { mutate: deleteDocument } =
		DoctorUploadDocumentService.useDeleteDoctorDocument();

	const handleFileChange = (event, type) => {
		if (event.target.files && event.target.files[0]) {
			const file = event.target.files[0];

			setIsSubmitted(true);
			setUploadProgress(0);

			setTimeout(() => setUploadProgress(20), 100);
			setTimeout(() => setUploadProgress(60), 100);
			setTimeout(() => setUploadProgress(90), 100);
			// setTimeout(() => setUploadProgress(80), 2000)

			const formData = new FormData();
			formData.append("docs", file);
			formData.append("document_type", type);

			uploadDocument(formData, {
				onSuccess: (data) => {
					setIsSubmitted(false);
					setUploadProgress(0);
					refetch();
					toast.success("Document uploaded successfully!");
				},
				onError: (error) => {
					console.error(error);
					setIsSubmitted(false);
					setUploadProgress(0);
					toast.error("Failed to upload document.");
				},
			});
		}
	};

	const finishRegistration = () => {
		navigate("/doctor/login");
	};

	const handleDelete = (documentId) => {
		deleteDocument(documentId);
		toast.success("Document deleted successfully!");
	};

	const isImage = (mimetype) => {
		if (mimetype?.includes("image")) {
			return true;
		}
		return false;
	};

	return (
		<div className={styles.registration_documents}>
			<div className={styles.head}>
				<h2>{Tr.tr("basic:Registration_Of_Documents")}</h2>
				<p>{Tr.tr("basic:We_Need_Verify_Information")}</p>
			</div>

			<div className={styles.upload_files}>
				{/* Uploaded Files */}
				<div className={styles.uploaded_files}>
					{documents &&
						Object.entries(documents).map(([key, doc]) => (
							<div key={key} className={styles.uploaded_file_box}>
								<div className={styles.file_info}>
									<div className={styles.left}>
										<img
											key={doc.id}
											src={
												isImage(doc?.file?.mimetype)
													? doc.file.url
													: "/img/pdf.svg"
											}
											alt=""
											className={styles.file_icon}
										/>
										<div className={styles.file_data}>
											<h5>{doc?.file?.name}</h5>
											<h6>
												{(doc?.file?.file_size / 1024 / 1024).toFixed(2)} MB
											</h6>
										</div>
									</div>
									<Button
										type="button"
										className={styles.delete_btn}
										onClick={() => handleDelete(doc.id)}>
										<img src="/img/delete-red.svg" alt="" />
									</Button>
								</div>

								{isSubmitted && (
									<ProgressBarWrapper
										value={uploadProgress}
										showStatus={false}
										// showVal={uploadProgress}
									/>
								)}
							</div>
						))}
				</div>

				{/* Upload Action */}
				<div className={styles.upload_inputs}>
					<div className={styles.upload_input}>
						<div className={styles.upload_title}>
							<h5>{Tr.tr("basic:Passport_Or_ID")}</h5>
							<h6>PDF, DOC, JPG, PNG</h6>
						</div>

						<label className={styles.upload_action_box}>
							<input
								type="file"
								onChange={(e) => handleFileChange(e, "id_document")}
							/>
							<img
								src="/img/upload-gray.svg"
								alt=""
								className={styles.upload_icon}
							/>
							<div className={styles.upload_txt}>
								<h4>{Tr.tr("basic:Upload_Drag_Drop")}</h4>
								<p>PDF, DOC, JPG, PNG</p>
							</div>
						</label>
					</div>

					<div className={styles.upload_input}>
						<div className={styles.upload_title}>
							<h5>{Tr.tr("basic:Residence_Certificate")}</h5>
							<h6>PDF, DOC, JPG, PNG</h6>
						</div>

						<label className={styles.upload_action_box}>
							<input
								type="file"
								onChange={(e) => handleFileChange(e, "residence_certificate")}
							/>
							<img
								src="/img/upload-gray.svg"
								alt=""
								className={styles.upload_icon}
							/>
							<div className={styles.upload_txt}>
								<h4>{Tr.tr("basic:Upload_Drag_Drop")}</h4>
								<p>PDF, DOC, JPG, PNG</p>
							</div>
						</label>
					</div>
				</div>

				<GradientButton
					btn
					type="button"
					label={Tr.tr("basic:Finish")}
					classes="w-100"
					isLoading={false}
					buttonClicked={finishRegistration}
				/>
			</div>
		</div>
	);
};

export default RegisterationDocuments;

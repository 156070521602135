import { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";

// Components
import { TextInput } from "components/Common/Form/Inputs";
import WhiteButton from "components/Common/UI/WhiteButton/WhiteButton";

// Translation
import { withTranslation } from "react-i18next";
import { Tr } from "utils/i18n";

// Styles
import styles from "./VitalsSignsAccordion.module.scss";

const VitalsSignsAccordion = () => {
	// Is Edit
	const [isEdit, setIsEdit] = useState(false);

	// Vitals Signs
	const [vitalsSigns, setVitalsSigns] = useState([
		{
			id: "1",
			weight: "74 kg",
			bp: "120/85",
			pulse: "78/min",
			temperature: "87° F",
			respRate: "55/min",
		},
	]);

	// Weight Template
	const weightTemplate = () => {
		return (
			<TextInput
				inputLabel=""
				inputName="weight"
				placeHolder={Tr.tr("basic:Enter_Weight")}
				inputVal={""}
				changeHandler={() => {}}
				inputError={""}
			/>
		);
	};

	// BP Template
	const bpTemplate = () => {
		return (
			<TextInput
				inputLabel=""
				inputName="bp"
				placeHolder={Tr.tr("basic:Enter_Blood_Pressure")}
				inputVal={""}
				changeHandler={() => {}}
				inputError={""}
			/>
		);
	};

	// Pulse Template
	const pulseTemplate = () => {
		return (
			<TextInput
				inputLabel=""
				inputName="pulse"
				placeHolder={Tr.tr("basic:Enter_Pulse")}
				inputVal={""}
				changeHandler={() => {}}
				inputError={""}
			/>
		);
	};

	// Temperature Template
	const temperatureTemplate = () => {
		return (
			<TextInput
				inputLabel=""
				inputName="temperature"
				placeHolder={Tr.tr("basic:Enter_Temperature")}
				inputVal={""}
				changeHandler={() => {}}
				inputError={""}
			/>
		);
	};

	// Resp Rate Template
	const respRateTemplate = () => {
		return (
			<TextInput
				inputLabel=""
				inputName="respRate"
				placeHolder={Tr.tr("basic:Enter_Respiratory_Rate")}
				inputVal={""}
				changeHandler={() => {}}
				inputError={""}
			/>
		);
	};

	return (
		<div className={styles.vitals_signs}>
			{!isEdit ? (
				<DataTable value={vitalsSigns} tableStyle={{ minWidth: "100%" }}>
					<Column field="weight" header={Tr.tr("basic:Weight")}></Column>
					<Column field="bp" header={Tr.tr("basic:BP") + " (mmHg)"}></Column>
					<Column field="pulse" header={Tr.tr("basic:Pulse")}></Column>
					<Column
						field="temperature"
						header={Tr.tr("basic:Temperature")}></Column>
					<Column
						field="respRate"
						header={Tr.tr("basic:Respiratory_Rate")}></Column>
				</DataTable>
			) : (
				<>
					<DataTable value={vitalsSigns} tableStyle={{ minWidth: "100%" }}>
						<Column
							field="weight"
							header={Tr.tr("basic:Weight")}
							body={weightTemplate}></Column>
						<Column
							field="bp"
							header={Tr.tr("basic:BP") + " (mmHg)"}
							body={bpTemplate}></Column>
						<Column
							field="pulse"
							header={Tr.tr("basic:Pulse")}
							body={pulseTemplate}></Column>
						<Column
							field="temperature"
							header={Tr.tr("basic:Temperature")}
							body={temperatureTemplate}></Column>
						<Column
							field="respRate"
							header={Tr.tr("basic:Respiratory_Rate")}
							body={respRateTemplate}></Column>
						<Column
							body={
								<Button
									type="button"
									icon="pi pi-times"
									rounded
									text
									severity="danger"
									aria-label="Delete"
									className={styles.delete}
								/>
							}></Column>
					</DataTable>

					{/* Add Line */}
					<WhiteButton btn type="button" label={Tr.tr("basic:Add_Line")} />
				</>
			)}
		</div>
	);
};

export default withTranslation()(VitalsSignsAccordion);

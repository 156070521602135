import { useEffect, useState } from "react";
import { getAppointmentsAPI } from "api/appointments";
import { useMutation, useQuery } from "react-query";
import { getMeApi } from "api/auth";
import { TabPanel, TabView } from "primereact/tabview";
import { ProgressSpinner } from "primereact/progressspinner";

// Components
import PageHead from "components/Patient/PageHead/PageHead";
import { SearchInput } from "components/Common/Form/Inputs";
import AppointmentCard from "components/Patient/AppointmentCard/AppointmentCard";
import FeedBackModal from "components/Patient/FeedBackModal/FeedBackModal";
import MessagesModal from "components/Patient/MessagesModal/MessagesModal";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./MyAppointments.module.scss";

const MyAppointments = () => {
	const [activeIndex, setActiveIndex] = useState(0);
	const [activeTabIndex, setActiveTabIndex] = useState(0);

	// FeedBack Modal
	const [feedbackVisible, setFeedbackModal] = useState(false);

	// Messages Modal
	const [messagesVisible, setMessagesModal] = useState(false);

	// Search
	const [searchQuery, setSearchQuery] = useState("");

	const [user, setUser] = useState();

	const { mutate: getMe } = useMutation(getMeApi, {
		onSuccess: (data) => {
			// debugger
			setUser(data.data);
		},
	});

	const { data: appointmentsData, isLoading } = useQuery(
		["appointments", activeIndex, activeTabIndex, user?.id],
		() => getAppointmentsAPI(getFilters()),
		{
			enabled: !!user?.id,
		}
	);
	const appointments = appointmentsData?.data?.data || [];

	// Tabs
	const tabs = [
		{
			label: Tr.tr("patient:PatientProfile:All"),
			value: "All",
		},
		{
			label: Tr.tr("patient:PatientProfile:In_Clinic"),
			value: "In Clinic",
		},
		{
			label: Tr.tr("patient:PatientProfile:Video_Consult"),
			value: "Video Consult",
		},
		// {
		// 	label: "Consultation Call",
		// 	value: "Consultation Call",
		// },
	];

	// Status Tabs
	const statusTabs = [
		{
			label: Tr.tr("patient:PatientProfile:All"),
			value: "All",
		},
		{
			label: Tr.tr("patient:PatientProfile:Upcoming"),
			value: "Upcoming",
		},
		{
			label: Tr.tr("patient:PatientProfile:Completed"),
			value: "Completed",
		},
		{
			label: Tr.tr("patient:PatientProfile:Canceled"),
			value: "Canceled",
		},
	];

	const getFilters = (userId) => {
		let status = "";
		if (activeTabIndex === 1) status = "confirmed";
		else if (activeTabIndex === 2) status = "done";
		else if (activeTabIndex === 3) status = "declined";

		let type = "";
		if (activeIndex === 1) type = "in_clinic";
		else if (activeIndex === 2) type = "video_call";

		const filters = {
			id: userId,
			status,
			type,
		};

		Object.keys(filters).forEach((key) => {
			if (filters[key] === "") {
				delete filters[key];
			}
		});

		return { id: user?.id, status, type };
	};

	useEffect(() => {
		getMe();
	}, []);

	return (
		<>
			<div className={styles.my_appointments}>
				<PageHead
					title={Tr.tr("patient:PatientProfile:My_Appointments")}
					subTitle="This is a subtitle"
					mb="16px"
				/>

				{/* Appointments Type */}
				<TabView
					activeIndex={activeIndex}
					onTabChange={(e) => {
						if (!isLoading) {
							setActiveIndex(e.index);
							setActiveTabIndex(0);
							// handleTabChange(user?.id);
						}
					}}
					className={styles.appointments_type}>
					{tabs.map((tab) => {
						return <TabPanel key={tab.value} header={tab.label}></TabPanel>;
					})}
				</TabView>

				{/* Status Tabs & Search */}
				<div className={styles.status_search}>
					{/* Status Tabs */}
					<TabView
						activeIndex={activeTabIndex}
						onTabChange={(e) => {
							if (!isLoading) {
								setActiveTabIndex(e.index);
								// handleTabChange(user?.id);
							}
						}}
						className={styles.status_tab}>
						{statusTabs.map((status) => {
							return (
								<TabPanel key={status.value} header={status.label}></TabPanel>
							);
						})}
					</TabView>

					{/* Search */}
					<SearchInput
						inputLabel=""
						inputName="search"
						placeHolder={Tr.tr("patient:PatientProfile:Search_By_Doctor_Name")}
						inputVal={searchQuery}
						changeHandler={(e) => setSearchQuery(e.target.value)}
					/>
				</div>

				{isLoading ? (
					<div className={styles.spinner}>
						<ProgressSpinner />
					</div>
				) : (
					<>
						{/* Appointments Count */}
						<p className={styles.appointments_count}>
							{appointments?.length}{" "}
							{Tr.tr("patient:PatientProfile:Appointments")}
						</p>

						{/* Appointments Cards */}
						<div className={styles.appointments_cards}>
							{appointments.map((data) => (
								<AppointmentCard
									data={data}
									key={data.id}
									openFeedbackModal={() => setFeedbackModal(true)}
									openMessagesModal={() => setMessagesModal(true)}
								/>
							))}
						</div>
					</>
				)}
			</div>

			{/* FeedBack Modal */}
			<FeedBackModal
				visible={feedbackVisible}
				hideHandler={() => setFeedbackModal(false)}
			/>

			{/* Messages Modal */}
			<MessagesModal
				visible={messagesVisible}
				hideHandler={() => setMessagesModal(false)}
			/>
		</>
	);
};

export default MyAppointments;

import { useState } from "react";
import { SelectButton } from "primereact/selectbutton";
import ReactApexChart from "react-apexcharts";

// Components
import { SelectDropdownWithRadioBtn } from "components/Common/Form/Inputs";

// Utils
import { NumbersFormater } from "utils/NumbersFormater";

// Styles
import styles from "./EarningsChart.module.scss";
import { Tr } from "utils/i18n";

const EarningsChart = () => {
	// Service Types
	const serviceTypes = [
		{
			label: Tr.tr("basic:All"),
			value: "All",
		},
		{
			label: Tr.tr("basic:In_Clinic"),
			value: "In Clinic",
		},
		{
			label: Tr.tr("basic:Video_Call"),
			value: "Video Call",
		},
	];
	const [selectedServiceType, setSelectedServiceType] = useState(
		serviceTypes[0].value
	);

	// Duration [Daily, Week, Monthly]
	const options = ["Daily", "Weekly", "Monthly"];
	const [value, setValue] = useState(options[0]);

	// Chart
	const chartSeries = [
		{
			name: "Sales",
			data: [4000, 500, 1000, 500, 2000, 1000, 3000],
		},
	];

	// Define your custom y-axis values
	const customYAxisValues = [0, 1000, 2000, 3000, 4000]; // Example custom values

	// Custom data for each point
	const customTooltipData = [
		{ date: "Wed, Nov 8, 2023", cost: "0.00 SAR" },
		{ date: "Wed, Nov 8, 2023", cost: "0.00 SAR" },
		{ date: "Wed, Nov 8, 2023", cost: "0.00 SAR" },
		{ date: "Wed, Nov 8, 2023", cost: "0.00 SAR" },
		{ date: "Wed, Nov 8, 2023", cost: "0.00 SAR" },
		{ date: "Wed, Nov 8, 2023", cost: "0.00 SAR" },
		{ date: "Wed, Nov 8, 2023", cost: "0.00 SAR" },
	];

	const chartOptions = {
		chart: {
			type: "area",
			toolbar: {
				show: false, // Hide all toolbar functions
				menu: {
					show: false, // Hide the menu button
				},
			},
			zoom: {
				enabled: false, // Disable zoom functionality
			},
		},
		xaxis: {
			categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
			tooltip: {
				enabled: false,
			},
		},
		yaxis: {
			// min: 0,
			// max: 4000,
			// Specify the custom y-axis values
			min: customYAxisValues[0],
			max: customYAxisValues[customYAxisValues.length - 1],
			tickAmount: customYAxisValues.length - 1,
			labels: {
				formatter: (value, index) => NumbersFormater(customYAxisValues[index]), // Use custom y-axis values
			},
		},
		grid: {
			// Customize the grid lines
			strokeDashArray: 4, // Set the dash array for the grid lines
			borderColor: "#E5E5EF", // Set the color of the grid lines
			row: {
				colors: ["#fff"], // Set the colors for alternating rows
				opacity: 0, // Set the opacity of the rows
			},
		},
		dataLabels: {
			enabled: false,
		},
		colors: ["#29B6C2"],
		stroke: {
			curve: "straight",
			width: 2,
		},
		fill: {
			type: "gradient",
			// Define gradient background for the area chart
			gradient: {
				// Specify gradient color stops
				shadeIntensity: 0,
				opacityFrom: 0.33,
				opacityTo: 0.9,
				stops: [0, 60, 100],
				colorStops: [
					{
						offset: 0,
						color: "#7FD3DA", // Start color
						opacity: 1,
					},
					{
						offset: 60,
						color: "rgba(127, 211, 218, 0.6)",
						opacity: 1,
					},
					{
						offset: 100,
						color: "rgba(127, 211, 218, 0)", // End color
						opacity: 1,
					},
				],
			},
		},
		tooltip: {
			custom: ({ series, seriesIndex, dataPointIndex }) => {
				const data = customTooltipData[dataPointIndex];
				return `
                    <span className="title">${data.date}</span>
                    <span className="val">${data.cost}</span>
                `;
			},
			// Set the tooltip to be aligned to the top
			x: {
				show: false, // Hide the tooltip along the x-axis
			},
			y: {
				title: {
					formatter: (seriesName) => "", // Hide the series name in the tooltip
				},
				formatter: (value) => value, // Display the tooltip value
				offsetY: -15, // Adjust the y-offset to position the tooltip above the data point
				style: {
					fontSize: "12px", // Adjust the font size of the tooltip
				},
			},
		},
	};

	return (
		<section className={styles.chart_holder}>
			<div className={styles.chart_head}>
				<div className={styles.text}>
					<h2>271 {Tr.tr("doctor:Reports:Appointments")}</h2>
					<div className={styles.statistics}>
						<img src="/img/growth-indicator.svg" alt="" />
						<span className={styles.green}>1.3%</span>
						<span className={styles.gray}>
							{Tr.tr("doctor:Reports:VS_LAST_YEAR")}
						</span>
					</div>
				</div>
				<div className={styles.right}>
					<div className={styles.service_type}>
						<span className={styles.tit}>
							{Tr.tr("doctor:Reports:Service_Type")}
						</span>
						<SelectDropdownWithRadioBtn
							inputLabel=""
							inputName="serviceType"
							placeHolder={Tr.tr("basic:Select")}
							options={serviceTypes}
							inputVal={selectedServiceType}
							changeHandler={(e) => setSelectedServiceType(e.value)}
						/>
					</div>
					<div className={styles.duration_selection}>
						<SelectButton
							value={value}
							onChange={(e) => setValue(e.value)}
							options={options}
							unselectable={false}
						/>
					</div>
				</div>
			</div>
			<div className={styles.chart_wrapper}>
				<ReactApexChart
					options={chartOptions}
					series={chartSeries}
					type="area"
					height={350}
				/>
			</div>
		</section>
	);
};

export default EarningsChart;

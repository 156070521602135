import { Outlet } from "react-router-dom";

// Components
import LanguageBtn from "components/Common/UI/LanguageBtn/LanguageBtn";

// Styles
import styles from "./AuthLayout.module.scss";

const AuthLayout = () => {
	return (
		<div className={styles.auth_layout}>
			<LanguageBtn classes={styles.auth_lang} />
			<Outlet />
		</div>
	);
};

export default AuthLayout;

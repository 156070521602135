import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { useMutation } from "react-query";

// Apis
import { activeAPI, loginAPI, sendVerificationCodeAPI } from "api/auth";

// Components
import { OtpInputs } from "components/Common/Form/Inputs";
import GradientButton from "components/Common/UI/GradientButton/GradientButton";
import { toast } from "react-hot-toast";
import Cookies from "js-cookie";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./DoctorAccountVerification.module.scss";

const DoctorAccountVerification = () => {
	const [otp, setOtp] = useState("");
	const ref = useRef(false);
	const { mutate: login } = useMutation(loginAPI, {
		onSuccess: (data) => {
			Cookies.set("session_id", data.data.session_id, { expires: 1 });
		},
	});
	const [phoneNumber, setPhoneNumber] = useState(
		localStorage.getItem("phoneNumber")
		// "+201030991504"
	);

	const [countryCode, setCountryCode] = useState(
		localStorage.getItem("countryCode")
	);

	const { mutate: sendVerificationCode } = useMutation(sendVerificationCodeAPI);
	const { mutate: verifyCode } = useMutation(activeAPI, {
		onSuccess: async (data) => {
			const password = localStorage.getItem("password");

			localStorage.removeItem("phoneNumber");
			localStorage.removeItem("password");

			await login({ login: countryCode + phoneNumber, password });
			navigate("/doctor/sign-up/personal-details");
		},
	});

	useEffect(() => {
		if (ref.current) {
			return;
		}
		ref.current = true;
		const sendCode = async () => {
			await sendVerificationCode(countryCode + phoneNumber);
		};
		if (phoneNumber) {
			sendCode();
		}
	}, []);

	const submitCode = () => {
		verifyCode({ phone: countryCode + phoneNumber, code: otp });
	};

	const navigate = useNavigate();

	// Number Of Digits [used in translation]
	const Digits = 4;

	return (
		<div className={styles.doctor_account_verification}>
			<div className={styles.head}>
				<h2>{Tr.tr("basic:Enter_Digit_Code", { Digits })}</h2>
				<p>{Tr.tr("basic:Check_Phone_Number")}</p>
			</div>

			<div className={styles.phone_num}>
				<div className={styles.phone_lbl}>{Tr.tr("basic:Phone_Number")}</div>
				<div className={styles.num}>{countryCode + phoneNumber}</div>
			</div>

			<form className={styles.form_holder}>
				<div className={styles.inputs_wrapper}>
					<div className="row">
						{/* OTP */}
						<div className="col-md-12">
							<OtpInputs
								inputLabel={Tr.tr("basic:Verification_Code")}
								inputVal={otp}
								otpHandler={setOtp}
								inputsNum={4}
							/>
						</div>
					</div>
				</div>
				<GradientButton
					btn
					type="button"
					label={Tr.tr("basic:Verify")}
					disabled={otp.length < 4}
					buttonClicked={submitCode}
					classes="w-100"
				/>
			</form>

			{/* Get The Code */}
			<div className={styles.get_code}>
				<span className={styles.txt}>{Tr.tr("basic:Did_Not_Get_Code")}</span>
				<Button
					type="button"
					label={Tr.tr("basic:Resend")}
					className={styles.resend_btn}
				/>
			</div>
		</div>
	);
};

export default DoctorAccountVerification;

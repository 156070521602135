// Components
import RatingsChart from "./components/RatingsChart/RatingsChart";
import RatingsSummary from "./components/RatingsSummary/RatingsSummary";
import RatingsDetails from "./components/RatingsDetails/RatingsDetails";

// Styles
import styles from "./RatingsReports.module.scss";

const RatingsReports = () => {
	return (
		<div className={styles.ratings_reports}>
			{/* Chart */}
			<RatingsChart />

			{/* Summary */}
			<RatingsSummary />

			{/* Details */}
			<RatingsDetails />
		</div>
	);
};

export default RatingsReports;

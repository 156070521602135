import { useState } from "react";

// Components
import { RadioButton } from "primereact/radiobutton";
import WhiteButton from "components/Common/UI/WhiteButton/WhiteButton";
import AddPaymentModal from "../../../pages/Patient/MyProfile/pages/AccountSettings/components/AddPaymentModal/AddPaymentModal";
// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./PaymentMethod.module.scss";
import { getPaymentMethod } from "api/payment";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

const PaymentMethod = ({ paymentMethod, onChangePayment }) => {
	const navigate = useNavigate()
	const { data, refetch, isLoading: loadingPayment } = useQuery('userData', getPaymentMethod)
	// Add Payment Modal Visible
	const [addPaymentModalVisible, setAddPaymentModalVisible] = useState(false);
	const paymentMethods = [
		{
			label: Tr.tr("patient:DoctorDetails:Pay_At_Clinic"),
			value: "Pay at the clinic",
		},
		{
			label: Tr.tr("patient:DoctorDetails:Credit_Card_Visa"),
			value: "Credit Card / Visa",
		},
	];

	const selectedMethod = paymentMethod

	// const [selectedMethod, setSelectedMethod] = useState();

	return (
		<div className={styles.payment_method}>
			<h2>{Tr.tr("patient:DoctorDetails:Payment_Method")}</h2>

			<div className={styles.payment_method_content}>
				{paymentMethods.map((m, index) => (
					<div className={styles.payment_row} key={index}>
						<label className={styles.option_holder}>
							<RadioButton
								name="paymentMethods"
								value={m}
								onChange={(e) => onChangePayment(e.value)}
								checked={selectedMethod?.value === m.value}
							/>
							{m.value === "Pay at the clinic" && (
								<img src="/img/dollar-gray.svg" alt="" />
							)}
							{m.value === "Credit Card / Visa" && (
								<img src="/img/credit-card.svg" alt="" />
							)}
							<div className={styles.lbl}>
								<h5>{m.label}</h5>
								<p>{Tr.tr("patient:DoctorDetails:Add_Credit_Debit_Card")}</p>
							</div>
						</label>
						{console.log(m.value === "Credit Card / Visa")}
						{(m.value === "Credit Card / Visa" && selectedMethod?.value === "Credit Card / Visa") &&
							(<>
								<div className={styles.credit_card}>

									{data?.data?.map((m) => (
										<div

											onClick={() => {
												onChangePayment({ ...selectedMethod, ...m })
											}}
											className={styles.credit_row}>
											<div className={styles.left}>
												<img
													src="/img/master-card.svg"
													alt=""
													className={styles.payment_icon}
												/>
												<p>{Tr.tr("patient:DoctorDetails:Ending_In")} {m?.display_name}</p>
											</div>
											{paymentMethod?.id === m?.id && <img
												src="/img/check-circle.svg"
												alt=""
												className={styles.check_icon}
											/>}
										</div>

									))}



									<WhiteButton
										btn
										type="button"
										label={Tr.tr("patient:DoctorDetails:Credit_Card_Visa")}
										withIconImg
										icon="/img/add-gradient.svg"
										classes={styles.add_btn}
										onClick={() => setAddPaymentModalVisible(true)}
									/>
								</div>
							</>)

						}
					</div>
				))}
			</div>
			<AddPaymentModal
				visible={addPaymentModalVisible}
				hideHandler={() => setAddPaymentModalVisible(false)}
			
			/>
		</div>
	);
};

export default PaymentMethod;

export const en = {
	dir: "ltr",
	Feel_Better_Finding_Healthcare: "Feel Better About Finding Healthcare",
	Welcome_back: "Welcome back!",
	New_To_Tiryaq: "New to Tiryaaq?",
	Create_account: "Create an account",
	Email_Address: "Email Address",
	Password: "Password",
	Forgot_Your_Password: "Forgot your password?",
	Sign_In: "Sign in",
	Email_Required: "Email is required.",
	Email_Invalid: "Invalid email address. E.g. user@example.com",
	Password_Required: "Password is required.",
	Already_Have_account: "Already have an account?",
	Login: "Login",
	First_Name: "First Name",
	Last_Name: "Last Name",
	Phone_Number: "Phone Number",
	Enter: "Enter",
	Sign_Up: "Sign Up",
	Is_Required: "is required",
	Terms: "Terms",
	Privacy: "Privacy",
	Help_Center: "Help center",
	Back: "Back",
	Forgot_Password: "Forgot Password",
	No_Problem:
		"No problem! Just enter the phone number that you signed up with to reset it.",
	Send_Code: "Send Code",
	Back_To_Login: "Back to log in",
	Reset_Password: "Reset Password",
	Protect_Your_Account:
		"In order to protect your account make sure your password",
	New_Password: "New Password",
	Confirm_Password: "Confirm Password",
	Enter_Digit_Code: "Enter {{Digits}} Digit Code",
	Check_Phone_Number:
		"Check your phone number, we have sent the verification code",
	Verification_Code: "Verification code",
	Verify: "Verify",
	Did_Not_Get_Code: "Did I not get the code?",
	Resend: "Resend",
	Verify_Your_Account: "Verify Your Account",
	Start: "Start",
	View_All: "View All",
	Learn_More: "Learn More",
	Read_More: "Read More",
	Download_App: "Download Tiryaaq App in your Mobile",
	Get_App_Link: "Get the link to download the app",
	Enter_Phone_Number: "Enter phone number",
	Send_SMS: "Send SMS",
	Previous: "Previous",
	Next: "Next",
	Consultation_Call: "Consultation Call",
	Service_Only_Available_On_App: "This service is only available on the app",
	View_Profile: "View Profile",
	Hi: "Hi",
	Saturday: "Saturday",
	Sunday: "Sunday",
	Monday: "Monday",
	Tuesday: "Tuesday",
	Wednesday: "Wednesday",
	Thursday: "Thursday",
	Friday: "Friday",
	Edit: "Edit",
	Description: "Description",
	Discard: "Discard",
	Save: "Save",
	Upload: "Upload",
	Last_Update: "Last Update",
	Update: "Update",
	Delete: "Delete",
	Drag_Drop__Files: "Drag or Drop your Files",
	File_Format_Supported: "File format Supported",
	Upload_Your_Files: "Upload your files",
	Search: "Search",
	Your_Name: "Your Name",
	Mobile_Number: "Mobile Number",
	Save_Changes: "Save Changes",
	Change_Password_Your_Account: "Change the password for your account",
	Current_Password: "Current Password",
	Password_Must_Be: "Password must be 8 characters or longer.",
	Confirm_New_Password: "Confirm New Password",
	Current_Password_Incorrect: "Current Password is incorrect",
	Current_Password_Required: "Current Password is required.",
	New_Password_Required: "New Password is required.",
	Passwords_Must_Match: "Passwords must match.",
	Profile_Updated_Successfully: "Profile Updated Successfully",
	Join: "Join",
	Cancel: "Cancel",
	Total: "Total",
	Complete_Your_Profile: "Complete your profile",
	Complete_Profile: "Complete Profile",
	Please_Sign_In_To_Continue: "Please sign in to continue.",
	Email_Phone: "Email/Phone",
	Donot_Have_Account: "don't have an account?",
	No_Problem_Provide_Email_Phone:
		"No problem! Provide us the email id/ mobile of your account and we will send you verification code",
	Email_Mobile_Number: "Email ID/Mobile Number",
	Welcome_To_Tiryaaq: "Welcome to Tiryaaq!",
	Millions_Patients_Looking_Doctor:
		"Millions of patients are looking for the right doctor. Start your digital journey",
	Personal_Details: "Personal Details",
	Registration_Of_Documents: "Registration of documents",
	Name: "Name",
	I_Agree_To: "I agree to",
	Terms_Of_Use: "terms of use",
	And: "and",
	Privacy_Policy: "privacy policy",
	Profile_Photo: "Profile Photo",
	Title: "Title",
	Specialities: "Specialities",
	Choose_Your_Specialties: "Choose your specialties",
	Languages: "Languages",
	Choose_Languages_Communicating_In:
		"Please choose the languages you are communicating in.",
	Years_Of_Experience: "Years of Experience",
	Enter_Number_Of_Years: "Enter number of years",
	City: "City",
	Select_City: "Select city",
	Gender: "Gender",
	Select_Gender: "Select gender",
	Date_Of_Birth: "Date of Birth",
	About_Me: "About Me",
	Continue: "Continue",
	We_Need_Verify_Information: "We need to verify your information",
	Passport_Or_ID: "Passport or ID",
	Upload_Drag_Drop: "Click to upload or drag and drop",
	Residence_Certificate: "Residence Certificate",
	Finish: "Finish",
    Add: "Add",
    Edit: "Edit",
	My_Appointments: "My Appointments",
	Settings: "Settings",
	Logout: "Logout",
	Dashboard: "Dashboard",
	Appointments: "Appointments",
	Patients: "Patients",
	Messages: "Messages",
	Question_Answer: "Question & Answer",
	Reports: "Reports",
	Enter_Name: "Enter name",
	Enter_Mobile_Number: "Enter mobile number",
	Enter_Email_Address: "Enter email address",
	SAR: "SAR",
	SMS_Language: "SMS Language",
	Choose_Your_Language: "Choose your Language",
	SMS_Patients_Language: "SMS to patients would be sent in this language.",
	SMS_Clinic_Name: "SMS Clinic Name",
	Enter_Clinic_Name: "Enter clinic name",
	Reschedule: "Reschedule",
	Cancel_Appointment: "Cancel Appointment",
	Check_In: "Check-In",
	Add_Records: "Add Records",
	Add_Appointment: "Add Appointment",
	Email: "Email",
	SMS: "SMS",
	In_Clinic: "In Clinic",
	Video_Call: "Video Call",
	Scheduled_On: "Scheduled On",
	At: "At",
	Notes: "Notes",
	Add_Notes: "Add notes",
	All: "All",
	Waiting: "Waiting",
	Done: "Done",
	Filter: "Filter",
	Age: "Age",
	Blood_Type: "Blood Type",
	Select_Blood_Type: "Select Blood Type",
	Apply_Filter: "Apply Filter",
	Reset_Filter: "Reset Filter",
	Add_Patient: "Add Patient",
	Last_Updated: "Last updated",
	Call: "Call",
	Send_Mail: "Send Mail",
	Information: "Information",
	Weight: "Weight",
	Height: "Height",
	Vitals: "Vitals",
	Blood_Pressure: "Blood Pressure",
	Pulse: "Pulse",
	Lab_Results: "Lab results",
	Current_Medications: "Current medications",
	Medical_History: "Medical History",
	Vitals_Signs: "Vitals Signs",
	Clinical_Notes: "Clinical Notes",
	Prescriptions: "Prescriptions",
	Lab_Test: "Lab Test",
	BP: "BP",
	Temperature: "Temperature",
	Respiratory_Rate: "Resp. Rate",
	Enter_Weight: "Enter weight",
	Enter_Blood_Pressure: "Enter Blood Pressure",
	Enter_Pulse: "Enter pulse",
	Enter_Temperature: "Enter temperature",
	Enter_Respiratory_Rate: "Enter Resp Rate",
	Add_Line: "Add Line",
	Complaints: "Complaints",
	Observations: "Observations",
	Add_Complaints: "Add Complaints",
	Add_Observations: "Add Observations",
	Add_Diagnoses: "Add Diagnoses",
	Select: "Select",
	Drug: "Drug",
	Dosage: "Dosage",
	Intake: "Intake",
	Duration: "Duration",
	Search_Drugs: "Search drugs",
	Search_Labs: "Search Labs",
	Enter_Test: "Enter Test",
	View: "View",
	Test: "Test",
	Recommended_Lab: "Recommended Lab",
	Submit: "Submit",
	From: "From",
	To: "To",
	Profile_Updated_Phone: "To update your phone number you have to verify the new phone number",
	Not_Now: "Not Now",
};

export const ar = {
	dir: "rtl",
	Feel_Better_Finding_Healthcare: "اشعر بتحسن في العثور على الرعاية الصحية",
	Welcome_back: "مرحباً بك!",
	New_To_Tiryaq: "هل أنت جديد في ترياق؟",
	Create_account: "إنشاء حساب",
	Email_Address: "عنوان البريد الإلكتروني",
	Password: "كلمة المرور",
	Forgot_Your_Password: "هل نسيت كلمة المرور؟",
	Sign_In: "تسجيل الدخول",
	Email_Required: "البريد الإلكتروني مطلوب.",
	Email_Invalid:
		"عنوان البريد الإلكتروني غير صالح. على سبيل المثال user@example.com",
	Password_Required: "كلمة المرور مطلوبة.",
	Already_Have_account: "هل لديك حساب بالفعل؟",
	Login: "تسجيل الدخول",
	First_Name: "الاسم الأول",
	Last_Name: "اسم العائلة",
	Phone_Number: "رقم الهاتف",
	Enter: "أدخل",
	Sign_Up: "التسجيل",
	Is_Required: "مطلوب",
	Terms: "الشروط",
	Privacy: "الخصوصيه",
	Help_Center: "مركز المساعدة",
	Back: "رجوع",
	Forgot_Password: "نسيت كلمة المرور",
	No_Problem:
		"لا توجد مشكلة! ما عليك سوى إدخال رقم الهاتف الذي قمت بالتسجيل به لإعادة تعيينه.",
	Send_Code: "ارسل الرمز",
	Back_To_Login: "العودة إلى تسجيل الدخول",
	Reset_Password: "إعادة تعيين كلمة المرور",
	Protect_Your_Account: "من أجل حماية حسابك تأكد من كلمة المرور الخاصة بك",
	New_Password: "كلمة مرور جديدة",
	Confirm_Password: "تأكيد كلمة المرور",
	Enter_Digit_Code: "أدخل الرمز المكون من {{Digits}} أرقام",
	Check_Phone_Number: "تحقق من رقم هاتفك ، لقد أرسلنا رمز التحقق",
	Verification_Code: "رمز التحقق",
	Verify: "تحقق",
	Did_Not_Get_Code: "ألم أحصل على الرمز؟",
	Resend: "ارسال",
	Verify_Your_Account: "تحقق من حسابك",
	Start: "ابدأ",
	View_All: "عرض الكل",
	Learn_More: "التعرف على المزيد",
	Read_More: "إقرأ المزيد",
	Download_App: "حمل تطبيق ترياق على هاتفك المحمول",
	Get_App_Link: "احصل على الرابط لتنزيل التطبيق",
	Enter_Phone_Number: "أدخل رقم الهاتف",
	Send_SMS: "ارسل رسالة قصيرة",
	Previous: "السابق",
	Next: "التالي",
	Consultation_Call: "مكالمة صوتية",
	Service_Only_Available_On_App: "هذه الخدمة متاحة فقط على التطبيق",
	View_Profile: "عرض الملف الشخصي",
	Hi: "مرحبًا",
	Saturday: "السبت",
	Sunday: "الأحد",
	Monday: "الإثنين",
	Tuesday: "الثلاثاء",
	Wednesday: "الأربعاء",
	Thursday: "الخميس",
	Friday: "الجمعة",
	Edit: "تعديل",
	Description: "الوصف",
	Discard: "تجاهل",
	Save: "حفظ",
	Upload: "رفع",
	Last_Update: "آخر تحديث",
	Update: "تحديث",
	Delete: "حذف",
	Drag_Drop__Files: "سحب أو إسقاط الملفات الخاصة بك",
	File_Format_Supported: "تنسيق الملف المدعوم",
	Upload_Your_Files: "قم برفع ملفاتك",
	Search: "بحث",
	Your_Name: "الاسم",
	Mobile_Number: "رقم الموبايل",
	Save_Changes: "حفظ التغييرات",
	Change_Password_Your_Account: "تغيير كلمة المرور لحسابك",
	Current_Password: "كلمة المرور الحالية",
	Password_Must_Be: "يجب أن تتكون كلمة المرور من 8 أحرف أو أكثر.",
	Confirm_New_Password: "تأكيد كلمة المرور الجديدة",
	Current_Password_Incorrect: "كلمة المرور الحالية غير صحيحة",
	Current_Password_Required: "كلمة المرور الحالية مطلوبة.",
	New_Password_Required: "مطلوب كلمة مرور جديدة.",
	Passwords_Must_Match: "يجب أن تتطابق كلمات المرور.",
	Profile_Updated_Successfully: "تم تحديث الملف الشخصي بنجاح",
	Join: "انضم",
	Cancel: "إلغاء",
	Total: "الإجمالي",
	Complete_Your_Profile: "أكمل ملفك الشخصي",
	Complete_Profile: "أكمل الملف الشخصي",
	Please_Sign_In_To_Continue: "يرجى تسجيل الدخول للمتابعة.",
	Email_Phone: "البريد الإلكتروني / الهاتف",
	Donot_Have_Account: "ليس لديك حساب؟",
	No_Problem_Provide_Email_Phone:
		"لا توجد مشكلة! زودنا بمعرف البريد الإلكتروني / الهاتف المحمول لحسابك وسنرسل لك رمز التحقق",
	Email_Mobile_Number: "البريد الإلكتروني / رقم الهاتف",
	Welcome_To_Tiryaaq: "مرحبا بكم في ترياق!",
	Millions_Patients_Looking_Doctor:
		"يبحث ملايين المرضى عن الطبيب المناسب. ابدأ رحلتك الرقمية",
	Personal_Details: "البيانات الشخصية",
	Registration_Of_Documents: "تسجيل الوثائق",
	Name: "الاسم",
	I_Agree_To: "أوافق على",
	Terms_Of_Use: "شروط الاستخدام",
	And: "و",
	Privacy_Policy: "سياسة الخصوصية",
	Profile_Photo: "صورة الملف الشخصي",
	Title: "اللقب",
	Specialities: "التخصصات",
	Choose_Your_Specialties: "اختر تخصصاتك",
	Languages: "اللغات",
	Choose_Languages_Communicating_In: "يرجى اختيار اللغات التي تتواصل بها.",
	Years_Of_Experience: "سنوات الخبرة",
	Enter_Number_Of_Years: "أدخل عدد السنوات",
	City: "المدينه",
	Select_City: "اختر المدينة",
	Gender: "النوع",
	Select_Gender: "اختر النوع",
	Date_Of_Birth: "تاريخ الميلاد",
	About_Me: "معلومات عني",
	Continue: "استمرار",
	We_Need_Verify_Information: "نحن بحاجة إلى التحقق من معلوماتك",
	Passport_Or_ID: "جواز السفر أو الهوية",
	Upload_Drag_Drop: "انقر للتحميل أو السحب والإفلات",
	Residence_Certificate: "شهادة الإقامة",
	Finish: "إنهاء",
    Add: "أضف",
    Edit: "تعديل",
	My_Appointments: "مواعيدي",
	Settings: "الإعدادات",
	Logout: "تسجيل خروج",
	Dashboard: "لوحه القياده",
	Appointments: "المواعيد",
	Patients: "المرضي",
	Messages: "الرسائل",
	Question_Answer: "أسئلة وأجوبة",
	Reports: "التقارير",
	Enter_Name: "أدخل الاسم",
	Enter_Mobile_Number: "أدخل رقم الجوال",
	Enter_Email_Address: "أدخل عنوان البريد الإلكتروني",
	SAR: "ريال سعودي",
	SMS_Language: "لغة الرسائل القصيرة",
	Choose_Your_Language: "اختر لغتك",
	SMS_Patients_Language: "سيتم إرسال الرسائل القصيرة إلى المرضى بهذه اللغة.",
	SMS_Clinic_Name: "اسم عيادة الرسائل القصيرة",
	Enter_Clinic_Name: "أدخل اسم العيادة",
	Reschedule: "إعادة جدوله",
	Cancel_Appointment: "إلغاء الموعد",
	Check_In: "تسجيل الوصول",
	Add_Records: "إضافة سجلات",
	Add_Appointment: "إضافة موعد",
	Email: "البريد الإلكتروني",
	SMS: "الرسائل القصيرة",
	In_Clinic: "في العيادة",
	Video_Call: "مكالمة فيديو",
	Scheduled_On: "مجدول في",
	At: "في",
	Notes: "ملاحظات",
	Add_Notes: "إضافة ملاحظات",
	All: "الكل",
	Waiting: "انتظار",
	Done: "منتهي",
	Filter: "تصفيه",
	Age: "العمر",
	Blood_Type: "فصيلة الدم",
	Select_Blood_Type: "اختر فصيلة الدم",
	Apply_Filter: "تطبيق الفلتر",
	Reset_Filter: "إعادة تعيين الفلتر",
	Add_Patient: "إضافة مريض",
	Last_Updated: "آخر تحديث",
	Call: "اتصال",
	Send_Mail: "ارسل بريد",
	Information: "معلومات",
	Weight: "الوزن",
	Height: "الطول",
	Vitals: "الحيويه",
	Blood_Pressure: "ضغط الدم",
	Pulse: "النبض",
	Lab_Results: "نتائج المختبر",
	Current_Medications: "الأدوية الحالية",
	Medical_History: "التاريخ الطبي",
	Vitals_Signs: "علامات حيوية",
	Clinical_Notes: "ملاحظات سريرية",
	Prescriptions: "وصفات",
	Lab_Test: "الفحوصات المخبرية",
	BP: "ضغط الدم",
	Temperature: "درجة الحرارة",
	Respiratory_Rate: "معدل التنفس",
	Enter_Weight: "أدخل الوزن",
	Enter_Blood_Pressure: "أدخل ضغط الدم",
	Enter_Pulse: "أدخل النبض",
	Enter_Temperature: "أدخل درجة الحرارة",
	Enter_Respiratory_Rate: "أدخل معدل التنفس",
	Add_Line: "إضافة سطر",
	Complaints: "الشكاوي",
	Observations: "الملاحظات",
	Add_Complaints: "إضافة شكاوى",
	Add_Observations: "إضافة ملاحظات",
	Add_Diagnoses: "إضافة تشخيصات",
	Select: "اختر",
	Drug: "الدواء",
	Dosage: "الجرعه",
	Intake: "تناول",
	Duration: "المده",
	Search_Drugs: "البحث عن الأدوية",
	Search_Labs: "البحث عن المعامل",
	Enter_Test: "أدخل الاختبار",
	View: "عرض",
	Test: "الاختبار",
	Recommended_Lab: "المختبر الموصى به",
	Submit: "إرسال",
	From: "من",
	To: "إلى",
	Profile_Updated_Phone: "لتحديث رقم هاتفك، يجب عليك التحقق من رقم الهاتف الجديد",
	Not_Now: "ليس الآن",
};

import { Button } from "primereact/button";

// Styles
import styles from "./Notifications.module.scss";

const Notifications = () => {
	return (
		<Button className={styles.notify_btn}>
			<img src="/img/notifications.svg" alt="" />
			<span className={styles.count}>2</span>
		</Button>
	);
};

export default Notifications;

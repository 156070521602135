// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./PossibleConditions.module.scss";

const PossibleConditions = () => {
	// Possible Conditions
	const conditions = [
		{
			id: 1,
			title: "Knee arthrosis",
			description: "Degenerative knee osteoarthritis",
		},
		{
			id: 2,
			title: "Runner's knee",
			description: "Patellofemoral pain syndrome",
		},
	];

	return (
		<div className={styles.possible_conditions}>
			<div className={styles.possible_conditions_head}>
				<h3>{Tr.tr("patient:CheckSymptoms:Possible_Conditions")}</h3>
				<p>{Tr.tr("patient:CheckSymptoms:Possible_Guidance")}</p>
			</div>
			<div className={styles.possible_conditions_rows}>
				{conditions.map((condition) => (
					<div className={styles.possible_conditions_row} key={condition.id}>
						<h6>{condition.title}</h6>
						<h5>{condition.description}</h5>
					</div>
				))}
			</div>
		</div>
	);
};

export default PossibleConditions;

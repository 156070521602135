import { ProgressBar } from "primereact/progressbar";

// Styles
import styles from "./ProgressBarWrapper.module.scss";

const ProgressBarWrapper = ({ value, status, ...props }) => {
	return (
		<div className={styles.progressBar_wrapper}>
			{props.showStatus && <div className={styles.progress_txt}>{status}</div>}
			<ProgressBar value={value} showValue={false}></ProgressBar>
			{props.showVal && <div className={styles.progress_val}>{value}%</div>}
		</div>
	);
};

export default ProgressBarWrapper;

import { Dropdown } from "primereact/dropdown";

// Components
import ErrorUI from "components/Common/UI/ErrorUI/ErrorUI";

// Styles
import styles from "./SelectDropdown.module.scss";

export function SelectDropdown({ ...props }) {
	const optionTemplate = (option, props) => {
		if (option) {
			return (
				<div className="option_template">
					<span>{option.label}</span>
					<img src="/img/check-gradient.svg" alt="" />
				</div>
			);
		}

		return <span>{props.placeholder}</span>;
	};

	const composeOptionTemplate = (option, props) => {
		if (option) {
			return (
				<div className="option_template">
					<div className={styles.option_left}>
						<div className={styles.name_holder}>{option.name}</div>
						<div className={styles.docInfo}>
							<span>{option.label}</span>
							<span>{option.desc}</span>
						</div>
					</div>
					<img src="/img/check-gradient.svg" alt="" />
				</div>
			);
		}

		return <span>{props.placeholder}</span>;
	};

	return (
		<div className="input_holder">
			<div className={styles.field_holder}>
				{props.inputLabel && (
					<h6 className={props.required ? "required" : ""}>
						{props.inputLabel}
					</h6>
				)}
				<div
					className={`w-100 ${styles.input_box} ${
						props.inputError ? "p-invalid" : ""
					} ${props.disabled ? styles.disabled : ""}`}>
					<Dropdown
						name={props.inputName}
						value={props.inputVal}
						options={props.options}
						optionLabel="label"
						optionValue="value"
						placeholder={props.placeHolder}
						onChange={props.changeHandler}
						panelClassName={`${styles.dropdown_panel} ${
							props.appendTo === "self" ? "w100 self_panel" : ""
						}`}
						className="w-100"
						appendTo={props.appendTo}
						itemTemplate={
							props.composedOptopn ? composeOptionTemplate : optionTemplate
						}
						filter={props.filter}
						filterPlaceholder={props.filterPlaceholder}
						disabled={props.disabled}
					/>
				</div>
			</div>
			{props.inputError && (
				<ErrorUI errorMsg={props.inputError} withBG={props.withBG} />
			)}
		</div>
	);
}

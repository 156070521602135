import { Button } from "primereact/button";

// import Swiper core and required modules
import { Autoplay, Navigation } from "swiper/modules";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";

// Translation
import { useTranslation } from "react-i18next";
import { Tr } from "utils/i18n";

// Styles
import styles from "./CustomersStories.module.scss";

const CustomersStories = () => {
	const { i18n } = useTranslation();
	const currLang = i18n.language;

	const stories = [
		{
			customerComment:
				"Very good app. Well thought out about booking/rescheduling/canceling an appointment. Also, Doctor's feedback mechanism is good and describes all the basics in a good way",
			customerName: "Ahmed Ali",
		},
		{
			customerComment:
				"Very good app. Well thought out about booking/rescheduling/canceling an appointment. Also, Doctor's feedback mechanism is good and describes all the basics in a good way",
			customerName: "Ahmed Ali",
		},
		{
			customerComment:
				"Very good app. Well thought out about booking/rescheduling/canceling an appointment. Also, Doctor's feedback mechanism is good and describes all the basics in a good way",
			customerName: "Ahmed Ali",
		},
		{
			customerComment:
				"Very good app. Well thought out about booking/rescheduling/canceling an appointment. Also, Doctor's feedback mechanism is good and describes all the basics in a good way",
			customerName: "Ahmed Ali",
		},
	];

	return (
		<section className={styles.customers_stories}>
			<div className="container">
				<div className={styles.sec_head}>
					<img src="/img/quote-mark.svg" alt="" className={styles.quote_mark} />
					<h2>{Tr.tr("patient:Home:Real_Stories_Customers")}</h2>
					<p>{Tr.tr("patient:Home:Real_Stories_Customers_Inspired")}</p>
				</div>

				{/* Stories Slider */}
				<div className={styles.stories_slider}>
					<Swiper
						key={currLang} // To re-render this component with language change
						dir={currLang === "ar" ? "rtl" : "ltr"}
						// install Swiper modules
						modules={[Navigation, Autoplay]}
						slidesPerView={"auto"}
						spaceBetween={24}
						loopedSlides={2}
						initialSlide={0}
						loop={true}
						centeredSlides={true}
						autoplay={{
							delay: 2500,
							disableOnInteraction: false,
						}}
						navigation={{
							nextEl: ".review-swiper-button-next",
							prevEl: ".review-swiper-button-prev",
						}}
						breakpoints={{
							1024: {
								slidesPerView: 3,
							},
							992: {
								slidesPerView: 2.3,
							},
							575: {
								slidesPerView: 2.3,
							},
							0: {
								slidesPerView: 1.3,
							},
						}}>
						{stories.map((story, index) => (
							<SwiperSlide key={index}>
								<div className={styles.story_box}>
									<img
										src="/img/quote-mark-small.svg"
										alt=""
										className={styles.quote_mark}
									/>
									<div className={styles.customer_comment}>
										{Tr.tr(
											`patient:Home:CustomersStories:${story.customerComment}`
										)}
									</div>
									<div className={styles.customer_name}>
										{story.customerName}
									</div>
								</div>
							</SwiperSlide>
						))}
					</Swiper>
					{/* Navigation */}
					<Button icon="pi-angle-left" className="review-swiper-button-prev" />
					<Button icon="pi-angle-right" className="review-swiper-button-next" />
				</div>
			</div>
		</section>
	);
};

export default CustomersStories;

// Styles
import { useState } from "react";
import styles from "./BookedDoctor.module.scss";

const BookedDoctor = ({ data }) => {
	const [htmlString, setHtmlString] = useState(data?.description);
	return (
		<div className={styles.booked_doctor}>
			<div className={styles.doctor_img}>
				<img src={data?.image} alt="" />
			</div>
			<div className={styles.doctor_info}>
				<div className={styles.name}>{data?.name}</div>
				<div className={styles.speciality}>{data?.doctor_specialty_ids[0]?.name}</div>
				{/* <div className={styles.desc}>{data?.description}</div> */}
				<div
					className={styles.desc}
					dangerouslySetInnerHTML={{ __html: htmlString }}
				></div>
			</div>
		</div>
	);
};

export default BookedDoctor;

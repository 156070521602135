import { useContext, useEffect } from "react";
import { useFormik } from "formik";
import { useMutation } from "react-query";
import { toast } from "react-hot-toast";

// Components
import TitleWithLine from "components/Common/UI/TitleWithLine/TitleWithLine";
import PageHead from "components/Patient/PageHead/PageHead";
import GradientButton from "components/Common/UI/GradientButton/GradientButton";
import { NumericInput } from "components/Common/Form/Inputs/NumericInput/NumericInput";

// Context
import { SidebarContext } from "context/DoctorSidebarContext";

// Utils
import { getFormErrorMessage } from "utils/FormField";

// APIs
import { updateMeApi } from "api/auth";
import { getMeApi } from "api/auth";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./SettingsPricingCatalog.module.scss";

const SettingsPricingCatalog = () => {
	const { setSmallSize } = useContext(SidebarContext);
	const { mutate: getMe } = useMutation(getMeApi, {
		onSuccess: (data) => {
			formik.setFieldValue("inClinicFees", data.data.clinic_fees);
			formik.setFieldValue("videoConsultingFees", data.data.video_fees);
		},
	});

	const { mutate: updateProfile } = useMutation(updateMeApi, {
		onSuccess: (data) => {
			toast.success(data.message);
		},
	});

	useEffect(() => {
		setSmallSize(true);
		getMe();
	}, [setSmallSize]);

	// Formik
	const formik = useFormik({
		initialValues: {
			inClinicFees: "",
			videoConsultingFees: "",
		},
		validate: (data) => {
			let errors = {};

			if (!data.inClinicFees) {
				errors.inClinicFees =
					"Examination fees in the clinic Price is required.";
			}

			if (!data.videoConsultingFees) {
				errors.videoConsultingFees = "Video consulting fees Price is required.";
			}

			return errors;
		},
		onSubmit: async (data, { setSubmitting }) => {
			// console.log("submitted");
			// setTimeout(() => {
			// 	setSubmitting(false);
			// 	formik.resetForm();
			// }, 2000);
			const payload = {
				clinic_fees: data.inClinicFees,
				video_fees: data.videoConsultingFees,
			};
			await updateProfile(payload);
		},
	});

	return (
		<div className={styles.settings_pricingCatalog}>
			<TitleWithLine
				title={Tr.tr("doctor:Settings:Pricing_Catalog")}
				mb="32px"
			/>

			<form onSubmit={formik.handleSubmit}>
				<div className={styles.inputs_wrapper}>
					<div className="row">
						{/* In Clinic Fees */}
						<div className="col-lg-7 mb-4">
							<PageHead
								title={Tr.tr("doctor:Settings:Examination_Fees_In_Clinic")}
								subTitle="Lorem ipsum dolor sit amet consectetur. Neque blandit"
								mb="24px"
								titleSize="16px"
							/>

							<NumericInput
								inputLabel={`${Tr.tr("doctor:Settings:Price")} (${Tr.tr(
									"basic:SAR"
								)})`}
								inputName="inClinicFees"
								placeHolder="0.00"
								inputVal={formik.values.inClinicFees}
								changeHandler={formik.handleChange}
								inputError={getFormErrorMessage("inClinicFees", formik)}
								minFractionDigits={0}
								maxFractionDigits={2}
								maxValue={10000}
							/>
						</div>

						{/* Video Consulting Fees */}
						<div className="col-lg-7">
							<PageHead
								title={Tr.tr("doctor:Settings:Video_Consulting_Fees")}
								subTitle="Lorem ipsum dolor sit amet consectetur. Neque blandit"
								mb="24px"
								titleSize="16px"
							/>

							<NumericInput
								inputLabel={`${Tr.tr("doctor:Settings:Price")} (${Tr.tr(
									"basic:SAR"
								)})`}
								inputName="videoConsultingFees"
								placeHolder="0.00"
								inputVal={formik.values.videoConsultingFees}
								changeHandler={formik.handleChange}
								inputError={getFormErrorMessage("videoConsultingFees", formik)}
								minFractionDigits={0}
								maxFractionDigits={2}
								maxValue={10000}
							/>
						</div>
					</div>
				</div>

				<GradientButton
					btn
					type="submit"
					label={Tr.tr("doctor:Settings:Save_Changes")}
					classes="p712 lineH-24"
				/>
			</form>
		</div>
	);
};

export default SettingsPricingCatalog;

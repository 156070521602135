import { joinAppointmentAPI } from 'api/videoCall';
import { useEffect } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { ProgressSpinner } from 'primereact/progressspinner';

const StartVideo = () => {
  const navigate = useNavigate();
  const appId = useParams().appId

  const { data, refetch } = useQuery(['joinAppointment', appId], () => joinAppointmentAPI({ appId }), {
    refetchInterval: 5000, // Poll every 5000ms
    onSuccess: (response) => {
      if (response.data.is_doctor_ready && response.data.is_patient_ready) {
        navigate(`/videoroom/${response.data.channel_name}`);
      }
    },
  });

  useEffect(() => {
    refetch();
  }, [refetch]);

  let statusMessage = "Checking if both doctor and patient are ready...";
  if (data && !data.data.is_doctor_ready && !data.data.is_patient_ready) {
    statusMessage = "Waiting for both doctor and patient to join...";
  } else if (data && !data.data.is_doctor_ready) {
    statusMessage = "Waiting for the doctor to join...";
  } else if (data && !data.data.is_patient_ready) {
    statusMessage = "Waiting for the patient to join...";
  }

  return (
    <div style={{ textAlign: 'center', marginTop: 50 }}>
      <ProgressSpinner style={{ width: '100px', height: '100px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
      <p style={{ marginTop: 50 , fontSize: '20px', fontWeight: 'bold' }}>{statusMessage}</p>
    </div>
  );
};

export default StartVideo;
// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./CurrentMedications.module.scss";
import { useQuery } from "react-query";
import { medicinesAPI } from "api/medicines";

const CurrentMedications = () => {
	const { data: medications, isLoading: medicinesLoading } = useQuery(
		["medications"],
		medicinesAPI,
		{
			select: (response) => {
				return response.data.data;
			},
		}
	);
	// const medications = [
	// 	{
	// 		id: 1,
	// 		name: "Medication name",
	// 	},
	// 	{
	// 		id: 2,
	// 		name: "Medication name",
	// 	},
	// 	{
	// 		id: 3,
	// 		name: "Medication name",
	// 	},
	// 	{
	// 		id: 4,
	// 		name: "Medication name",
	// 	},
	// ];
	return (
		<section className={styles.current_medications}>
			{/* Section Head */}
			<div className="title_action">
				<h2>{Tr.tr("patient:PatientProfile:Current_Medications")}</h2>
			</div>

			{/* Current Medication Card */}
			{medications ? <div className={styles.current_medications_card}>
				{medications?.map((medication) => (
					<div className={styles.medication_row} key={medication.id}>
						<img src="/img/medication-gradient.svg" alt="" />
						<span>{medication.name}</span>
					</div>
				))}
			</div> : null}
		</section>
	);
};

export default CurrentMedications;

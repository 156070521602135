import { useContext, useEffect } from "react";
import { useMutation } from "react-query";
import { TabPanel, TabView } from "primereact/tabview";
import { Button } from "primereact/button";

// Components
import AccountTab from "./components/AccountTab/AccountTab";
import PasswordTab from "./components/PasswordTab/PasswordTab";

// Context
import { SidebarContext } from "context/DoctorSidebarContext";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./DoctorAccountSettings.module.scss";

const DoctorAccountSettings = () => {
	const { setSmallSize } = useContext(SidebarContext);

	useEffect(() => {
		setSmallSize(true);
	}, [setSmallSize]);

	// Tabview Header
	const headerTemplate = (options) => {
		return (
			<Button
				type="button"
				onClick={options.onClick}
				className={`${options.className} tab_btn`}>
				{options.index === 0 && (
					<>
						<img src="/img/profile-gray.svg" alt="" className="gray" />
						<img src="/img/profile-gradient.svg" alt="" className="gradient" />
					</>
				)}
				{options.index === 1 && (
					<>
						<img src="/img/lock-gray.svg" alt="" className="gray" />
						<img src="/img/lock-gradient.svg" alt="" className="gradient" />
					</>
				)}
				{options.titleElement}
			</Button>
		);
	};

	return (
		<div className={styles.doctor_accountSettings}>
			<h1>{Tr.tr("doctor:Settings:Account_Settings")}</h1>

			<div className={styles.settings_tabs}>
				<TabView>
					<TabPanel
						header={Tr.tr("doctor:Settings:Account")}
						headerTemplate={(options) => headerTemplate(options)}>
						<AccountTab />
					</TabPanel>
					<TabPanel
						header={Tr.tr("doctor:Settings:Password")}
						headerTemplate={(options) => headerTemplate(options)}>
						<PasswordTab />
					</TabPanel>
				</TabView>
			</div>
		</div>
	);
};

export default DoctorAccountSettings;

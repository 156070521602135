import { ar as basic } from "./namespaces/basic";
import { ar as patient } from "./namespaces/patient";
import { ar as doctor } from "./namespaces/doctor";

// eslint-disable-next-line
export default {
	basic,
	patient,
	doctor,
};

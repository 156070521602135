import { useState } from "react";
import { TabPanel, TabView } from "primereact/tabview";

// Components
import PageHead from "components/Patient/PageHead/PageHead";
import ProfileContent from "./components/ProfileContent/ProfileContent";
import ChangePassContent from "./components/ChangePassContent/ChangePassContent";
import PaymentMethods from "./components/PaymentMethods/PaymentMethods";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./AccountSettings.module.scss";
import { useLocation } from 'react-router-dom';

const AccountSettings = () => {
	const location = useLocation()
	const searchParams = new URLSearchParams(location?.search)
	const id = searchParams.get('id')
	const [activeIndex, setActiveIndex] = useState(id ? 2 : 0); // Default to the second tab

	return (
		<div className={styles.account_settings}>
			<PageHead
				title={Tr.tr("patient:PatientProfile:Account_Settings")}
				subTitle={Tr.tr(
					"patient:PatientProfile:Edit_Account_Settings_And_Change_Password"
				)}
				mb="16px"
			/>

			{/* Profile & Change Password */}
			<div className={styles.profile_pass_tabs}>
				<TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
					<TabPanel header={Tr.tr("patient:PatientProfile:Profile")}>
						<ProfileContent />
					</TabPanel>
					<TabPanel header={Tr.tr("patient:PatientProfile:Change_Password")}>
						<ChangePassContent />
					</TabPanel>
					<TabPanel header={Tr.tr("patient:PatientProfile:Payment_Methods")}>
						<PaymentMethods />
					</TabPanel>
				</TabView>
			</div>
		</div>
	);
};

export default AccountSettings;

import { useFormik } from "formik";
import { Button } from "primereact/button";

// Components
import DialogWrapper from "components/Patient/DialogWrapper/DialogWrapper";
import PageHead from "components/Patient/PageHead/PageHead";
import { SelectDropdown, TextInput } from "components/Common/Form/Inputs";
import GradientButton from "components/Common/UI/GradientButton/GradientButton";

// Utils
import { getFormErrorMessage } from "utils/FormField";

// Styles
import styles from "./BankAccountModal.module.scss";

const BankAccountModal = ({ ...props }) => {
	// Banks
	const banks = [
		{ label: "CIB", value: "CIB" },
		{ label: "HSBC", value: "HSBC" },
	];

	// Formik
	const formik = useFormik({
		initialValues: {
			bank: "",
			accountName: "",
			iban: "",
		},
		validate: (data) => {
			let errors = {};

			if (!data.bank) {
				errors.bank = "Bank Name is required.";
			}

			if (!data.accountName) {
				errors.accountName = "Account Name is required.";
			}

			if (!data.iban) {
				errors.iban = "IBAN is required.";
			}

			return errors;
		},
		onSubmit: (data, { setSubmitting }) => {
			console.log("submitted");
			setTimeout(() => {
				setSubmitting(false);
				formik.resetForm();
			}, 2000);
		},
	});

	return (
		<DialogWrapper
			header="Add a Billing Method"
			visible={props.visible}
			width="42vw"
			hideHandler={props.hideHandler}>
			<form onSubmit={formik.handleSubmit}>
				<div className="modal_body">
					<PageHead
						title="Add Bank Account"
						subTitle="Lorem ipsum dolor sit amet, vince adipiscing elit"
						mb="20px"
						titleSize="16px"
					/>

					<div className="row">
						{/* Bank Name */}
						<div className="col-md-12 mb-3">
							<SelectDropdown
								inputLabel="Bank Name"
								inputName="bank"
								placeHolder="Select your bank"
								options={banks}
								inputVal={formik.values.bank}
								changeHandler={(e) => formik.setFieldValue("bank", e.value)}
								appendTo={document.body}
								inputError={getFormErrorMessage("bank", formik)}
							/>
						</div>

						{/* Account Name */}
						<div className="col-md-12 mb-3">
							<TextInput
								inputLabel="Accuont Name"
								inputName="accountName"
								placeHolder="Enter accuont name"
								inputVal={formik.values.accountName}
								changeHandler={formik.handleChange}
								inputError={getFormErrorMessage("accountName", formik)}
							/>
						</div>

						{/* IBAN */}
						<div className="col-md-12 mb-3">
							<TextInput
								inputLabel="IBAN"
								inputName="iban"
								placeHolder="IBAN"
								inputVal={formik.values.iban}
								changeHandler={formik.handleChange}
								inputError={getFormErrorMessage("iban", formik)}
							/>
						</div>
					</div>
				</div>

				<div className="modal_footer">
					<Button
						type="button"
						label="Cancel"
						className="discard_btn withBorder"
						onClick={props.hideHandler}
					/>
					<GradientButton btn type="submit" label="Save" classes="done_btn" />
				</div>
			</form>
		</DialogWrapper>
	);
};

export default BankAccountModal;

// Components
import Rate from "components/Common/UI/Rate/Rate";

// Utils
import { NameCharacters } from "utils/NameCharacters";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./RatingsDetails.module.scss";

const RatingsDetails = () => {
	// Details
	const details = [
		{
			id: 1,
			name: "Ahmed Agrma",
			rating: 4,
			time: "a week ago",
			desc: "It was a great course. Got to learn exactly what I was looking for. Would definitely recommend this course. Thanks for the awesome course",
		},
		{
			id: 2,
			name: "Ahmed Agrma",
			rating: 4,
			time: "a week ago",
			desc: "It was a great course. Got to learn exactly what I was looking for. Would definitely recommend this course. Thanks for the awesome course",
		},
		{
			id: 3,
			name: "Ahmed Agrma",
			rating: 4,
			time: "a week ago",
			desc: "It was a great course. Got to learn exactly what I was looking for. Would definitely recommend this course. Thanks for the awesome course",
		},
		{
			id: 4,
			name: "Ahmed Agrma",
			rating: 4,
			time: "a week ago",
			desc: "It was a great course. Got to learn exactly what I was looking for. Would definitely recommend this course. Thanks for the awesome course",
		},
		{
			id: 5,
			name: "Ahmed Agrma",
			rating: 4,
			time: "a week ago",
			desc: "It was a great course. Got to learn exactly what I was looking for. Would definitely recommend this course. Thanks for the awesome course",
		},
		{
			id: 6,
			name: "Ahmed Agrma",
			rating: 4,
			time: "a week ago",
			desc: "It was a great course. Got to learn exactly what I was looking for. Would definitely recommend this course. Thanks for the awesome course",
		},
	];

	return (
		<section className={styles.details_sec}>
			<h2>{Tr.tr("doctor:Reports:Details")}</h2>
			<div className={styles.details_holder}>
				{details.map((data) => (
					<div className={styles.details_row} key={data.id}>
						<div className={styles.circle}>{NameCharacters(data.name)}</div>
						<div className={styles.info}>
							<div className={styles.name}>{data.name}</div>
							<div className={styles.rate_time}>
								<Rate rateVal={data.rating} readOnly={true} />
								<div className={styles.time}>{data.time}</div>
							</div>
							<p className={styles.desc}>{data.desc}</p>
						</div>
					</div>
				))}
			</div>
		</section>
	);
};

export default RatingsDetails;

import { useState } from "react";
import { Button } from "primereact/button";

// Components
import Rate from "components/Common/UI/Rate/Rate";
import ProgressBarWrapper from "components/Common/UI/ProgressBarWrapper/ProgressBarWrapper";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./PatientsReviews.module.scss";

const PatientsReviews = ({ reviewers }) => {
	const [showAll, setShowAll] = useState(false);

	// Reviews Progress
	const reviewsProgress = [
		{
			status: Tr.tr("patient:DoctorDetails:Excellent"),
			value: 0,
		},
		{
			status: Tr.tr("patient:DoctorDetails:Very_Good"),
			value: 0,
		},
		{
			status: Tr.tr("patient:DoctorDetails:Average"),
			value: 0,
		},
		{
			status: Tr.tr("patient:DoctorDetails:Poor"),
			value: 0,
		},
		{
			status: Tr.tr("patient:DoctorDetails:Terrible"),
			value: 0,
		},
	];

	// Reviewers
	// const reviewers = [
	// 	{
	// 		id: 1,
	// 		name: "Ahmed Agrma",
	// 		rate: 4,
	// 		date: "a week ago",
	// 		comment:
	// 			"It was a great course. Got to learn exactly what I was looking for. Would definitely recommend this course. Thanks for the awesome course",
	// 	},
	// 	{
	// 		id: 2,
	// 		name: "Ahmed Agrma",
	// 		rate: 4,
	// 		date: "a week ago",
	// 		comment:
	// 			"It was a great course. Got to learn exactly what I was looking for. Would definitely recommend this course. Thanks for the awesome course",
	// 	},
	// 	{
	// 		id: 3,
	// 		name: "Ahmed Agrma",
	// 		rate: 4,
	// 		date: "a week ago",
	// 		comment:
	// 			"It was a great course. Got to learn exactly what I was looking for. Would definitely recommend this course. Thanks for the awesome course",
	// 	},
	// 	{
	// 		id: 4,
	// 		name: "Ahmed Agrma",
	// 		rate: 4,
	// 		date: "a week ago",
	// 		comment:
	// 			"It was a great course. Got to learn exactly what I was looking for. Would definitely recommend this course. Thanks for the awesome course",
	// 	},
	// ];

	const [totalRate, setTotalRate] = useState(
		reviewers?.reduce((acc, reviewer) => acc + reviewer.rating, 0) /
			reviewers?.length
	);

	return (
		<div className={styles.patients_reviews}>
			<h3>{Tr.tr("patient:DoctorDetails:Patients_Reviews")}</h3>

			{/* Rates */}
			<div className={styles.rates_holder}>
				{/* Total Rate  */}
				<div className={styles.total_rate}>
					<div className={styles.rate_val}>{totalRate ? totalRate : 0}</div>
					<Rate rateVal={totalRate} readOnly={true} />
					<div className={styles.ratings}>
						{reviewers.length} {Tr.tr("patient:DoctorDetails:Ratings")}
					</div>
				</div>

				{/* Progress Bar Rate */}
				<div className={styles.rate_progress}>
					{reviewsProgress.map((review, index) => (
						<ProgressBarWrapper
							value={review.value}
							status={review.status}
							key={index}
							showStatus={true}
							showVal={true}
						/>
					))}
				</div>
			</div>

			{/* patients Comments */}
			<div className={styles.comments}>
				<div
					className={`${styles.comments_rows} ${
						showAll ? styles.showAll_reviews : ""
					}`}>
					{reviewers?.length > 0 ? (
						reviewers?.map((reviewer) => (
							<div className={styles.comment_row} key={reviewer.id}>
								{reviewer?.message_id?.author_id?.image ? (
									<div className={styles.reviewer_img}>
										<img
											src={reviewer?.message_id?.author_id?.image}
											width={55}
											height={55}
											alt=""
										/>
									</div>
								) : (
									<div className={styles.reviewer_chars}>
										{reviewer?.message_id?.author_id.name
											.split(" ")
											.map((n) => n[0])
											.join("")}
									</div>
								)}
								{/* <div className={styles.reviewer_chars}>
								{reviewer?.message_id?.author_id.name
									.split(" ")
									.map((n) => n[0])
									.join("")}
							</div> */}
								<div className={styles.right}>
									<div className={styles.reviewer_name}>
										{reviewer?.message_id?.author_id.name}
									</div>
									<div className={styles.rate_date}>
										<Rate rateVal={reviewer.rating} readOnly={true} />
										<div className={styles.date}>
											{reviewer?.message_id.date}
										</div>
									</div>
									<div
										className={styles.reviewer_comment}
										dangerouslySetInnerHTML={{
											__html: reviewer?.message_id?.body,
										}}
									/>
									{/* {reviewer.message_id.body}
								</div> */}
								</div>
							</div>
						))
					) : (
						<p className={styles.no_reviews}>
							{Tr.tr("patient:DoctorDetails:No_Reviews")}
						</p>
					)}
				</div>

				{/* Show All Reviews */}
				{reviewers?.length > 0 && (
					<Button
						type="button"
						label={
							showAll
								? Tr.tr("patient:DoctorDetails:Show_Less_Reviews")
								: Tr.tr("patient:DoctorDetails:Show_All_Reviews")
						}
						className={styles.showAll_btn}
						onClick={() => setShowAll(!showAll)}
					/>
				)}
			</div>
		</div>
	);
};

export default PatientsReviews;

import OTPInput from "react-otp-input";

// Components
import ErrorUI from "components/Common/UI/ErrorUI/ErrorUI";

// Styles
import styles from "./OtpInputs.module.scss";

export function OtpInputs({ ...props }) {
	return (
		<div className="input_holder">
			<div className={styles.field_holder}>
				{props.inputLabel && (
					<h6 className={props.required ? "required" : ""}>
						{props.inputLabel}
					</h6>
				)}
				<OTPInput
					value={props.inputVal}
					onChange={props.otpHandler}
					numInputs={props.inputsNum}
					renderInput={(props) => (
						<div className={styles.otp_inpt}>
							<input {...props} />
						</div>
					)}
					containerStyle={`${styles.otp_inputs} ${
						props.inputError ? styles.otp_input_error : ""
					}`}
					placeholder={props.placeholder}
					shouldAutoFocus
				/>
			</div>
			{props.inputError && (
				<ErrorUI errorMsg={props.inputError} withBG={props.withBG} />
			)}
		</div>
	);
}

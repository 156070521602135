import { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { TabPanel, TabView } from "primereact/tabview";
import { useMutation } from "react-query";
import moment from "moment";

// import Swiper core and required modules
import { Autoplay, Navigation } from "swiper/modules";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";

// Translation
import { useTranslation } from "react-i18next";
import { Tr } from "utils/i18n";

// Styles
import styles from "./ScheduleData.module.scss";

const ScheduleData = ({ scheduleInfo }) => {
	const { i18n } = useTranslation();
	const currLang = i18n.language;

	// Tabs Index
	const [activeTabIndex, setActiveTabIndex] = useState(0);

	// Slide Index
	const [activeSlideIndex, setActiveSlideIndex] = useState(0);

	// Tabs
	const tabs = [
		{
			label: Tr.tr("baisc:All"),
			value: "All",
		},
		{
			label: Tr.tr("basic:In_Clinic"),
			value: "In Clinic",
		},
		{
			label: Tr.tr("basic:Video_Call"),
			value: "Video Call",
		},
	];

	const [state, setState] = useState([]);

	console.log(scheduleInfo);
	if (!scheduleInfo || scheduleInfo.length === 0) {
		return <div>Loading...</div>;
	}

	return (
		<div className={styles.schedule_data}>
			{/* Navigation */}
			<div className={styles.title_arrows}>
				<Button
					icon="pi-angle-left"
					className="schedule-swiper-button-prev"
					onClick={() => setActiveTabIndex(0)}
				/>
				<h2>{scheduleInfo[activeSlideIndex]?.day} Schedule</h2>
				<Button
					icon="pi-angle-right"
					className="schedule-swiper-button-next"
					onClick={() => setActiveTabIndex(0)}
				/>
			</div>

			{/* Appointments Type */}
			<TabView
				activeIndex={activeTabIndex}
				onTabChange={(e) => {
					setActiveTabIndex(e.index);
				}}
				className={styles.appointments_type}>
				{tabs.map((tab) => {
					return <TabPanel key={tab.value} header={tab.label}></TabPanel>;
				})}
			</TabView>

			<Swiper
				key={currLang} // To re-render this component with language change
				dir={currLang === "ar" ? "rtl" : "ltr"}
				// install Swiper modules
				modules={[Navigation, Autoplay]}
				slidesPerView={1}
				spaceBetween={0}
				loopedSlides={1}
				initialSlide={0}
				loop={false}
				centeredSlides={true}
				autoplay={false}
				navigation={{
					nextEl: ".schedule-swiper-button-next",
					prevEl: ".schedule-swiper-button-prev",
				}}
				onSlideChange={(swiper) => {
					// Access the active slide index
					console.log("Active Slide Index:", swiper.activeIndex);
					setActiveSlideIndex(swiper.activeIndex);
				}}
				breakpoints={{
					1024: {
						slidesPerView: 1,
					},
					992: {
						slidesPerView: 1,
					},
					575: {
						slidesPerView: 1,
					},
					0: {
						slidesPerView: 1,
					},
				}}>
				{scheduleInfo.map((info, index) => (
					<SwiperSlide key={index}>
						<div className={styles.slide_content}>
							<div className={styles.schedule_status}>
								<div className={`${styles.status_box} ${styles.waiting}`}>
									<span className={styles.txt}>{Tr.tr("basic:Waiting")}</span>
									<span className={styles.val}>
										{/* {info?.slideData[activeTabIndex]?.waiting} */}
									</span>
								</div>
								<div className={`${styles.status_box} ${styles.done}`}>
									<span className={styles.txt}>{Tr.tr("basic:Done")}</span>
									<span className={styles.val}>
										{/* {info.slideData[activeTabIndex]?.done} */}
									</span>
								</div>
							</div>

							<h6 className={styles.appointments_count}>
								{info.appointments[tabs[activeTabIndex].value]?.length}{" "}
								{Tr.tr("basic:Appointments")}
							</h6>

							<div className={styles.appointments}>
								{info.appointments[tabs[activeTabIndex].value]?.map(
									(appointment, index) => {
										const location =
											appointment?.type === "in_clinic"
												? "In Clinic"
												: "Video Call";
										const dateFromString = appointment?.date_from
											? new Date(appointment.date_from)
											: null;

										const padWithZero = (number) => {
											return number.toString().padStart(2, "0");
										};

										const appointmentTime = dateFromString
											? `${dateFromString.getHours()}:${padWithZero(
													dateFromString.getMinutes()
											  )} ${dateFromString.getHours() >= 12 ? "PM" : "AM"}`
											: "Invalid date";
										return (
											<div
												className={`${styles.appointment_box} ${
													appointment.type === "in_clinic"
														? styles.inClinic
														: styles.videoCall
												}`}
												key={index}>
												<div className={styles.left}>
													{appointment.type === "in_clinic" ? (
														<img src="/img/hospital-gray.svg" alt="" />
													) : (
														<img src="/img/video-gray.svg" alt="" />
													)}
													<span>{appointmentTime}</span>
												</div>
												<div className={styles.right}>
													<h5>{appointment?.partner_id?.name}</h5>
													<p>{location}</p>
												</div>
											</div>
										);
									}
								)}
							</div>
						</div>
					</SwiperSlide>
				))}
			</Swiper>
		</div>
	);
};

export default ScheduleData;

import { useEffect, useState } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { useQuery } from "react-query";

// Components
import {
	CheckBox,
	FilterRange,
	SearchInput,
} from "components/Common/Form/Inputs";
import Rate from "components/Common/UI/Rate/Rate";

// Api
import { getLanguages, getTitles } from "api/filters";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./FilterDoctors.module.scss";

const FilterDoctors = ({
	updateFilters,
	globalFilter,
	appendToURL,
	removeFromURL,
}) => {
	const [selectedTitles, setSelectedTitles] = useState([]);
	const [geneicFiltered, setGeneicFiltered] = useState({
		title: [],
		lang_ids: [],
		gender: [],
		fees: null,
	});
	const [selectedLanguages, setSelectedLanguages] = useState([]);
	const [selectedAvailabilities, setSelectedAvailabilities] = useState([]);
	const [selectedNationalities, setSelectedNationalities] = useState([]);
	const [selectedGender, setSelectedGender] = useState([]);
	const [selectedReviews, setSelectedReviews] = useState([]);

	// Search
	const [searchQuery, setSearchQuery] = useState("");

	// View All
	const [heightAuto, setHeightAuto] = useState(false);

	const [initMinMax, setInitMinMax] = useState({
		fees: null,
	});

	const { data: titles, isLoading: titleLoading } = useQuery(
		"titles",
		getTitles,
		{
			select: (response) => {
				return response?.data;
				//return response?.data?.map( spec => ({...spec,label:spec.name,value:spec.name}) )
			},
		}
	);
	const { data: languages, isLoading: languagesLoading } = useQuery(
		"languages",
		getLanguages,
		{
			select: (response) => {
				return response?.data;
				//return response?.data?.map( spec => ({...spec,label:spec.name,value:spec.name}) )
			},
		}
	);

	useEffect(() => {
		// if (ref.current) {
		// 	return
		// }
		// if(citiesLoading || countriesLoading || specialtiesLoading) {
		// 	ref.current = false
		// }
		// else {
		// 	ref.current = true
		// }

		const urlParams = new URLSearchParams(window.location.search);
		let gender = urlParams.get("gender");
		let title = urlParams.get("title");
		let lang_ids = urlParams.get("lang_ids");
		let min = urlParams.get("min");
		let max = urlParams.get("max");

		if (min || max) {
			let newFees = {};

			if (min && (!initMinMax.fees || !initMinMax.fees.min)) {
				newFees.min = +min;
			}

			if (max && (!initMinMax.fees || !initMinMax.fees.max)) {
				newFees.max = +max;
			}

			if (initMinMax.fees) {
				if (min && initMinMax.fees.max) {
					newFees = { ...newFees, max: initMinMax.fees.max };
				}

				if (max && initMinMax.fees.min) {
					newFees = { ...newFees, min: initMinMax.fees.min };
				}
			}

			setInitMinMax({ fees: { ...initMinMax.fees, ...newFees } });
		}

		if (title) {
			// find the title name by id
			const titleIds = title.split(",").map((item) => +item);
			const names = [];
			titleIds.forEach((element) => {
				let data = titles?.find((spec) => spec.id === element);
				names.push({
					id: data?.id,
					value: data?.name,
				});
			});
			setSelectedTitles(names);

			setGeneicFiltered({
				title: titleIds,
			});
		}
		if (lang_ids) {
			const languageIds = lang_ids.split(",").map((item) => +item);
			const names = [];
			languageIds.forEach((element) => {
				let data = languages?.find((spec) => spec.id === element);
				names.push({
					id: data?.id,
					value: data?.name,
				});
			});
			setSelectedLanguages(names);
			setGeneicFiltered({
				lang_ids: languageIds,
			});
		}
		if (gender) {
			const genders = gender.split(",");
			let names = [];
			genders.forEach((element) => {
				if (element === "female" || element === "male") {
					names.push({
						value: element,
					});
				}
			});
			setSelectedGender(names);
			setGeneicFiltered({
				gender: genders,
			});
		}
	}, [titles, languages]);

	useEffect(() => {
		globalFilter(geneicFiltered);
	}, [geneicFiltered]);

	// const titles = [
	// 	{
	// 		label: "Professor",
	// 		value: "Professor",
	// 	},
	// 	{
	// 		label: "Lecturer",
	// 		value: "Lecturer",
	// 	},
	// 	{
	// 		label: "Consultant",
	// 		value: "Consultant",
	// 	},
	// 	{
	// 		label: "Specialist",
	// 		value: "Specialist",
	// 	},
	// ];

	const availabilities = [
		{
			label: "Available Today",
			value: "Available Today",
		},
		{
			label: "Available Tomorrow",
			value: "Available Tomorrow",
		},
		{
			label: "Available in next 7 days",
			value: "Available in next 7 days",
		},
	];

	const nationalities = [
		{
			label: "Arabic",
			value: "Arabic2",
		},
		{
			label: "Foreign",
			value: "Foreign",
		},
	];

	const genderTypes = [
		{
			name: "female",
		},
		{
			name: "male",
		},
	];

	const reviews = [
		{
			value: 5,
		},
		{
			value: 4,
		},
		{
			value: 3,
		},
		{
			value: 2,
		},
	];
	const onSelectionChange = (
		e,
		selectedOptions,
		setValue,
		filterType = "title"
	) => {
		let _selectedOptions = [...selectedOptions];
		let updateValue = {
			title: geneicFiltered["title"],
			lang_ids: geneicFiltered["lang_ids"],
			gender: geneicFiltered["gender"],
		};
		if (e.checked) {
			_selectedOptions.push({ value: e.value, id: e.target.id });
			if (filterType === "gender") {
				if (!Array.isArray(updateValue[filterType])) {
					updateValue[filterType] = [];
				}

				updateValue[filterType] = [...updateValue[filterType], e.value];
				appendToURL("gender", [...updateValue[filterType]]);
			} else {
				// updateValue[filterType] = [...geneicFiltered[filterType], e.target.id];
				updateValue[filterType] = Array.isArray(geneicFiltered[filterType])
					? [...geneicFiltered[filterType], e.target.id]
					: [e.target.id];
				appendToURL(filterType, [updateValue[filterType]]);
			}
		} else {
			for (let i = 0; i < _selectedOptions.length; i++) {
				const selectedOption = _selectedOptions[i];
				if (selectedOption.value === e.value) {
					if (filterType === "gender") {
						updateValue[filterType] = geneicFiltered[filterType].filter(
							(item) => item !== e.value
						);
						removeFromURL("gender");
						appendToURL("gender", updateValue[filterType]);
					} else {
						updateValue[filterType] = geneicFiltered[filterType]?.filter(
							(item) => item !== e.target.id
						);
						removeFromURL(filterType);
						appendToURL(filterType, updateValue[filterType]);
					}
					_selectedOptions.splice(i, 1);
					break;
				}
			}
		}
		setValue(updateValue);

		setSelectedTitles(_selectedOptions);
		setSelectedLanguages(_selectedOptions);
		setSelectedAvailabilities(_selectedOptions);
		setSelectedNationalities(_selectedOptions);
		setSelectedGender(_selectedOptions);
		setSelectedReviews(_selectedOptions);

		// Call the updateFilters function with the updated filters
		updateFilters({
			titles: _selectedOptions,
			languages: _selectedOptions,
			availabilities: _selectedOptions,
			nationalities: _selectedOptions,
			gender: _selectedOptions,
			reviews: _selectedOptions,
		});
	};
	const setFees = (value) => {
		appendToURL("min", value[0]);
		appendToURL("max", value[1]);

		setGeneicFiltered({
			...geneicFiltered,
			fees: {
				min: value[0],
				max: value[1],
			},
		});
	};
	// Filter Languages
	const filteredLanguages = languages?.filter((language) =>
		language.name?.toLowerCase().includes(searchQuery?.toLowerCase())
	);
	return (
		<div className={styles.filter_doctors}>
			<Accordion multiple>
				<AccordionTab header={Tr.tr("patient:FindADoctor:Title")}>
					<div className={styles.check_boxes}>
						{titles?.map((title) => (
							<CheckBox
								name="titles"
								label={title.name}
								value={title.name}
								handleChange={(e) =>
									onSelectionChange(
										e,
										selectedTitles,
										setGeneicFiltered,
										"title"
									)
								}
								checked={selectedTitles.some(
									(item) => item?.value === title.name
								)}
								id={title.id}
								key={title.id}
							/>
						))}
					</div>
				</AccordionTab>
				<AccordionTab header={Tr.tr("patient:FindADoctor:Language")}>
					<div className={styles.lang_box}>
						<SearchInput
							inputLabel=""
							inputName="search"
							placeHolder="Search"
							inputVal={searchQuery}
							changeHandler={(e) => setSearchQuery(e.target.value)}
						/>
						<div
							className={`${styles.check_boxes} ${
								heightAuto ? styles.height_auto : ""
							}`}>
							{filteredLanguages?.map((lang) => (
								<CheckBox
									name="languages"
									label={lang.name}
									value={lang.name}
									handleChange={(e) =>
										onSelectionChange(
											e,
											selectedLanguages,
											setGeneicFiltered,
											"lang_ids"
										)
									}
									checked={selectedLanguages.some(
										(item) => item.value === lang.name
									)}
									id={lang.id}
									key={lang.id}
								/>
							))}
						</div>
						<Button
							type="button"
							label={heightAuto ? "View Less.." : "View all.."}
							className={styles.view_all}
							onClick={() => setHeightAuto(!heightAuto)}
						/>
					</div>
				</AccordionTab>
				<AccordionTab header={Tr.tr("patient:FindADoctor:Availability")}>
					<div className={styles.check_boxes}>
						{availabilities.map((availability) => (
							<CheckBox
								name="availabilities"
								label={availability.label}
								value={availability.value}
								handleChange={(e) =>
									onSelectionChange(
										e,
										selectedAvailabilities,
										setGeneicFiltered
									)
								}
								checked={selectedAvailabilities.some(
									(item) => item.value === availability.value
								)}
								key={availability.value}
							/>
						))}
					</div>
				</AccordionTab>
				<AccordionTab header={Tr.tr("patient:FindADoctor:Nationality")}>
					<div className={styles.check_boxes}>
						{nationalities.map((nationality) => (
							<CheckBox
								name="nationalities"
								label={nationality.label}
								value={nationality.value}
								handleChange={(e) =>
									onSelectionChange(e, selectedNationalities, setGeneicFiltered)
								}
								checked={selectedNationalities.some(
									(item) => item.value === nationality.value
								)}
								key={nationality.value}
							/>
						))}
					</div>
				</AccordionTab>
				<AccordionTab header={Tr.tr("patient:FindADoctor:Gender")}>
					<div className={styles.check_boxes}>
						{genderTypes.map((gender) => (
							<CheckBox
								name="gender"
								label={gender.name}
								value={gender.name}
								handleChange={(e) =>
									onSelectionChange(
										e,
										selectedGender,
										setGeneicFiltered,
										"gender"
									)
								}
								checked={selectedGender.some(
									(item) => item.value === gender.name
								)}
								key={gender.name}
							/>
						))}
					</div>
				</AccordionTab>
				<AccordionTab header={Tr.tr("patient:FindADoctor:Price_Range")}>
					<FilterRange
						from={100}
						to={500}
						initMinMax={initMinMax}
						setFees={setFees}
					/>
				</AccordionTab>
				<AccordionTab header={Tr.tr("patient:FindADoctor:Review")}>
					<div className={styles.check_boxes}>
						{reviews.map((review) => (
							<div
								className={`check_box ${styles.rate_checkbox}`}
								key={review.value}>
								<Checkbox
									name="review"
									value={review.value}
									label={review.label}
									onChange={(e) =>
										onSelectionChange(e, selectedReviews, setGeneicFiltered)
									}
									checked={selectedReviews.some(
										(item) => item.value === review.value
									)}
								/>
								<Rate rateVal={review.value} readOnly={true} />
							</div>
						))}
					</div>
				</AccordionTab>
			</Accordion>
		</div>
	);
};

export default FilterDoctors;

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useMutation } from "react-query";

// Apis
import { updateMeApi } from "api/auth";

// Components
import {
	CalendarInput,
	SelectDropdown,
	TextAreaInput,
	TextInput,
	MultiSelectDropdown,
} from "components/Common/Form/Inputs";
import GradientButton from "components/Common/UI/GradientButton/GradientButton";
import { toast } from "react-hot-toast";
import { NumericInput } from "components/Common/Form/Inputs/NumericInput/NumericInput";

// Utils
import { getFormErrorMessage } from "utils/FormField";
import { formatDate } from "utils/formatDate";
import { dtoMapper } from "utils/DtoMapper";

// Api
import { specialtiesAPI, titlesAPI } from "api/specialties";
import { getLangaugesAPI } from "api/language";
import { citiesAPI } from "api/location";

// Translation
import { Tr } from "utils/i18n";

// Styles
import styles from "./PersonalDetails.module.scss";

const PersonalDetails = () => {
	const navigate = useNavigate();

	// state managements
	const [cities, setCities] = useState([]);
	const [specialities, setSpecialities] = useState([]);
	const [titles, setTitles] = useState([]);
	const [languages, setLanguages] = useState([]);
	const [savedUserName, setSavedUserName] = useState(
		localStorage.getItem("user_name")
	);
	const [defaultTitle, setDefaultTitle] = useState("");

	// Handle Image Change
	const [image, setImage] = useState(null);
	const [imageFile, setImageFile] = useState(null);
	const onImageChange = (event) => {
		if (event.target.files && event.target.files[0]) {
			let img = event.target.files[0];
			setImage(URL.createObjectURL(img));
		}
		// send image to server as base64
		let reader = new FileReader();
		reader.onload = function (e) {
			// e.target.result;
			setImageFile(e.target.result);
		};
		reader.readAsDataURL(event.target.files[0]);
	};

	// data fetching
	const { mutate: getSpecialities } = useMutation(specialtiesAPI, {
		onSuccess: (data) => {
			setSpecialities(dtoMapper(data.data));
		},
		// onError: (error) => {
		// 	toast.error(error.response.data.message);
		// },
	});

	const { mutate: getTitles } = useMutation(titlesAPI, {
		onSuccess: (data) => {
			setTitles(dtoMapper(data.data));
		},
		// onError: (error) => {
		// 	toast.error(error.response.data.message);
		// },
	});

	const { mutate: getLanguages } = useMutation(getLangaugesAPI, {
		onSuccess: (data) => {
			setLanguages(dtoMapper(data.data));
		},
		// onError: (error) => {
		// 	toast.error(error.response.data.message);
		// },
	});

	const { mutate: getCities } = useMutation(citiesAPI, {
		onSuccess: (data) => {
			setCities(dtoMapper(data.data));
		},
		// onError: (error) => {
		// 	toast.error(error.response.data.message);
		// },
	});

	useEffect(() => {
		getSpecialities();
		getTitles();
		getLanguages();
		getCities();
	}, []);

	// Gender
	const genders = [
		{
			label: "Male",
			value: "Male",
		},
		{
			label: "Female",
			value: "Female",
		},
	];

	const { mutate: sendPersonalDetails } = useMutation(updateMeApi, {
		onSuccess: (data) => {
			// console.log(data);
			navigate("/doctor/sign-up/registeration-documents");
		},
		// onError: (error) => {
		// 	console.log(error);
		// 	toast.error(error.response.data.message);
		// },
	});
	// Formik
	const formik = useFormik({
		initialValues: {
			title: defaultTitle,
			name: savedUserName,
			speciality: null,
			language: null,
			experience: null,
			city: null,
			gender: null,
			birthDate: "",
			aboutMe: "",
		},
		validate: (data) => {
			let errors = {};

			if (!data.title) {
				errors.title = "Title is required.";
			}

			if (!data.name) {
				errors.name = "Name is required.";
			}

			if (!data.language) {
				errors.language = "language is required.";
			}

			if (!data.speciality) {
				errors.language = "speciality is required.";
			}

			return errors;
		},
		onSubmit: async (data, { setSubmitting }) => {
			// console.log("submitted");
			// setTimeout(() => {
			// 	setSubmitting(false);
			// 	navigate("/doctor/sign-up/registeration-documents");
			// 	formik.resetForm();
			// }, 2000);
			// convert into base64

			var langs = [];
			for (let item of data?.language)
				langs.push(languages.find((lang) => item === lang.value)?.id);

			const payload = {
				...data,
				signup_stage: 1,
				gender: data?.gender ? data?.gender?.toLowerCase() : null,
				image: imageFile?.split("base64,")[1],
				years_of_experience: +data?.experience,
				description: data?.aboutMe ? data?.aboutMe : null,
				lang_ids: langs,
				doctor_specialty_ids: [
					specialities.find(
						(speciality) => speciality.value === data?.speciality
					)?.id,
				],
				title: titles.find((title) => title.value === data?.title)?.id,
				state_id: cities.find((city) => city.value === data?.city)?.id,
				birth_date: data?.birthDate ? formatDate(data.birthDate) : null,
			};
			// console.log(payload);
			await sendPersonalDetails(payload);
		},
	});

	useEffect(() => {
		const updateUserName = () => {
			const userName = localStorage.getItem("user_name") || "";
			setSavedUserName(userName);
			formik.setFieldValue("name", savedUserName);
		};

		updateUserName();
	}, []);

	useEffect(() => {
		if (titles && titles.length > 0) {
			setDefaultTitle(titles[0].value);
			formik.setFieldValue("title", defaultTitle);
		}
	}, [titles]);

	return (
		<div className={styles.personal_details}>
			<div className={styles.head}>
				<h2>{Tr.tr("basic:Personal_Details")}</h2>
				<p>This is a description.</p>
			</div>

			<form className={styles.form_holder} onSubmit={formik.handleSubmit}>
				{/* Upload Photo */}
				<div className={styles.profile_photo}>
					<h6>{Tr.tr("basic:Profile_Photo")}</h6>
					<label className={styles.upload_img}>
						<input type="file" name="profileImg" onChange={onImageChange} />
						<div className={styles.img_holder}>
							{image && image.length ? (
								<img src={image} alt="" className={styles.uploadedImg} />
							) : (
								<img
									src="/img/plus-gray.svg"
									alt=""
									className={styles.uploadIcon}
								/>
							)}
						</div>
					</label>
				</div>

				{/* Inputs */}
				<div className={styles.inputs_wrapper}>
					<div className="row">
						{/* Title & Name */}
						<div className="col-md-12">
							<div className="row">
								{/* Title */}
								<div className="col-5 col-lg-4 col-xl-3 m-b-20">
									<SelectDropdown
										inputLabel={Tr.tr("basic:Title")}
										inputName="title"
										// placeHolder="Dr."
										options={titles}
										inputVal={formik.values.title}
										changeHandler={(e) =>
											formik.setFieldValue("title", e.value)
										}
										appendTo="self"
										required={true}
									/>
								</div>

								{/* Name */}
								<div className="col-7 col-lg-8 col-xl-9 m-b-20">
									<TextInput
										inputLabel={Tr.tr("basic:Name")}
										inputName="name"
										// placeHolder={savedUserName}
										inputVal={formik.values.name}
										changeHandler={formik.handleChange}
										inputError={getFormErrorMessage("name", formik)}
										required={true}
									/>
								</div>
							</div>
						</div>

						{/* Specialities */}
						<div className="col-md-12 m-b-20">
							<SelectDropdown
								inputLabel={Tr.tr("basic:Specialities")}
								inputName="speciality"
								placeHolder={Tr.tr("basic:Choose_Your_Specialties")}
								options={specialities}
								inputVal={formik.values.speciality}
								changeHandler={(e) =>
									formik.setFieldValue("speciality", e.value)
								}
								appendTo="self"
								inputError={getFormErrorMessage("speciality", formik)}
								required={true}
							/>
						</div>

						{/* Languages */}
						<div className="col-md-12 m-b-20">
							<MultiSelectDropdown
								inputLabel={Tr.tr("basic:Languages")}
								inputName="specialties"
								placeHolder={Tr.tr("basic:Languages")}
								options={languages}
								inputVal={formik.values.language}
								changeHandler={(e) => formik.setFieldValue("language", e.value)}
								appendTo="self"
								filter={false}
								display="chip"
								required={true}
							/>

							<div className={styles.hint}>
								{Tr.tr("basic:Choose_Languages_Communicating_In")}
							</div>
						</div>

						{/* Experience & City */}
						<div className="col-md-12">
							<div className="row">
								{/* Experience */}
								<div className="col-md-6 m-b-20">
									<NumericInput
										inputLabel={Tr.tr("basic:Years_Of_Experience")}
										inputName="experience"
										placeHolder={Tr.tr("basic:Enter_Number_Of_Years")}
										inputVal={formik.values.experience}
										changeHandler={formik.handleChange}
										inputError={getFormErrorMessage("experience", formik)}
										maxValue={100}
									/>
								</div>

								{/* City */}
								<div className="col-md-6 m-b-20">
									<SelectDropdown
										inputLabel={Tr.tr("basic:City")}
										inputName="city"
										placeHolder={Tr.tr("basic:Select_City")}
										options={cities}
										inputVal={formik.values.city}
										changeHandler={(e) => formik.setFieldValue("city", e.value)}
										appendTo="self"
										inputError={getFormErrorMessage("city", formik)}
									/>
								</div>
							</div>
						</div>

						{/* Gender & Date Of Birth */}
						<div className="col-md-12">
							<div className="row">
								{/* Gender */}
								<div className="col-md-6 m-b-20">
									<SelectDropdown
										inputLabel={Tr.tr("basic:Gender")}
										inputName="gender"
										placeHolder={Tr.tr("basic:Select_Gender")}
										options={genders}
										inputVal={formik.values.gender}
										changeHandler={(e) =>
											formik.setFieldValue("gender", e.value)
										}
										appendTo="self"
										inputError={getFormErrorMessage("gender", formik)}
									/>
								</div>

								{/* Date Of Birth */}
								<div className="col-md-6 m-b-20">
									<CalendarInput
										inputLabel={Tr.tr("basic:Date_Of_Birth")}
										inputName="birthDate"
										placeHolder="DD/MM/YYYY"
										inputVal={formik.values.birthDate}
										changeHandler={(e) =>
											formik.setFieldValue("birthDate", e.value)
										}
										appendTo="self"
										showIcon={true}
										view="date"
										dateFormat="mm/dd/yy"
									/>
								</div>
							</div>
						</div>

						{/* About Me */}
						<div className="col-md-12">
							<TextAreaInput
								inputLabel={Tr.tr("basic:About_Me")}
								inputName="aboutMe"
								placeHolder={Tr.tr("basic:About_Me")}
								inputVal={formik.values.aboutMe}
								changeHandler={formik.handleChange}
								inputError={getFormErrorMessage("aboutMe", formik)}
							/>
						</div>
					</div>
				</div>
				<GradientButton
					btn
					type="submit"
					label={Tr.tr("basic:Continue")}
					disabled={!(formik.isValid && formik.dirty) || formik.isSubmitting}
					isLoading={formik.isSubmitting}
					classes="w-100"
				/>
			</form>
		</div>
	);
};

export default PersonalDetails;

import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
import { useFormik } from "formik";

// Components
import GrayButton from "components/Common/UI/GrayButton/GrayButton";
import GradientButton from "components/Common/UI/GradientButton/GradientButton";
import { SelectDropdown, TextInput } from "components/Common/Form/Inputs";

// Utils
import { getFormErrorMessage } from "utils/FormField";

// Translation
import { useTranslation, withTranslation } from "react-i18next";
import { Tr } from "utils/i18n";

// Styles
import styles from "./AddPatientDrawer.module.scss";
import { useMutation, useQueryClient } from "react-query";
import { addPatientAPI } from "api/doctors";
import toast from "react-hot-toast";

const AddPatientDrawer = ({ ...props }) => {
    const { i18n } = useTranslation();
    const currLang = i18n.language;
    const queryClient = useQueryClient();

    const { mutate: addPatient, isSuccess, isError } = useMutation(addPatientAPI, {
        onSuccess: (data) => {
            toast.success("the patient has been added Successfully");
            queryClient.invalidateQueries('patients');
            // props.updateView();
            props.hideHandler();
        },
        onError: (error) => {
            toast.error(error?.response?.data?.message);
        },
    });

    // Age
    const ageOptions = [
        { label: "26", value: 26 },
        { label: "30", value: 30 },
    ];

    // Gender
    const genders = [
        { label: "Male", value: "male" },
        { label: "Female", value: "female" },
    ];

    // Blood Type
    const bloodTypes = [
        { label: "A+", value: "A+" },
        { label: "B-", value: "B-" },
    ];

    // Formik
    const formik = useFormik({
        initialValues: {
            patientName: "",
            mobileNumber: "",
            emailAddress: "",
            age: null,
            gender: null,
            bloodType: null,
            weight: null,
            height: null,
        },
        validate: (data) => {
            let errors = {};

            if (!data.patientName) {
                errors.patientName = "Patient name is required.";
            }

            return errors;
        },
        onSubmit: (data, { setSubmitting }) => {
            addPatient({
                id: props.userId,
                data: {
                    name: data?.patientName,
                    email: data?.emailAddress,
                    phone: data?.mobileNumber,
                    age: data?.age,
                    blood_type: data?.bloodType,
                    gender: data?.gender,
                    weight: data?.weight,
                    height: data?.height
                }
            });
            if (isSuccess) {
                setSubmitting(false)
            }

        },
    });

    return (
        <Sidebar
            visible={props.visible}
            position={currLang === "ar" ? "left" : "right"}
            onHide={props.hideHandler}
            className={styles.add_drawer_holder}
            maskClassName={styles.add_drawer}
            blockScroll={true}>
            <div className={styles.drawer_header}>
                <h2>{Tr.tr("basic:Add_Patient")}</h2>
                <Button
                    type="button"
                    icon="pi pi-times"
                    className={styles.close_btn}
                    onClick={props.hideHandler}></Button>
            </div>

            <form className={styles.drawer_form} onSubmit={formik.handleSubmit}>
                <div className={styles.drawer_content}>
                    <h3>{Tr.tr("doctor:Patients:Patient_Details")}</h3>

                    <div className="row">
                        {/* Patient Name */}
                        <div className="col-md-12 mb-4">
                            <TextInput
                                inputLabel={Tr.tr("doctor:Patients:Patient_Name")}
                                inputName="patientName"
                                placeHolder={Tr.tr("doctor:Patients:Enter_Patient_Name")}
                                inputVal={formik.values.patientName}
                                changeHandler={formik.handleChange}
                                inputError={getFormErrorMessage("patientName", formik)}
                            />
                        </div>

                        {/* Mobile Number */}
                        <div className="col-md-12 mb-4">
                            <TextInput
                                inputLabel={Tr.tr("basic:Mobile_Number")}
                                inputName="mobileNumber"
                                placeHolder={Tr.tr("basic:Enter_Mobile_Number")}
                                inputVal={formik.values.mobileNumber}
                                changeHandler={formik.handleChange}
                                inputError={getFormErrorMessage("mobileNumber", formik)}
                            />
                        </div>

                        {/* Email Address */}
                        <div className="col-md-12 mb-4">
                            <TextInput
                                inputLabel={Tr.tr("basic:Email_Address")}
                                inputName="emailAddress"
                                placeHolder={Tr.tr("basic:Enter_Email_Address")}
                                inputVal={formik.values.emailAddress}
                                changeHandler={formik.handleChange}
                                inputError={getFormErrorMessage("emailAddress", formik)}
                            />
                        </div>

                        {/* Age */}
                        <div className="col-md-12 mb-4">
                            <SelectDropdown
                                inputLabel={Tr.tr("basic:Age")}
                                inputName="age"
                                placeHolder={Tr.tr("basic:Age")}
                                options={ageOptions}
                                inputVal={formik.values.age}
                                changeHandler={(e) => formik.setFieldValue("age", e.value)}
                                appendTo={document.body}
                            />
                        </div>

                        {/* Gender */}
                        <div className="col-md-12 mb-4">
                            <SelectDropdown
                                inputLabel={Tr.tr("basic:Gender")}
                                inputName="gender"
                                placeHolder={Tr.tr("basic:Gender")}
                                options={genders}
                                inputVal={formik.values.gender}
                                changeHandler={(e) => formik.setFieldValue("gender", e.value)}
                                appendTo={document.body}
                            />
                        </div>

                        {/* Blood Type */}
                        <div className="col-md-12 mb-4">
                            <SelectDropdown
                                inputLabel={Tr.tr("basic:Blood_Type")}
                                inputName="bloodType"
                                placeHolder={Tr.tr("basic:Blood_Type")}
                                options={bloodTypes}
                                inputVal={formik.values.bloodType}
                                changeHandler={(e) =>
                                    formik.setFieldValue("bloodType", e.value)
                                }
                                appendTo={document.body}
                            />
                        </div>

                        {/* Weight */}
                        <div className="col-md-6 mb-4">
                            <TextInput
                                inputLabel={Tr.tr("basic:Weight")}
                                inputName="weight"
                                placeHolder={Tr.tr("basic:Weight")}
                                inputVal={formik.values.weight}
                                changeHandler={formik.handleChange}
                                inputError={getFormErrorMessage("weight", formik)}
                            />
                        </div>

                        {/* Height */}
                        <div className="col-md-6 mb-4">
                            <TextInput
                                inputLabel={Tr.tr("basic:Height")}
                                inputName="height"
                                placeHolder={Tr.tr("basic:Height")}
                                inputVal={formik.values.height}
                                changeHandler={formik.handleChange}
                                inputError={getFormErrorMessage("height", formik)}
                            />
                        </div>
                    </div>
                </div>

                <div className={styles.drawer_footer}>
                    <GrayButton
                        btn
                        type="button"
                        label={Tr.tr("basic:Discard")}
                        outlined
                        classes="p712"
                        buttonClicked={props.hideHandler}
                    />
                    <GradientButton
                        btn
                        type="submit"
                        label={Tr.tr("basic:Save")}
                        classes="p712"
                    />
                </div>
            </form>
        </Sidebar>
    );
};

export default withTranslation()(AddPatientDrawer);
